import {
  CloudUploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Upload,
  Popover,
  Form,
  Input,
  Slider,
  Typography,
} from "antd";
import React from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useSelector, useDispatch } from "react-redux";
import { setMicrositeDefaultStyle } from "../../../store/actions/SettingAction";
import { API } from "aws-amplify";
import axios from "axios";
import { invertColor } from "../../../utility/LightenDarkenColor";

export default function ThemeSettings() {
  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );

  const { roAdmin } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const content = (data) => {
    return (
      <div style={{ padding: "20px" }}>
        <HexColorPicker
          color={
            data === "baseColor"
              ? micrositeDefaultStyle?.themeStyle?.backgroundColor
              : data === "secondaryColor"
              ? micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor
              : "black"
          }
          onChange={(e) => handleSetColor(data, e)}
          style={{ border: "1px solid black", borderRadius: "8px" }}
        />
        <HexColorInput
          color={
            data === "baseColor"
              ? micrositeDefaultStyle?.themeStyle?.backgroundColor
              : data === "secondaryColor"
              ? micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor
              : "black"
          }
          style={{
            border: "1px solid black",
            borderRadius: "3px",
            marginTop: "5px",
            width: "100%",
            textAlign: "center",
          }}
          onChange={(e) => handleSetColor(data, e)}
          placeholder="Type a color"
        />
      </div>
    );
  };

  const handleSetColor = (data, e) => {
    if (data === "baseColor") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          themeStyle: {
            ...micrositeDefaultStyle?.themeStyle,
            backgroundColor: e,
          },
        })
      );
    } else if (data === "secondaryColor") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          themeStyle: {
            ...micrositeDefaultStyle?.themeStyle,
            secondaryBackgroundColor: e,
          },
        })
      );
    }
  };

  const uploadFile = async (options) => {
    API.get(
      "blustreamREST",
      `/getSignedPutURL?filename=${options.file.name}`
    ).then(async (response) => {
      await axios
        .put(response.url, options.file, {
          headers: {
            "Content-Type": options.file.type,
            "x-amz-acl": "public-read",
          },
        })
        .then((res) => {
          dispatch(
            setMicrositeDefaultStyle({
              ...micrositeDefaultStyle,
              themeStyle: {
                ...micrositeDefaultStyle?.themeStyle,
                backgroundImageUrl: response.end_url,
              },
            })
          );
        })
        .catch((error) => {
          new Error(error);
        });
    });
  };

  const deleteBackgroundImage = () => {
    dispatch(
      setMicrositeDefaultStyle({
        ...micrositeDefaultStyle,
        themeStyle: {
          ...micrositeDefaultStyle?.themeStyle,
          backgroundImageUrl: "",
        },
      })
    );
  };

  return (
    <>
      <p style={{ color: "#464646" }}>
        The color below is used as backgrounds for the template
      </p>

      <Row gutter={[10, 10]}>
        <Col span={24}>
          <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>
            Left Background
          </h3>
        </Col>
        <Col span={12} xs={24} sm={12} md={12} lg={24} xl={18} xxl={12}>
          <div className="white-box">
            <h4 style={{ textAlign: "center", paddingTop: 5 }}>
              Background Color
            </h4>
            <Row>
              <Col
                span={15}
                style={{
                  background:
                    micrositeDefaultStyle?.themeStyle?.backgroundColor,
                }}
              >
                <HexColorInput
                  className="colorpicker-input"
                  color={micrositeDefaultStyle?.themeStyle?.backgroundColor}
                  style={{
                    background:
                      micrositeDefaultStyle?.themeStyle?.backgroundColor,
                    color: `${invertColor(
                      micrositeDefaultStyle?.themeStyle?.backgroundColor
                    )}`,
                  }}
                  onChange={(e) => handleSetColor("baseColor", e)}
                  disabled={roAdmin}
                  placeholder="Type a color"
                />
              </Col>
              <Col span={9}>
                <Popover
                  trigger={roAdmin ? "" : "hover"}
                  content={() => content("baseColor")}
                >
                  <Button
                    style={{
                      background: "#124173",
                      border: "none",
                      color: "white",
                      width: "100%",
                      borderRadius: 0,
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Popover>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={24} style={{ marginTop: "20px" }}>
          <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>
            Right Background
          </h3>
        </Col>
        <Col span={12} xs={24} sm={12} md={12} lg={24} xl={18} xxl={12}>
          <div className="white-box">
            <h4 style={{ textAlign: "center", paddingTop: 5 }}>
              Background Color
            </h4>
            <Row>
              <Col
                span={15}
                style={{
                  background:
                    micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor,
                }}
              >
                <HexColorInput
                  className="colorpicker-input"
                  color={
                    micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor
                  }
                  style={{
                    background:
                      micrositeDefaultStyle?.themeStyle
                        ?.secondaryBackgroundColor,
                    color: `${invertColor(
                      micrositeDefaultStyle?.themeStyle
                        ?.secondaryBackgroundColor
                    )}`,
                  }}
                  onChange={(e) => handleSetColor("secondaryColor", e)}
                  disabled={roAdmin}
                  placeholder="Type a color"
                />
              </Col>
              <Col span={9}>
                <Popover
                  trigger={roAdmin ? "" : "hover"}
                  content={() => content("secondaryColor")}
                >
                  <Button
                    style={{
                      background: "#124173",
                      border: "none",
                      color: "white",
                      width: "100%",
                      borderRadius: 0,
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Popover>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={24} style={{ marginTop: "20px" }}>
          <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>
            Background Image
          </h3>
        </Col>
        <Col span={12} xs={24} sm={12} md={12} lg={24} xl={18} xxl={12}>
          <div className="white-box">
            <h4 style={{ textAlign: "center", paddingTop: 5 }}>
              Background Image
            </h4>
            <Row>
              <Col span={24}>
                {micrositeDefaultStyle?.themeStyle?.backgroundImageUrl
                  .length === 0 ? (
                  <Upload
                    customRequest={(e) => {
                      uploadFile(e);
                    }}
                    accept="image/*"
                  >
                    <Button
                      className="standard-button primary-dark-blue-button"
                      icon={<CloudUploadOutlined />}
                      style={{
                        background: "#124173",
                        border: "none",
                        color: "white",
                        width: "100%",
                        borderRadius: 0,
                      }}
                      disabled={roAdmin}
                    >
                      Upload Image
                    </Button>
                  </Upload>
                ) : (
                  <Button
                    className="standard-button primary-dark-blue-button"
                    icon={<DeleteOutlined />}
                    onClick={() => deleteBackgroundImage()}
                    style={{
                      background: "#124173",
                      border: "none",
                      color: "white",
                      width: "100%",
                      borderRadius: 0,
                    }}
                    disabled={roAdmin}
                  >
                    Remove Image
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>
                Background Image Size
              </h3>
            </Col>
            <Col span={24}>
              <Typography.Text level={5}>
                Width (in Percentage) :
              </Typography.Text>
              <Slider
                value={micrositeDefaultStyle?.themeStyle?.backgroundWidth}
                onChange={(value) => {
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      themeStyle: {
                        ...micrositeDefaultStyle?.themeStyle,
                        backgroundWidth: value,
                      },
                    })
                  );
                }}
                disabled={roAdmin}
              />
            </Col>
            <Col span={24}>
              <Typography.Text level={5}>
                Height (in Percentage) :
              </Typography.Text>
              <Slider
                value={micrositeDefaultStyle?.themeStyle?.backgroundHeight}
                onChange={(value) => {
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      themeStyle: {
                        ...micrositeDefaultStyle?.themeStyle,
                        backgroundHeight: value,
                      },
                    })
                  );
                }}
                disabled={roAdmin}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Form layout="vertical">
            <Form.Item
              label={
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Page Title
                </span>
              }
            >
              <Input
                className="standard-input"
                placeholder="Enter Your Page Title"
                onChange={(event) => {
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      micrositePageTitle: event.target.value,
                    })
                  );
                }}
                disabled={roAdmin}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
