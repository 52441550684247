import React from "react";
import CopyImg from "assets/Images/copy-icon.svg";
import { Button, Col, Input, Row, message, Skeleton } from "antd";
import { QRCode } from "react-qrcode-logo";
import { CloseOutlined } from "@ant-design/icons";
import SkeletonBlock from "commons/SkeletonBlock";
import { useSelector } from "react-redux";

const { TextArea } = Input;

export default function Publish({ url, loading, hide, formId }) {
  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );
  const embedIframeRef = `<iframe title="contactUserForm" class='gmap_iframe' frameborder='1' scrolling='yes'marginheight='0' marginwidth='0' height='500' width='100%' justify-content='center' src='${url}?z=14&amp;'></iframe>`;
  const scriptRef = `<script id="blustream-microsite-form" ORIGIN="${window.location.origin}" FORMID="${formId}" src="${window.location.origin}/FormBuilder.js"></script>`;

  const copyText = (name) => {
    if (name === "link") {
      navigator.clipboard.writeText(url);
      message.success("Link copied!");
    } else if (name === "iframe") {
      navigator.clipboard.writeText(embedIframeRef);
      message.success("Iframe copied!");
    } else if (name === "script") {
      navigator.clipboard.writeText(scriptRef);
      message.success("Script copied!");
    }
  };

  const downloadQRCode = (format) => {
    const qrCodeURL = document
      .getElementById("qr-code2")
      .toDataURL(`image/${format}`)
      .replace(`image/${format}`, "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `Form-QR-Code.${format}`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <div style={{ width: 500, position: "relative" }} className="publish">
      <Button
        style={{
          color: "#ffff",
          position: "absolute",
          right: 0,
          top: 5,
          zIndex: 99,
        }}
        type="text"
        onClick={hide}
      >
        <CloseOutlined />
      </Button>
      <Row
        style={{
          background: "#124173",
          padding: "26px 0px",
          display: "flex",
          justifyContent: "center",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "29px",
          color: "white",
        }}
      >
        Form details
      </Row>

      <Row className="wrapper-box" gutter={10}>
        <Col span={18} xs={24} sm={18}>
          <div>
            <h3>Share your Form</h3>
            {loading ? (
              <SkeletonBlock style={{ height: 30, width: 250 }} />
            ) : (
              <Input.Group compact className="input-group">
                <Input style={{ width: "60%" }} value={url} disabled />
                <Input
                  style={{ width: "40%" }}
                  value={micrositeDefaultStyle?.micrositePageTitle}
                  disabled
                />
              </Input.Group>
            )}
          </div>
        </Col>
        <Col
          span={6}
          xs={24}
          sm={6}
          style={{ display: "flex", alignItems: "flex-end" }}
          className="mt-3"
        >
          {loading ? (
            <SkeletonBlock style={{ height: 30, width: 70 }} />
          ) : (
            <Button
              className="standard-button primary-dark-blue-button"
              onClick={() => copyText("link")}
              icon={
                <img
                  src={CopyImg}
                  style={{ height: 15, paddingRight: 10 }}
                  alt=""
                />
              }
            >
              Copy
            </Button>
          )}
        </Col>
      </Row>

      <Row className="wrapper-box" gutter={10}>
        <Col span={12} xs={24} sm={12}>
          <Row align="middle" style={{ marginBottom: 20 }}>
            <h4 style={{ marginBottom: 0, marginRight: 10 }}>
              Generated QR Code
            </h4>
          </Row>

          <h3>Download In</h3>
          <div style={{ display: "flex", gap: "7px" }}>
            {loading ? (
              <SkeletonBlock style={{ height: 30, width: 50 }} />
            ) : (
              <Button
                className="standard-button secondary-dark-blue-button"
                onClick={() => downloadQRCode("png")}
              >
                PNG
              </Button>
            )}
            {loading ? (
              <SkeletonBlock style={{ height: 30, width: 50 }} />
            ) : (
              <Button
                className="standard-button secondary-dark-blue-button"
                onClick={() => downloadQRCode("jpeg")}
              >
                JPG
              </Button>
            )}
          </div>
        </Col>

        <Col
          span={12}
          xs={24}
          sm={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
          className="qr-container"
        >
          <QRCode id="qr-code" value={url} qrStyle="dots" eyeRadius={7} />
          {/*dev :- below QR code is for increasing resolution of downloadable QR don't remove */}
          <QRCode
            id="qr-code2"
            value={url}
            qrStyle="dots"
            eyeRadius={200}
            size={4080}
            quietZone={30}
          />
        </Col>
      </Row>

      <Row className="wrapper-box" gutter={10}>
        <Col span={18} xs={24} sm={12}>
          <Row align="middle">
            <h3 style={{ marginBottom: 0 }}>Get embed code</h3>
          </Row>
        </Col>

        <Col
          span={6}
          xs={24}
          sm={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loading ? (
            <SkeletonBlock style={{ width: 70, height: 40 }} />
          ) : (
            <Button
              className="standard-button primary-dark-blue-button"
              onClick={() => copyText("iframe")}
            >
              Copy Code
            </Button>
          )}
        </Col>

        <Col span={24}>
          {loading ? (
            <Skeleton active />
          ) : (
            <TextArea
              autoSize={{ minRows: 4, maxRows: 6 }}
              value={embedIframeRef}
            />
          )}
        </Col>
      </Row>
      <Row className="wrapper-box" gutter={10}>
        <Col span={18} xs={24} sm={12}>
          <Row align="middle">
            <h3 style={{ marginBottom: 0 }}>Get Auto Pop Up Code</h3>
          </Row>
        </Col>

        <Col
          span={6}
          xs={24}
          sm={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loading ? (
            <SkeletonBlock style={{ width: 70, height: 40 }} />
          ) : (
            <Button
              className="standard-button primary-dark-blue-button"
              onClick={() => copyText("script")}
            >
              Copy Script
            </Button>
          )}
        </Col>

        <Col span={24}>
          {loading ? (
            <Skeleton active />
          ) : (
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} value={scriptRef} />
          )}
        </Col>
      </Row>
    </div>
  );
}
