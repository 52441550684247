import React, { Component } from 'react';
import { Button, Result } from 'antd';
import Stats from 'Stats';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (this.props.onError) {
      this.props.onError();
    }
    console.error(error);
    Stats.error(error);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Result
          status="error"
          title="Something went wrong"
          subTitle="An unexpected error occurred loading this page. Reload this page"
          extra={[
            <Button type="primary" className='btn-primary' onClick={() => window.location.reload()}>
              Try Again
            </Button>,
          ]}
        ></Result>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
