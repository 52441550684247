import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import pageHeaderImg from "../../assets/Images/support-page-header.svg";
// import phoneImg from "../../assets/Images/support-phone.svg";
import SupportImg from "../../assets/Images/support-email.svg";
import "./support.css";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";

function Support() {
  const [form] = Form.useForm();
  const { userData } = useSelector((state) => state.loginReducer);
  const [loading, setloading] = useState(false);

  const setUserData = () => {
    form.resetFields();
    form.setFieldsValue(userData);
  };
  useEffect(() => {
    setUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const submitForm = () => {
    setloading(true);
    form
      .validateFields()
      .then((values) => {
        if (values.email)
          API.post("blustreamREST", "/support", {
            body: values,
          })
            .then(() => {
              message.success("Thank you for your words");
              setloading(false);
              setUserData();
            })
            .catch(() => {
              message.error("Something went wrong");
              setloading(false);
              setUserData();
            });
        else message.error("Something went wrong");
      })
      .catch((error) => console.log("Field Validation error", error));
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        borderRadius: "8px",
        padding: 30,
      }}
    >
      <Row justify="center">
        <Col span={16} xxl={16} xl={16} lg={20} md={24} sm={24} xs={24}>
          <Row justify="center">
            <img
              src={pageHeaderImg}
              alt=""
              style={{ maxWidth: "414px", marginTop: "15px" }}
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Space
              direction="vertical"
              style={{ width: "100%" }}
              size={"small"}
            >
              <Typography.Title level={4} strong>
                Have any message for us?
              </Typography.Title>
              <Form
                layout="vertical"
                onFinish={submitForm}
                form={form}
                requiredMark={false}
                className="support-form"
              >
                <Row gutter={20}>
                  <Col span={12} xs={24} sm={12}>
                    <Form.Item label="First Name" name="given_name">
                      <Input
                        className="standard-input"
                        placeholder="Enter First Name"
                        disabled
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <Form.Item label="Last Name" name="family_name">
                      <Input
                        className="standard-input"
                        placeholder="Enter Last Name"
                        disabled
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={12} xs={24} sm={12}>
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number",
                        },
                        {
                          pattern: /^([0|\\+[0-9]{1,3})?([0-9]{10})$/,
                          message: "Please Enter Valid Mobile Number",
                        },
                      ]}
                    >
                      <Input
                        className="standard-input"
                        placeholder="Enter Phone number"
                        size="large"
                        disabled={loading}
                        maxLength="10"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <Form.Item label="Email Address" name="email">
                      <Input
                        className="standard-input"
                        placeholder="Enter EmailID"
                        disabled
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Message/query"
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message for us!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        disabled={loading}
                        placeholder="Write us any questions/doubts/message"
                        autoSize={{ minRows: 5, maxRows: 25 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Button
                    htmlType="submit"
                    size="large"
                    className="standard-button primary-orange-button"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Row>
              </Form>

              <Divider style={{ margin: "15px 0px" }} />

              <Typography.Title level={4} strong>
                Contact us
              </Typography.Title>
              <Row>
                <Col span={24} className="contact-us-container">
                  <div className="contact-us-image-container">
                    <img
                      src={SupportImg}
                      height={30}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="contact-us-text-container">
                    <Typography.Text
                      style={{ fontSize: 16, fontWeight: "lighter" }}
                    >
                      Email id
                    </Typography.Text>
                    <Typography.Text
                      strong
                      style={{ fontSize: 16, cursor: "pointer" }}
                      onClick={() => {
                        window.location.href = "mailto:help@blustream.io";
                      }}
                    >
                      help@blustream.io
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Support;
