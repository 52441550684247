import {Storage} from "aws-amplify";

const uploadFileToS3 = async (file,productId,customerId) => {
  const newFilename = new Date().valueOf();
  console.log(file,productId,customerId,5);
  return await Storage.put(
    `${file.name.replace(
      /\.[^/.]+$/,
      ""
    )}_${newFilename}.${file.name.replace(/(.*)\./g, "")}`,
    file,
    {
      level: "public",
      contentType: file.type, // contentType is optional
      metadata:{"customerId": `${customerId}`,"productIds": `[${productId}]`}
    }
  );
}

export default uploadFileToS3;
