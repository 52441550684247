/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input, Row, Col, Button, message, Empty, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import teamIcon from "./../../assets/Images/teamIcon.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
import deleteIcon3 from "./../../assets/Images/deleteIcon3.svg";
import { useSelector, useDispatch } from "react-redux";
import { API, Auth } from "aws-amplify";
import {
  setAccountDetails,
  updateCompanyAccount,
  updateUserGroups,
} from "store/actions/loginAction";
import { useHistory } from "react-router-dom";
import { setTouchpoint } from "store/actions/SettingAction";
import sdk from "sdk/Accounts";

const CurrentUsers = (props) => {
  const { acct, fetchAccountDetails } = props;
  const { roAdmin, userData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [Usersdata, setUsersdata] = useState([]);
  const [usersBackup, setusersBackup] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [removeUserLoading, setRemoveUserLoading] = useState(false);
  const [selectedUserAttributes, setSelectedUserAttributes] = useState(null);

  const get_attribute = (user, key) => {
    let result = user?.filter((attribute) => attribute.Name === key);
    if (result?.length > 0) {
      return result[0]["Value"];
    } else {
      return "";
    }
  };
  const getUserData = () => {
    let usersdata = [];
    acct?.users?.forEach((item) => {
      item && usersdata.push(JSON.parse(item));
    });
    setUsersdata(usersdata);
    setusersBackup(usersdata);
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acct]);

  useEffect(() => {
    if (searchValue?.length > 0) {
      setUsersdata(
        usersBackup.filter((item) =>
          get_attribute(item?.Attributes, "email")
            .toLowerCase()
            .includes(String(searchValue).toLowerCase())
        )
      );
    } else {
      setUsersdata(usersBackup);
    }
  }, [usersBackup]);

  const searchUser = (value) => {
    if (value) {
      let filteredData = usersBackup.filter((item) =>
        get_attribute(item?.Attributes, "email")
          .toLowerCase()
          .includes(String(value.trimStart()).toLowerCase())
      );
      setUsersdata(filteredData);
    } else {
      setUsersdata(usersBackup);
    }
  };

  const removeUser = async () => {
    try {
      setRemoveUserLoading(true);
      const apiName = "blustreamREST";
      const path = "/accounts/remove-user-from-organisation";
      const body = {
        email: get_attribute(selectedUserAttributes, "email"),
        account: acct?.id,
      };
      await API.post(apiName, path, { body: body });
      message.success("Left organization Successfully!");
      await fetchAccountDetails();
      if (get_attribute(selectedUserAttributes, "email") === userData?.email) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          async (err, session) => {
            const { accessToken } = session;
            const response = Auth.currentUserInfo();
            response.then((data) => {
              dispatch(updateUserGroups(session, data?.attributes));
            });
            if ("cognito:groups" in accessToken.payload) {
              const onlyCompanyGroups = accessToken?.payload?.[
                "cognito:groups"
              ]?.filter((item) => item?.length === 36);
              const selectedOrganizationDetail = await sdk.fecthCompanies(
                onlyCompanyGroups[0]
              );
              selectCompany(
                selectedOrganizationDetail?.data?.getCustomerAccount
              );
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    } finally {
      setRemoveUserLoading(false);
    }
  };

  const selectCompany = async (orgDetail) => {
    dispatch(updateCompanyAccount(orgDetail));
    dispatch(setTouchpoint(""));

    if (orgDetail) {
      const account = await sdk.fetchAccount(orgDetail?.id);
      dispatch(setAccountDetails(account));
    }
    window.location.href = "/";
  };

  return (
    <>
      <Row
        style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
      >
        <Col span={12}>
          <div className="user-request-title">Current users</div>
        </Col>
        <Col span={12}>
          <Input.Search
            className="standard-searchbox"
            allowClear
            prefix={<SearchOutlined className="standard-searchbox-prefix" />}
            placeholder="Search users"
            type="search"
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
              searchUser(event.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        {Usersdata?.length > 0 ? (
          Usersdata?.map((item, index) => {
            return (
              <Col
                span={24}
                key={index}
                className="user-request-main-wrapper"
                style={{ padding: "12px 0px" }}
              >
                <div className="user-request-details-wrapper">
                  <img className="user-icon" src={teamIcon} alt="teamIcon" />
                  <div className="user-request-email">
                    {get_attribute(item?.Attributes, "email")}
                  </div>
                </div>
                <div className="user-request-button-container">
                  <img
                    className={roAdmin ? "disable-pointer-events" : ""}
                    style={{ height: "12px", cursor: "pointer" }}
                    src={deleteIcon3}
                    alt="deleteIcon3"
                    onClick={() => {
                      setSelectedUserAttributes(item.Attributes);
                      setShowRemoveUserModal(true);
                    }}
                  />
                </div>
              </Col>
            );
          })
        ) : (
          <Empty
            description="No users found!"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          />
        )}
      </Row>

      {/* for remove user */}
      <Modal
        centered
        visible={showRemoveUserModal}
        footer={null}
        className="decline-invite-modal"
        onCancel={() => {
          setShowRemoveUserModal(false);
        }}
      >
        <div className="decline-invite-modal-content-wrapper">
          <img src={glowingBulbIcon} alt="" />
          {selectedUserAttributes ? (
            get_attribute(selectedUserAttributes, "email") ===
            userData?.email ? (
              <p className="decline-invite-confirmation-text">
                Are you sure you want to leave this organization?
              </p>
            ) : (
              <p className="decline-invite-confirmation-text">
                {`Are you sure you want to remove ${get_attribute(
                  selectedUserAttributes,
                  "given_name"
                )} ${get_attribute(
                  selectedUserAttributes,
                  "family_name"
                )} from your
            organization?`}
              </p>
            )
          ) : null}
          <div className="decline-invite-buttons-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => {
                setShowRemoveUserModal(false);
              }}
            >
              No
            </Button>
            <Button
              loading={removeUserLoading}
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                await removeUser();
                setShowRemoveUserModal(false);
              }}
            >
              Yes, Sure
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CurrentUsers;
