import React from 'react'
function BillingIframe() {
    return (
     <>
<iframe src="http://localhost:3000/billingpage" title="Billing iframe" style={{width:'500px', border:"none"}}>
</iframe>      
  </>
    )
  }
  
  export default BillingIframe