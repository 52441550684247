export const GetUserCustom = /* GraphQL */ `
  query GetUserCustom {
    listAccounts {
      items {
        accountStatus
        company
        limits
        plan {
          name
          order
          priceValues
        }
        planID
        stripeCustomerID
        stripePriceID
        stripeSubscriptionID
        url
        users
      }
    }
  }
`

export const listCustomerAccountsCustom = /* GraphQL */ `
  query ListCustomerAccounts(
    $filter: ModelCustomerAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company
        company_loweredcased
        url
        disabled
        users
        limits
        metadata
        dashboardID
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
        }
        tags {
          items {
            tag {
              name
              id
              updatedAt
            }
            id
          }
        }
      }
      nextToken
    }
  }
`

export const getCustomerAccountCustom = /* GraphQL */ `
  query GetCustomerAccount($id: ID!) {
    getCustomerAccount(id: $id) {
      id
      company
      company_loweredcased
      accountStatus
      source
    }
  }
`

export const getCustomerAccountForInvitedUsersCustom = /* GraphQL */ `
  query GetCustomerAccount($id: ID!) {
    getCustomerAccount(id: $id) {
      id
      company
      company_loweredcased
      users
    }
  }
`

export const searchCustomerAccountsCustom = /* GraphQL */ `
  query SearchCustomerAccounts(
    $filter: SearchableCustomerAccountFilterInput
    $sort: SearchableCustomerAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        company
        company_loweredcased
        disabled
        users
        dashboardID
        createdAt
        updatedAt
        accountStatus
        planID
        manualBilling
        source
        tags {
          items {
            tag {
              name
              id
              updatedAt
            }
            id
          }
        }
      }
      total
    }
  }
`

export const searchCustomerAccountsWithDashboardCustom = /* GraphQL */ `
  query SearchCustomerAccounts(
    $filter: SearchableCustomerAccountFilterInput
    $sort: SearchableCustomerAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        company
        dashboardID
      }
    }
  }
`

export const triTechProfileByAccountIdCustom = /* GraphQL */ `
  query TriTechProfileByAccountId(
    $account: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTriTechProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TriTechProfileByAccountId(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        tritech_contract_action_mapper {
          items {
            id
            triTechProfileId
            payloadDescription
            payloadTransactionStatus
            action
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
