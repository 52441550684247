import Icon from "@ant-design/icons";

const PenSVG = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3127 0.803228C13.3827 -0.267743 15.1248 -0.267743 16.1956 0.803228C16.7141 1.32152 16.9997 2.01136 16.9997 2.74505C16.9997 3.47875 16.7141 4.16859 16.1956 4.68588L14.6755 6.20615L10.7927 2.3233L12.3127 0.803228ZM0.78722 12.4591C0.809143 12.3537 0.860831 12.256 0.937659 12.1792L9.62932 3.48871L13.5122 7.37135L4.82171 16.0618C4.74589 16.1388 4.64815 16.1903 4.54055 16.2124L0.657698 16.989C0.621496 16.9966 0.585093 16.9998 0.550098 16.9998C0.406095 16.9998 0.265511 16.9439 0.161129 16.8395C0.0316073 16.7088 -0.0255111 16.5232 0.0106907 16.3432L0.78722 12.4591Z"
      fill="#959595"
    />
  </svg>
);

const CopySVG = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 1.9951C21.8386 1.29412 21.5159 0.808824 21.0318 0.431373C20.709 0.215686 20.3863 0.107843 20.0098 0L5.11002 0L4.89487 0.0539216C3.87286 0.377451 3.1198 1.34804 3.1198 2.42647C3.1198 2.42647 3.1198 3.55882 3.1198 3.88235H2.58191C1.88264 3.88235 1.23716 4.15196 0.753056 4.63726C0.268949 5.12255 0 5.76961 0 6.41667C0 7.92647 0 9.4902 0 11C0 11.6471 0 12.2941 0 12.9412C0 12.9412 0 18.0098 0 19.5196C0 20.9216 1.07579 22 2.47433 22H8.01467H15.6528C15.7604 22 15.9218 22 16.0293 22C17.2665 21.7843 18.1271 20.7598 18.1271 19.5196V18.9265H19.6333C20.1174 18.9265 20.5477 18.7647 20.9242 18.4951C21.4083 18.1176 21.7848 17.6324 21.9462 16.9314L22 1.9951ZM18.1271 17.0931C18.1271 13.5343 18.1271 9.97549 18.1271 6.41667C18.1271 4.96078 17.0513 3.88235 15.5452 3.88235C15.5452 3.88235 7.10024 3.88235 4.89487 3.88235C4.89487 3.88235 4.89487 2.53431 4.89487 2.48039C4.94866 2.04902 5.16381 1.83333 5.59413 1.83333H19.4181C19.9022 1.83333 20.1174 2.10294 20.1174 2.53431C20.1174 7.17157 20.1174 11.7549 20.1174 16.3922C20.1174 16.4461 20.1174 16.5539 20.1174 16.6618C20.0636 16.9314 19.8484 17.0931 19.5795 17.1471C19.5795 17.0931 18.1271 17.0931 18.1271 17.0931ZM1.82885 6.52451C1.82885 6.2549 1.88264 6.03922 2.04401 5.93137C2.20538 5.76961 2.36675 5.71569 2.68949 5.71569C4.84108 5.71569 15.5452 5.71569 15.5452 5.71569C16.0831 5.71569 16.3521 5.98529 16.3521 6.52451C16.3521 6.52451 16.3521 16.4461 16.3521 19.4118C16.3521 19.6814 16.2983 19.8971 16.1369 20.0049C15.9755 20.1667 15.8142 20.2206 15.4914 20.2206C13.2861 20.2206 11.0807 20.2206 8.87531 20.2206H2.58191C1.99022 20.2206 1.77506 20.0049 1.77506 19.4118V11.1618C1.82885 9.59804 1.82885 8.03431 1.82885 6.52451Z"
      fill="#959595"
    />
  </svg>
);

const CloseSVG = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5405 0.459564C20.9277 -0.153207 19.9342 -0.153168 19.3214 0.459564L11 8.78097L2.67862 0.459588C2.06586 -0.153184 1.07237 -0.153184 0.459582 0.459588C-0.153208 1.0724 -0.153181 2.06588 0.459586 2.67865L8.78097 11L0.45961 19.3214C-0.153149 19.9341 -0.153181 20.9276 0.45961 21.5404C1.0724 22.1532 2.06589 22.1531 2.67865 21.5404L11 13.219L19.3214 21.5404C19.9342 22.1532 20.9276 22.1532 21.5404 21.5404C22.1532 20.9276 22.1532 19.9342 21.5404 19.3214L13.219 11L21.5405 2.6786C22.1532 2.06583 22.1532 1.07237 21.5405 0.459564Z"
      fill="white"
    />
  </svg>
);

export const PenIcon = () => <Icon component={PenSVG} />;
export const CopyIcon = () => <Icon component={CopySVG} />;
export const CloseIcon = () => <Icon component={CloseSVG} />;
