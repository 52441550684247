export const gettimeDiff = (curr, prev) => {
    var ms_Min = 60 * 1000; 
    var ms_Hour = ms_Min * 60;
    var ms_Day = ms_Hour * 24;
    var ms_Mon = ms_Day * 30; 
    var ms_Yr = ms_Day * 365; 
    var diff = curr - prev; 
    if (diff < ms_Min) {
        return Math.round(diff / 1000) + ' seconds';
    } else if (diff < ms_Hour) {
        return Math.round(diff / ms_Min) + ' minutes';
    } else if (diff < ms_Day) {
        return Math.round(diff / ms_Hour) + ' hours';
    } else if (diff < ms_Mon) {
        return  Math.round(diff / ms_Day) + ' days';
    } else if (diff < ms_Yr) {
        return  Math.round(diff / ms_Mon) + ' months';
    } else {
        return  Math.round(diff / ms_Yr) + ' years';
    }
  }