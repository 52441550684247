import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Typography,
  Row,
  Space,
  Form,
  Input,
  message,
} from "antd";
import sdk from "sdk/Accounts";
import RequestPending from "./RequestPending";
import Teamwork from "assets/Images/undraw_join_existing.svg";
import Modal from "antd/lib/modal/Modal";
import { CloseOutlined } from "@ant-design/icons";

const RequestAccess = ({
  expand,
  select,
  reset,
  requestAccesss,
  accountid,
  getRequest,
}) => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (accountid) {
      if (accountid.length === 36) {
        var patternId = new RegExp(
          "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"
        );
        if (patternId.test(accountid)) {
          handleSubmit({ accountid: accountid });
        }
      } else {
        message.error("Account id is not valid");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountid]);

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const request = await getRequest(e.accountid);
      if (request?.length > 0) {
        setPending(true);
        requestAccesss();
        localStorage.removeItem("accountReqId");
        message.success("Your request has been submitted");
      } else {
        let response = await sdk.requestAccess(e.accountid);
        if (response.errors || response.error) {
          message.error(response.errors || response.error);
        } else {
          setPending(true);
          requestAccesss();
          localStorage.removeItem("accountReqId");
          message.success("Your request has been submitted");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      reset();
      form.resetFields();
    }
  };

  return (
    <>
      {pending ? (
        <RequestPending accountid={accountid} />
      ) : (
        <Card
          style={{ border: "none" }}
          className="selection-card selection-card-req"
        >
          <Row style={{ justifyContent: "space-around" }}>
            <Col span={6} style={{ display: "flex", justifyContent: "left" }}>
              <div style={{ width: "100%", margin: "20px 0px" }}>
                <img
                  src={Teamwork}
                  alt="new account"
                  style={{ width: "100%", maxHeight: "130px" }}
                />
              </div>
            </Col>
            <Col
              span={14}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <Typography.Title
                level={4}
                style={{ textAlign: "left", fontSize: "22px" }}
              >
                Join An Existing Company
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                You can request access to an existing account if you know the
                account identification number.
              </Typography.Paragraph>

              <Row>
                <Col span={8}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    className="standard-button primary-dark-blue-button"
                    onClick={select}
                  >
                    <b>Request Access</b>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
      <Modal
        className="new-account-model"
        visible={expand}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              style={{
                fontSize: 20,
                color: "#ffffff",
                marginLeft: 30,
                position: "absolute",
              }}
              onClick={reset}
            />
          </strong>
        }
      >
        <Row justify="center">
          <Col span={20}>
            <Typography.Title level={5} className="modal-title">
              Request access
            </Typography.Title>
            <Typography.Text style={{ marginBottom: 20 }}>
              Please enter the Account ID you’d like to request access to. The
              account administrator will receive an email to approve your
              access. Once your access is approved you will receive an email
              notification.
            </Typography.Text>

            <Form
              requiredMark={false}
              layout="vertical"
              name="request_access"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
              form={form}
              initialValues={{ accountid: accountid }}
            >
              <Space
                direction="vertical"
                style={{ width: "100%", paddingTop: 15 }}
              >
                <Form.Item
                  name="accountid"
                  label={
                    <strong style={{ fontSize: "16px" }}>Customer ID</strong>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Account ID of your company",
                    },
                    {
                      pattern:
                        /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
                      message: "Please enter valid Account ID",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    className="standard-input"
                    size="large"
                    id="accountid"
                    key="accountid"
                    name="accountid"
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    loading={loading}
                    tabIndex={2}
                    className="standard-button primary-orange-button"
                  >
                    Get Started
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default RequestAccess;
