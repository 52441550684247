import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, PageHeader, Select, Modal } from "antd";
import {
  createContact,
  getProduct,
  AddProduct,
} from "../../apiService/RESTApi";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import Confirm from "../../assets/Images/Group.svg";
import Error from "../../assets/Images/Error.svg";
import { useSelector } from "react-redux";
import { emailPattern } from "utility/Constants";

export default function CreateContactPage() {
  const { groups } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  // const location = useLocation();
  const [productDetails, setProductDetails] = useState([]);
  const [productId, setProductId] = useState([]);
  const [confirmModel, setConfirmModel] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const { customerId } = location?.state;

  const { Option } = Select;

  const onSubmit = async () => {
    try {
      setLoading(true);
      let res = await createContact(form.getFieldsValue());
      if (res?.id) {
        await Promise.all(
          productId?.map((pId) => AddProduct(res?.id, pId))
        ).then((res) => setConfirmModel(true));

        setTimeout(() => {
          history.goBack();
        }, 2000);
        setFirstName("");
        setLastName("");
      } else {
        setErrorMessage(res?.message);
        setErrorModel(true);
      }
    } catch (err) {
      console.error(err);
      message.error("Something went wrong!");
    }
    setLoading(false);
  };
  const getProductsApi = async () => {
    const fetchProducts = await getProduct(groups[0]);
    let productValue = fetchProducts?.response?.data;
    setProductDetails(productValue);
  };
  const onErrorModleClose = () => [setErrorModel(false)];
  useEffect(() => {
    getProductsApi();
    // eslint-disable-next-line
  }, []);
  function handleChange(value) {
    setProductId(value);
  }

  const filterOption = (input, option) => {
    return (
      option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <>
      <div className="detailPage">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Back to all contacts"
        ></PageHeader>

        <div className="formBox">
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            requiredMark={false}
            className="add-contact-form"
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "center",
                borderBottom: "3px solid rgb(239, 244, 249)",
              }}
            >
              <h2 style={{ paddingLeft: "20px", color: "#F37064" }}>
                {firstName} {lastName}
              </h2>
              <div>
                <Button
                  className="standard-button secondary-orange-button"
                  htmlType="submit"
                  style={{
                    marginRight: "20px",
                    marginBottom: "14px",
                  }}
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: "100%",
                padding: "0 20px",
              }}
            >
              <Form.Item
                className="w-100"
                label="First Name *"
                style={{ width: "48%" }}
                name="firstName"
                value={firstName}
                rules={[
                  {
                    required: true,
                    message: "This field cannot be empty!",
                  },
                ]}
                onChange={(e) => setFirstName(e.target.value)}
              >
                <Input
                  className="standard-input"
                  placeholder="Enter First Name"
                />
              </Form.Item>
              <Form.Item
                className="w-100"
                label="Last Name *"
                style={{ width: "48%" }}
                name="lastName"
                value={lastName}
                rules={[
                  {
                    required: true,
                    message: "This field cannot be empty!",
                  },
                ]}
                onChange={(e) => setLastName(e.target.value)}
              >
                <Input
                  className="standard-input"
                  placeholder="Enter Last Name"
                />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "0 20px",
              }}
            >
              <Form.Item
                className="w-100"
                label="Phone Number *"
                style={{ width: "48%" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be empty!",
                  },
                  {
                    pattern: /^\+?([\\(\\)\s-]*\d[\\(\\)]*){10,13}$/,
                    message: "Please enter valid phone number",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  placeholder="Enter Phone Number"
                />
              </Form.Item>

              <Form.Item
                className="w-100"
                label="Email ID"
                style={{ width: "48%" }}
                name="emailAddress"
                rules={[
                  {
                    pattern: emailPattern,
                    message: "Please enter valid email",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  placeholder="Enter Email ID"
                />
              </Form.Item>
              <Form.Item
                className="product-container"
                label="Select Product"
                name="product"
              >
                <Select
                  placeholder="Select a product"
                  className="standard-dropdown"
                  mode="multiple"
                  filterOption={filterOption}
                  onChange={handleChange}
                >
                  {productDetails?.map((item, index) => {
                    return (
                      <Option value={item?.productId} key={index}>
                        {item?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
        <Modal
          visible={confirmModel}
          footer={null}
          centered
          className="contact-model"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          >
            <img src={Confirm} alt="" />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <h3 style={{ fontSize: 22, fontWeight: 600 }}>
              Contacts added successfully!
            </h3>
          </div>
        </Modal>
        <Modal
          visible={errorModel}
          footer={null}
          centered
          className="contact-model"
          onCancel={onErrorModleClose}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          >
            <img src={Error} alt="" />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
            className="contact-model-text"
          >
            <h3 style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}>
              Something went wrong!
            </h3>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="contact-model-text"
          >
            <h3 style={{ color: "#959595", fontSize: 16 }}>{errorMessage}</h3>
          </div>
        </Modal>
      </div>
    </>
  );
}
