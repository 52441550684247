import {API} from "aws-amplify";
import {createContact, createTag, createTermsAndConditions, updateCustomerAccount, updateTermsAndConditions, createCustomerAccountTag, updateTag, deleteCustomerAccountTag, updatePrivacyPolicy, createPrivacyPolicy} from "graphql/mutations";
import {listContacts, listCustomerAccountRequests, listCustomerAccountTags, listTags, listTermsAndConditions, listPrivacyPolicies, getCustomerAccount} from "graphql/queries";

const createContactApi = (inputData) => {
    return API.graphql({
        query: createContact,
        variables: {input: inputData}
    });
};
const getAllConatctApi = () => {
    return API.graphql({query: listContacts})
}
const listCustomerAccountRequestss = () => {
    return API.graphql({query: listCustomerAccountRequests})
}

const listTermAndConditionsApi = () => {
    return API.graphql({query : listTermsAndConditions})
}

const updateTermsAndConditionsApi = (inputData) => {
    let hasTnc = inputData?.id
    return API.graphql({
        query: hasTnc ? updateTermsAndConditions : createTermsAndConditions,
        variables: {input: inputData}
    });
};

const updateCompanyDetailsApi = (inputData) => {
    return API.graphql({
        query : updateCustomerAccount,
        variables:{input:inputData}
    })
}

const updatePrivcayPolicyApi = (inputData) => {
    let hasPP = inputData?.id
    return API.graphql({
        query:  hasPP ? updatePrivacyPolicy : createPrivacyPolicy,
        variables: {input: inputData}
    });
};

const listPrivacyPolicyApi = () => {
    return API.graphql({query: listPrivacyPolicies})
}

const getAllTagsApi = () => {
    return API.graphql({
        query: listTags
    })
}
const addTagApi = (inputData) => {
    return API.graphql({
        query:createTag,
        variables:{input:inputData}
        
    })
}

const updateTagsApi = (inputData) => {
    return API.graphql({
        query: updateTag,
        variables:{input:inputData}
    })
}

const deleteCustomerAccountTagApi = (inputData) => {
    return API.graphql({
      query: deleteCustomerAccountTag,
      variables: { input: inputData },
    });
  };

const createCustomerTagApi = (inputData) => {
    return API.graphql({
        query: createCustomerAccountTag,
        variables: {input:inputData}
    })
}

const GetCustomerAccountTagApi = (filter) => {
    return API.graphql({
        query: listCustomerAccountTags,
        variables: {filter: filter },
    })
}

const getAccointApi = (id) => {
    return API.graphql({ query: getCustomerAccount, variables: { id: id } });
  };

export const BlustreamAPI = {
    createContactApi,
    getAllConatctApi,
    listCustomerAccountRequestss,
    listTermAndConditionsApi,
    updateTermsAndConditionsApi,
    updateCompanyDetailsApi,
    getAllTagsApi,
    addTagApi,
    createCustomerTagApi,
    updateTagsApi,
    deleteCustomerAccountTagApi,
    GetCustomerAccountTagApi,
    listPrivacyPolicyApi,
    updatePrivcayPolicyApi,
    getAccointApi
};
