/* eslint-disable react-hooks/exhaustive-deps */
import React , {useEffect} from 'react'
import './touchPoints.css'
import WorkFlow from './WorkFlow'
import {useDispatch} from "react-redux"
import { setTouchpoint } from '../../store/actions/SettingAction'

export default function Touchpoints() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTouchpoint(''))
    },[])
    
    return (
        <>
        {/* {!groups.includes("internal") ? 

            <div className="wrapperClass" style={{backgroundImage: `url(${BG})`}}>
                <Row>
                    <Col span={24}>
                        <h1>
                            Touchpoint Manager<br/>
                            <span>Coming Soon</span>
                        </h1>
                    </Col>
                </Row>

                <div className='textbox'>
                    <h2 style={{marginBottom: 20}}>
                        You'll soon be able to manage contact<br/>
                        touchpoints right here!
                    </h2>
                    <h2>
                        Meanwhile, please contact our team<br/>
                        at <a href="mailto:support@blustream.io">support@blustream.io</a><br/>
                        for help designing and managing your<br/>
                        contact touchpoints.
                    </h2>
                </div>

            </div>
            : */}
            <WorkFlow />

        </>
    )
}
