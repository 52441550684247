/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import "css/touchpointManager.css";
import {
  Input,
  Tooltip,
  Table,
  Checkbox,
  Button,
  Form,
  Modal,
  Drawer,
  message,
  Row,
  Col,
  Skeleton,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  editTuchPointData,
  getTuchPointIdData,
  tresableLinkData,
  getCustomerId,
  testJourneyTouchpoint,
  contactSearch,
  getContactbyID,
} from "../../apiService/RESTApi";
import successIcon from "../../assets/Images/successIcon.svg";

const { TextArea, Search } = Input;
export default function TableView(props) {
  let debounceValue = "";
  const [edit, setEdit] = useState(false);
  const [editJourney, setEditJourney] = useState(false);
  const [journeyState, setJourneyState] = useState('live');
  const [journeyData, setJourneyData] = useState({});
  const [form] = Form.useForm();
  const [touchPointForm, setTouchPointForm] = useState({});
  const [journeyForm, setJourneyForm] = useState({});
  const [touchPointId, setTouchPointId] = useState();
  const [tableData, setTableData] = useState([]);
  const { groups, roAdmin } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [touchpointLoading, setTouchpointLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [testJourneyId, setTestJourneyId] = useState("");
  const [testTouchPointId, setTestTouchPointId] = useState("");
  const [contactSearchQuery, setContactSearchQuery] = useState("");
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contactSearchResultsComplete, setContactSearchResultsComplete] = useState(false);
  const [journeyIdForSelectedTouchPoint, setJourneyIdForSelectedTouchPoint] = useState("");
  const contactSearchLoader = useRef(false);
  const [contactSearchPaginationProperties, setContactSearchPaginationProperties] = useState({
    current: 1,
    pageSize: 10,
    totalPages: 0
  });

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (row?.name === true) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns = [
    {
      title: "Touchpoint Name",
      dataIndex: "Tname",
      key: "Tname",
      className: "touchpoint-name ",
      render: (text, row, index) => {
        let journey = row["journey"];
        var currentState = journey?.currentState ?? "";

        var journeyInfo = `Journey: ${text}`;
        if (currentState && currentState.effectiveDate && currentState.state) {
          journeyInfo += ` - (${currentState.state})`;
          setJourneyData(journey);
        }

        if (row?.name === true) {
          return {
            children: (
              <div className="name-wrapper p-16">
                <span style={{ color: "#124173", fontWeight: 600 }}>
                  {journeyInfo}
                </span>
              </div>
            ),
            props: {
              colSpan: 4,
            },
          };
        }
        return <span className="p-16 d-block">{text}</span>;
      },
    },

    {
      title: "Trigger/Timing",
      dataIndex: "type",
      key: "type",
      className: "p-16",
      render: renderContent,
    },

    {
      title: "Message",
      key: "msg",
      dataIndex: "msg",
      className: "p-16",
      render: renderContent,
    },
    {
      title: "Links",
      key: "link",
      dataIndex: "link",
      className: "p-16",
      render: renderContent,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      className: "edit-journey-name",
      width: 80,
      render: (text, row, index) => {
        if (row.name === true) {
          return {
            children: (
              <div className="edit-journey-wrapper">
                <Button
                  disabled={roAdmin}
                  type="text"
                  style={{ color: "#959595" }}
                  onClick={() => handleEditJourney(row)}
                >
                  <EditOutlined style={{ fontSize: "20px" }} />
                </Button>
              </div>
            ),
          }
        }
        return <span className="p-16 d-block">{text}</span>;
      }
    },
  ];

  const handleEdit = (data, item) => {
    let temp = { ...touchPointForm };
    temp["id"] = data?.id !== undefined && data?.id;
    temp["ordinal"] = data.ordinal !== undefined && data?.ordinal;
    temp["wait"] =
      data?.wait !== undefined && (data?.wait || "Immediate on insert");
    temp["name"] = data?.name !== undefined && data?.name;
    temp["messageContent"] =
      data?.messageContent !== undefined && data?.messageContent;
    temp["messageTemplateName"] =
      data?.messageTemplateName !== undefined && data?.messageTemplateName;
    temp["contentLinkToTrackableLink"] =
      data?.contentLinkToTrackableLink !== undefined &&
      data?.contentLinkToTrackableLink !== null
        ? Object.keys(data?.contentLinkToTrackableLink)
        : "";
    setTouchPointId(data?.id);
    setJourneyIdForSelectedTouchPoint(item?.id);
    form.setFieldsValue(temp);
    setTouchPointForm(temp);

    setEdit(true);
  };

  const handleEditJourney = (item) => {
    let journey = item["journey"];
    let temp = { ...journeyForm };
    temp["journeyName"] = journey.name;
    temp["journeyId"] = journey.id;
    temp["currentState"] = journey.currentState?.state;

    form.setFieldsValue(temp);
    setJourneyForm(temp);
    setState(journey.currentState?.state.toLowerCase());
    setEditJourney(true);
  };

  const onClose = () => {
    if (edit === true) {
      setEdit(false);
    } else  {
      setEditJourney(false);
      setState(journeyData.currentState?.state.toLowerCase());
    }
  };

  //Update touchpoint details
  const handleUpdate = async () => {
    setTouchpointLoading(true);
    let temp = { ...touchPointForm };
    let formData = form.getFieldsValue();
    for (let item in formData) {
      temp[item] = formData[item];
    }

    setTouchPointForm(temp);
    let res = await getCustomerId(groups[0]);
    let customerId = res.response?.data?.[0]?.customerId;
    await tresableLinkData(
      customerId,
      encodeURIComponent(formData?.contentLinkToTrackableLink),
      touchPointId
    )
      .then((res) => {
        if (
          temp?.contentLinkToTrackableLink !== null &&
          temp?.contentLinkToTrackableLink !== ""
        ) {
          temp["linkCode"] = res?.response?.data?.[0]?.[0];
        } else {
          delete temp.linkCode;
        }
        delete temp?.contentLinkToTrackableLink;

        editTuchPointData(
          groups[0],
          journeyIdForSelectedTouchPoint,
          touchPointId,
          temp
        )
          .then(() => {
            setUpdateData(temp);
            getTableIdData(updateData);
            setEdit(false);
            setTouchpointLoading(false);
          })
          .catch((error) => {
            message.error("Unable to edit touchpoint");

            setEdit(false);
            setTouchpointLoading(false);
          });
      })
      .catch((error) => {
        message.error("Unable to edit touchpoint");
        setEdit(false);
        setTouchpointLoading(false);
      });
  };

  const getTableIdData = async () => {
    if (props.journeyId !== undefined) {
      setLoading(true);

      await getTuchPointIdData(groups[0], props?.journeyId)
        .then((res) => {
          setUpdateData(res);
          let temp = [];
          Promise.all(
            res?.response?.data?.map((item, index) => {
              temp.push({ Tname: item?.name, name: true, journey: item });
              item?.touchpoints?.map((value) => {
                return temp.push({
                  key: value?.id !== undefined ? value.id : "",
                  journeyId: item.id,
                  Tname: (
                    <>
                      <p>
                        {value?.messageTemplateName !== undefined
                          ? value?.messageTemplateName
                          : ""}
                      </p>
                      <button
                        disabled={roAdmin}
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          color: "#F37064",
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                        }}
                        onClick={() => showModal(item?.id, value.id)}
                      >
                        Test now
                      </button>
                    </>
                  ),
                  edit: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Button
                        disabled={roAdmin}
                        type="text"
                        style={{ color: "#959595" }}
                        onClick={() => handleEdit(value, item)}
                      >
                        <EditOutlined style={{ fontSize: "20px" }} />
                      </Button>
                    </div>
                  ),
                  type: (
                    <p>
                      {value?.wait !== undefined &&
                      value?.wait !== null &&
                      value.wait !== ""
                        ? moment.duration(value?.wait).asDays() >= 1
                          ? `After ${moment
                              .duration(value?.wait)
                              .asDays()} days to previous`
                          : `After ${moment
                              .duration(value?.wait)
                              .asHours()} hours to previous`
                        : "Immediate on insert"}
                    </p>
                  ),
                  msg:
                    value?.messageContent !== undefined ? (
                      <pre
                        style={{
                          whiteSpace: "break-spaces",
                          fontFamily: "Hellix",
                        }}
                      >
                        {value?.messageContent}
                      </pre>
                    ) : (
                      ""
                    ),
                  link:
                    value?.contentLinkToTrackableLink !== undefined &&
                    value?.contentLinkToTrackableLink !== null ? (
                      <a
                        href={Object.keys(value?.contentLinkToTrackableLink)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {Object.keys(value?.contentLinkToTrackableLink)}
                      </a>
                    ) : (
                      ""
                    ),
                });
              });
            })
          )
            .then((res) => {
              setTableData(temp);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTableIdData();
  }, [props?.journeyId]);

  const handleOk = async () => {
    setOpen(false);
    setSelectedContacts([]);
    setLoading(true);
    selectedContacts.map((contactId) => {
      const response = testJourneyTouchpoint(contactId, testJourneyId, testTouchPointId);
      return response;
    });
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedContacts([]);
  };

  const showModal = (journeyId, touchpointId) => {
    setOpen(true);
    setTestJourneyId(journeyId);
    setTestTouchPointId(touchpointId);
  };

  const checkedContacts = (checkedValue) => {
    let contacts;
    if (selectedContacts?.includes(checkedValue)) {
      contacts = selectedContacts?.filter((id) => id !== checkedValue);
    } else {
      contacts = [...selectedContacts, checkedValue];
    }
    setSelectedContacts(contacts);
  };

  useEffect(async() => {
    await getContactsData("", contactSearchPaginationProperties, true);
  }, []);

  const listInnerRef = useRef();

  const onContactScroll = () => {
    if (contactSearchLoader.current) {
      return;
    }
    
    if (listInnerRef.current && contactSearchPaginationProperties.current < contactSearchPaginationProperties.totalPages) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight * .98 ) {
        
        const nextPage = contactSearchPaginationProperties.current + 1;
        const newContactSearchPaginationProperties = {
          current: nextPage,
          pageSize: contactSearchPaginationProperties.pageSize,
          total: 0,
          totalPages: contactSearchPaginationProperties.totalPages
        };
       
        updateContactSearchPaginationProperties(newContactSearchPaginationProperties);
        getContactsData(contactSearchQuery, newContactSearchPaginationProperties, false);

      }
    }
  };

  const updateContactSearchLoader = (searchLoaderState) => {
    contactSearchLoader.current = searchLoaderState;
  }; 
  const updateSearchedContacts = (searchedContactsValue) => {
    setSearchedContacts(searchedContactsValue);
  }
  const updateContactSearchResultsComplete = (contactSearchResultsCompleteValue) => {
    setContactSearchResultsComplete(() => contactSearchResultsCompleteValue);
  }; 
  const updateContactSearchQuery = (value) => {
    setContactSearchQuery(() => value);
  }
  const updateContactSearchPaginationProperties = (contactSearchPaginationPropertiesValue) => {
    setContactSearchPaginationProperties(() => contactSearchPaginationPropertiesValue);
  }

  //  get Contacts
  const getContactsData = async (contactSearchQuery, paginationState, newSearch) => {
    updateContactSearchLoader(true);
    await getCustomerId(groups[0]).then(async (res) => {
      const customerArn = res.response.data[0].customerArn;
      const searchResults = await contactSearch(
        customerArn,
        contactSearchQuery,
        paginationState.current,
        paginationState.pageSize
      );
      const promiseArray = searchResults?.data?.map((contact) => {
        return getContactbyID(contact?.contactId);
      });
      Promise.all(promiseArray).then((values) => {
        const contacts = values?.map((item) => ({
          label: `${item?.firstName} ${item?.lastName}`,
          value: item?.contactId
        }));

        if (newSearch) {
          updateSearchedContacts(contacts);
        }
        else {
          updateSearchedContacts([...searchedContacts, ...contacts]);
        }

        updateContactSearchPaginationProperties({
              current: searchResults.page.number + 1,
              pageSize: searchResults.page.size,
              total: searchResults.page.totalElements,
              totalPages: searchResults.page.totalPages
            });

        updateContactSearchLoader(false);

        if ((paginationState.current === searchResults.page.totalPages)
              || (newSearch && contacts.length === 0)) {
          updateContactSearchResultsComplete(true);
        }
        else {
          updateContactSearchResultsComplete(false);
        }

        return contacts;
      });

      updateContactSearchLoader(false);
    })
    .catch((error) => {
      updateContactSearchLoader(false);
      console.log("Error :", error);
      return error;
    });
    
  };

  const onContactSearch = (value) => {
    
    updateSearchedContacts([]);
    updateContactSearchResultsComplete(false);
    updateContactSearchQuery(value);
    updateContactSearchLoader(true);

    if (value) {
      value = value.trim();
    }
    if (value.length === 0 || value.length > 2 ) {
      clearTimeout(debounceValue);
      debounceValue = setTimeout(async () => {

        const newContactSearchPaginationProperties = {
          current: 1,
          pageSize: contactSearchPaginationProperties.pageSize,
          total: 0,
          totalPages: 0
        };
      
        updateContactSearchPaginationProperties(newContactSearchPaginationProperties);
        getContactsData(value, newContactSearchPaginationProperties, true);

      }, 1000);
    } else {
      clearTimeout(debounceValue);
    }
  };

  useEffect(() => {
    if (journeyData) {
      const currentState = journeyData.currentState?.state?.toLowerCase();
      if (currentState) {
        return currentState;
      }
    }
  }, [journeyData]);

  const setState = (newState) => {
    setJourneyState(newState);
  };

  return (
    <>
      <div className="tableView">
        <Table
          className="journey-table"
          columns={columns}
          loading={loading}
          pagination={false}
          tableLayout="fixed"
          bordered
          dataSource={tableData}
          scroll={{ y: 450 }}
        />
        <Drawer
          width={430}
          title="Edit Journey"
          placement="right"
          onClose={onClose}
          open={editJourney}
        >
          <Form
              form={form}
              layout="vertical"
              className="edit-journey-form"
              style={{paddingBottom: 60}}
              // onFinish={handleUpdate}
          >
            <Form.Item
                label="Journey Name"
                style={{width: "100%"}}
                name="journeyName"
            >
              <Input
                  className="standard-input"
                  disabled={true}
                  placeholder="Enter Journey Name"
              />
            </Form.Item>
            <Form.Item
                label={
                  <Tooltip
                      placement="rightBottom"
                      title={
                        <>
                          <strong>Live:</strong> Sending messages to all eligible contacts.<br/>
                          <strong>Muted:</strong> Continue the journey's schedule but silence all outbound messages and
                          responses.<br/>
                          <strong>Paused:</strong> Temporarily stop the schedule and resume from current position when
                          you leave this state.<br/>
                          <strong>Disabled:</strong> Stop all messaging and reset all journeys. Journeys will restart
                          from the beginning for all eligible contacts when resuming.
                        </>
                      }
                  >
                    <div style={{display: "flex", alignItems: "center"}}>
                      <div>State</div>
                      <InfoCircleOutlined style={{paddingLeft: "3px"}}/>
                    </div>
                  </Tooltip>
                }
                style={{width: "100%"}}
                name="messageContent"
                type="textarea"
            >
              <div className="journey-state-switcher">
                {/*added disabled class and commented onClick function to avoid the clicking behavior but still able to hover the buttons.*/}
                <Button
                    className={`disabled journey-state-button ant-btn ant-btn-secondary standard-button ${journeyState === 'live' ? "active primary-orange-button" : ""}`}
                    /*onClick={() => setState('live')}*/>Live</Button>
                  <Button
                      className={`disabled journey-state-button ant-btn ant-btn-secondary standard-button ${journeyState === 'muted' ? "active primary-orange-button" : ""}`}
                      /*onClick={() => setState('muted')}*/>Muted</Button>
                <Button
                    className={`disabled journey-state-button ant-btn ant-btn-secondary standard-button ${journeyState === 'paused' ? "active primary-orange-button" : ""}`}
                    /*onClick={() => setState('paused')}*/>Paused</Button>
                <Button
                    className={`disabled journey-state-button ant-btn ant-btn-secondary standard-button ${journeyState === 'disabled' ? "active primary-orange-button" : ""}`}
                    /*onClick={() => setState('disabled')}*/>Disabled</Button>
              </div>
            </Form.Item>
            {/* } */}
            <div className="action-buttons-wrapper">
              <Button
                  className="standard-button tertiary-button"
                  style={{marginRight: 8, width: "48%"}}
                  size="middle"
                  onClick={onClose}
              >
              Cancel
              </Button>
              <Button
                  type="primary"
                  style={{width: "48%"}}
                  htmlType="submit"
                  className="standard-button primary-orange-button"
                  size="middle"
                  // loading={touchpointLoading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Drawer>

        <Drawer
            width={430}
            title="Edit touchpoint details"
            placement="right"
            onClose={onClose}
            visible={edit}
        >
          <Form
              form={form}
              layout="vertical"
              className="edit-touchpoint-form"
            style={{ paddingBottom: 60 }}
            onFinish={handleUpdate}
          >
            <Form.Item
              label="Touchpoint Name"
              style={{ width: "100%" }}
              name="messageTemplateName"
            >
              <Input
                className="standard-input"
                disabled={true}
                placeholder="Enter Touchpoint Name"
              />
            </Form.Item>
            <Form.Item
              label="Trigger/Timing"
              style={{ width: "100%" }}
              name="wait"
            >
              <Input className="standard-input" disabled={true} />
            </Form.Item>
            <Form.Item
              label={
                <Tooltip
                  placement="rightBottom"
                  title="To insert the link from the field below into the message, add {{LINK}} to the message’s text."
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Message</div>
                    <InfoCircleOutlined style={{ paddingLeft: "3px" }} />
                  </div>
                </Tooltip>
              }
              style={{ width: "100%" }}
              name="messageContent"
              type="textarea"
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                placeholder="Enter Message"
              />
            </Form.Item>
            <Form.Item
              label={
                <Tooltip
                  placement="rightBottom"
                  title="Links added here become trackable automatically."
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Link</div>
                    <InfoCircleOutlined style={{ paddingLeft: "3px" }} />
                  </div>
                </Tooltip>
              }
              style={{ width: "100%" }}
              name="contentLinkToTrackableLink"
            >
              <Input className="standard-input" placeholder="Enter Link" />
            </Form.Item>
            {/* } */}
            <div className="action-buttons-wrapper">
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                size="middle"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
                loading={touchpointLoading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
      <Modal
        className="journey-test-modal"
        open={open}
        title={
          <span
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "22px",
              color: "#252525",
            }}
          >
            Choose Contact
          </span>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <p
            style={{
              textAlign: "start",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "19px",
              color: "#959595",
              marginBottom: "10px",
            }}
          >
            You’re about to start this contact on a journey partway through,
            which may trigger future messages.{" "}
            <span style={{ color: "#252525" }}>Click send</span> to confirm.
          </p>,
          <Button
            key="1"
            type={selectedContacts.length > 0 ? "primary" : ""}
            className="standard-button primary-orange-button"
            style={{
              width: "100%",
            }}
            onClick={() => {
              handleOk();
            }}
            disabled={selectedContacts.length <= 0}
          >
            Send
          </Button>,
        ]}
        centered
      >
        <div>
          <Search
            type="search"
            className="standard-searchbox"
            allowClear
            prefix={<SearchOutlined className="standard-searchbox-prefix" />}
            placeholder="Search Contact Name"
            style={{ display: "block", height: "42px" }}
            onChange={(e) => onContactSearch(e.target.value)}
          />
        </div>
        <div onScroll={onContactScroll} ref={listInnerRef} style={{ height: "40vh", overflow: "scroll" }}>
            <Row gutter={[0, 16]} style={{ padding: "20px 0px" }}>
              {searchedContacts?.map((contact) => {
                return (
                  <Col span={24} style={{ padding: "0px" }}>
                    <Checkbox
                      value={contact?.value}
                      onChange={(event) => checkedContacts(event.target.value)}
                      checked={selectedContacts?.includes(contact?.value)}
                    >
                      {contact?.label}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
            <div name="contact-search-loader" style={ contactSearchLoader.current ? {display: 'block', padding: "0 0 0 50%"} : {display :'none'}}>
              <Skeleton.Avatar
                  active
                  size="default"
                  shape="circle"
                />
            </div>
            <div name="no-more-contacts-found" style={ contactSearchResultsComplete ? {display :'block'} : {display: 'none'}}>
              No more contacts to view {contactSearchQuery.trim().length > 3 ? "for search '" + contactSearchQuery + "'": "" }
            </div>
        </div>
      </Modal>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 40px",
            gap: "22px",
          }}
        >
          <img src={successIcon} alt="" />
          <p
            style={{
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "30px",
              color: "#252525",
            }}
          >
            Touchpoint Tested Successfully !
          </p>
        </div>
      </Modal>
    </>
  );
}
