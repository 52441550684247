import React, { useEffect } from "react";
const Dashboard = () => {
  useEffect(() => {
    return () => {
      document.getElementById("quicksight").style.display = "none";
    };
  }, []);
  return <></>;
};
export default Dashboard;
