import Auth from "@aws-amplify/auth";
import { Button, Result } from "antd";
import React from "react";

function AccountSuspended(props) {
  const handleSignOut = async () => {
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        props.logOut();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="account-suspend-container">
      <Result
        status="warning"
        title="Account Suspended"
        subTitle={
          <p>
            Sorry, Your account is suspended <br /> Please contact Blusteam
            admin at <a href="mailto:help@blustream.io">help@blustream.io</a>
          </p>
        }
        extra={
          <Button
            type="primary"
            className="standard-button primary-orange-button"
            onClick={handleSignOut}
          >
            Log out
          </Button>
        }
      ></Result>
    </div>
  );
}

export default AccountSuspended;
