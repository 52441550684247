import { Col, Row, Form, Input, Button } from "antd";
import React from "react";

export default function Template2({ micrositeStyle, micrositeFormElements }) {
  const headingText = micrositeFormElements
    ?.find((element) => element?.type === "heading")
    ?.headingText?.toString();

  const companyText =
    micrositeFormElements?.[micrositeFormElements?.length - 1]?.textValue;
  return (
    <div className="template-body noneClickable">
      <Row style={{ boxShadow: "0px 1px 7px rgba(18, 65, 115, 0.2)" }}>
        <Col
          span={10}
          style={{
            backgroundColor: micrositeStyle?.themeStyle?.backgroundColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            className="template-form-company-text"
            style={{ fontSize: 8.5, textAlign: "center", padding: 10 }}
            dangerouslySetInnerHTML={{
              __html: companyText ? companyText : "",
            }}
          ></p>
        </Col>

        <Col
          span={14}
          style={{
            padding: 5,
            backgroundColor:
              micrositeStyle?.themeStyle?.secondaryBackgroundColor,
            backgroundImage: `url("${micrositeStyle?.themeStyle?.backgroundImageUrl}")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: `${micrositeStyle?.themeStyle?.backgroundWidth}% ${micrositeStyle?.themeStyle?.backgroundHeight}%`,
          }}
        >
          <img
            src={micrositeStyle?.logoUrl}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
              height: "15px",
              margin: "auto",
            }}
            alt="logo"
          />
          <p
            className="template-form-heading"
            style={{ marginBottom: 4 }}
            dangerouslySetInnerHTML={{
              __html: headingText ? headingText : "",
            }}
          ></p>

          <Form layout="vertical">
            <Row gutter={5}>
              <Col span={12}>
                <Form.Item label="First name">
                  <Input
                    style={{ height: 18, fontSize: 9, padding: 2 }}
                    value="Eric"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Last name">
                  <Input
                    style={{ height: 18, fontSize: 9, padding: 2 }}
                    value="Smith"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={5}>
              <Col span={12}>
                <Form.Item label="Phone No.">
                  <Input
                    style={{ height: 18, fontSize: 9, padding: 2 }}
                    value="+165 254 7894"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  <Input
                    style={{ height: 18, fontSize: 9, padding: 2 }}
                    value="eric@healthfit.com"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Button
              className="secondary-btn"
              shape="round"
              style={{ fontSize: 12, height: "auto", padding: "2px 14px" }}
            >
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
