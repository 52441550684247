import FullPageLayout from "containers/FullPageLayout";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

class PublicRoutes extends Component {
  render() {
    const { token, groups, render, verificationDetails, ...rest } = this.props;
    const storeDetails = localStorage.getItem("storeDetails");
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {token && groups.length > 0 && (
              <Redirect from={matchProps.path} to="/" />
            )}
            {token &&
              groups?.length === 0 &&
              !storeDetails &&
              Object.keys(verificationDetails)?.length <= 0 && (
                <Redirect from={matchProps.path} to="/setup" />
              )}
            {token && groups.length >= 0 && storeDetails && (
              <Redirect from={matchProps.path} to="/unboxing-tour" />
            )}
            {!token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    errors: state.errors,
    verificationDetails: state.loginReducer.verificationDetails,
  };
};

export default connect(mapStateToProps)(PublicRoutes);
