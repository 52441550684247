import React from "react";
import termspdf from "../../../assets/pdf/Terms&Condition.pdf";
function TermsConditionPDF() {
  return (
    <div>
      <iframe
        title="terms and condition pdf"
        src={termspdf}
        style={{ width: "100%", height: "100vh", border: "none" }}
      />
    </div>
  );
}

export default TermsConditionPDF;
