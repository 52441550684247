import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Input,
  Spin,
  Modal,
  Typography,
  message,
} from "antd";

import greenShopifyLogo from "./../../assets/Images/greenShopifyLogo.svg";
import NoProductBox from "./../../assets/Images/NoProductBox.svg";
import verifiedIcon from "../../assets/Images/verifiedIcon.svg";
import giftBox from "./../../assets/Images/giftBox.svg";
import "./shopifySteppers.css";

import React, { useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { updateShopifyProfile } from "graphql/mutations";
import { useSelector, useDispatch } from "react-redux";
import { logout, setVerificationDetails } from "store/actions/loginAction";
import axios from "axios";

const ShopifySteppers = () => {
  const { Search } = Input;
  const dispatch = useDispatch();

  const { userData, verificationDetails } = useSelector(
    (state) => state.loginReducer
  );

  const [shopifyProducts, setShopifyProducts] = useState(null);
  const [searchedProductList, setSearchedProductList] = useState([]);
  const [shopifyProductLoader, setshopifyProductLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [selectedShopifyProductIds, setSelectedShopifyProductIds] = useState(
    []
  );
  const [selectedShopifyProductList, setSelectedShopifyProductList] = useState(
    []
  );

  const getShopifyProducts = async () => {
    setshopifyProductLoader(true);
    try {
      let apiName = "blustreamREST";
      let path = "/shopify-intergration/shopify/products";
      let body = {
        accountId: verificationDetails?.shopify_profile?.account,
      };

      const response = await API.post(apiName, path, { body: body });
      setShopifyProducts(response?.products);
      structureData(response?.products);
    } catch (error) {
      console.log(error);
    } finally {
      setshopifyProductLoader(false);
    }
  };

  const structureData = async (response) => {
    let grouped_products = {};

    response?.forEach((product) => {
      let product_type = product?.product_type || "Other";

      if (!grouped_products[product_type]) {
        grouped_products[product_type] = [];
      }

      grouped_products[product_type].push({
        id: product?.id,
        title: product?.title,
        product_type: product_type,
        image: product?.image,
      });
    });

    const result = Object.entries(grouped_products)?.map(([key, value]) => ({
      type: key,
      products: value,
    }));
    result.forEach((item) => {
      item?.products?.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }

        if (titleA > titleB) {
          return 1;
        }

        return 0;
      });
    });
    result.sort((a, b) => {
      const typeA = a.type.toUpperCase();
      const typeB = b.type.toUpperCase();

      if (typeA < typeB) {
        return -1;
      }

      if (typeA > typeB) {
        return 1;
      }

      return 0;
    });
    setSearchedProductList(result);
  };

  const filterProductList = (event) => {
    const list = shopifyProducts?.filter((product) =>
      product?.title.toLowerCase().includes(event.toLowerCase())
    );
    structureData(list);
  };

  const selectedShopifyProduct = (value) => {
    let ids;
    if (selectedShopifyProductIds?.includes(value)) {
      ids = selectedShopifyProductIds?.filter((id) => id !== value);
    } else {
      ids = [...selectedShopifyProductIds, value];
    }
    setSelectedShopifyProductIds(ids);
    setSelectedShopifyProductList(
      shopifyProducts?.filter((product) => {
        return ids.includes(product.id);
      })
    );
  };

  const hideSteper = async () => {
    try {
      await API.graphql(
        graphqlOperation(updateShopifyProfile, {
          input: {
            id: verificationDetails?.shopify_profile?.id,
            showSteper: false,
          },
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const sendDataToHubspot = async () => {
    try {
      await API.post(
        "blustreamREST",
        "/shopify-intergration/send_shopify_products_to_hubspot",
        {
          body: {
            email: userData?.email,
            products: selectedShopifyProductList
              ?.map((product, index) => `${index + 1}.${product?.title}`)
              .join(", "),
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const productSubmitTracker = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let apiName = "blustreamREST";
    let path = "/shopify-intergration/notify_about_user_tracker_event";
    let body = {
      event_name: "Shopify Products Picked",
      account_id: verificationDetails?.shopify_profile?.account,
      email: userData?.email,
      firstname: userData?.given_name,
      lastname: userData?.family_name,
      ipaddress: res.data.IPv4,
      products: selectedShopifyProductList
        ?.map((product) => product?.title)
        .join(","),
    };

    await API.post(apiName, path, { body: body });
  };

  const handleSignOut = async () => {
    Auth.signOut()
      .then((data) => {
        localStorage.removeItem("storeDetails");
        dispatch(logout());
      })
      .catch((err) => console.log(err));
  };

  return shopifyProductLoader ? (
    <div className="shopify-product-fetch-loader-container">
      <Spin size="large" className="shopify-loader" />
      <h2>Fetching your products…</h2>
    </div>
  ) : (
    <>
      <Row justify="center">
        <Col span={20} className="shopify-steppers-main-col">
          <Button
            className="standard-button tertiary-button stepper-logout"
            onClick={() => {
              handleSignOut();
            }}
          >
            Logout
          </Button>
          <Row justify="center">
            <Col span={20} className="shopify-steppers-sub-col">
              <div className="progress-bar-container">
                <div
                  className="store-detail-container"
                  style={{
                    backgroundColor: "#e4f5f1",
                  }}
                >
                  <img
                    className="success-icon"
                    src={verifiedIcon}
                    alt="verifiedIcon"
                  />
                  <p className="store-text-message">
                    {`Connected with ${verificationDetails?.shopify_profile?.store_name} Shopify store`}
                  </p>
                </div>
                <p className="unboxing-text">
                  Let’s set up a 5-star unboxing experience for some of your
                  products. Pick which ones we should start with.
                </p>
                <div className="shopify-search-container">
                  <div className="shopify-logo-wrapper">
                    <img
                      className="shopify-logo"
                      src={greenShopifyLogo}
                      alt=""
                    />
                  </div>
                  <div className="shopify-product-search">
                    <Search
                      className="shopify-search standard-searchbox"
                      prefix={
                        <SearchOutlined className="standard-searchbox-prefix" />
                      }
                      placeholder="Search"
                      style={{ height: "42px" }}
                      onChange={(event) =>
                        filterProductList(event.target.value)
                      }
                    />
                  </div>
                </div>
                <Row justify="center" style={{ width: "100%" }}>
                  <Col span={16}>
                    {shopifyProducts?.length > 0 ? (
                      <Row
                        style={{
                          maxHeight: "500px",
                          overflow: "scroll",
                        }}
                      >
                        {searchedProductList?.map((typeObject) => {
                          return (
                            <Col
                              span={24}
                              style={{ backgroundColor: "#FBFBFB" }}
                            >
                              <div className="product-type-title">
                                {typeObject?.type}
                              </div>

                              <Row style={{ margin: "0px 18px" }}>
                                {typeObject?.products?.map((product) => {
                                  return (
                                    <Col
                                      className="shopify-product-container"
                                      span={24}
                                      xs={24}
                                      sm={12}
                                      xl={12}
                                      xxl={8}
                                    >
                                      <Checkbox
                                        value={product?.id}
                                        onChange={(event) =>
                                          selectedShopifyProduct(
                                            event.target.value
                                          )
                                        }
                                        checked={selectedShopifyProductIds?.includes(
                                          product?.id
                                        )}
                                      />
                                      <div className="shopify-product">
                                        <img
                                          className="shopify-product-image"
                                          src={product?.image?.src}
                                          alt=""
                                        />
                                        <span className="shopify-product-name">
                                          {product?.title}
                                        </span>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <Row
                        style={{
                          maxHeight: "500px",
                          overflow: "scroll",
                        }}
                      >
                        <Col
                          span={24}
                          style={{
                            height: "500px",
                            backgroundColor: "#FBFBFB",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="no-product-container">
                            <img src={NoProductBox} alt="" />
                            <p className="no-product-text">
                              No products found in your store!
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col className="steper-1">
                        <Button
                          className="standard-button primary-orange-button"
                          onClick={async () => {
                            try {
                              setSubmitLoader(true);
                              await hideSteper();
                              await sendDataToHubspot();
                              await productSubmitTracker();
                              dispatch(
                                setVerificationDetails({
                                  ...verificationDetails,
                                  shopify_profile: {
                                    ...verificationDetails?.shopify_profile,
                                    showSteper: false,
                                  },
                                })
                              );
                            } catch (error) {
                              console.log(error);
                              message.error("Something went wrong!");
                            } finally {
                              setSubmitLoader(false);
                            }
                          }}
                          loading={submitLoader}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title={null}
        visible={showWelcomeModal}
        closable={false}
        footer={null}
        className="welcome-modal"
        centered
      >
        <div className="welcome-modal-content">
          <img src={giftBox} alt="giftBox" />
          <Typography.Title className="welcome-title">
            Welcome to Blustream
          </Typography.Title>
          <Typography.Text className="welcome-text">
            You're in! Let's get you started with the best 5-star unboxing
            experiences for your customers.
          </Typography.Text>
          <Button
            className="standard-button primary-orange-button"
            onClick={() => {
              setShowWelcomeModal(false);
              getShopifyProducts();
            }}
          >
            Continue
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ShopifySteppers;
