import { convertToRaw, EditorState, ContentState } from "draft-js";
import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "./textEditor.css";
import { message } from "antd";

export default function TextEditor({ initialHtmlContent, onChange }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setEditorState(htmlToDraftBlocks(initialHtmlContent));
  }, []);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(html);
  };

  const isUrl = (url) => {
    // Regular expression to validate URL format
    //this is also url pattern --> /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  };

  return (
    <>
      <div className="text-editor">
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "colorPicker",
              "link",
              "emoji",
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "history",
              "remove",
            ],
            link: {
              defaultTargetOption: "_blank",
              linkCallback: (object) => {
                const isValidated = isUrl(object?.target);
                return isValidated
                  ? object
                  : message.error("Please enter valid url!");
              },
            },
            inline: {
              options: [
                "bold",
                "italic",
                "underline",
                "superscript",
                "subscript",
              ],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
            colorPicker: {
              colors: [
                "rgb(97,189,109)",
                "rgb(26,188,156)",
                "rgb(84,172,210)",
                "rgb(44,130,201)",
                "rgb(147,101,184)",
                "rgb(71,85,119)",
                "rgb(204,204,204)",
                "rgb(65,168,95)",
                "rgb(0,168,133)",
                "rgb(61,142,185)",
                "rgb(41,105,176)",
                "rgb(85,57,130)",
                "rgb(40,50,78)",
                "rgb(0,0,0)",
                "rgb(247,218,100)",
                "rgb(251,160,38)",
                "rgb(235,107,86)",
                "rgb(226,80,65)",
                "rgb(163,143,132)",
                "rgb(239,239,239)",
                "rgb(255,255,255)",
                "rgb(250,197,28)",
                "rgb(243,121,52)",
                "rgb(209,72,65)",
                "rgb(184,49,47)",
                "rgb(124,112,107)",
                "rgb(209,213,216)",
              ],
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
              ],
            },
            fontFamily: {
              options: [
                "Arial",
                "Georgia",
                "Impact",
                "Tahoma",
                "Times New Roman",
                "Verdana",
              ],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            emoji: {
              emojis: [
                "😀",
                "😁",
                "😂",
                "😃",
                "😉",
                "😋",
                "😎",
                "😍",
                "😗",
                "🤗",
                "🤔",
                "😣",
                "😫",
                "😴",
                "😌",
                "🤓",
                "😛",
                "😜",
                "😠",
                "😇",
                "😷",
                "😈",
                "👻",
                "😺",
                "😸",
                "😹",
                "😻",
                "😼",
                "😽",
                "🙀",
                "🙈",
                "🙉",
                "🙊",
                "👼",
                "👮",
                "🕵",
                "💂",
                "👳",
                "🎅",
                "👸",
                "👰",
                "👲",
                "🙍",
                "🙇",
                "🚶",
                "🏃",
                "💃",
                "⛷",
                "🏂",
                "🏌",
                "🏄",
                "🚣",
                "🏊",
                "⛹",
                "🏋",
                "🚴",
                "👫",
                "💪",
                "👈",
                "👉",
                "👉",
                "👆",
                "🖕",
                "👇",
                "🖖",
                "🤘",
                "🖐",
                "👌",
                "👍",
                "👎",
                "✊",
                "👊",
                "👏",
                "🙌",
                "🙏",
                "🐵",
                "🐶",
                "🐇",
                "🐥",
                "🐸",
                "🐌",
                "🐛",
                "🐜",
                "🐝",
                "🍉",
                "🍄",
                "🍔",
                "🍤",
                "🍨",
                "🍪",
                "🎂",
                "🍰",
                "🍾",
                "🍷",
                "🍸",
                "🍺",
                "🌍",
                "🚑",
                "⏰",
                "🌙",
                "🌝",
                "🌞",
                "⭐",
                "🌟",
                "🌠",
                "🌨",
                "🌩",
                "⛄",
                "🔥",
                "🎄",
                "🎈",
                "🎉",
                "🎊",
                "🎁",
                "🎗",
                "🏀",
                "🏈",
                "🎲",
                "🔇",
                "🔈",
                "📣",
                "🔔",
                "🎵",
                "🎷",
                "💰",
                "🖊",
                "📅",
                "✅",
                "❎",
                "💯",
              ],
            },
          }}
        />
      </div>
    </>
  );
}
