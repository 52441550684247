import React, { useState } from "react";
import { Button, Col, Form, Popover, Row, Select, Modal } from "antd";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { EditOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setMicrositeDefaultStyle } from "../../../store/actions/SettingAction";
import { invertColor } from "../../../utility/LightenDarkenColor";

const { Option } = Select;

export default function FormSettings() {
  const initialStyle = {
    questionStyle: {
      fontFamily: "arial",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    answerStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    descriptionStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    fieldStyle: {
      backgroundColor: "#ffffff",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "7px",
    },
  };

  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );
  const { roAdmin } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const [visible, setvisible] = useState(false);

  const handleCancel = () => {
    setvisible(false);
  };

  const content = (data) => {
    return (
      <div style={{ padding: "20px" }}>
        <HexColorPicker
          color={defaultColor(data)}
          onChange={(e) => handleSetColor(data, e)}
          style={{ border: "1px solid black", borderRadius: "8px" }}
        />
        <HexColorInput
          color={defaultColor(data)}
          onChange={(e) => handleSetColor(data, e)}
          style={{
            border: "1px solid black",
            borderRadius: "3px",
            marginTop: "5px",
            width: "100%",
            textAlign: "center",
          }}
          placeholder="Type a color"
        />
      </div>
    );
  };
  const defaultColor = (data, e) => {
    if (data === "question") {
      return micrositeDefaultStyle?.questionStyle.color;
    } else if (data === "desc") {
      return micrositeDefaultStyle?.descriptionStyle.color;
    } else if (data === "answer") {
      return micrositeDefaultStyle?.answerStyle.color;
    } else if (data === "error") {
      return micrositeDefaultStyle?.errorMessageColor;
    } else if (data === "fieldbackground") {
      return micrositeDefaultStyle?.fieldStyle.backgroundColor;
    } else {
      return micrositeDefaultStyle?.fieldStyle.borderColor;
    }
  };

  const handleSetColor = (data, e) => {
    if (data === "question") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          questionStyle: {
            ...micrositeDefaultStyle?.questionStyle,
            color: e,
          },
        })
      );
    } else if (data === "desc") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          descriptionStyle: {
            ...micrositeDefaultStyle?.descriptionStyle,
            color: e,
          },
        })
      );
    } else if (data === "answer") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          answerStyle: {
            ...micrositeDefaultStyle?.answerStyle,
            color: e,
          },
        })
      );
    } else if (data === "error") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          errorMessageColor: e,
        })
      );
    } else if (data === "fieldbackground") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          fieldStyle: {
            ...micrositeDefaultStyle?.fieldStyle,
            backgroundColor: e,
          },
        })
      );
    } else {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          fieldStyle: {
            ...micrositeDefaultStyle?.fieldStyle,
            borderColor: e,
          },
        })
      );
    }
  };

  return (
    <>
      <Form className="typography-form form-grid" layout="horizontal">
        <Form.Item label="Border">
          <Select
            value={micrositeDefaultStyle?.fieldStyle?.borderWidth}
            style={{ width: "33%" }}
            className="Opt-1 round"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  fieldStyle: {
                    ...micrositeDefaultStyle?.fieldStyle,
                    borderWidth: `${e}px`,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Label">
          <Select
            value={micrositeDefaultStyle?.questionStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  questionStyle: {
                    ...micrositeDefaultStyle?.questionStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.questionStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-2"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  questionStyle: {
                    ...micrositeDefaultStyle?.questionStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={
              micrositeDefaultStyle?.questionStyle?.fontStyle === "Italic"
                ? micrositeDefaultStyle?.questionStyle?.fontStyle
                : micrositeDefaultStyle?.questionStyle?.fontWeight === 700
                ? "Bold"
                : "SemiB"
            }
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      questionStyle: {
                        ...micrositeDefaultStyle?.questionStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      questionStyle: {
                        ...micrositeDefaultStyle?.questionStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      questionStyle: {
                        ...micrositeDefaultStyle?.questionStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
            disabled={roAdmin}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Description">
          <Select
            value={micrositeDefaultStyle?.descriptionStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  descriptionStyle: {
                    ...micrositeDefaultStyle?.descriptionStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.descriptionStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-2"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  descriptionStyle: {
                    ...micrositeDefaultStyle?.descriptionStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={
              micrositeDefaultStyle?.descriptionStyle?.fontStyle === "Italic"
                ? micrositeDefaultStyle?.descriptionStyle?.fontStyle
                : micrositeDefaultStyle?.descriptionStyle?.fontWeight === 700
                ? "Bold"
                : "SemiB"
            }
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      descriptionStyle: {
                        ...micrositeDefaultStyle?.descriptionStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      descriptionStyle: {
                        ...micrositeDefaultStyle?.descriptionStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      descriptionStyle: {
                        ...micrositeDefaultStyle?.descriptionStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
            disabled={roAdmin}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Answer">
          <Select
            value={micrositeDefaultStyle?.answerStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  answerStyle: {
                    ...micrositeDefaultStyle?.answerStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.answerStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-2"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  answerStyle: {
                    ...micrositeDefaultStyle?.answerStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={
              micrositeDefaultStyle?.answerStyle?.fontStyle === "Italic"
                ? micrositeDefaultStyle?.answerStyle?.fontStyle
                : micrositeDefaultStyle?.answerStyle?.fontWeight === 700
                ? "Bold"
                : "SemiB"
            }
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      answerStyle: {
                        ...micrositeDefaultStyle?.answerStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      answerStyle: {
                        ...micrositeDefaultStyle?.answerStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      answerStyle: {
                        ...micrositeDefaultStyle?.answerStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
            disabled={roAdmin}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>
      </Form>

      <Row style={{ marginTop: 10 }}>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Label Text</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
                color: "white",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.questionStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.questionStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.questionStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("question", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("question")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Description Text</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
                color: "white",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.descriptionStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.descriptionStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.descriptionStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("desc", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("desc")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined style={{ fontSize: 15 }} />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Answer Text</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
                color: "white",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.answerStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.answerStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.answerStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("answer", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("answer")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined style={{ fontSize: 15 }} />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row style={{ marginTop: 10 }}>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Error Messages</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                background: micrositeDefaultStyle?.errorMessageColor,
                borderRadius: "7px 0 0 7px",
                color: "white",
              }}
            >
              <p style={{ marginBottom: 0, padding: "5px 0 0 5px",color:`${invertColor(micrositeDefaultStyle?.errorMessageColor)}` }}>
                {micrositeDefaultStyle?.errorMessageColor}
              </p>
            </Col>
            <Col span={6}>
              <Popover content={() => content("error")}>
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined style={{ fontSize: 15 }} />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row style={{ marginTop: 10 }}>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Field Background</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
                color: "black",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.fieldStyle?.backgroundColor}
                style={{
                  background:
                    micrositeDefaultStyle?.fieldStyle?.backgroundColor,
                  color: `${invertColor(
                    micrositeDefaultStyle?.fieldStyle?.backgroundColor
                  )}`,
                }}
                onChange={(e) => handleSetColor("fieldbackground", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("fieldbackground")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined style={{ fontSize: 15 }} />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Field Border</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
                color: "black",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.fieldStyle?.borderColor}
                style={{
                  background: micrositeDefaultStyle?.fieldStyle?.borderColor,
                  color: `${invertColor(
                    micrositeDefaultStyle?.fieldStyle?.borderColor
                  )}`,
                }}
                onChange={(e) => handleSetColor("border", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("border")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined style={{ fontSize: 15 }} />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }} className="typography-setting-wrapper">
        <Col span={24}>
          <Button
            className="standard-button primary-dark-blue-button"
            disabled={roAdmin}
            onClick={() => {
              setvisible(true);
            }}
          >
            Reset Form
          </Button>
        </Col>
      </Row>
      <Modal
        visible={visible}
        footer={null}
        className="btn-modal"
        onCancel={handleCancel}
      >
        <div className="form-delete-modal">
          <p className="delete-modal-text">
            Are you sure you want to reset Form?
          </p>
          <div className="delete-modal-button-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => handleCancel()}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                dispatch(
                  setMicrositeDefaultStyle({
                    ...micrositeDefaultStyle,
                    questionStyle: initialStyle?.questionStyle,
                    answerStyle: initialStyle?.answerStyle,
                    descriptionStyle: initialStyle?.descriptionStyle,
                    fieldStyle: initialStyle?.fieldStyle,
                  })
                );
                handleCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
