import React from "react";
import logo from "assets/Images/logo_blue.svg";
import { useHistory } from "react-router-dom";

const Logo = (props) => {
  const history = useHistory();
  return (
    <div className="company_logo" style={{ height: "auto", marginTop: "10px" }}>
      <img
        src={logo}
        height={30}
        alt="Blustream Logo"
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (Object.keys(props).length > 0) {
            props.handleChangKey("dashboard");
          }
          if (history.location.pathname !== "/setup") {
            history.push("/");
          }
        }}
      />
    </div>
  );
};

export default Logo;
