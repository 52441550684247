import { Button, Drawer, Empty, Form, Input, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTnC,
  updateTnC,
  getPrivacyPolicy,
  updatePrivacyPolicy,
  setTncShow,
  setPrShow,
} from "store/actions/SettingAction";
import { PenIcon } from "utility/CustomIcons";

function TermsAndCondition() {
  const dispatch = useDispatch();
  const { termAndConditions, loading, privacyPolicy } = useSelector(
    (state) => state.SettingReducer
  );
  const { roAdmin } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    dispatch(getTnC());
    dispatch(getPrivacyPolicy());
  }, [dispatch]);

  const UpdateTnC = useCallback((values) => {
    let inputData = {
      url: values?.url,
    };
    if (termAndConditions) {
      inputData.id = termAndConditions?.id;
    }
    dispatch(updateTnC(inputData)).then((res) => {
      setaddTncDrawer(false);
    });
    dispatch(setTncShow(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdatePrivacyPolicy = useCallback((values) => {
    let inputData = {
      url: values?.privacypolicy,
    };
    if (privacyPolicy) {
      inputData.id = privacyPolicy?.id;
    }
    dispatch(updatePrivacyPolicy(inputData)).then((res) => {
      setaddPrivacyDrawer(false);
    });
    dispatch(setPrShow(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addTncDrawer, setaddTncDrawer] = useState(false);
  const [addPrivacyDrawer, setaddPrivacyDrawer] = useState(false);

  return (
    <div className="tnc">
      <Typography.Title level={5} style={{ flex: 1 }}>
        Current Terms and Conditions
      </Typography.Title>
      <div
        style={{
          paddingBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        {termAndConditions ? (
          // <iframe src={termAndConditions?.url} title="title" style={{width:'100%', height:600, border:'none'}}>
          // </iframe>
          <a
            href={termAndConditions?.url}
            style={{
              border: "none",
              color: "black",
              textDecoration: "underline",
            }}
          >
            Terms & Conditions
          </a>
        ) : (
          <div
            style={{
              height: "100%",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty description={"Terms and Conditions not found"} />
          </div>
        )}
        <Button
          className="antd-pen-icon"
          onClick={() => setaddTncDrawer(true)}
          disabled={roAdmin}
          style={{
            border: "none",
            boxShadow: "none",
          }}
        >
          <PenIcon />
        </Button>
      </div>

      <div>
        <div
          style={{
            paddingBottom: 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              textDecoration: "underline",
            }}
            rel="noreferrer"
            href={privacyPolicy?.url}
          >
            Privacy Policy
          </a>
          <Button
            className="antd-pen-icon"
            onClick={() => setaddPrivacyDrawer(true)}
            disabled={roAdmin}
            style={{
              border: "none",
              boxShadow: "none",
            }}
          >
            <PenIcon />
          </Button>
        </div>
      </div>

      <Drawer
        visible={addTncDrawer}
        title={<strong>Edit Company Details</strong>}
        width={416}
        bodyStyle={{ paddingBottom: 80 }}
        onClose={() => {
          setaddTncDrawer(false);
        }}
        destroyOnClose
        maskClosable={false}
      >
        <Form
          layout="vertical"
          className="edit_account_form"
          name="edit_account_form"
          requiredMark={"optional"}
          onFinish={UpdateTnC}
          initialValues={{ url: termAndConditions?.url }}
        >
          <Form.Item
            name="url"
            style={{
              marginTop: "0px",
              marginBottom: "12px",
            }}
            label={<span>Enter hosted link</span>}
            rules={[
              {
                required: true,
                message: "Please enter hosted link of terms and conditions",
              },
              {
                pattern:
                  /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                message: "Please enter valid URL",
              },
            ]}
          >
            <Input
              className="standard-input"
              size="large"
              id="url"
              key="url"
              name="url"
              placeholder="Terms And Conditions URL"
            />
          </Form.Item>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
              display: "flex",
            }}
          >
            <Button
              className="standard-button tertiary-button"
              onClick={() => {
                setaddTncDrawer(false);
              }}
              style={{
                marginRight: 8,
                width: "48%",
                border: "1px solid lightgray",
              }}
              size="middle"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="standard-button primary-orange-button"
              size="middle"
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Drawer>
      <Drawer
        visible={addPrivacyDrawer}
        title={<strong>Edit Company Details</strong>}
        width={416}
        bodyStyle={{ paddingBottom: 80 }}
        onClose={() => {
          setaddPrivacyDrawer(false);
        }}
        destroyOnClose
        maskClosable={false}
      >
        <Form
          layout="vertical"
          className="edit_account_form"
          name="edit_account_form"
          requiredMark={"optional"}
          onFinish={UpdatePrivacyPolicy}
          initialValues={{ privacypolicy: privacyPolicy?.url }}
        >
          <Form.Item
            name="privacypolicy"
            style={{
              marginTop: "0px",
              marginBottom: "12px",
            }}
            label={<span>Enter hosted link</span>}
            rules={[
              {
                required: true,
                message: "Please enter hosted link of terms and conditions",
              },
              {
                pattern:
                  /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                message: "Please enter valid URL",
              },
            ]}
          >
            <Input
              className="standard-input"
              size="large"
              id="privacypolicy"
              key="privacypolicy"
              name="privacypolicy"
              placeholder="Privacy Policy URL"
            />
          </Form.Item>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
              display: "flex",
            }}
          >
            <Button
              className="standard-button tertiary-button"
              onClick={() => {
                setaddPrivacyDrawer(false);
              }}
              style={{
                marginRight: 8,
                width: "48%",
                border: "1px solid lightgray",
              }}
              size="middle"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="standard-button primary-orange-button"
              size="middle"
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  );
}

export default TermsAndCondition;
