/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Card, Radio, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const PublicResponsiveBilling = ({
  messageCostobject,
  switchState,
  onChange,
  toCommas,
}) => {
  const [list, setList] = useState([]);
  const [planValue, setPlanValue] = useState();

  const plansData = [
    {
      id: "f3c55c11-d593-43c9-8e61-28d12ddccee9",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":1000}',
      stripePriceID: [
        "price_1MCewoF2ZqjBhZSfJ9Pz1H3E",
        "price_1MCevhF2ZqjBhZSfAb0S91dP",
      ],
      priceValues:
        '{"price_1MCevhF2ZqjBhZSfAb0S91dP":{"billing_period":"metered","value":"0"},"price_1MCewoF2ZqjBhZSfJ9Pz1H3E":{"billing_period":"month","value":"199"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-08T13:37:05.103Z",
    },
    {
      id: "f3c55c11-d593-43c9-8e61-28d12ddcced8",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":5000}',
      stripePriceID: [
        "price_1LBc2MF2ZqjBhZSfRUnUHoWV",
        "price_1LBcARF2ZqjBhZSfSTPT3LAm",
        "price_1LBc9JF2ZqjBhZSfyEdwefSQ",
      ],
      priceValues:
        '{"price_1LBc9JF2ZqjBhZSfyEdwefSQ":{"billing_period":"year","value":"6710"},"price_1LBcARF2ZqjBhZSfSTPT3LAm":{"billing_period":"metered","value":"0"},"price_1LBc2MF2ZqjBhZSfRUnUHoWV":{"billing_period":"month","value":"699"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-02T19:12:33.103Z",
    },
    {
      id: "f3c55c11-d593-43c9-8e61-28d12eecced8",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":10000}',
      stripePriceID: [
        "price_1LBc2bF2ZqjBhZSfMIoSv1Nu",
        "price_1LBcC1F2ZqjBhZSfrNCTcty8",
        "price_1LBcDGF2ZqjBhZSf83cf01Jd",
      ],
      priceValues:
        '{"price_1LBc2bF2ZqjBhZSfMIoSv1Nu":{"billing_period":"month","value":"1299"},"price_1LBcDGF2ZqjBhZSf83cf01Jd":{"billing_period":"year","value":"12470"},"price_1LBcC1F2ZqjBhZSfrNCTcty8":{"billing_period":"metered","value":"0"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-02T19:12:33.103Z",
    },
    {
      id: "f3c55c11-d593-43c9-8e61-28d12ddccdd8",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":2500}',
      stripePriceID: [
        "price_1LBc2AF2ZqjBhZSfKbkzaLWp",
        "price_1LBc4wF2ZqjBhZSf9jzKmD4n",
        "price_1LBc5uF2ZqjBhZSffJ8P8ldP",
      ],
      priceValues:
        '{"price_1LBc4wF2ZqjBhZSf9jzKmD4n":{"billing_period":"metered","value":"0"},"price_1LBc2AF2ZqjBhZSfKbkzaLWp":{"billing_period":"month","value":"399"},"price_1LBc5uF2ZqjBhZSffJ8P8ldP":{"billing_period":"year","value":"3830"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-02T19:12:33.103Z",
    },
    {
      id: "8a526a44-cf27-467c-6391-435f141af7bc",
      name: "Pro",
      description: "Learn more about this plan from our sales team",
      limits: '{"touchpoints":100000}',
      stripePriceID: [
        "price_1LBcQLF2ZqjBhZSfiPpLpqP5",
        "price_1LBcR4F2ZqjBhZSfSwGkbBtG",
        "price_1LBcPwF2ZqjBhZSfZhVoKQvR",
      ],
      priceValues:
        '{"price_1LBcR4F2ZqjBhZSfSwGkbBtG":{"billing_period":"metered","value":"0"},"price_1LBcQLF2ZqjBhZSfiPpLpqP5":{"billing_period":"month","value":"5999"},"price_1LBcPwF2ZqjBhZSfZhVoKQvR":{"billing_period":"year","value":"57590"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:13:12.746Z",
      updatedAt: "2021-12-02T19:13:12.746Z",
    },
    {
      id: "ba10ffdd-df43-42af-980a-464e99b7d65d",
      name: "Standard",
      description: "Standard Plan",
      limits: '{"touchpoints":50000}',
      stripePriceID: [
        "price_1LBcNaF2ZqjBhZSfyt3mVLFi",
        "price_1LBcMZF2ZqjBhZSfFOYCx0fU",
        "price_1LBcNRF2ZqjBhZSfVWAfnmUm",
      ],
      priceValues:
        '{"price_1LBcNaF2ZqjBhZSfyt3mVLFi":{"billing_period":"month","value":"3999"},"price_1LBcMZF2ZqjBhZSfFOYCx0fU":{"billing_period":"metered","value":"0"},"price_1LBcNRF2ZqjBhZSfVWAfnmUm":{"billing_period":"year","value":"38390"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:13:12.746Z",
      updatedAt: "2021-12-02T19:13:12.746Z",
    },
    {
      id: "aa10ffdd-df43-42af-980a-464e99b7d65d",
      name: "Standard",
      description: "Standard Plan",
      limits: '{"touchpoints":25000}',
      stripePriceID: [
        "price_1LBcJCF2ZqjBhZSfr59c5mCM",
        "price_1LBcKQF2ZqjBhZSfrBPyhkcl",
        "price_1LBcJPF2ZqjBhZSfZp7v0Ikt",
      ],
      priceValues:
        '{"price_1LBcKQF2ZqjBhZSfrBPyhkcl":{"billing_period":"metered","value":"0"},"price_1LBcJCF2ZqjBhZSfr59c5mCM":{"billing_period":"month","value":"2499"},"price_1LBcJPF2ZqjBhZSfZp7v0Ikt":{"billing_period":"year","value":"23990"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:13:12.746Z",
      updatedAt: "2021-12-02T19:13:12.746Z",
    },
  ];

  //sorting plan list.
  useEffect(() => {
    const sortedPlanList = plansData.sort((a, b) => {
      return (
        Number(JSON.parse(a.limits)?.touchpoints) -
        Number(JSON.parse(b.limits)?.touchpoints)
      );
    });
    setList(sortedPlanList);
  }, []);

  return (
    <Row>
      <Col span={24} sm={24} lg={24} md={24} xl={24}>
        <div
          style={{
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <div
            style={{
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2
              style={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#252525",
                margin: "0px",
              }}
            >
              Choose Your Plan
            </h2>
          </div>
          {/* dev :- needed in future */}
          {/* <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%",background:"#EFF4F9",padding:"16px",borderRadius:'7px',marginTop:"28px"}}>
                <div style={{display:"flex",alignItems:"center"}}>
                <Typography.Text  className={`switch-text ${switchState ? "":"switchToggle"}`}>Messages</Typography.Text>
                <Switch value={switchState} onChange={onChange} style={{marginLeft: 7, background: '#124173', fontSize: 16}}/>
                <Typography.Text style={{marginLeft: 7}} className={`switch-text ${switchState ?'switchToggle':""}`}>Customer</Typography.Text>
                </div>
                <div>
                <Tooltip placement="left" title="Switch to see customer reach for the message range selected">
                <InfoCircleOutlined style={{cursor: 'pointer',marginLeft: 7, color: '#252525', fontSize: "17px"}}/>
                </Tooltip>
                </div>
              </div> */}
          <div
            style={{
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Card
              className="card-header"
              title="Lite"
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#252525",
                }}
              >
                Great for trials and companies with 1-1,000 customers
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7692AE",
                  marginBottom: "14px",
                }}
              >
                Choose number of {switchState ? "customers" : "messages"}
              </Typography.Text>
              <br />
              <Radio.Group
                onChange={(e) => {
                  setPlanValue(e.target.value);
                }}
                value={planValue}
              >
                <Space direction="vertical" style={{ gap: "10px" }}>
                  {list.slice(0, 4).map((plan) => {
                    const priceValues = plan && JSON.parse(plan?.priceValues);
                    const limit = plan && JSON.parse(plan?.limits);
                    return (
                      <Radio value={limit.touchpoints}>
                        <span className="radio-labels">
                          {switchState
                            ? limit.touchpoints / 5
                            : limit.touchpoints}
                        </span>
                        {planValue === limit.touchpoints && (
                          <div className="plan-details">
                            <span style={{ marginBottom: "12px" }}>
                              <span
                                style={{
                                  fontSize: "28px",
                                  fontWeight: "600",
                                  lineHeight: "34px",
                                }}
                              >
                                $
                                {priceValues &&
                                  toCommas(
                                    priceValues?.[plan?.stripePriceID[0]]?.value
                                  )}
                              </span>
                              <span>
                                /
                                {priceValues &&
                                  priceValues?.[plan?.stripePriceID[0]]
                                    ?.billing_period}
                              </span>
                            </span>
                            <span
                              style={{ marginBottom: "10px", fontSize: 16 }}
                            >
                              <CheckOutlined
                                style={{ color: "green", fontSize: 16 }}
                              />{" "}
                              ${messageCostobject[limit.touchpoints][0]}{" "}
                              Associated cost/message
                            </span>
                            <span
                              style={{ marginBottom: "20px", fontSize: 16 }}
                            >
                              <CheckOutlined
                                style={{ color: "green", fontSize: 16 }}
                              />{" "}
                              ${messageCostobject[limit.touchpoints][1]} Overage
                              rates/message
                            </span>
                          </div>
                        )}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </Card>
          </div>
          <div
            style={{
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Card
              className="card-header"
              title="Standard"
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#252525",
                }}
              >
                Great for small & medium businesses looking to grow with 1,000 -
                25,000 customers
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7692AE",
                  marginBottom: "14px",
                }}
              >
                Choose number of {switchState ? "customers" : "messages"}
              </Typography.Text>
              <br />
              <Radio.Group
                onChange={(e) => {
                  setPlanValue(e.target.value);
                }}
                value={planValue}
              >
                <Space direction="vertical" style={{ gap: "10px" }}>
                  {list.slice(4, 6).map((plan) => {
                    const priceValues = plan && JSON.parse(plan?.priceValues);
                    const limit = plan && JSON.parse(plan?.limits);
                    return (
                      <Radio value={limit.touchpoints}>
                        <span className="radio-labels">
                          {switchState
                            ? `${limit.touchpoints / 5}`
                            : limit.touchpoints}
                        </span>
                        {planValue === limit.touchpoints && (
                          <div className="plan-details">
                            <span style={{ marginBottom: "12px" }}>
                              <span
                                style={{
                                  fontSize: "28px",
                                  fontWeight: "600",
                                  lineHeight: "34px",
                                }}
                              >
                                $
                                {priceValues &&
                                  toCommas(
                                    priceValues?.[plan?.stripePriceID[0]]?.value
                                  )}
                              </span>
                              <span>
                                /
                                {priceValues &&
                                  priceValues?.[plan?.stripePriceID[0]]
                                    ?.billing_period}
                              </span>
                            </span>
                            <span
                              style={{ marginBottom: "10px", fontSize: 16 }}
                            >
                              <CheckOutlined
                                style={{ color: "green", fontSize: 16 }}
                              />{" "}
                              ${messageCostobject[limit.touchpoints][0]}{" "}
                              Associated cost/message
                            </span>
                            <span
                              style={{ marginBottom: "20px", fontSize: 16 }}
                            >
                              <CheckOutlined
                                style={{ color: "green", fontSize: 16 }}
                              />{" "}
                              ${messageCostobject[limit.touchpoints][1]} Overage
                              rates/message
                            </span>
                          </div>
                        )}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </Card>
          </div>
          <div
            style={{
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Card
              className="card-header"
              title="Pro"
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#252525",
                }}
              >
                Great for medium to larger businesses looking to scale widely
                with 25,000+ customers
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7692AE",
                  marginBottom: "14px",
                }}
              >
                Choose number of {switchState ? "customers" : "messages"}
              </Typography.Text>
              <br />
              <Radio.Group
                onChange={(e) => {
                  setPlanValue(e.target.value);
                }}
                value={planValue}
              >
                <Space direction="vertical" style={{ gap: "10px" }}>
                  {list.slice(6).map((plan) => {
                    const limit = plan && JSON.parse(plan?.limits);
                    return (
                      <Radio value={limit.touchpoints}>
                        <span className="radio-labels">
                          {switchState
                            ? `${limit.touchpoints / 5}`
                            : limit.touchpoints}
                          +
                        </span>
                        {planValue === limit.touchpoints && (
                          <div className="plan-details">
                            <span
                              className="pro-plan-text"
                              style={{ marginTop: "0px", marginBottom: "10px" }}
                            >
                              {plan?.description}
                            </span>
                          </div>
                        )}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </Card>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PublicResponsiveBilling;
