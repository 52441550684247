/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import sdk from "sdk/Accounts";
import Moment from "react-moment";
import {
  Button,
  Input,
  Space,
  Table,
  Typography,
  Form,
  Select,
  Modal,
  Row,
  Col,
  message,
  Tag,
  Skeleton,
  DatePicker,
  Image,
  Pagination,
  Tooltip,
  Checkbox,
} from "antd";
import "./Admin.css";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { PenIcon } from "utility/CustomIcons";
import { API, graphqlOperation } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import {
  addtags,
  getTags,
  createCustomTag,
  deleteCustomAccoutTag,
} from "store/actions/SettingAction";
import { setRoAdmin } from "store/actions/loginAction";
import {
  listPlans,
  listAdminPlans,
  listDelayStartAccounts,
  listShopifyProfiles,
} from "graphql/queries";
import billing from "sdk/Checkout";
import successIcon2 from "./../../assets/Images/successIcon2.svg";
import shopifyLock from "./../../assets/Images/shopifyLock.svg";
import shopifyUnlock from "./../../assets/Images/shopifyUnlock.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
import pen2 from "./../../assets/Images/pen2.svg";
import deleteIcon4 from "./../../assets/Images/deleteIcon4.svg";

import moment from "moment";
import { searchCustomerAccountsWithDashboardCustom } from "graphql/CustomQueries";
import { updateCustomerAccount } from "graphql/mutations";

const Company = (props) => {
  const { Search } = Input;

  const [tableloading, settableloading] = useState(false);
  const [SearchOption, setSearchOption] = useState("Name");
  const [searchValue, setSearchValue] = useState("");
  const [addCompanyModelShow, setaddCompanyModelShow] = useState(false);
  const [addcompanyform] = Form.useForm();
  const [addDashboardForm] = Form.useForm();
  const [searchCompanyForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [companyToEditStatus, setcompanyToEditStatus] = useState({});
  const [companyToEditDashboard, setcompanyToEditDashboard] = useState({});
  const [CompanyToEditTags, setCompanyToEditTags] = useState({});
  const [form] = Form.useForm();
  const [searchedValue, setSearchedValue] = useState("");
  const [accountStatus, setAccountStatus] = useState(null);
  const [updateStatusLoader, setUpdateStatusLoader] = useState(false);
  const [updateStatusLoaderFlag, setupdateStatusLoaderFlag] = useState(false);
  const [lateStartDate, setLateStartDate] = useState("");
  const [planList, setplanList] = useState([]);
  const [confirmCreateCompany, setConfirmCreateCompany] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [updateCompanyStatus, setUpdateCompanyStatus] = useState(false);
  const [purchasedPlan, setPurchasedPlan] = useState({});
  const [selectedPlateform, setSelectedPlateform] = useState("all");
  const [shopifyCompanyData, setShopifyCompanyData] = useState([]);
  const [showAddDashboardModal, setShowAddDashboardModal] = useState(false);
  const [dashboardsObject, setDashboardsObject] = useState({});
  const [dashboards, setDashboards] = useState([]);
  const [dashboardLoader, setDashboardLoader] = useState(false);
  const [selectedShopifyProfile, setSelectedShopifyProfile] = useState({});
  const [showLockUnlockModal, setShowLockUnlockModal] = useState(false);

  const [totalNumbersOfCompanies, setTotalNumbersOfCompanies] = useState(0);
  const [newCompaniesData, setNewCompaniesData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalNumberOfRecordsPerPage, setTotalNumberOfRecordsPerPage] =
    useState(10);
  const [selectedFilter, setSelectedFilter] = useState("descend");
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [companiesWithSelectedDashboard, setCompaniesWithSelectedDashboard] =
    useState([]);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] =
    useState(false);
  const [deleteDashboardLoader, setDeleteDashboardLoader] = useState(false);
  const [deleteIconclickLoading, setDeleteIconclickLoading] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(null);
  const [tagLoader, setTagLoader] = useState(false);
  const [isadminPlanDisable, setIsadminPlanDisable] = useState(false);
  const [hasManualBilling, setHasManualBilling] = useState(null);
  const [showManualBilling, setShowManualBilling] = useState(false);

  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const issorted = useRef(false);
  const account = useSelector((state) => state.loginReducer.groups);
  const { Tags } = useSelector((state) => state.SettingReducer);
  const { roAdmin } = useSelector((state) => state.loginReducer);
  // eslint-disable-next-line

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (name, company) => (
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div>
            {selectedPlateform === "shopify" ? (
              company?.isProfileLock ? (
                <img
                  src={shopifyLock}
                  alt=""
                  onClick={() => {
                    setSelectedShopifyProfile(company);
                    setShowLockUnlockModal(true);
                  }}
                />
              ) : (
                <img src={shopifyUnlock} alt="" />
              )
            ) : (
              ""
            )}
          </div>
          <div>{name}</div>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "company",
      key: "company",
      render: (name) => <span>{name}</span>,
    },
    {
      title: "View As",
      dataIndex: "company",
      key: "View As",
      render: (name, record) => (
        <div>
          <span
            title="Click here to select company for ro-admin view"
            className="view-option"
            onClick={() => {
              props.selectCompany(record, "company");
              dispatch(setRoAdmin(true));
            }}
          >
            RO Admin
          </span>
          {!account.includes("roadmin") && (
            <span
              title="Click here to select company for admin view"
              className="view-option"
              onClick={() => {
                props.selectCompany(record, "company");
                dispatch(setRoAdmin(false));
              }}
            >
              {" "}
              | Admin
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "accountStatus",
      key: "company_status",
      render: (status, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{status}</span>{" "}
            {!roAdmin ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  setupdateStatusLoaderFlag(index);
                  setUpdateStatusLoader(true);
                  await findLateStartDate(record?.id);
                  if (record?.source === "shopify") {
                    const response = await isShopifyConnected(record?.id);
                    if (response?.statusCode === 200) {
                      setIsadminPlanDisable(true);
                    } else {
                      setIsadminPlanDisable(false);
                    }
                  } else {
                    setIsadminPlanDisable(false);
                  }
                  if (record?.planID) {
                    await findPurchasedPlan(record?.planID);
                  } else {
                    await findAssignedPlan(record?.id);
                  }
                  setHasManualBilling(record?.manualBilling);
                  await getShopifyProfile(record?.id, record?.source);
                  setcompanyToEditStatus(record);
                  setUpdateStatusLoader(false);
                }}
              >
                {index === updateStatusLoaderFlag && updateStatusLoader ? (
                  <SyncOutlined
                    className="dashboard-delete-loading-icon"
                    spin
                  />
                ) : (
                  <PenIcon />
                )}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "Dashboard",
      dataIndex: "dashboardID",
      key: "dashboardID",
      render: (dashboard, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              {dashboardsObject[dashboard]
                ? dashboardsObject[dashboard]
                : "DEFAULT"}
            </span>
            {!roAdmin ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setcompanyToEditDashboard(record);
                }}
              >
                <PenIcon />
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tag, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: 300,
            }}
          >
            <span>
              {record.tags.items.length > 0 &&
                record.tags.items.map((item) => {
                  return <Tag>{item?.tag?.name}</Tag>;
                })}
            </span>
            {!roAdmin ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCompanyToEditTags(record);
                  form.setFieldsValue({
                    tags: record.tags?.items?.map((item) => item?.tag?.name),
                  });
                }}
              >
                <PenIcon />
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div
          onClick={() => {
            if (selectedFilter === null) {
              setSelectedFilter("descend");
            } else if (selectedFilter === "descend") {
              setSelectedFilter("ascend");
            } else if (selectedFilter === "ascend") {
              setSelectedFilter(null);
            }
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <div>Created At</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ lineHeight: "10px", position: "relative", top: "2px" }}
            >
              <CaretUpOutlined
                style={{
                  fontSize: "12px",
                  color: selectedFilter === "ascend" ? "#1990FF" : "#A6A6A6",
                }}
              />
            </div>
            <div
              style={{
                lineHeight: "10px",
                position: "relative",
                bottom: "2px",
              }}
            >
              <CaretDownOutlined
                style={{
                  fontSize: "12px",
                  color: selectedFilter === "descend" ? "#1990FF" : "#A6A6A6",
                }}
              />
            </div>
          </div>
        </div>
      ),
      dataIndex: "createdAt",
      key: "created_at",
      render: (modifyDate) => (
        <span>
          <Moment date={modifyDate} format="lll" />
        </span>
      ),
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (data) => <span>{data?.length}</span>,
    },
  ];

  const defaultAccountStatus = [
    "ACTIVE",
    "CREATED",
    "PENDING_PAYMENT",
    "SUSPENDED",
    "LATE_START",
    "LATE_START_END",
  ];

  useEffect(() => {
    getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
    dispatch(getTags());
    fetchPlan();
    getDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboards = async () => {
    let DashboardsTemp = {};
    const response = await sdk.listDashboardsData();
    response?.forEach((dashboard) => {
      DashboardsTemp[dashboard?.id] = dashboard?.name;
    });
    const sortedDashboards = response?.sort((a, b) => {
      const A = a.name.toUpperCase();
      const B = b.name.toUpperCase();

      if (A < B) {
        return -1;
      }

      if (A > B) {
        return 1;
      }

      return 0;
    });
    setDashboards(sortedDashboards);
    setDashboardsObject(DashboardsTemp);
  };

  const lockUnlockShopifyAccount = async (shopifyProfileId, accountStatus) => {
    settableloading(true);
    try {
      let apiName = "blustreamREST";
      let path = "/shopify-intergration/update-profile-lock-status";
      let body = {
        shopifyProfileId: shopifyProfileId,
        isLock: accountStatus,
      };

      await API.post(apiName, path, { body: body });
      await getShopifyAccounts();
      message.success("Account status updated successfully");
    } catch (error) {
      console.log(error);
      message.error("something went wrong");
      settableloading(false);
    }
  };

  const getShopifyAccounts = async (
    from = 0,
    limit = totalNumberOfRecordsPerPage,
    sort = {}
  ) => {
    setcurrentPage(from / limit + 1);
    try {
      settableloading(true);
      const response = await API.graphql(graphqlOperation(listShopifyProfiles));
      const shopifyAccountsList = response?.data?.listShopifyProfiles?.items;
      const or = shopifyAccountsList?.map((item) => {
        return { id: { eq: item?.account } };
      });
      getCompanyDataNew(from, limit, { or: or }, shopifyAccountsList, sort);
      setShopifyCompanyData(shopifyAccountsList);
    } catch (error) {
      console.log(error);
      settableloading(false);
    }
  };

  const findLateStartDate = async (accountId) => {
    const lateDate = await API.graphql(
      graphqlOperation(listDelayStartAccounts)
    );
    const filteredLateBilling =
      lateDate.data.listDelayStartAccounts.items.filter(
        (obj) => obj?.account === accountId
      );
    if (filteredLateBilling.length > 0) {
      setLateStartDate(moment.unix(filteredLateBilling[0]?.expirationUnixTime));
    } else {
      setLateStartDate("");
    }
  };

  const isShopifyConnected = async (id) => {
    const apiName = "blustreamREST";
    const profilePath =
      "/shopify-intergration/get-shopify-profile-attach-with-company";
    const profileBody = {
      companyId: id,
    };
    const profileResponse = await API.post(apiName, profilePath, {
      body: profileBody,
    });
    return profileResponse;
  };

  const findAssignedPlan = async (id) => {
    const profileResponse = await isShopifyConnected(id);
    if (profileResponse?.statusCode === 200) {
      const apiName = "blustreamREST";
      let path = "/shopify-intergration/shopify-profile";
      let body = {
        shopifyProfileId: JSON.parse(profileResponse.body)?.shopifyprofiles[0]
          ?.id,
      };
      const response = await API.post(apiName, path, { body: body });
      const planPrice = JSON.parse(response?.getShopifyProfile?.plan_details)
        ?.recurring_application_charge?.price;
      const planInfo = planList.find(
        (item) =>
          JSON.parse(item?.priceValues)?.[
            item?.stripePriceID[0]
          ]?.value.toString() === planPrice
      );
      if (planInfo) {
        setPurchasedPlan(planInfo);
      } else {
        setPurchasedPlan({});
      }
    } else {
      setPurchasedPlan({});
    }
  };

  const findPurchasedPlan = async (value) => {
    const planInfo = planList.find(
      (item) =>
        JSON.parse(item?.priceValues)?.[
          item?.stripePriceID[0]
        ]?.value.toString() === value
    );
    setPurchasedPlan(planInfo);
  };
  const getShopifyProfile = async (accountId, source) => {
    const apiName = "blustreamREST";
    try {
      const path =
        "/shopify-intergration/get-shopify-profile-attach-with-company";
      const body = {
        companyId: accountId,
      };
      const response = await API.post(apiName, path, {
        body: body,
      });
      if (response?.statusCode === 200 && source === "shopify") {
        setShowManualBilling(false);
      } else {
        setShowManualBilling(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlan = async () => {
    try {
      let plans = await API.graphql(graphqlOperation(listPlans));
      const planList = plans?.data?.listPlans?.items;
      const adminOnlyPlans = await API.graphql(
        graphqlOperation(listAdminPlans)
      );
      adminOnlyPlans?.data?.listAdminPlans?.items.forEach((item) => {
        planList.push(item);
      });
      const sortedPlanList = planList.sort((a, b) => {
        return (
          Number(JSON.parse(a?.priceValues)[a?.stripePriceID[0]]?.value) -
          Number(JSON.parse(b?.priceValues)[b?.stripePriceID[0]]?.value)
        );
      });
      setplanList(sortedPlanList);
      return plans;
    } catch (e) {
      console.log(e);
      return { errors: ["Failed retrieving plans"] };
    }
  };

  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const hideConfirmCompanyModal = () => {
    setConfirmCreateCompany(false);
  };

  const hideUpdateCompanyStatusModal = () => {
    setUpdateCompanyStatus(false);
  };

  const addDashboard = async (e) => {
    setDashboardLoader(true);
    try {
      if (selectedDashboard) {
        await sdk.updateDashboardDetails(e?.dashboard_id, e?.dashboard_name);
        setShowAddDashboardModal(false);
        message.success("Dashboard updated Successfully!");
        addDashboardForm.resetFields();
        await getDashboards();
        setShowDashboardModal(true);
      } else {
        const response = await sdk.addDashboard(
          e?.dashboard_id.trim(),
          e?.dashboard_name.trim()
        );

        if (response?.errors) {
          message.error("Failed to add dashboard!");
        } else {
          setShowAddDashboardModal(false);
          message.success("Dashboard added Successfully!");
          addDashboardForm.resetFields();
          await getDashboards();
          setShowDashboardModal(true);
        }
      }
    } catch (error) {
      console.log(error);
      message.error("something went wrong");
    } finally {
      setDashboardLoader(false);
      setSelectedDashboard(null);
    }
  };

  const getAccountsWithSelectedDashboards = async (id) => {
    setDeleteIconclickLoading(true);
    try {
      const companyList = await API.graphql(
        graphqlOperation(searchCustomerAccountsWithDashboardCustom, {
          limit: 999, //for getting more than 100 records at a time
          filter: {
            dashboardID: {
              eq: id,
            },
          },
        })
      );
      setCompaniesWithSelectedDashboard(
        companyList?.data?.searchCustomerAccounts?.items
      );
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteIconclickLoading(false);
    }
  };

  const updateCompanyDashboard = (id) => {
    const response = API.graphql(
      graphqlOperation(updateCustomerAccount, {
        input: {
          id: id,
          dashboardID: process.env.REACT_APP_DEFAULT_DASHBOARD_ID,
        },
      })
    );
    return response;
  };

  const deleteDashboard = async () => {
    try {
      setDeleteDashboardLoader(true);
      var remainingCompanies = companiesWithSelectedDashboard?.length;
      if (remainingCompanies) {
        await Promise.all(
          companiesWithSelectedDashboard?.map(async (company) => {
            const response = await updateCompanyDashboard(company?.id);
            return response;
          })
        );
        await sdk.deleteDashboardData(selectedDashboard?.id);
        await getDashboards();
        if (selectedPlateform === "shopify") {
          await getShopifyAccounts();
        } else if (selectedPlateform === "all") {
          await getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
        }
        setDeleteDashboardLoader(false);
        setShowDeleteDashboardModal(false);
        message.success("Dashboard deleted successfully!");
        setSelectedDashboard(null);
        setShowDashboardModal(true);
      } else {
        await sdk.deleteDashboardData(selectedDashboard?.id);
        await getDashboards();
        setDeleteDashboardLoader(false);
        setShowDeleteDashboardModal(false);
        message.success("Dashboard deleted successfully!");
        setSelectedDashboard(null);
        setShowDashboardModal(true);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
      setDeleteDashboardLoader(false);
      setShowDeleteDashboardModal(false);
      setSelectedDashboard(null);
      setShowDashboardModal(true);
    }
  };

  const addCompany = () => {
    setaddCompanyModelShow(true);
  };

  const handleSubmit = async (e) => {
    let late_start_time_to_be = lateStartDate
      ? lateStartDate.unix() - 120 > Math.floor(Date.now() / 1000)
        ? lateStartDate.unix()
        : Math.floor(Date.now() / 1000) + 120
      : "";
    setLoading(true);
    let result = await sdk.createAccount(
      e.company,
      e.url,
      [],
      false,
      true,
      e.account_status,
      e.dashboardID,
      late_start_time_to_be
    );
    if (result.error) {
      message.error(result.error);
    } else {
      addcompanyform.resetFields();
      if (Object.keys(selectedPlan).length > 0) {
        handlePlan(
          selectedPlan,
          result?.msg,
          e?.late_start_date?.unix() + 120,
          "added"
        );
      } else {
        setConfirmCreateCompany(true);
        setLoading(false);
      }
    }
    if (selectedPlateform === "shopify") {
      setTimeout(() => {
        getShopifyAccounts();
      }, 1500);
    } else if (selectedPlateform === "all") {
      setTimeout(() => {
        getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
      }, 1500);
    }
    setaddCompanyModelShow(false);
  };

  const handlePlan = async (plan, account, late_start_time, text) => {
    const accountID = account ? account : null;
    const priceValues = plan && JSON.parse(plan.priceValues);
    const price = priceValues?.[plan?.stripePriceID[0]]?.value;
    const limit = plan && JSON.parse(plan?.limits);
    const late_start_timestamp = late_start_time ? late_start_time : "";

    let line_items = plan && plan.stripePriceID;
    line_items = line_items
      .filter((i) => priceValues[i]?.billing_period !== "year")
      .map((item) => {
        let dataObj = {};
        if (priceValues[item]?.billing_period !== "metered") {
          dataObj["quantity"] = 1;
        }
        return { ...dataObj, price: item };
      });

    if (
      plan &&
      plan.stripePriceID &&
      price &&
      limit.touchpoints &&
      accountID &&
      line_items
    ) {
      let sessionResult = await billing.getOrgCheckoutSession(
        plan.stripePriceID[0],
        price,
        limit.touchpoints,
        accountID,
        JSON.stringify(line_items),
        plan?.name,
        late_start_timestamp
      );

      if (sessionResult.error) {
        message.error(sessionResult.error);
      } else {
        if (text === "added") {
          message.success(`Company added successfully`);
        } else if (text === "updated") {
          message.success("Account status updated successfully");
        }
      }
    }
    setSelectedPlan({});
    setLoading(false);
  };

  const updatecompanyAccount = async () => {
    setLoading(true);
    let id = companyToEditStatus?.id;
    let account_status = companyToEditStatus?.accountStatus;
    let late_start_date =
      lateStartDate && account_status === "LATE_START"
        ? lateStartDate.unix() - 120 > Math.floor(Date.now() / 1000)
          ? lateStartDate.unix()
          : Math.floor(Date.now() / 1000) + 120
        : "";
    let manualBilling = hasManualBilling ? hasManualBilling : false;
    let apiName = "blustreamREST";
    let path = "/accounts/update";
    try {
      var result = API.post(apiName, path, {
        body: { id, account_status, late_start_date, manualBilling },
      });
      result
        .then(async (data) => {
          if (selectedPlateform === "shopify") {
            setTimeout(() => {
              getShopifyAccounts();
            }, 1500);
          } else if (selectedPlateform === "all") {
            setTimeout(() => {
              getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
            }, 1500);
          }
          setAccountStatus(companyToEditStatus?.accountStatus);
          setcompanyToEditStatus({});
          if (
            Object.keys(purchasedPlan).length > 0 &&
            companyToEditStatus?.accountStatus === "LATE_START"
          ) {
            handlePlan(purchasedPlan, id, late_start_date, "updated");
          } else {
            message.success("Account status updated successfully");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setcompanyToEditStatus({});
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
      setcompanyToEditStatus({});
      setLateStartDate("");
    }
  };

  const updatecompanyAccountDashboard = async () => {
    setLoading(true);
    let id = companyToEditDashboard?.id;
    let dashboardID = companyToEditDashboard?.dashboardID;
    let account_status = companyToEditDashboard?.accountStatus;
    let apiName = "blustreamREST";
    let path = "/accounts/update";
    try {
      var result = API.post(apiName, path, {
        body: { id, dashboardID, account_status },
      });
      result
        .then(async (data) => {
          message.success("Account dashboard updated successfuly");
          if (selectedPlateform === "shopify") {
            setTimeout(() => {
              getShopifyAccounts();
            }, 1500);
          } else if (selectedPlateform === "all") {
            setTimeout(() => {
              getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
            }, 1500);
          }
          setLoading(false);
          setcompanyToEditDashboard({});
        })
        .catch((error) => {
          console.log("error:", error);
          setLoading(false);
          message.error("Something went wrong");
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const updateAccountTags = async (updatedTags, tagList) => {
    try {
      const companyId = CompanyToEditTags?.id;
      const companyTags = CompanyToEditTags?.tags?.items;
      const companyTagNames = companyTags?.map((tag) => tag?.tag?.name);
      const removedTags = companyTags?.filter(
        (tag) => !updatedTags?.includes(tag?.tag?.name)
      );
      const newAddedTagNames = updatedTags?.filter(
        (tag) => !companyTagNames?.includes(tag)
      );

      const newAddedTags = tagList?.filter((tag) =>
        newAddedTagNames?.includes(tag?.name)
      );
      await Promise.all(
        removedTags.map((item) => {
          return dispatch(deleteCustomAccoutTag({ id: item?.id }));
        })
      );
      await Promise.all(
        newAddedTags?.map((item) => {
          dispatch(
            createCustomTag({
              customerAccountTagCustomerAccountId: companyId,
              customerAccountTagTagId: item?.id,
            })
          );
        })
      );
      if (selectedPlateform === "shopify") {
        getShopifyAccounts();
      } else if (selectedPlateform === "all") {
        getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCompanyToEditTags({});
      setTagLoader(false);
    }
  };

  const updateTags = async (tags) => {
    try {
      setTagLoader(true);
      const existingTags = Tags.map((tag) => tag?.name);
      const newTags = tags?.tags?.filter((tag) => !existingTags.includes(tag));
      if (newTags?.length > 0) {
        await Promise.all(
          newTags.map(async (item) => {
            return dispatch(addtags({ name: item }));
          })
        ).then((res) => {
          dispatch(getTags()).then(async (res) => {
            await updateAccountTags(tags.tags, res);
          });
        });
      } else {
        await updateAccountTags(tags.tags, Tags);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPlateform = (value) => {
    setSearchValue("");
    searchCompanyForm.resetFields();
    setSelectedPlateform(value);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (selectedPlateform === "shopify") {
        getShopifyAccounts();
      } else if (selectedPlateform === "all") {
        getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlateform]);

  useEffect(() => {
    if (issorted.current) {
      if (selectedPlateform === "shopify") {
        getShopifyAccounts();
      } else if (selectedPlateform === "all") {
        getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
      }
    } else {
      issorted.current = true;
    }
  }, [selectedFilter]);

  const getCompanyDataNew = async (
    from = 0,
    limit = 10,
    filter = {},
    shopifyAccountsList = [],
    sort = {}
  ) => {
    setcurrentPage(from / limit + 1);
    setTotalNumberOfRecordsPerPage(limit);
    if (searchValue?.length > 0) {
      let and;
      if (SearchOption === "Name") {
        const keywords = searchValue?.trim()?.split(" ");
        and = keywords?.map((keyword) => {
          return { company_loweredcased: { wildcard: "*" + keyword + "*" } };
        });
      } else if (SearchOption === "id") {
        const keywords = searchValue?.trim()?.split("-");
        and = keywords?.map((keyword) => {
          return { id: { wildcard: "*" + keyword + "*" } };
        });
      }
      filter["and"] = and;
      if (selectedPlateform === "shopify") {
        const or = shopifyCompanyData?.map((item) => {
          return { id: { eq: item?.account } };
        });
        filter["and"] = [{ and: and }, { or: or }];
      }
    }

    if (selectedFilter) {
      sort = {
        direction: selectedFilter === "descend" ? "desc" : "asc",
        field: "createdAt",
      };
    }
    settableloading(true);

    let listCompanies;
    const response = await sdk.getAllCompanies(from, limit, filter, sort);
    setTotalNumbersOfCompanies(response?.data?.searchCustomerAccounts?.total);
    listCompanies = response?.data?.searchCustomerAccounts?.items;
    if (selectedPlateform === "shopify") {
      listCompanies = listCompanies?.map((companyData) => {
        const shopifyAccount = shopifyAccountsList?.find(
          (shopifyDetail) => shopifyDetail?.account === companyData?.id
        );
        return {
          ...companyData,
          isProfileLock: shopifyAccount?.isProfileLock,
          shopifyprofileId: shopifyAccount?.id,
        };
      });
    }
    setNewCompaniesData(listCompanies);
    settableloading(false);
  };

  const companyTablePageChange = (pageNumber, pageSize) => {
    if (selectedPlateform === "shopify") {
      getShopifyAccounts((pageNumber - 1) * pageSize, pageSize);
    } else if (selectedPlateform === "all") {
      getCompanyDataNew((pageNumber - 1) * pageSize, pageSize, {});
    }
  };

  const handleBillingStatus = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setHasManualBilling(e.target.checked);
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }} size={1}>
        <Typography.Title level={5}>Company Management</Typography.Title>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
          className="flexCol mb-2"
        >
          <Form
            form={searchCompanyForm}
            name="search_Companies"
            initialValues={{ search_type: "Name" }}
            style={{ width: "100%" }}
            className="search_Companies"
          >
            <Space
              direction="horizontal"
              className="flexCol company-search-type"
            >
              <Form.Item name="search_type">
                <Select
                  className="standard-dropdown"
                  size="large"
                  onSelect={(event) => {
                    setSearchOption(event);
                  }}
                  value={SearchOption}
                >
                  <Select.Option value="Name" key="Name">
                    Name
                  </Select.Option>

                  <Select.Option value="id" key="id">
                    Id
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="search_value">
                <Search
                  className="company-searchbox"
                  placeholder={`Enter ${SearchOption}`}
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  value={searchValue}
                  onSearch={() => {
                    if (selectedPlateform === "shopify") {
                      getShopifyAccounts();
                    } else if (selectedPlateform === "all") {
                      getCompanyDataNew(0, totalNumberOfRecordsPerPage, {});
                    }
                  }}
                />
              </Form.Item>
            </Space>
          </Form>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Select
              size="large"
              value={selectedPlateform}
              onSelect={(event) => setPlateform(event)}
              style={{ width: "100px" }}
              className="standard-dropdown"
              disabled={tableloading}
            >
              <Select.Option key="all" value="all">
                All
              </Select.Option>

              <Select.Option key="shopify" value="shopify">
                Shopify
              </Select.Option>
            </Select>
            <Button
              size="middle"
              onClick={() => setShowDashboardModal(true)}
              className="standard-button secondary-orange-button"
              disabled={roAdmin || dashboards?.length <= 0}
            >
              View Dashboards
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={addCompany}
              className="standard-button primary-orange-button"
              disabled={roAdmin}
            >
              Add Company
            </Button>
          </div>
        </div>
        {tableloading ? (
          <div style={{ background: "#fff", padding: 20 }}>
            <Skeleton active paragraph={{ rows: 7 }} />{" "}
          </div>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <Table
              bordered
              size="middle"
              dataSource={newCompaniesData}
              columns={columns}
              pagination={false}
            ></Table>
            <div style={{ alignSelf: "flex-end" }}>
              <Pagination
                current={currentPage}
                onChange={companyTablePageChange}
                total={totalNumbersOfCompanies}
                pageSize={totalNumberOfRecordsPerPage}
                size
                hideOnSinglePage={true}
                showSizeChanger={true}
              />
            </div>
          </div>
        )}
      </Space>

      {/* lock unlock modal */}
      <Modal
        centered
        visible={showLockUnlockModal}
        footer={null}
        className="mapped-warning-modal-container"
        onCancel={() => setShowLockUnlockModal(false)}
      >
        <div className="mapped-warning-modal">
          <img src={glowingBulbIcon} alt="" />
          <p className="warning-modal-dark-text">
            Sure you want to unlock this account?
          </p>
          <p className="warning-modal-light-text">
            Once you unlock, the customer will receive access to the platform as
            per plan selected
          </p>
          <div className="mapped-warning-modal-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => setShowLockUnlockModal(false)}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                lockUnlockShopifyAccount(
                  selectedShopifyProfile?.shopifyprofileId,
                  !selectedShopifyProfile?.isProfileLock
                );
                setShowLockUnlockModal(false);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* add company modal */}
      <Modal
        visible={addCompanyModelShow}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <CloseOutlined
            style={{ fontSize: 18, marginTop: 10 }}
            onClick={() => {
              setaddCompanyModelShow(false);
              addcompanyform.resetFields();
            }}
          />
        }
      >
        <Row justify="center">
          <Col span={20}>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              &nbsp;
            </Typography.Title>

            <Form
              form={addcompanyform}
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              className="login-form"
              onFinish={(e) => {
                handleSubmit(e);
              }}
            >
              <Form.Item
                name="company"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of your company",
                  },
                  {
                    pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .']+)$/,
                    message: "Please enter valid company name",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  size="middle"
                  id="company"
                  key="company"
                  name="company"
                  placeholder="Company Name"
                  disabled={loading}
                  tabIndex={1}
                />
              </Form.Item>
              <Form.Item
                label="Company URL"
                name="url"
                rules={[
                  {
                    required: true,
                    message: "Please enter the URL of the company",
                  },
                  {
                    pattern:
                      /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                    message: "Please enter valid URL",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  size="middle"
                  id="url"
                  key="url"
                  name="url"
                  placeholder="Company URL"
                  disabled={loading}
                  tabIndex={1}
                />
              </Form.Item>
              <Form.Item
                label="Account Status"
                name="account_status"
                rules={[
                  {
                    required: true,
                    message: "Please Select Status of the company",
                  },
                ]}
              >
                <Select
                  value={accountStatus}
                  className="standard-dropdown"
                  placeholder="Select Account Status"
                  onChange={(event) => setAccountStatus(event)}
                >
                  {defaultAccountStatus.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {accountStatus === "LATE_START" && (
                <Form.Item label="Late Start Date" name="late_start_date">
                  <DatePicker
                    disabledDate={(current) => {
                      return current && current < moment().add(-1, "day");
                    }}
                    className="late-start-date-picker"
                  />
                </Form.Item>
              )}

              {accountStatus === "LATE_START" && (
                <Form.Item label="Choose Plan" name="selected_plan">
                  <Select
                    placeholder="Select plan"
                    className="standard-dropdown"
                    onChange={(event) => {
                      const plan = planList.find((item) => item?.id === event);
                      setSelectedPlan(plan);
                    }}
                  >
                    {planList.map((plan) => {
                      const priceValues = plan && JSON.parse(plan?.priceValues);
                      const limit = plan && JSON.parse(plan?.limits);
                      return (
                        <Select.Option
                          key={plan?.id}
                          value={plan?.id}
                          disabled={
                            priceValues?.[plan?.stripePriceID[0]]?.value ===
                            "25"
                          }
                        >{`${plan?.name}-${limit.touchpoints}-$${
                          priceValues &&
                          toCommas(priceValues?.[plan?.stripePriceID[0]]?.value)
                        }`}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                label="Account Dashboard"
                name="dashboardID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Dashboard of the company",
                  },
                ]}
              >
                <Select
                  className="standard-dropdown"
                  placeholder="Select Account Dashboard"
                >
                  {Object.keys(dashboardsObject).map((item) => (
                    <Select.Option key={item} value={item}>
                      {dashboardsObject[item]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  className="standard-button primary-orange-button"
                  type="primary"
                  size="large"
                  block
                  htmlType="submit"
                  loading={loading}
                  tabIndex={5}
                >
                  Add Company
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* add dashboard modal */}
      <Modal
        visible={showAddDashboardModal}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <CloseOutlined
            style={{ fontSize: 18, marginTop: 10 }}
            onClick={() => {
              setShowAddDashboardModal(false);
              addDashboardForm.resetFields();
              setSelectedDashboard(null);
              setShowDashboardModal(true);
            }}
          />
        }
      >
        <Row justify="center">
          <Col span={20}>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              &nbsp;
            </Typography.Title>

            <Form
              form={addDashboardForm}
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_dashboard"
              className="login-form"
              onFinish={(e) => {
                addDashboard(e);
              }}
              onChange={() => {
                const formValues = addDashboardForm.getFieldsValue();
                addDashboardForm?.setFieldsValue({
                  dashboard_name: formValues?.dashboard_name?.trimStart(),
                  dashboard_id: formValues?.dashboard_id?.trimStart(),
                });
              }}
            >
              <Form.Item
                name="dashboard_name"
                label="Dashboard Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of your dashboard",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  size="middle"
                  id="dashboard_name"
                  placeholder="Dashboard Name"
                  tabIndex={1}
                />
              </Form.Item>
              <Form.Item
                label="Dashboard Id"
                name="dashboard_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter the dashboard id",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  size="middle"
                  id="dashboard_id"
                  placeholder="Dashboard Id"
                  tabIndex={1}
                  disabled={selectedDashboard}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className="standard-button primary-orange-button"
                  type="primary"
                  size="large"
                  block
                  loading={dashboardLoader}
                  htmlType="submit"
                  tabIndex={5}
                >
                  {selectedDashboard ? "Save" : "Add Dashboard"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* show dashboard modal */}
      <Modal
        className="dashboard-modal"
        visible={showDashboardModal}
        title={
          <div className="dashboard-modal-header-wrapper">
            <div className="header-title">All Dashboards</div>
            <div className="header-button-container">
              <Button
                className="standard-button secondary-orange-button"
                onClick={() => {
                  setShowDashboardModal(false);
                  setShowAddDashboardModal(true);
                }}
              >
                Add
              </Button>
            </div>
          </div>
        }
        onCancel={() => {
          setShowDashboardModal(false);
        }}
        footer={null}
        centered
      >
        {dashboards?.map((dashboard, index) => {
          return (
            <div className="dashboard-details-wrapper">
              <div className="dashboard-details">
                <p className="dashboard-name">{dashboard?.name}</p>
                {dashboard?.id ===
                process.env.REACT_APP_DEFAULT_DASHBOARD_ID ? (
                  <p className="default-tag">Default</p>
                ) : null}
              </div>

              <div className="action-buttons">
                <img
                  src={pen2}
                  alt="pen2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedDashboard(dashboard);
                    addDashboardForm?.setFieldsValue({
                      dashboard_id: dashboard?.id,
                      dashboard_name: dashboard?.name,
                    });
                    setShowDashboardModal(false);
                    setShowAddDashboardModal(true);
                  }}
                />
                {dashboard?.id !==
                process.env.REACT_APP_DEFAULT_DASHBOARD_ID ? (
                  index === loadingFlag && deleteIconclickLoading ? (
                    <SyncOutlined
                      className="dashboard-delete-loading-icon"
                      style={{ height: "11px" }}
                      spin
                    />
                  ) : (
                    <img
                      src={deleteIcon4}
                      alt="deleteIcon4"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        setLoadingFlag(index);
                        await getAccountsWithSelectedDashboards(dashboard?.id);
                        setSelectedDashboard(dashboard);
                        setShowDashboardModal(false);
                        setShowDeleteDashboardModal(true);
                      }}
                    />
                  )
                ) : (
                  <div style={{ width: "12px" }}></div>
                )}
              </div>
            </div>
          );
        })}
      </Modal>

      {/* delete dashboard modal */}
      <Modal
        centered
        visible={showDeleteDashboardModal}
        footer={null}
        closable={false}
        maskClosable={false}
        className="delete-dashboard-modal"
      >
        <div className="delete-dashboard-modal-content">
          <img
            className="bulb-icon"
            src={glowingBulbIcon}
            alt="glowingBulbIcon"
          />
          <p className="warning-text">
            Are you sure you want to delete this dashboard?
          </p>
          {companiesWithSelectedDashboard?.length > 0 ? (
            <p className="accounts-text">
              {companiesWithSelectedDashboard?.length === 1 ? (
                `(${companiesWithSelectedDashboard[0]?.company} is using this dashboard
                  already.)`
              ) : companiesWithSelectedDashboard?.length === 2 ? (
                `(${companiesWithSelectedDashboard[0]?.company} & ${companiesWithSelectedDashboard[1]?.company} are using this dashboard
                  already.)`
              ) : (
                <>
                  {"("}
                  {companiesWithSelectedDashboard[0]?.company}
                  {", "}
                  {companiesWithSelectedDashboard[1]?.company}
                  {" & "}
                  <Tooltip
                    title={companiesWithSelectedDashboard
                      .slice(2)
                      ?.map((company) => company?.company)
                      .join(", ")}
                    placement="right"
                  >
                    <span style={{ textDecoration: "underline" }}>others</span>
                  </Tooltip>{" "}
                  are using this dashboard already.{")"}
                </>
              )}
            </p>
          ) : null}
          <p className="notify-text">
            Once you choose to delete this dashboard, organizations already
            using this will see default dashboard
          </p>
          <div className="delete-dashboard-modal-buttons-container">
            <Button
              className="standard-button primary-orange-button"
              onClick={async () => {
                await deleteDashboard();
              }}
              loading={deleteDashboardLoader}
            >
              Yes, Sure
            </Button>
            <Button
              className="standard-button secondary-orange-button"
              onClick={() => {
                setShowDeleteDashboardModal(false);
                setSelectedDashboard(null);
                setShowDashboardModal(true);
              }}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>

      {/* EDIT USER STATUS  */}
      <Modal
        visible={Object.keys(companyToEditStatus).length > 0}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <CloseOutlined
            style={{ fontSize: 18, marginTop: 10 }}
            onClick={() => {
              setcompanyToEditStatus({});
            }}
          />
        }
      >
        <Row justify="center">
          <Col span={18}>
            <Typography.Title level={4} style={{ textAlign: "center" }}>
              Update Account Status
            </Typography.Title>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "20px 0px 24px 0px",
              }}
            >
              <label style={{ fontSize: "16px" }}>Name</label>
              <Input
                className="standard-input"
                value={companyToEditStatus?.company}
                disabled
                style={{ width: "100%" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "24px",
              }}
            >
              <label style={{ fontSize: "16px" }}>Status</label>
              <Select
                className="standard-dropdown"
                value={companyToEditStatus?.accountStatus}
                onChange={(data) => {
                  setcompanyToEditStatus((old) => {
                    return { ...old, accountStatus: data };
                  });
                  if (data === "LATE_START") {
                    setHasManualBilling(false);
                  } else {
                    setHasManualBilling(companyToEditStatus?.manualBilling);
                  }
                }}
                style={{ width: "100%" }}
              >
                {defaultAccountStatus.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>

            {showManualBilling && (
              <div className="manual-billing-container">
                <label className="manual-billing-label">
                  <span>Billing Plan</span>{" "}
                  <Tooltip
                    title={`Selecting the "Late Start" Status disables the Admin's capability to select the Manual Billing Plan`}
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </label>
                <Checkbox
                  checked={hasManualBilling}
                  onChange={handleBillingStatus}
                  disabled={companyToEditStatus?.accountStatus === "LATE_START"}
                >
                  Manual
                </Checkbox>
              </div>
            )}

            {companyToEditStatus?.accountStatus === "LATE_START" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "24px",
                }}
              >
                <label style={{ fontSize: "16px" }}>Late Start Date</label>
                <DatePicker
                  value={lateStartDate ? lateStartDate : ""}
                  disabledDate={(current) => {
                    return current && current < moment().add(-1, "day");
                  }}
                  className="late-start-date-picker"
                  onChange={(event) => {
                    setLateStartDate(event);
                  }}
                />
              </div>
            )}

            {companyToEditStatus?.accountStatus === "LATE_START" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "24px",
                }}
              >
                <label style={{ fontSize: "16px" }}>Choose Plan</label>
                <Select
                  value={purchasedPlan?.id ? purchasedPlan?.id : null}
                  placeholder="Select plan"
                  className="standard-dropdown"
                  onChange={(event) => {
                    const plan = planList.find((item) => item?.id === event);
                    setPurchasedPlan(plan);
                  }}
                >
                  {planList.map((plan) => {
                    const priceValues = plan && JSON.parse(plan?.priceValues);
                    const limit = plan && JSON.parse(plan?.limits);
                    return (
                      <Select.Option
                        key={plan?.id}
                        value={plan?.id}
                        disabled={
                          (priceValues?.[plan?.stripePriceID[0]]?.value ===
                            "0" &&
                            isadminPlanDisable) ||
                          (priceValues?.[plan?.stripePriceID[0]]?.value ===
                            "25" &&
                            !isadminPlanDisable)
                        }
                      >{`${plan?.name}-${limit.touchpoints}-$${
                        priceValues &&
                        toCommas(priceValues?.[plan?.stripePriceID[0]]?.value)
                      }`}</Select.Option>
                    );
                  })}
                </Select>
              </div>
            )}

            <Button
              className="standard-button primary-orange-button"
              type="primary"
              size="large"
              block
              htmlType="submit"
              loading={loading}
              tabIndex={5}
              onClick={() => {
                if (companyToEditStatus?.accountStatus === "LATE_START") {
                  setUpdateCompanyStatus(true);
                } else {
                  updatecompanyAccount();
                }
              }}
            >
              Update Account
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* EDIT USER DASHBOARD  */}
      <Modal
        visible={Object.keys(companyToEditDashboard).length > 0}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <CloseOutlined
            style={{ fontSize: 18, marginTop: 10 }}
            onClick={() => {
              setcompanyToEditDashboard({});
            }}
          />
        }
      >
        <Row justify="center">
          <Col span={18}>
            <Typography.Title level={4} style={{ textAlign: "center" }}>
              Update Account Dashboard
            </Typography.Title>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ fontSize: "16px" }}>Name</label>
              <Input
                className="standard-input"
                value={companyToEditDashboard?.company}
                disabled
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "24px 0px 24px 0px",
              }}
            >
              <label style={{ fontSize: "16px" }}>Dashboard</label>
              <Select
                className="standard-dropdown"
                placeholder="Select Account Dashboard"
                value={companyToEditDashboard?.dashboardID}
                onChange={(data) =>
                  setcompanyToEditDashboard((old) => {
                    return { ...old, dashboardID: data };
                  })
                }
              >
                {Object.keys(dashboardsObject).map((item) => (
                  <Select.Option key={item} value={item}>
                    {dashboardsObject[item]}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <Button
              className="standard-button primary-orange-button"
              type="primary"
              size="large"
              block
              htmlType="submit"
              loading={loading}
              tabIndex={5}
              onClick={updatecompanyAccountDashboard}
            >
              Update Account
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* EDIT USER TAGS  */}
      <Modal
        visible={Object.keys(CompanyToEditTags).length > 0}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <CloseOutlined
            style={{ fontSize: 18, marginTop: 10 }}
            onClick={() => {
              setCompanyToEditTags({});
              form.resetFields();
            }}
          />
        }
      >
        <Row justify="center">
          <Col span={18}>
            <Space
              align="center"
              direction="vertical"
              style={{ width: "100%" }}
              size={20}
            >
              <Typography.Title level={4} style={{ textAlign: "center" }}>
                Update Account Tags
              </Typography.Title>

              <Form
                form={form}
                // onFinish={updatecompanyAccountTags}
                onFinish={updateTags}
                initialValues={{
                  tags: CompanyToEditTags?.tags
                    ? CompanyToEditTags?.tags?.items?.map(
                        (item) => item?.tag?.name
                      )
                    : [],
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ fontSize: "16px" }}>Tags</label>
                  <Form.Item name={"tags"} style={{ width: 300 }}>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      size="large"
                      id="tags"
                      key="tags"
                      name="tags"
                      placeholder="Select or add Tags"
                      value={
                        CompanyToEditTags?.tags
                          ? CompanyToEditTags?.tags?.items?.map(
                              (item) => item?.tag?.id
                            )
                          : []
                      }
                      searchValue={searchedValue}
                      onSearch={(value) =>
                        setSearchedValue(
                          value.replace(/^\s+/, "").replace(/\s\s+/g, " ")
                        )
                      }
                      onSelect={() => setSearchedValue("")}
                    >
                      {Tags?.map((tag, index) => {
                        return (
                          <Select.Option key={tag.id} value={tag?.name}>
                            {tag?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <Button
                  className="standard-button primary-orange-button"
                  type="primary"
                  size="large"
                  block
                  htmlType="submit"
                  loading={tagLoader}
                  tabIndex={5}
                >
                  Update Tags
                </Button>
              </Form>
            </Space>
          </Col>
        </Row>
      </Modal>

      {/* CONFIRM COMPANY MODAL */}
      <Modal
        centered
        visible={confirmCreateCompany}
        footer={null}
        className="btn-modal"
        onCancel={hideConfirmCompanyModal}
      >
        <Row justify="center" align="middle" className="form-save">
          <Image src={successIcon2} preview={false} alt="successIcon" />
          <Typography.Title
            level={4}
            style={{ color: "#25B291", margin: "0px" }}
          >
            Company added successfully!
          </Typography.Title>
        </Row>
      </Modal>

      {/* UPDATE COMPANY STATUS MODAL */}
      <Modal
        centered
        visible={updateCompanyStatus}
        footer={null}
        className="btn-modal"
        onCancel={hideUpdateCompanyStatusModal}
      >
        <div className="form-delete-modal">
          <p className="delete-modal-text" style={{ textAlign: "center" }}>
            This will start new subscription and it will cancel all previous
            subscriptions, Do you want to proceed?
          </p>
          <div className="delete-modal-button-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => hideUpdateCompanyStatusModal()}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                updatecompanyAccount();
                hideUpdateCompanyStatusModal();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Company;
