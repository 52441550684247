/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Layout, Select, Row, Skeleton, Tooltip } from "antd";
import "./workflow.css";
import TableView from "./TableView";
import { InfoCircleOutlined } from "@ant-design/icons";

import { getTuchPointData, getTuchPointIdData } from "../../apiService/RESTApi";
import { useSelector } from "react-redux";
import { Label } from "aws-amplify-react";
import touchpointSVGNew from "./../../assets/Images/touchpointSVGNew.svg";
const { Header, Content } = Layout;
const { Option } = Select;

const WorkFlow = () => {
  const [dropdowndata, setDropdowndata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tData, setTData] = useState([]);
  const [journeyId, setJourneyId] = useState();
  const { groups } = useSelector((state) => state.loginReducer);
  const { touchpoint } = useSelector((state) => state.SettingReducer);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  //Fetch all the details of journeys
  const fetchTableData = async () => {
    setIsLoading(true);
    if (touchpoint === "") {
      await getTuchPointData(groups[0])
        .then(async (res) => {
          //res?.response?.status === -1 this is for handling error when we not get account from backend
          if (res?.response?.status === -1 || !res?.response?.data) {
            return;
          }
          let data = res?.response?.data;
          let data1 = data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          if (data1 !== null && data1.length > 0) {
            setJourneyId(data1[0]?.Id);
            const touchpoint = await getTuchPointIdData(
              groups[0],
              data1[0]?.Id
            );
            setTData(touchpoint?.response?.data?.[0]?.touchpoints);
            setDropdowndata(data1[0]);
            setTableData(data1?.sort((a, b) => a.Name.localeCompare(b.Name)));
          } else {
            setTableData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getTuchPointData(groups[0])
        .then((res) => {
          let data = res?.response?.data;
          if (data !== null && data.length > 0) {
            setTableData(data?.sort((a, b) => a.Name.localeCompare(b.Name)));
          } else {
            setTableData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setIsLoading(false);
  };

  function generateFriendlyTouchpointCount(count) {
    if (count === undefined)  {
      return ""; 
    }
    else if (count === 1) {
      return `( ${count} Touchpoint )`;
    } else {
      return `( ${count} Touchpoints )`;
    }
  };

  //Handel the dropdown of select Option
  const handleOption = async (option) => {
    let data = tableData.filter((item) => item.Id === option);
    setDropdowndata(data?.[0]);
    setJourneyId(data?.[0]?.Id);
  };

  useEffect(() => {
    fetchTableData();
  }, []);
  return (
    <>
      <div
        className="workFlow"
        style={{
          width: "100%",
          borderRadius: "8px",
          height: "100%",
        }}
      >
        <Layout>
          <Header style={{ backgroundColor: "white" }}>
            <Row className="workflow_header">
              <div
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  zIndex: 200,
                }}
              >
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "22px",
                    color: "#464646",
                    lineHeight: "1.5",
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  Welcome to Blustream{" "}
                  <span style={{ color: "#F37064" }}>Touchpoint</span> Manager!
                </h2>
                {/* <p>
                            Manage all your product details from this dashboard. Create new properties<br/>
                            for the products and integrate them easily with different Touchpoints.
                            </p> */}
                {/* <p style={{lineHeight:"1"}}>Change messages and links for all your product journeys.</p> */}
                {isLoading ? (
                  <Skeleton.Button
                    active
                    style={{ marginLeft: "110px", width: 250 }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      marginRight: 8,
                      alignItems: "center",
                    }}
                  >
                    <Label
                      style={{
                        lineHeight: "1",
                        marginRight: "3px",
                        fontSize: "16px",
                      }}
                    >
                      Select Journey :{" "}
                    </Label>
                    <Select
                      showSearch
                      allowClear
                      className="standard-dropdown"
                      style={{
                        minWidth: 250,
                        width: "50vw",
                        background: "transparent",
                      }}
                      placeholder="Select Journey"
                      optionFilterProp="children"
                      value={dropdowndata?.Name || searchTerm}
                      onChange={handleOption}
                      onClear={() => {
                        setSearchTerm('');
                        // Prevent the search from closing
                      }}
                      filterSort={(optionA, optionB) =>
                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                      }
                      filterOption={(input, option) =>
                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {/* <Option disabled style={{color: '#3B84D1', cursor: 'default'}}>Select product</Option> */}
                      {tableData.map((journeyInfo, index) => {
                        return (
                          <Option
                            key={index}
                            value={journeyInfo?.Id}
                            name={journeyInfo?.Name}
                            style={
                              journeyInfo?.Id === dropdowndata?.Id
                                ? {
                                    backgroundColor: "#f5f5f5",
                                    fontWeight: "bold",
                                  }
                                : {}
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <Avatar size="small" icon={<img src={Profile} />} /> */}
                              <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                {journeyInfo?.Name}
                              </p>
                              <span style={{marginLeft:"auto", color: "#959595", fontSize: "smaller" }}>{generateFriendlyTouchpointCount(journeyInfo?.numberOfTouchpoints)}</span>
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                    <Tooltip
                      placement="bottom"
                      title="Journeys are a series of touchpoints that send more messages. Contacts start new journeys when they answer a questions or you associate them with a product."
                    >
                      <InfoCircleOutlined style={{ color: "#959595" }} />
                    </Tooltip>
                  </div>
                )}
              </div>
              {/* <Button onClick={() => this.setState({tableView: false})} className="listbtn" icon={<img src={this.state.tableView ? ListIcon : ListIconActive} />}></Button> */}
              {/* <Button onClick={() => setTableView(true)} className="tablebtn" icon={<img src={tableView ? TableIconActive : TableIcon} />}></Button> */}
              {/* </div> */}
              <div className="wrapper">
                {/* <Search className="searchNames" prefix={<SearchOutlined />} placeholder="Search Touchpoints" onSearch={onSearch} style={{ width: "200px" , margin: '0 15px' }} />      */}

                <img src={touchpointSVGNew} alt="" />

                {/* <Select
                          className="w-50"
                          showSearch
                          style={{ width: 200 , background: 'transparent' , marginLeft: 15 }}
                          placeholder="Select Tags"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="0">SMS</Option>
                          <Option value="1">Email</Option>
                          <Option value="2">After 6 days of purchase</Option>
                        </Select> */}
              </div>
            </Row>
          </Header>

          <Layout>
            {/* style={{ marginTop: 20 }} */}

            {isLoading ? (
              <div
                className="p-20"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Skeleton active paragraph={{ rows: 5 }} />
              </div>
            ) : (
              <Content>
                {/* this.state.tableView ?  */}
                <TableView data={tData} journeyId={journeyId} />
              </Content>
            )}
            {/* 
                  <Drawer 
                // className="addProperty"  
                width={430} 
                title='Add new product attribute'
                placement="right" 
                // onClose={showonClose} 
                // loading={loading}
                visible={true}
                >
                    <Form
                    // onFinish={handleAttribute}
                        // form={form}
                        // loading={loading}
                        layout="vertical"
                    >
                        <Form.Item
                            label='Attribute Title'
                            name='AttributeTitle'
                            style={{width: '100%'}}
                            rules={[
                                {
                                  required: true,
                                  message: "This field can not be empty!",
                                },
                              ]}
                        >
                            <Input placeholder="Enter attribute title" />
                        </Form.Item>

                        <Form.Item
                            label='Attribute Type'
                            style={{width: '100%'}}
                            name='AttributeType'
                            rules={[
                                {
                                  required: true,
                                  message: "This field can not be empty!",
                                },
                              ]}>
                <Radio.Group >
                                <Space direction="vertical">
                                    <Radio value={1}>Long</Radio>
                                    <Radio value={2}>Date Selection</Radio>
                                    <Radio value={3}>String</Radio>
                                    <Radio value={4}>Boolean</Radio>
                                    <Radio value={5}>Integer</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        
                        
                    

                    <div
                        style={{
                            position: "absolute",
                            right: 0,
                            bottom: 0,
                            left: 0,
                            width: "auto",
                            borderTop: "1px solid #e9e9e9",
                            fontSize: "18px",
                            padding: "10px 16px",
                            background: "#EFF4F9",
                            textAlign: "right",
                            display:"flex"
                        }}
                        >
                            <Button
                                className="btn-secondary"
                                style={{ marginRight: 8, width: "auto" , border: '1px solid #252525' , color: 'black'}}
                                size="middle"
                                // onClick={showonClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                // onClick={onSubmitAttribute}
                                type="primary"
                                // loading={loading}
                                style={{  width: "auto" }}
                                htmlType="submit"
                                className="btn-primary"
                                size="middle"
                            >
                                Add
                            </Button>
                    </div>
                    </Form>
            </Drawer> */}

            {/* {React.createElement(this.state.collapsed ? DoubleLeftOutlined : DoubleRightOutlined, {
                      className: 'trigger2',
                      onClick: this.toggle,
                  })} */}

            {/* <Sider id="work-layout" className="site-layout-background" collapsedWidth="0" trigger={null} collapsible collapsed={this.state.tableView ?  !this.state.collapsed : this.state.collapsed}>
                    <Sidebar />
                  </Sider> */}
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default WorkFlow;
