import { Button, Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./orgRequestAccess.css";

import lock from "./../../assets/Images/lock.svg";
import verifiedIcon from "./../../assets/Images/verifiedIcon.svg";
import notVerifiedIcon from "./../../assets/Images/notVerifiedIcon.svg";

import { useDispatch, useSelector } from "react-redux";
import sdk from "sdk/Accounts";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { onDeleteCustomerAccountRequest } from "graphql/subscriptions";
import {
  setAccountDetails,
  updateCompanyAccount,
  updateUserGroups,
} from "store/actions/loginAction";

const OrgRequestAccess = ({
  connectedOrganization,
  hasRequestSent,
  getRequest,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { admin_groups_self, verificationDetails, userData } = useSelector(
    (state) => state.loginReducer
  );
  const onlyCompanyGroups = admin_groups_self?.filter(
    (item) => item?.length === 36
  );

  const [sendRequestLoader, setSendRequestLoader] = useState(false);
  const [hasRequestDenied, setHasRequestDenied] = useState(false);

  useEffect(() => {
    const deleteCustomerAccountRequestSubscription = API.graphql(
      graphqlOperation(onDeleteCustomerAccountRequest, {
        owner: userData?.email,
      })
    ).subscribe({
      next: (data) => {
        checkRequestStatus();
      },
      error: (error) => console.warn(error),
    });

    return () => {
      deleteCustomerAccountRequestSubscription?.unsubscribe();
    };
  }, []);

  const sendRequest = async () => {
    try {
      setSendRequestLoader(true);
      await sdk.requestAccess(connectedOrganization?.id);
      await getRequest(connectedOrganization?.id);
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    } finally {
      setSendRequestLoader(false);
    }
  };

  const checkRequestStatus = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      async (err, session) => {
        const { accessToken } = session;
        if ("cognito:groups" in accessToken.payload) {
          const response = Auth.currentUserInfo();
          response.then((data) => {
            dispatch(updateUserGroups(session, data?.attributes));
          });
          const groupsArray = accessToken?.payload?.["cognito:groups"];
          if (groupsArray?.includes(connectedOrganization?.id)) {
            //request approved
            dispatch(updateCompanyAccount(connectedOrganization));
            if (connectedOrganization?.id) {
              let account = await sdk.fetchAccount(connectedOrganization?.id);
              dispatch(setAccountDetails(account));
            }
            localStorage.removeItem("storeDetails");
            history.push("/");
          } else {
            //request denied
            setHasRequestDenied(true);
          }
        } else {
          //request denied
          setHasRequestDenied(true);
        }
      }
    );
  };
  return (
    <>
      <Row justify="center">
        <Col span={20} className="organization-request-access-main-container">
          {onlyCompanyGroups && onlyCompanyGroups?.length > 0 && (
            <Button
              className="standard-button tertiary-button skip-button"
              onClick={() => {
                localStorage.removeItem("storeDetails");
                history.push("/");
              }}
            >
              Skip
            </Button>
          )}
          <div className="organization-request-access-container">
            <Row justify="center" style={{ width: "100%" }}>
              <Col span={10}>
                <Row className="organization-request-access-content-row">
                  <Col className="organization-request-access-content-col">
                    <img className="lock-image" src={lock} alt="lock" />
                    <div className="simple-text">
                      You are not authorized to access this organization!
                    </div>
                    <div className="organization-name">
                      {connectedOrganization?.company}
                    </div>
                    <div className="reason-txt">
                      This organization is linked with the Shopify store
                      <span className="linked-store-name">
                        {" "}
                        “{verificationDetails?.shopify_profile?.store_name}”
                      </span>
                      . Please request admin to grant access.
                    </div>
                    {!hasRequestSent ? (
                      <Button
                        className="standard-button primary-orange-button request-access-button"
                        onClick={sendRequest}
                        loading={sendRequestLoader}
                      >
                        Request Access{" "}
                      </Button>
                    ) : (
                      <div className="request-text-container">
                        {hasRequestDenied ? (
                          <div className="request-denied-text-container">
                            <div className="org-request-denied-message-container">
                              <img
                                className="denied-icon"
                                src={notVerifiedIcon}
                                alt="notVerifiedIcon"
                              />
                              <p className="org-request-denied-message">
                                Admin denied your request to join organization.
                              </p>
                            </div>
                            <div className="notify-text">
                              Reach out to your admin staff for more details
                            </div>
                          </div>
                        ) : (
                          <div className="request-success-text-container">
                            <div className="org-request-success-message-container">
                              <img
                                className="success-icon"
                                src={verifiedIcon}
                                alt="verifiedIcon"
                              />
                              <p className="org-request-success-message">
                                Your request sent successfully!
                              </p>
                            </div>
                            <div className="notify-text">
                              We will notify you via email once admin approves
                              your request.
                            </div>
                          </div>
                        )}
                        <div className="new-organization-text-wrapper">
                          <p className="new-organization-text">
                            Want to create your own organization or join
                            existing one?
                          </p>
                          <Button
                            className="standard-button primary-orange-button"
                            onClick={() => {
                              localStorage.removeItem("storeDetails");
                              history?.push("/setup");
                            }}
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OrgRequestAccess;
