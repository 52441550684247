/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Descriptions,
  Tabs,
  Button,
  message,
  Row,
  Col,
  Modal,
  Input,
  Select,
  Form,
  Drawer,
  Space,
  Grid,
  Divider,
} from "antd";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { setTouchpoint as setTouchpointInRedux } from "store/actions/SettingAction";

import sdk from "sdk/Accounts";
import linkPortal from "sdk/Checkout";
import { useDispatch, useSelector } from "react-redux";
import UserRequests from "../account/UserRequests";
import CurrentUsers from "../account/CurrentUsers";
import PersonalDetails from "../account/PersonalDetails";
import MyInvitations from "../account/MyInvitations";
import PendingRequests from "../account/PendingRequests";
import DesktopBilling from "./DesktopBilling";
import ResponsiveBilling from "./ResponsiveBilling";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  listPlans,
  listApiKeys,
  listShopifyProfiles,
  listCompanyInviteHistories,
} from "graphql/queries";
import { loadStripe } from "@stripe/stripe-js";
import billing from "sdk/Checkout";
import { useHistory, useLocation } from "react-router";
import PlanSelected from "../account/PlanSelected";
import {
  setAccountDetails,
  updateCompanyAccount,
  updateUserGroups,
} from "store/actions/loginAction";

// Importing Custom stuff from utility
import { CopyIcon, PenIcon } from "utility/CustomIcons";
import { emailPattern } from "utility/Constants";

// Images Import
import inviteIMG from "assets/Images/invite-user-modal-img.svg";
import blurBG from "assets/Images/blurBG.png";
import inviteSuccessIMG from "assets/Images/invite-success-img.svg";
import changePlanModalImg from "assets/Images/change-plan-modal.svg";
import messageIcon from "assets/Images/messageIcon.svg";
import historyIcon from "assets/Images/historyIcon.svg";
import userIcon2 from "assets/Images/userIcon2.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
import manualBilling from "./../../assets/Images/manualBilling.svg";

//CSS import
import "../account/Account.css";
import "./setting.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const msgKey = "checkout_message";
const { useBreakpoint } = Grid;
const messageCostobject = {
  250: ["0", "0.60"],
  1000: ["0.20", "0.60"],
  2500: ["0.16", "0.32"],
  5000: ["0.14", "0.28"],
  10000: ["0.13", "0.26"],
  25000: ["0.10", "0.20"],
  50000: ["0.08", "0.16"],
};

const Settings = () => {
  const location = useLocation();
  const { TabPane } = Tabs;
  const screens = useBreakpoint();
  const {
    groups,
    accountDetails,
    roAdmin,
    userData,
    shopifyProfileId,
    admin_groups_self,
  } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [acct, setAcct] = useState(null);
  const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);
  const [currentUserEmail, setcurrentUserEmail] = useState("");
  const [form] = Form.useForm();
  const [accountform] = Form.useForm();
  const [list, setList] = useState([]);
  const [stripe, setStripe] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [inviteSuccess, setinviteSuccess] = useState(false);
  const [editAccountDrawerOpen, seteditAccountDrawerOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(
    location?.state?.key === "1" ? "1" : "2"
  );
  const [planDetails, setPlanDetails] = useState("");
  const [ischangePlanModelOpen, setischangePlanModelOpen] = useState(false);
  const [planselectedSuccess, setplanselectedSuccess] = useState(false);
  const [accountInfo, setaccountInfo] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [secretkey, setSecretkey] = useState(false);
  const [secretkeys, setSecretkeys] = useState([]);
  const [touchpoint, setTouchpoint] = useState({});
  const [sortData, setSortData] = useState([]);
  const [touchpointObject, setTouchpointObject] = useState([]);
  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [billingList, setBillingList] = useState([]);
  const [sliderValue, setsliderValue] = useState();
  const [myPlanLoading, setMyPlanLoading] = useState(false);
  const [ShopifyprofileData, setShopifyProfileData] = useState();
  const [alreadyInvitedUser, setAlreadyInvitedUser] = useState([]);
  const [alreadyUser, setAlreadyUser] = useState([]);
  const [removeUserLoading, setRemoveUserLoading] = useState(false);
  const [showLeaveOrganizationModal, setShowLeaveOrganizationModal] =
    useState(false);
  const [secretKeyLoading, setSecretKeyLoading] = useState(false);

  // pending request states
  const [pendingRequestList, setPendingRequestList] = useState([]);

  //personal tab states
  const [personalTabSubKey, setPersonalTabSubKey] = useState(
    location?.state?.subKey === "2" ? "2" : "1"
  );

  const isScoped = admin_groups_self && !admin_groups_self?.includes(groups[0]);

  useEffect(() => {
    if (location?.state) {
      setActiveTabKey("1");
      setPersonalTabSubKey("2");
    }
  }, [location]);

  const listShopifyProfile = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(listShopifyProfiles, {
          filter: {
            account: {
              eq: accountDetails?.id,
            },
          },
        })
      );
      setShopifyProfileData(response?.data?.listShopifyProfiles?.items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    listShopifyProfile();
  }, []);

  const onChange = (checked) => {
    setSwitchState(checked);
  };

  const getOrgMeteredUsage = async (accountId) => {
    let url = `/billing/getUsage?account_id=${accountId}`;

    try {
      const result = await API.get("blustreamREST", url);
      const sortedBillingList = result.data.sort((a, b) => {
        return a.period.start > b.period.start;
      });
      setBillingList(sortedBillingList);
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  useEffect(() => {
    touchpointMarks();
    getOrgMeteredUsage(groups[0]);
  }, []);

  const touchpointMarks = async (a, b) => {
    const touchpoints = await API.graphql(graphqlOperation(listPlans));
    const shopifyPlans = [
      "f5h607a7-7896-49a0-8392-89qs8b7f0gjr",
      "g7e607a7-7896-49a0-8392-89qs8b7f0tyu",
    ];
    let data, sliderTickmarks;
    const sliderTickmarksObject = {};
    if (
      accountDetails?.source === "shopify" &&
      shopifyProfileId?.shopifyProfileId
    ) {
      data = touchpoints?.data?.listPlans?.items;
      sliderTickmarks = [2, 9, 16, 23, 30, 40, 60, 85];
    } else {
      data = touchpoints?.data?.listPlans?.items?.filter(
        (item) => !shopifyPlans.includes(item?.id)
      );
      sliderTickmarks = [2, 10, 19, 28, 40, 60, 85];
    }
    setList(data);
    let sortedPlans = data.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setSortData(sortedPlans);
    let mapData = data.map((item) => JSON.parse(item?.limits));
    let sorting = mapData.sort((a, b) => {
      return a.touchpoints - b.touchpoints;
    });
    sorting.forEach((item, index) => {
      sliderTickmarksObject[sliderTickmarks[index]] =
        item?.touchpoints === 250
          ? item?.touchpoints
          : item?.touchpoints === 100000
          ? `${item?.touchpoints / 1000}k+`
          : `${item?.touchpoints / 1000}k`;
    });
    setTouchpoint(sliderTickmarksObject);
  };

  const fetchSecretsKey = async () => {
    try {
      let keys = await API.graphql(graphqlOperation(listApiKeys));
      let tempSecretKeys = keys?.data?.listApiKeys?.items;
      let keyList = tempSecretKeys.filter(
        (secretkey) => secretkey?.account === groups[0]
      );
      setSecretkeys(keyList);
    } catch (e) {
      console.log(e);
      return { errors: ["Failed retrieving keys"] };
    }
  };
  useEffect(() => {
    fetchSecretsKey();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const resolveStripe = async () => {
      let s = await stripePromise;
      setStripe(s);
    };
    resolveStripe();
  }, []);

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        let alreadyUser = [];
        let alreadyInvitedUser = [],
          requestPending = values?.emails?.length;

        //finding already joined user
        values?.emails?.forEach((email) => {
          acct?.users?.forEach((item) => {
            const user = JSON.parse(item);
            user?.Attributes?.forEach((attribute) => {
              if (attribute?.Name === "email") {
                if (attribute?.Value === email) {
                  alreadyUser.push(email);
                }
              }
            });
          });
        });

        //finding already invited user
        await values?.emails?.forEach((email) => {
          let invite = API.graphql(
            graphqlOperation(listCompanyInviteHistories, {
              filter: {
                account: {
                  eq: accountDetails?.id,
                },
                email: {
                  eq: email,
                },
              },
            })
          );
          invite.then((invite) => {
            requestPending--;
            if (invite?.data?.listCompanyInviteHistories?.items?.length > 0) {
              alreadyInvitedUser.push(email);
            }
            if (requestPending === 0) {
              if (alreadyInvitedUser?.length > 0) {
                setAlreadyInvitedUser(alreadyInvitedUser);
                setLoading(false);
              } else if (alreadyUser?.length > 0) {
                setAlreadyUser(alreadyUser);
                setLoading(false);
              } else {
                handleValidForm(values);
              }
            }
          });
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleValidForm = async (value) => {
    value.account_id = accountInfo?.id;
    value.name = accountInfo?.company;
    setLoading(true);
    let result = await sdk.sendEmailInvitation(value);

    if (result.error) {
      message.error(result.error, 5);
      setLoading(false);
    } else {
      await ListPendingRequests();
      setLoading(false);
      form.resetFields();
      setinviteSuccess(true);
      return;
    }
  };
  const validateEmail = (e) => {
    setAlreadyUser([]);
    setAlreadyInvitedUser([]);
    if (e.length > 0) {
      const found = e[e.length - 1].match(emailPattern);
      if (!found) {
        e.pop();
        message.error("Please enter valid email");
      } else {
        e[e.length - 1] = e[e.length - 1].toLowerCase();
      }
    }
  };
  useEffect(() => {
    Auth.currentUserInfo().then((result) => {
      setcurrentUserEmail(result?.attributes?.email);
    });
  }, []);

  const fetchAccountDetailsForBilling = async () => {
    setMyPlanLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    setTimeout(async () => {
      if (accountID) {
        let account = await sdk.fetchAccount(accountID);
        dispatch(setAccountDetails(account));
        setAcct(account);
      }
      setMyPlanLoading(false);
    }, 7000);
  };

  const fetchAccountDetails = async () => {
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      dispatch(setAccountDetails(account));
      setAcct(account);
    }
  };

  useEffect(() => {
    let accountDetail = acct;
    let sliderValueObject;
    if (
      accountDetail?.source === "shopify" &&
      shopifyProfileId?.shopifyProfileId
    ) {
      sliderValueObject = {
        25: 2,
        199: 9,
        399: 16,
        699: 23,
        1299: 30,
        2499: 40,
        3999: 68,
        5999: 85,
      };
    } else {
      sliderValueObject = {
        199: 2,
        399: 10,
        699: 19,
        1299: 28,
        2499: 40,
        3999: 68,
        5999: 85,
      };
    }
    if (
      accountDetail &&
      list.length > 0 &&
      accountDetail?.planID !== null &&
      accountDetail?.planID !== ""
    ) {
      let planInfo = list.find(
        (item) =>
          JSON.parse(item?.priceValues)?.[
            item?.stripePriceID[0]
          ]?.value.toString() === accountDetail?.planID
      );
      if (planInfo) {
        setPlanDetails(planInfo);
        setsliderValue(
          sliderValueObject[
            JSON.parse(planInfo?.priceValues)?.[planInfo?.stripePriceID[0]]
              ?.value
          ]
        );
        setTouchpointObject([planInfo]);
      }
    } else if (
      accountDetail?.source === "shopify" &&
      shopifyProfileId?.shopifyProfileId &&
      shopifyProfileId?.plan_details &&
      list.length > 0
    ) {
      setsliderValue(
        sliderValueObject[
          shopifyProfileId?.plan_details?.recurring_application_charge?.price
        ]
      );
      const assignedPlan = list.find(
        (item) =>
          JSON.parse(item?.priceValues)?.[
            item?.stripePriceID[0]
          ]?.value.toString() ===
          shopifyProfileId?.plan_details?.recurring_application_charge?.price
      );
      setTouchpointObject([assignedPlan]);
    } else {
      if (list.length > 0) {
        setsliderValue(2);
        if (
          accountDetail?.source === "shopify" &&
          shopifyProfileId?.shopifyProfileId
        ) {
          const defaultPlan = list.find((plan) => {
            return JSON.parse(plan?.limits)?.touchpoints === 250;
          });
          setTouchpointObject([defaultPlan]);
        } else {
          const defaultPlan = list.find(
            (plan) => JSON.parse(plan?.limits)?.touchpoints === 1000
          );
          setTouchpointObject([defaultPlan]);
        }
      }
    }
  }, [acct, list]);

  useEffect(() => {
    let account = acct;
    if (account) {
      if (!account?.url?.includes("http")) {
        account.url = "https://" + account?.url;
      }
      setaccountInfo(account);
    }
  }, [acct]);

  useEffect(() => {
    fetchAccountDetailsForBilling();
    // eslint-disable-next-line
  }, []);

  const onEditAccountSubmit = () => {
    accountform
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        EditAccount(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const EditAccount = (values) => {
    setLoading(true);
    let result = sdk.EditAccount(values);
    result
      .then((data) => {
        setAcct(data);
        seteditAccountDrawerOpen(false);
        accountform.resetFields();
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error(error);
        setLoading(false);
      });
  };

  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePlan = async (plan, index) => {
    const accountID = groups.length > 0 ? groups[0] : null;
    const priceValues = plan && JSON.parse(plan.priceValues);
    const price = priceValues?.[plan?.stripePriceID[0]]?.value;
    const limit = plan && JSON.parse(plan?.limits);

    let line_items = plan && plan.stripePriceID;
    line_items = line_items
      .filter((i) => priceValues[i]?.billing_period !== "year")
      .map((item) => {
        let dataObj = {};
        if (priceValues[item]?.billing_period !== "metered") {
          dataObj["quantity"] = 1;
        }
        return { ...dataObj, price: item };
      });
    if (
      plan &&
      plan.stripePriceID &&
      price &&
      limit.touchpoints &&
      accountID &&
      line_items
    ) {
      setLoader(true);
      setselectedIndex(index);
      try {
        let sessionResult = await billing.getOrgCheckoutSession(
          plan.stripePriceID[0],
          price,
          limit.touchpoints,
          accountID,
          JSON.stringify(line_items),
          plan?.name
        );
        if (
          sessionResult.type === "checkout" &&
          sessionResult.sessionId &&
          sessionResult?.source !== "shopify"
        ) {
          stripe
            .redirectToCheckout({
              sessionId: sessionResult.sessionId,
            })
            .catch((e) => {
              console.log(e);
              message.error({
                content: "Failed to update plan information.",
                key: msgKey,
                duration: 3,
              });
              setLoader(false);
            });
        } else if (
          sessionResult?.type === "checkout" &&
          sessionResult?.source === "shopify" &&
          sessionResult?.sessionId?.recurring_application_charge
        ) {
          window.open(
            sessionResult?.sessionId?.recurring_application_charge
              ?.confirmation_url,
            "_self"
          );
        } else if (sessionResult.type === "update") {
          window.location.reload();
        } else {
          message.error({
            content: "Failed to update plan information.",
            key: msgKey,
            duration: 3,
          });
          setLoader(false);
        }
      } catch (e) {
        console.log("error creating stripe session", e);
        message.error({
          content: "Failed to update plan information.",
          key: msgKey,
          duration: 3,
        });
        setLoader(false);
      }
    }
    setselectedIndex(null);
  };

  const handleAddNewkey = async () => {
    setSecretKeyLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    await sdk
      .addSecretsKey(accountID)
      .then(async () => {
        message.success("Secret key added successfully");
        await fetchSecretsKey();
        setSecretKeyLoading(false);
      })
      .catch(() => {
        message.success("Failed to add secret key");
        setSecretKeyLoading(false);
      });
  };

  const handleKeyDelete = async (deleteId) => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    await sdk
      .deleteSecretsKey(accountID, deleteId)
      .then(async () => {
        message.success("Deleted successfully");
        await fetchSecretsKey();
        setLoading(false);
      })
      .catch(() => {
        message.error("Failed delete secret key");
        setLoading(false);
      });
  };

  const handleDisable = async (disableId, value) => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    await sdk
      .disableSecretsKey(accountID, disableId, value)
      .then(async (res) => {
        if (res?.error) {
          message.error(res?.error);
          setLoading(false);
        } else {
          if (value === true) {
            message.success("Disable successfully");
            await fetchSecretsKey();
            setLoading(false);
          } else {
            message.success("Enable successfully");
            await fetchSecretsKey();
            setLoading(false);
          }
        }
      })
      .catch(() => {
        message.error("Failed disable secret key");
        setLoading(false);
      });
  };

  const handleSlider = (value) => {
    setsliderValue(value);
    let touchPointValue = touchpoint[value];
    touchPointValue =
      touchPointValue === 250
        ? 250
        : Number(
            touchPointValue.slice(-1) === "+"
              ? touchPointValue.slice(0, touchPointValue.length - 2)
              : touchPointValue.slice(0, touchPointValue.length - 1)
          ) * 1000;
    let FilterData = sortData.filter(
      (data) => JSON.parse(data?.limits).touchpoints === touchPointValue
    );
    setTouchpointObject(FilterData);
  };

  const handleHere = async (id) => {
    setLoadingCompanyInfo(true);
    await linkPortal
      .getPortalLink(id)
      .then((res) => {
        window.location.href = res?.url;
        setLoadingCompanyInfo(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingCompanyInfo(false);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("stripe_session")) {
      setplanselectedSuccess(true);
    }

    for (var key of params.keys()) {
      params.delete(key);
    }
    history.replace({
      search: params.toString(),
    });
    if (params.has("error")) {
      history.replace({
        search: "",
      });
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const cancelPlan = async () => {
    setMyPlanLoading(true);
    try {
      const apiName = "blustreamREST";
      const path = "/billing/shopify/cancel";
      const response = await API.post(apiName, path);
      if (response?.statusCode === 200) {
        message.success("Plan cancelled successfully!");
      }
    } catch (error) {
      console.log(error);
    }
    setTimeout(async () => {
      fetchAccountDetailsForBilling();
    }, 2000);
  };

  const ListPendingRequests = async () => {
    const response = await API.graphql(
      graphqlOperation(listCompanyInviteHistories, {
        filter: {
          account: {
            eq: accountDetails?.id,
          },
        },
      })
    );
    setPendingRequestList(response?.data?.listCompanyInviteHistories?.items);
  };

  const removeUser = async () => {
    try {
      setRemoveUserLoading(true);
      const apiName = "blustreamREST";
      const path = "/accounts/remove-user-from-organisation";
      const body = {
        email: userData?.email,
        account: accountDetails?.id,
      };
      await API.post(apiName, path, { body: body });
      message.success("Left organization Successfully!");
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        async (err, session) => {
          const { accessToken } = session;
          const response = Auth.currentUserInfo();
          response.then((data) => {
            dispatch(updateUserGroups(session, data?.attributes));
          });
          if ("cognito:groups" in accessToken.payload) {
            const onlyCompanyGroups = accessToken?.payload?.[
              "cognito:groups"
            ]?.filter((item) => item?.length === 36);
            const selectedOrganizationDetail = await sdk.fecthCompanies(
              onlyCompanyGroups[0]
            );
            selectCompany(selectedOrganizationDetail?.data?.getCustomerAccount);
          }
        }
      );
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    } finally {
      setRemoveUserLoading(false);
    }
  };

  const selectCompany = async (orgDetail) => {
    dispatch(updateCompanyAccount(orgDetail));
    dispatch(setTouchpointInRedux(""));

    if (orgDetail) {
      const account = await sdk.fetchAccount(orgDetail?.id);
      dispatch(setAccountDetails(account));
    }
    window.location.href = "/";
  };

  return (
    <>
      {(myPlanLoading || loadingCompanyInfo) && (
        <div className="loading">Loading;</div>
      )}

      <div>
        {planselectedSuccess && (
          <PlanSelected
            planreset={() => {
              setplanselectedSuccess(false);
            }}
          />
        )}
        <Tabs
          activeKey={activeTabKey}
          onChange={(key) => setActiveTabKey(key)}
          className="setting-table setting-table-tab"
          centered
          type="card"
        >
          <TabPane tab="Personal" key="1" className="setting-user-tab">
            <Row className="user-tab-content-wrapper">
              <Col span={22}>
                <Tabs
                  activeKey={personalTabSubKey}
                  onChange={(key) => setPersonalTabSubKey(key)}
                  tabPosition={window.innerWidth < 576 ? "bottom" : "left"}
                  type="card"
                >
                  <TabPane
                    tab={
                      <div className="tab-title-wrapper">
                        <img
                          src={userIcon2}
                          alt="userIcon2"
                          style={{ width: "20px", height: "18px" }}
                        />
                        <div className="tab-title">My Details</div>
                      </div>
                    }
                    key="1"
                  >
                    <PersonalDetails />
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="tab-title-wrapper">
                        <img
                          src={messageIcon}
                          alt="messageIcon"
                          style={{ width: "21px", height: "16px" }}
                        />
                        <div className="tab-title">My Invite requests</div>
                      </div>
                    }
                    key="2"
                  >
                    <MyInvitations />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Billing" key="2">
            {accountDetails?.manualBilling ? (
              <div className="manual-billing-placeholder-container">
                <div className="manual-billing-title-container">
                  <div className="manual-billing-title">Manage Billing</div>
                  <Divider className="manual-billing-title-divider" />
                </div>
                <div className="manual-billing-placeholder-content">
                  <img
                    className="manual-billing-placeholder-image"
                    src={manualBilling}
                    alt="manualBilling"
                  />
                  <div className="text-container">
                    <div className="manual-billing-text1">
                      To know about your Billing Status
                    </div>
                    <div className="manual-billing-text2">
                      Please send us an email requesting more information about
                      billing details. We'll be glad to provide you with the
                      necessary details.
                    </div>
                    <div className="manual-billing-text3">
                      help@blustream.io
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="desktopBilling">
                  <DesktopBilling
                    messageCostobject={messageCostobject}
                    switchState={switchState}
                    touchpoint={touchpoint}
                    accountInfo={accountInfo}
                    touchpointObject={touchpointObject}
                    loader={loader}
                    selectedIndex={selectedIndex}
                    planDetails={planDetails}
                    accountDetails={accountDetails}
                    billingList={billingList}
                    onChange={onChange}
                    toCommas={toCommas}
                    handlePlan={handlePlan}
                    handleSlider={handleSlider}
                    handleHere={handleHere}
                    sliderValue={sliderValue}
                    cancelPlan={cancelPlan}
                    ShopifyprofileData={ShopifyprofileData}
                  />
                </div>
                <div className="responsiveBilling">
                  <ResponsiveBilling
                    list={sortData}
                    messageCostobject={messageCostobject}
                    switchState={switchState}
                    touchpoint={touchpoint}
                    accountInfo={accountInfo}
                    touchpointObject={touchpointObject}
                    loader={loader}
                    selectedIndex={selectedIndex}
                    planDetails={planDetails}
                    accountDetails={accountDetails}
                    billingList={billingList}
                    onChange={onChange}
                    toCommas={toCommas}
                    handlePlan={handlePlan}
                    handleSlider={handleSlider}
                    handleHere={handleHere}
                    cancelPlan={cancelPlan}
                    ShopifyprofileData={ShopifyprofileData}
                  />
                </div>
              </>
            )}
          </TabPane>

          <TabPane
            tab="Organization"
            key="3"
            style={{ padding: "10px", fontSize: "16px" }}
          >
            <div className="desc_item" style={{ paddingRight: 20 }}>
              <span
                style={{
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Hellix",
                  fontSize: "16px",
                }}
                strong
              >
                Company Information
              </span>
              <Button
                className="antd-pen-icon"
                type="link"
                style={{ color: "black", padding: "0px" }}
                onClick={() => seteditAccountDrawerOpen(true)}
                disabled={roAdmin}
              >
                <PenIcon />
              </Button>
            </div>
            <Descriptions
              bordered
              column={1}
              size="small"
              style={{ marginTop: "5px", overflow: "hidden" }}
              className="company-description"
            >
              <Descriptions.Item
                labelStyle={{
                  fontWeight: "500",
                  color: "#959595",
                  background: "#fff",
                  fontFamily: "Hellix",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
                label="Company Name"
              >
                <Typography.Text
                  style={{
                    fontWeight: "500",
                    color: "#252525",
                    background: "#fff",
                    fontFamily: "Hellix",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  {accountInfo ? accountInfo.company : "-"}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{
                  fontWeight: "500",
                  color: "#959595",
                  background: "#fff",
                  fontFamily: "Hellix",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
                label="Company URL"
              >
                <Typography.Text
                  style={{
                    fontWeight: "500",
                    color: "#252525",
                    background: "#fff",
                    fontFamily: "Hellix",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  {accountInfo ? (
                    <a
                      href={accountInfo.url}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {accountInfo.url}
                    </a>
                  ) : (
                    "-"
                  )}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{
                  fontWeight: "500",
                  color: "#959595",
                  background: "#fff",
                  fontFamily: "Hellix",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
                label="Account ID"
              >
                {accountInfo ? (
                  <Typography.Text
                    copyable={{ text: accountInfo.id, icon: <CopyIcon /> }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: "500",
                      color: "#252525",
                      background: "#fff",
                      fontFamily: "Hellix",
                      fontSize: "16px",
                      lineHeight: "19px",
                    }}
                  >
                    {accountInfo.id}
                  </Typography.Text>
                ) : (
                  "-"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{
                  fontWeight: "500",
                  color: "#959595",
                  background: "#fff",
                  fontFamily: "Hellix",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
                label="Secret Key"
              >
                {secretkey ? (
                  <>
                    {secretkeys.length > 0 ? (
                      <>
                        {secretkeys.map((item, index) => {
                          return (
                            <>
                              <div
                                style={{
                                  display: screens.xs ? "" : "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography.Text
                                  copyable={{
                                    text: item?.key,
                                    icon: <CopyIcon />,
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontWeight: "bold",
                                    width: "100%",
                                    position: "relative",
                                  }}
                                  className={
                                    item?.disabled && "secret-key-value"
                                  }
                                >
                                  {index + 1}. {item?.key}
                                </Typography.Text>
                                <Button
                                  className="add-btn"
                                  disabled={roAdmin}
                                  style={{
                                    fontSize: "23px",
                                    color: "#959595",
                                    marginBottom: 16,
                                  }}
                                  shape="circle"
                                  type="text"
                                  onClick={() => handleKeyDelete(item?.id)}
                                >
                                  <DeleteOutlined />
                                </Button>
                                {item?.disabled ? (
                                  <Button
                                    className="standard-button tertiary-button"
                                    disabled={roAdmin}
                                    onClick={() =>
                                      handleDisable(item?.id, false)
                                    }
                                  >
                                    Enable
                                  </Button>
                                ) : (
                                  <Button
                                    className="standard-button tertiary-button"
                                    disabled={roAdmin}
                                    onClick={() =>
                                      handleDisable(item?.id, true)
                                    }
                                  >
                                    Disable
                                  </Button>
                                )}
                              </div>
                              {index === 0 && secretkeys.length > 1 && (
                                <Divider style={{ margin: 5 }} />
                              )}
                            </>
                          );
                        })}
                        {secretkeys.length < 2 && (
                          <Button
                            className="standard-button secondary-orange-button"
                            onClick={handleAddNewkey}
                            disabled={isScoped || roAdmin}
                            loading={secretKeyLoading}
                          >
                            Generate new secret key
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        className="standard-button secondary-orange-button"
                        onClick={handleAddNewkey}
                        disabled={isScoped || roAdmin}
                        loading={secretKeyLoading}
                      >
                        Generate new secret key
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {secretkeys.length > 0 && (
                      <div
                        style={{
                          width: "100%",
                          backgroundImage: `url(${blurBG})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeate",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          className="standard-button primary-orange-button"
                          onClick={() => setSecretkey(true)}
                          disabled={isScoped || roAdmin}
                        >
                          Reveal test key
                        </Button>
                      </div>
                    )}

                    {secretkeys.length < 2 && (
                      <Button
                        className="standard-button secondary-orange-button"
                        onClick={handleAddNewkey}
                        disabled={isScoped || roAdmin}
                        loading={secretKeyLoading}
                      >
                        Generate new secret key
                      </Button>
                    )}
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Button
              className="standard-button secondary-orange-button"
              style={{ marginTop: "20px" }}
              onClick={() => setShowLeaveOrganizationModal(true)}
            >
              Leave organization
            </Button>
          </TabPane>

          <TabPane tab="Users" key="4" className="setting-user-tab">
            <Row className="user-tab-content-wrapper">
              <Col span={22}>
                <Tabs
                  tabPosition={window.innerWidth < 576 ? "bottom" : "left"}
                  type="card"
                  tabBarExtraContent={
                    <Row style={{ width: "100%" }}>
                      <Col span={24}>
                        <Button
                          size="large"
                          htmlType="submit"
                          className="standard-button primary-orange-button"
                          onClick={() => setisInviteUserModelOpen(true)}
                          disabled={roAdmin}
                          style={{ width: "100%" }}
                        >
                          <span>Invite a user</span>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <TabPane
                    tab={
                      <div className="tab-title-wrapper">
                        <img
                          src={messageIcon}
                          alt="messageIcon"
                          style={{ width: "21px", height: "16px" }}
                        />
                        <div className="tab-title">User requests</div>
                      </div>
                    }
                    key="1"
                  >
                    <UserRequests
                      acct={acct}
                      fetchAccountDetails={fetchAccountDetails}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="tab-title-wrapper">
                        <img
                          src={userIcon2}
                          alt="userIcon2"
                          style={{ width: "20px", height: "18px" }}
                        />
                        <div className="tab-title">Current users</div>
                      </div>
                    }
                    key="2"
                  >
                    <CurrentUsers
                      acct={acct}
                      fetchAccountDetails={fetchAccountDetails}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="tab-title-wrapper">
                        <img
                          src={historyIcon}
                          alt="historyIcon"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <div className="tab-title">Pending Invitations</div>
                      </div>
                    }
                    key="3"
                  >
                    <PendingRequests
                      ListPendingRequests={ListPendingRequests}
                      pendingRequestList={pendingRequestList}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </TabPane>
        </Tabs>

        <Modal
          visible={isInviteUserModelOpen}
          className="invite-modal"
          footer={null}
          width={400}
          centered
          closeIcon={
            <strong>
              <CloseOutlined
                style={{
                  fontSize: 20,
                  color: "#ffffff",
                  marginLeft: 30,
                  position: "absolute",
                }}
              />
            </strong>
          }
          onCancel={() => {
            setisInviteUserModelOpen(false);
            form.resetFields();
            setAlreadyUser([]);
            setAlreadyInvitedUser([]);
            setinviteSuccess(false);
          }}
        >
          <div className="invite-modal-body">
            {!inviteSuccess ? (
              <>
                {" "}
                <img
                  src={inviteIMG}
                  alt=""
                  style={{ width: 148, paddingBottom: "20px" }}
                />
                <Typography.Title
                  level={4}
                  style={{
                    padding: "0px 0px 5px",
                    marginBottom: "0px",
                    fontSize: "22px",
                  }}
                >
                  Invite Team Members
                </Typography.Title>
                <Typography.Text
                  style={{ padding: "0px 0px 10px", fontSize: "16px" }}
                >
                  Invite your team members so they can manage contacts, setup
                  integrations, and view analytics.
                </Typography.Text>
                <Form
                  form={form}
                  requiredMark={false}
                  layout="vertical"
                  style={{ width: "100%" }}
                  className="invite-user-form"
                >
                  <Form.Item
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: "This field can not be empty!",
                      },
                    ]}
                    label={<strong>Email</strong>}
                    style={{
                      marginBottom:
                        alreadyInvitedUser?.length > 0 ||
                        alreadyUser?.length > 0
                          ? "0px"
                          : "24px",
                    }}
                  >
                    <Select
                      className="invite-user-multi-select"
                      placeholder="name@yourcompany.com"
                      mode="tags"
                      style={{
                        width: "100%",
                        paddingBottom: "2px",
                      }}
                      onChange={validateEmail}
                      tokenSeparators={[","]}
                      notFoundContent=""
                      size="large"
                    ></Select>
                  </Form.Item>
                  {alreadyInvitedUser?.length > 0 && (
                    <p
                      style={{
                        marginBottom: "24px",
                        color: "#ff4d4f",
                        fontSize: "14px",
                      }}
                    >
                      The invitation to {alreadyInvitedUser.join(", ")} has
                      already been sent.
                    </p>
                  )}
                  {alreadyUser?.length > 0 && (
                    <p
                      style={{
                        marginBottom: "24px",
                        color: "#ff4d4f",
                        fontSize: "14px",
                      }}
                    >
                      {alreadyUser.join(", ")} has already joined the
                      organization.
                    </p>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      block
                      htmlType="submit"
                      loading={loading}
                      tabIndex={2}
                      className="standard-button primary-orange-button"
                      onClick={onSubmit}
                    >
                      Send Invite
                    </Button>
                  </Form.Item>
                </Form>
              </>
            ) : (
              <div
                style={{
                  padding: 20,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img src={inviteSuccessIMG} alt="" style={{ width: 84 }} />
                <Typography.Title
                  level={4}
                  style={{ paddingTop: 20, paddingBottom: 5 }}
                >
                  Invitation sent successfully!
                </Typography.Title>
                <Typography.Text
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  Tell your team to check their spam folder if they do not
                  receive an invite.
                </Typography.Text>
              </div>
            )}
          </div>
        </Modal>

        <Drawer
          visible={editAccountDrawerOpen}
          title={<strong>Edit Company Details</strong>}
          width={416}
          bodyStyle={{ paddingBottom: 80 }}
          onClose={() => {
            seteditAccountDrawerOpen(false);
            accountform.resetFields();
          }}
        >
          <Form
            form={accountform}
            layout="vertical"
            className="edit_account_form"
            name="edit_account_form"
            requiredMark={"optional"}
            initialValues={{
              name: accountInfo?.company,
              url: accountInfo?.url,
              id: accountInfo?.id,
            }}
          >
            <Form.Item
              name="name"
              style={{
                marginTop: "0px",
              }}
              label={<span>Company Name</span>}
              rules={[
                {
                  required: true,
                  message: "Please enter the name of your company",
                },
                {
                  pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                  message: "Please enter valid company name",
                },
              ]}
            >
              <Input
                className="standard-input"
                size="large"
                id="company"
                key="company"
                name="company"
                placeholder="Company Name"
                disabled={loading}
                tabIndex={1}
              />
            </Form.Item>
            <Form.Item
              name="url"
              label={<span>Company URL</span>}
              rules={[
                {
                  required: true,
                  message: "Please enter the URL of your company",
                },
                {
                  pattern:
                    /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                  message: "Please enter valid URL",
                },
              ]}
            >
              <Input
                className="standard-input"
                size="large"
                id="url"
                key="url"
                name="url"
                placeholder="Company URL"
                disabled={loading}
                tabIndex={1}
              />
            </Form.Item>
            <Form.Item
              name="id"
              style={{
                marginTop: "0px",
              }}
              label={<span>Company ID</span>}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                className="standard-input"
                size="large"
                id="id"
                key="id"
                name="id"
                placeholder="Company ID"
                disabled
              />
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                fontSize: "18px",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                display: "flex",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                onClick={() => {
                  seteditAccountDrawerOpen(false);
                  accountform.resetFields();
                }}
                style={{ marginRight: 8, width: "48%" }}
                size="middle"
              >
                Cancel
              </Button>
              <Button
                onClick={onEditAccountSubmit}
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
              >
                Save
              </Button>
            </div>
          </Form>
        </Drawer>

        <Modal
          visible={ischangePlanModelOpen}
          className="invite-modal"
          footer={null}
          width={437}
          centered
          closeIcon={
            <strong>
              <CloseOutlined
                style={{
                  fontSize: 20,
                  color: "#ffffff",
                  marginLeft: 30,
                  position: "absolute",
                }}
              />
            </strong>
          }
          onCancel={() => {
            setischangePlanModelOpen(false);
          }}
        >
          <div className="invite-modal-body">
            <img src={changePlanModalImg} alt="" />
            <Space
              direction="vertical"
              align="center"
              size={20}
              style={{ paddingBottom: 30 }}
            >
              <Typography.Text strong style={{ fontSize: 20 }}>
                Are you sure about changing the plan?
              </Typography.Text>
              <div style={{ textAlign: "center" }}>
                <Typography.Text style={{ fontSize: 16, textAlign: "center" }}>
                  If looking to downgrade or cancel you will need to contact
                  Blustream support to help in the process.
                </Typography.Text>
              </div>
              <Button
                size="large"
                type="primary"
                className="standard-button primary-orange-button"
                onClick={() => {
                  setischangePlanModelOpen(false);
                }}
              >
                Yes, I am sure.
              </Button>
            </Space>
          </div>
        </Modal>

        {/* for leave organization confirmation */}
        <Modal
          centered
          visible={showLeaveOrganizationModal}
          footer={null}
          className="decline-invite-modal"
          onCancel={() => {
            setShowLeaveOrganizationModal(false);
          }}
        >
          <div className="decline-invite-modal-content-wrapper">
            <img src={glowingBulbIcon} alt="" />
            <p className="decline-invite-confirmation-text">
              Are you sure you want to leave this organization?
            </p>
            <div className="decline-invite-buttons-container">
              <Button
                className="standard-button tertiary-button"
                onClick={() => {
                  setShowLeaveOrganizationModal(false);
                }}
              >
                No
              </Button>
              <Button
                loading={removeUserLoading}
                className="standard-button primary-orange-button"
                type="primary"
                onClick={async () => {
                  await removeUser();
                  setShowLeaveOrganizationModal(false);
                }}
              >
                Yes, Sure
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Settings;
