/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData, updateUserData } from "store/actions/loginAction";

const PersonalDetails = () => {
  const [form] = Form.useForm();
  const [resetPasswordForm] = Form.useForm();
  const dispatch = useDispatch();
  const { roAdmin, userData } = useSelector((state) => state.loginReducer);

  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      firstname: userData?.given_name,
      lastname: userData?.family_name,
      email: userData?.email,
    });
  }, []);

  const updateUser = async (data) => {
    try {
      setUpdateUserLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;

      // Update the firstName and lastName fields
      attributes.given_name = data?.firstname;
      attributes.family_name = data?.lastname;

      await Auth.updateUserAttributes(user, attributes);
      message.success("User details updated successfully!");
      dispatch(updateUserData(attributes));
    } catch (error) {
      console.error("Error updating user details:", error);
    } finally {
      setUpdateUserLoading(false);
    }
  };

  const resetPassword = async (data) => {
    try {
      if (data.new_password === data.confirm_new_password) {
        setResetPasswordLoading(true);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(
          cognitoUser,
          data.current_password,
          data.new_password
        ).then(async () => {
          message.success("Password updated successfully!");
          const updatedCognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          updatedCognitoUser.refreshSession(
            currentSession.refreshToken,
            async (err, session) => {
              const response = Auth.currentUserInfo();
              response.then((data) => {
                dispatch(setLoginData(session, data?.attributes));
              });
            }
          );
          setShowResetPasswordModal(false);
          resetPasswordForm.resetFields();
        });
      } else {
        setResetPasswordLoading(false);
        message.error("New Passwords must match!");
      }
    } catch (error) {
      console.log(error);
      if (error) {
        message.error(error?.message);
      }
    } finally {
      setResetPasswordLoading(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        className="personal-details-form"
        form={form}
        onFinish={updateUser}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="First name"
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input className="standard-input" disabled={roAdmin} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last name"
              name="lastname"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input className="standard-input" disabled={roAdmin} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email">
              <Input className="standard-input" disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            gap: "15px",
          }}
        >
          <Button
            className="standard-button primary-orange-button"
            htmlType="submit"
            disabled={roAdmin}
            loading={updateUserLoading}
          >
            Save
          </Button>
          <Button
            className="standard-button secondary-orange-button"
            disabled={roAdmin}
            onClick={() => {
              setShowResetPasswordModal(true);
            }}
          >
            Reset password
          </Button>
        </div>
      </Form>

      <Modal
        visible={showResetPasswordModal}
        className="invite-modal"
        footer={null}
        width={400}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              style={{
                fontSize: 20,
                color: "#ffffff",
                marginLeft: 30,
                position: "absolute",
              }}
            />
          </strong>
        }
        onCancel={() => {
          setShowResetPasswordModal(false);
          resetPasswordForm.resetFields();
        }}
      >
        <div className="invite-modal-body ">
          <Typography.Title
            level={4}
            style={{
              padding: "0px 0px 25px",
              marginBottom: "0px",
              fontSize: "22px",
            }}
          >
            Reset Password
          </Typography.Title>
          <Form
            form={resetPasswordForm}
            requiredMark={false}
            layout="vertical"
            style={{ width: "100%" }}
            className="invite-user-form"
            onFinish={resetPassword}
          >
            <Form.Item
              name="current_password"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
              label="Current Password"
            >
              <Input.Password className="standard-input"></Input.Password>
            </Form.Item>
            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
              label="New Password"
            >
              <Input.Password className="standard-input"></Input.Password>
            </Form.Item>
            <Form.Item
              name="confirm_new_password"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
              label="Confirm New Password"
            >
              <Input.Password className="standard-input"></Input.Password>
            </Form.Item>

            <Form.Item style={{ marginTop: "5px" }}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={resetPasswordLoading}
                tabIndex={2}
                className="standard-button primary-orange-button"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PersonalDetails;
