import { Auth, Amplify } from "aws-amplify";
import "App.css";
import "responsive.css";
import Routes from "Routers/Routes";
import Maintenance from "UnderMaintenance";

import config from "./aws-exports";

const updatedAwsConfig = {
  ...config,
  // Set API to use id token. This will ensure email is passed within the token
  API: {
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
  },
};
const REGEX = /.*-(\w+)/;
const env = config.aws_content_delivery_bucket.match(REGEX)[1];
if (env === "prod") {
  updatedAwsConfig["aws_user_files_s3_bucket"] =
    "live-s3-gandalf-contactcsvuploads";
}
Amplify.configure(updatedAwsConfig);

function App() {
  return (
    <>
      {process.env.REACT_APP_IS_UNDER_MAINTENANCE === "true" ? (
        <Maintenance />
      ) : (
        <Routes />
      )}
    </>
  );
}

export default App;
