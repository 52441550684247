import React from "react";
import privacypdf from "../../../assets/pdf/BlustreamPrivacyPolicy.pdf";
function PrivacyPolicy() {
  return (
    <iframe
      title="privacy pdf"
      src={privacypdf}
      style={{ width: "100%", height: "100vh", border: "none" }}
    />
  );
}

export default PrivacyPolicy;
