import React, { useState } from "react";
import {
  Button,
  Collapse,
  Checkbox,
  Row,
  Col,
  Form,
  Rate,
  Popover,
  Radio,
  Mentions,
  Input,
  Tooltip,
  Select,
  Drawer,
} from "antd";
import {
  CheckCircleFilled,
  InfoCircleOutlined,
  PlusOutlined,
  StarFilled,
  StopOutlined,
} from "@ant-design/icons";
import "./trigger.css";
import BinocularIcon from "../../assets/Images/BinocularIcon.svg";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Mentions;

export default function Triggers() {
  const [editJourney, setEditJourney] = useState(false);
  const showDrawer = () => {
    setEditJourney(true);
  };
  const onClose = () => {
    setEditJourney(false);
  };

  const genExtra = () => (
    <>
      <Button
        type="text"
        onClick={showDrawer}
        style={{
          fontStyle: "italic",
          borderBottom: "1px solid",
          fontSize: "12px",
          padding: 0,
          marginRight: 10,
        }}
        danger
      >
        Test Now
      </Button>
      <Button shape="round" style={{ color: "#959595", marginRight: 10 }}>
        Add Property
      </Button>
      <Button shape="round" danger>
        Save
      </Button>
    </>
  );

  const starMenu = (
    <div style={{ width: 300 }}>
      <Row
        justify="space-between"
        style={{
          borderBottom: "1px solid #F0F0F0",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <h3 style={{ marginBottom: 0, color: "#124173", fontWeight: 600 }}>
          Moderate
        </h3>
        <Rate disabled defaultValue={3} style={{ fontSize: 15 }} />
      </Row>
      <Row
        justify="space-between"
        style={{ padding: "10px 0", alignItems: "center", flexWrap: "wrap" }}
      >
        <p style={{ marginBottom: 0, color: "#959595" }}>
          You can try this message
        </p>
        <Button
          style={{
            background: "#D7E8F9",
            color: "#124173",
            border: "none",
            fontSize: 13,
          }}
        >
          Try Now
        </Button>
      </Row>
      <p>
        Hey, congratulations for the new @Bass Guitar. We really want you to
        unbox the product carefully to avoid breaking any part of the product.
        Here is the 4 min video for you for unboxing.
      </p>
    </div>
  );

  const stopMessageMenu = (
    <div style={{ width: 230 }}>
      <Row style={{ alignItems: "center", flexWrap: "wrap" }}>
        <Radio>
          <h3 style={{ marginBottom: 5, color: "#124173" }}>
            {" "}
            Enable Stop messages
          </h3>
        </Radio>
      </Row>
      <p style={{ color: "#808080", marginBottom: 0 }}>
        Contacts will get the option to stop recieving messages once you check
        this.
      </p>
    </div>
  );

  const linkTrackingMenu = (
    <div style={{ width: 230 }}>
      <Row style={{ alignItems: "center", flexWrap: "wrap" }}>
        <Radio>
          <h3 style={{ marginBottom: 5, color: "#124173" }}>
            {" "}
            Enable link tracking
          </h3>
        </Radio>
      </Row>
      <p style={{ color: "#808080", marginBottom: 0 }}>
        You will be able to track whether contacts clicked this link or not.
      </p>
    </div>
  );
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  return (
    <>
      <Collapse
        className="site-collapse-custom-collapse"
        bordered={false}
        defaultActiveKey={["1", "2", "3"]}
        expandIcon={({ isActive }) =>
          isActive ? (
            <CheckCircleFilled style={{ fontSize: 18, color: "#252525" }} />
          ) : (
            <div className="circle"></div>
          )
        }
      >
        <Panel
          header="Channel Type & Message"
          key="1"
          className="site-collapse-custom-panel"
          extra={genExtra()}
        >
          <p>Select communication channel type</p>
          <Checkbox.Group
            defaultValue={["SMS"]}
            style={{ width: "100%" }}
            onChange={onChange}
          >
            <Checkbox value="SMS">SMS</Checkbox>
            <Checkbox value="Email">Email</Checkbox>
            <Checkbox value="Push">Push</Checkbox>
            <Checkbox value="Webhook">Webhook</Checkbox>
          </Checkbox.Group>

          <Row style={{ margin: "20px 0" }} gutter={10}>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <Form layout="vertical">
                <Form.Item
                  name="msg1"
                  className="message"
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>Message</p>

                      <div>
                        <Popover
                          placement="bottomRight"
                          content={starMenu}
                          arrow
                        >
                          <Button type="text" shape="circle">
                            <StarFilled
                              style={{ color: "#EEBE15", fontSize: 15 }}
                            />
                          </Button>
                        </Popover>
                        <Tooltip
                          color="black"
                          placement="bottomRight"
                          title="Use @ to tag your contacts/products/store"
                          arrow
                        >
                          <Button type="text" shape="circle">
                            <InfoCircleOutlined
                              style={{ color: "#B5B5B5", fontSize: 15 }}
                            />
                          </Button>
                        </Tooltip>
                        <Popover
                          placement="bottomRight"
                          content={stopMessageMenu}
                          arrow
                        >
                          <Button type="text" shape="circle">
                            <StopOutlined
                              style={{ color: "#B5B5B5", fontSize: 15 }}
                            />
                          </Button>
                        </Popover>
                      </div>
                    </div>
                  }
                >
                  <Mentions
                    rows={5}
                    placeholder="Use @ to tag your contacts/products/store"
                    style={{ borderRadius: 10 }}
                  >
                    <Option value="BassGuitar">Bass Guitar</Option>
                    <Option value="BassDrumStores">Bass Drum Stores</Option>
                    <Option value="Bass Speakers">Bass Speakers</Option>
                  </Mentions>
                </Form.Item>
              </Form>
            </Col>

            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <Form layout="vertical">
                <Form.Item
                  name="msg2"
                  className="message"
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>Content Link</p>

                      <div>
                        <Popover
                          placement="bottomRight"
                          content={linkTrackingMenu}
                          arrow
                        >
                          <Button
                            type="text"
                            shape="circle"
                            icon={
                              <img
                                src={BinocularIcon}
                                alt="BinocularIcon"
                                style={{ height: 15 }}
                              />
                            }
                          />
                        </Popover>
                      </div>
                    </div>
                  }
                >
                  <TextArea
                    placeholder="Controlled autosize"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    defaultValue="www.youtube.com/watch?v=BBz-Jyr23M4"
                  ></TextArea>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Panel>

        <Panel
          header="Timed (Single Usage)"
          key="2"
          className="site-collapse-custom-panel"
          extra={genExtra()}
        >
          <Form
            layout="vertical"
            style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
          >
            <Form.Item
              style={{ width: "300px", marginRight: 20 }}
              name="event"
              className="message"
              label="Select Event"
            >
              <Select defaultValue="demo">
                <Select.Option value="demo">Product purchase day</Select.Option>
                <Select.Option value="demo1">
                  Product purchase day2
                </Select.Option>
                <Select.Option value="demo2">
                  Product purchase day3
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{ width: "300px" }}
              name="time"
              className="message"
              label="Select Time"
            >
              <Select defaultValue="demo">
                <Select.Option value="demo">2 days after</Select.Option>
                <Select.Option value="demo1">4 days after</Select.Option>
                <Select.Option value="demo2">1 week after</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Panel>

        <Panel
          header="External System"
          key="3"
          className="site-collapse-custom-panel"
          extra={genExtra()}
        >
          <p style={{ marginBottom: 3 }}>Select CRM</p>
          <Radio.Group
            name="radiogroup"
            defaultValue={1}
            style={{ display: "flex" }}
          >
            <Radio value={1}>Hubspot</Radio>
            <Radio value={2}>Spotify</Radio>
            <Radio value={3}>Salesforce</Radio>
            <Radio value={4}>Zendesk</Radio>
          </Radio.Group>

          <Form
            layout="vertical"
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              marginTop: 20,
            }}
          >
            <Form.Item
              style={{ width: "50%", marginRight: 20 }}
              name="event"
              className="message"
              label="Attach hubspot social media post URL"
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ width: "50%" }}
              name="time"
              className="message"
              label="Add product survey form hubspot path"
            >
              <Input />
            </Form.Item>
          </Form>
        </Panel>

        <Panel
          header="Usage Equation"
          key="4"
          className="site-collapse-custom-panel"
        ></Panel>

        <Panel
          header="Calendar"
          key="5"
          className="site-collapse-custom-panel"
        ></Panel>

        <Panel
          header="Weather Equation"
          key="6"
          className="site-collapse-custom-panel"
        ></Panel>

        <Panel
          header="IoT data"
          key="7"
          className="site-collapse-custom-panel"
        ></Panel>
      </Collapse>

      {/* edit drawer  */}
      <Drawer
        className="TestTouchpoint"
        title={<strong>Test Touchpoint</strong>}
        width={416}
        placement="right"
        onClose={onClose}
        visible={editJourney}
      >
        <Form layout="vertical" name="Journey_edit">
          <Form.Item
            name="name"
            style={{
              marginTop: "0px",
              marginBottom: "12px",
            }}
            label={<span>Enter cell number 1</span>}
          >
            <Input
              size="large"
              id="number"
              key="number"
              name="number"
              placeholder="Number"
              tabIndex={1}
            />
          </Form.Item>

          <Form.Item
            name="name"
            style={{
              marginTop: "0px",
              marginBottom: "12px",
            }}
            label={<span>Enter cell number 2</span>}
          >
            <Input
              size="large"
              id="number"
              key="number"
              name="number"
              placeholder="Number"
              tabIndex={1}
            />
          </Form.Item>

          <Button type="text" danger icon={<PlusOutlined />}>
            Add More
          </Button>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
              display: "flex",
            }}
          >
            <Button
              className="btn-secondary"
              onClick={() => {
                setEditJourney(false);
              }}
              style={{
                marginRight: 8,
                width: "48%",
                border: "1px solid black",
              }}
              size="middle"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="btn-primary"
              size="middle"
            >
              Test now
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
