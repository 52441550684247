import { Tabs } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateCompanyAccount,
  setAccountDetails,
} from "store/actions/loginAction";
import Company from "./Company";
import TermsAndCondition from "./TermsAndCondition";
import Users from "./Users";
import sdk from "sdk/Accounts";
import "./Admin.css";
import { useHistory } from "react-router-dom";

function Admin() {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
  const selectCompany = async (accountId, from) => {
    setLoadingCompanyInfo(true);
    if (from === "user") {
      dispatch(updateCompanyAccount(accountId?.Company_Data));
    } else {
      dispatch(updateCompanyAccount(accountId));
    }
    if (accountId) {
      let account = await sdk.fetchAccount(accountId?.id);

      dispatch(setAccountDetails(account));
      setTimeout(() => {
        window.location.href = "/admin";
      }, 0);
    }
    setLoadingCompanyInfo(false);
    history.push("/admin");
  };
  return (
    <div>
      {loadingCompanyInfo && <div className="loading">Loading;</div>}
      <Tabs centered className="setting-table course-admin-tab" type="card">
        <TabPane style={{ paddingTop: "20px" }} tab="Companies" key="1">
          <Company selectCompany={selectCompany} />
        </TabPane>
        <TabPane style={{ paddingTop: "20px" }} tab="Users" key="2">
          <Users selectCompany={selectCompany} />
        </TabPane>
        <TabPane
          style={{ paddingTop: "20px" }}
          tab="Terms & Conditions"
          key="3"
        >
          <TermsAndCondition />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Admin;
