import React, { Component } from "react";
import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { Auth } from "aws-amplify";
import { emailPattern } from "utility/Constants";
import logoWhite from "assets/Images/logo-white.svg";
import moment from "moment";
import { CopyrightOutlined, MailOutlined } from "@ant-design/icons";

export class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      error: "",
      loading: false,
    };
  }

  handleResetPassword = (e) => {
    this.setState(
      {
        loading: true,
        error: "",
      },
      async () => {
        try {
          await Auth.forgotPassword(e.email);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.history.push({
                pathname: `/reset-password`,
                state: { userName: e.email },
              });
            }
          );
        } catch (error) {
          console.log("error resetting password:", error);
          this.setState({
            error: error.message,
            loading: false,
          });
        }
      }
    );
  };

  render() {
    const { error, feedback, loading } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={7} xl={6} className="sidebar-background">
          <Col
            className="info"
            style={{ position: "absolute", top: 0, left: 0 }}
          >
            <div className="info-logo">
              <a href="/signin">
                <img src={logoWhite} alt="" />
              </a>
            </div>
            <div className="info-text">
              <Typography.Title className="login-heading" level={1}>
                Welcome to Blustream!
              </Typography.Title>
              <Button
                type="primary"
                size="large"
                tabIndex={5}
                className="btn-secondary"
                style={{
                  marginTop: 44,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  lineHeight: "16px",
                }}
                onClick={() => history.push("/login")}
              >
                <span style={{ fontWeight: "bold" }}>Sign In</span>
              </Button>
            </div>
          </Col>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={17}
          xl={18}
          style={{ transform: "scale(0.88)" }}
        >
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight: 580,
            }}
          >
            <Card
              className="reset-password-form-container"
              style={{ border: "none", width: "437px" }}
            >
              <div>
                <Typography.Title
                  level={3}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingBottom: 34,
                  }}
                >
                  Reset Password{" "}
                </Typography.Title>
              </div>
              {feedback && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="success"
                  showIcon={false}
                  message={feedback}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_reset"
                className="reset-form"
                onFinish={(e) => this.handleResetPassword(e)}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Email ID"
                    disabled={loading}
                    size="large"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loading}
                      tabIndex={5}
                      className="standard-button primary-orange-button"
                    >
                      <span style={{ fontWeight: "bold" }}>Send Code</span>
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            </Card>

            <span
              style={{
                color: "#646C79",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
                userSelect: "none",
                marginBottom: "0px",
                position: "fixed",
                bottom: 0,
              }}
            >
              <div>
                <div>
                  Version{" "}
                  {process.env.REACT_APP_VERSION
                    ? process.env.REACT_APP_VERSION
                    : "0.1.0"}
                </div>
                <CopyrightOutlined />
                {`${moment().year()}. Blustream.io`}
              </div>
            </span>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default ForgetPassword;
