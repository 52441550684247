/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Collapse, Form, Row, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import FormSettings from "../Settings/FormSettings";
import Logo from "../Settings/Logo";
import ThemeSettings from "../Settings/ThemeSettings";
import Typography from "../Settings/TypographySettings";
import HideForm from "../Settings/HideForm";
import { useSelector, useDispatch } from "react-redux";
import {
  setLogokey,
  setMicrositeDefaultStyle,
} from "../../../store/actions/SettingAction";
import TextEditor from "commons/TextEditor/TextEditor";
import DropdownLang from "commons/DropDownLang/DropdownLang";
const { Panel } = Collapse;

export default function Settings({ popupForm }) {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  const { activeKey, logoKey, micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );
  const { roAdmin } = useSelector((state) => state.loginReducer);
  const [key, setKey] = useState([]);

  useEffect(() => {
    if (isMounted.current) {
      if (activeKey === "3") {
        setKey((prev) => [...prev, "2"]);
      }
    } else {
      isMounted.current = true;
      if (logoKey) {
        dispatch(setLogokey(!logoKey));
      }
    }
  }, [logoKey]);

  return (
    <div className="settings-opt">
      <Collapse
        activeKey={key}
        expandIconPosition="right"
        onChange={(keys) => setKey(keys)}
      >
        <Panel header="Theme setting" key="1">
          <ThemeSettings />
        </Panel>
        <Panel header="Logo" key="2">
          <Logo />
        </Panel>
        <Panel header="Typography" key="3">
          <Typography />
        </Panel>
        <Panel header="Form" key="4">
          <FormSettings />
        </Panel>
        <Panel header="Successful Sign Up Message" key="5">
          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Form layout="vertical">
                <Form.Item
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Message Text</span>
                      <span style={{ lineHeight: "1" }}>
                        <Tooltip title="Customize the Successful Sign up message that your contacts see when they sign up. Edits can be made on the left side and will show the preview on the right side. For example, add a link to a word or phrase or change the style of the text.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>{" "}
                    </div>
                  }
                >
                  <TextEditor
                    initialHtmlContent={micrositeDefaultStyle?.successMessage}
                    onChange={(text) => {
                      dispatch(
                        setMicrositeDefaultStyle({
                          ...micrositeDefaultStyle,
                          successMessage: text,
                        })
                      );
                    }}
                    style={{
                      width: "100%",
                      pointerEvents: roAdmin ? "none" : "",
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Panel>
        <Panel header="Form Language" key="5">
          <DropdownLang formLanguage={micrositeDefaultStyle?.formLanguage} />
        </Panel>
        <Panel header="Pop Up Settings" key="6">
          <HideForm popupForm={popupForm} />
        </Panel>
      </Collapse>
    </div>
  );
}
