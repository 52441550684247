import { message } from "antd";
import { BlustreamAPI } from "utility/Api";
import { SET_DATA,SET_CONTACTS, SHOW_LODER , TOUCHPOINT_DATA,SET_TNC, SET_TAGS,SET_MICROSITE_DEFAULT_STYLE,SET_MICROSITE_FORM_ELEMENTS,SET_PRIVACYPOLICY,SET_TNCSHOW,SET_PRSHOW,SET_MICROSITE_FORM_ELEMENT_ID,SET_MICROSITE_ACTIVE_KEY,SET_TEMPLATE_NAME,SET_MICROSITE_ID,SET_DISABLED,SET_LOGO_KEY} from ".";

export const setContactData = (inputdata) => {
    return {
      type: SET_CONTACTS,
      payload: inputdata
    };
};

export const setLoader = (inputdata) => {
    return {
        type: SHOW_LODER,
        payload: inputdata
      };
}

export const setTouchpoint = (inputdata) => {
  return {
      type: TOUCHPOINT_DATA,
      payload: inputdata
    };
}

export const setTnC = (inputdata) => {
  return {
    type: SET_TNC,
    payload:inputdata
  }
}

export const setTags = (inputdata) => {
  return {
    type: SET_TAGS,
    payload:inputdata
  }
}

export const setMicrositeDefaultStyle = (inputdata) => {
  return {
    type : SET_MICROSITE_DEFAULT_STYLE,
    payload : inputdata,
  }
}

export const setTemplateName= (inputdata) => {
  return {
    type : SET_TEMPLATE_NAME,
    payload : inputdata,
  }
}

export const setMicrositeFormElements = (inputdata) => {
  return {
    type : SET_MICROSITE_FORM_ELEMENTS,
    payload : inputdata,}}


export const setCurrentFormElementId = (inputdata) => {
  return {
    type : SET_MICROSITE_FORM_ELEMENT_ID,
    payload : inputdata,
  }
}

export const setActiveKey = (inputdata) => {
  return {
    type : SET_MICROSITE_ACTIVE_KEY,
    payload : inputdata,
  }
}

export const setLogokey = (inputdata) => {
  return {
    type : SET_LOGO_KEY,
    payload : inputdata,
  }
}

export const setPrivacyPolicy = (inputdata) => {
  return {
    type: SET_PRIVACYPOLICY,
    payload: inputdata
  }
}

export const setMicrositeId= (inputdata) => {
  return {
    type : SET_MICROSITE_ID,
    payload : inputdata,
  }
}

export const setDisabled= (inputdata) => {
  return {
    type : SET_DISABLED,
    payload : inputdata,
  }
}

export const setTncShow = (inputdata) => {
  return {
    type: SET_TNCSHOW,
    payload: inputdata
  }
}
export const setPrShow = (inputdata) => {
  return {
    type: SET_PRSHOW,
    payload: inputdata
  }
}

export const setCompanyData = (inputdata) => {
  return {
    type: SET_DATA,
    payload: inputdata
  }
}



export const createNewContact = (inputData) => (dispatch, getState) => {
    dispatch(setLoader(true));
    BlustreamAPI.createContactApi(inputData)
        .then((response) => {
            dispatch(getAllContacts())
        message.success("Contact created successfully");
        dispatch(setLoader(false));
        return response.data;
      })
      .catch((e) => {
        console.log("error :", e);
        message.error("Something went wrong");
        dispatch(setLoader(false));
      });
  };
  
export const getAllContacts = (filter) => (dispatch, getState) => {
    BlustreamAPI.getAllConatctApi().then((response) => {
        let contacts = response?.data?.listContacts?.items
        dispatch(setContactData(contacts))
    })
  }

export const getTnC = () => (dispatch) =>{
  return BlustreamAPI.listTermAndConditionsApi().then(response => {
    let data = response?.data?.listTermsAndConditions?.items
    if(data?.length > 0){
      dispatch(setTnC(data?.[0]))
    }
  dispatch(setLoader(false))
  return response

  }).catch(err => {console.log(err); dispatch(setLoader(false)); return err})
}
export const updateTnC = (inputData) => (dispatch) => {
  dispatch(setLoader(true))
  return BlustreamAPI.updateTermsAndConditionsApi(inputData).then(res => {
    dispatch(getTnC())
  }).catch(err => {
    console.log(err)
  })
}

// export const getPrivacyPolicy = (dispatch) =>{
//   return BlustreamAPI.listPrivacyPolicyApi().then(response => {
//     console.log(response,95)
//   }).catch(err => {
//     console.log(err)
//   })
// }
export const getPrivacyPolicy = () => (dispatch) =>{
  return BlustreamAPI.listPrivacyPolicyApi().then(response => {
    let data = response?.data?.listPrivacyPolicies?.items
    if(data?.length > 0){
      dispatch(setPrivacyPolicy(data?.[0]))
    }
  dispatch(setLoader(false))
  return response

  }).catch(err => {console.log(err); dispatch(setLoader(false)); return err})
}

export const updatePrivacyPolicy = (inputData) => (dispatch) => {
  dispatch(setLoader(true))
  return BlustreamAPI.updatePrivcayPolicyApi(inputData).then(res => {
    dispatch(getPrivacyPolicy())
  }).catch(err => {
    console.log(err)
  })
}

export const getTags = () => (dispatch,getState) => {
  return BlustreamAPI.getAllTagsApi().then(res => {
    dispatch(setTags(res?.data?.listTags?.items))
    return res?.data?.listTags?.items
  }).catch(err => {
    console.log(err);
    return err
  })
}
export const addtags = (inputData) => (dispatch) => {
  // dispatch(setLoader(true));
  return BlustreamAPI.addTagApi(inputData).then(res => {
    console.log(res)
  }).catch(err => {
    console.log(err)
  })
  // .then(async (response) => {
  //   console.log(response,99)
  //   const createdTag = response?.data?.createTag?.name;
  //   console.log(createdTag,100)
  //   await Promise.all(
  //     tagData.map((tagData) => {
  //       console.log(tagData,102)
  //     })
  //   )
  //   console.log(response,99)
  // })
}

export const createCustomTag = (inputData) => (dispatch, getState) => {
 return BlustreamAPI.createCustomerTagApi(inputData).then((res) => {
 }).catch(err => {
   console.log(err)
 })
    
}

export const getCustomerTagList = (filter) => (dispatch, getState) => {
 return BlustreamAPI.GetCustomerAccountTagApi(filter).then((res) => {
   return res
 }).catch(err => {
   console.log(err)
 })
    
}

export const updateAccountTags = (inputData) => (dispatch) => {
  dispatch(setLoader(true))
  return BlustreamAPI.updateTagsApi(inputData).then(res => {
    dispatch(getTags())
  }).catch(err => {
    console.log(err)
  })
}

export const deleteAccountTags = (inputData) => (dispatch) => {
return BlustreamAPI.deleteTagApi(inputData).then(res =>{
}).catch(err => {
  console.log(err)
})

}

export const deleteCustomAccoutTag = (inputData) => (dispatch) => {
return BlustreamAPI.deleteCustomerAccountTagApi(inputData).then(res =>{
  return res
}).catch(err => {
  console.log(err)
})

}



