/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Typography } from "antd";
import { Auth } from "aws-amplify";
import logoWhite from "assets/Images/logo-white.svg";
import moment from "moment";
import { CopyrightOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const SetNewPassword = (props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(
    props.location?.state ? props.location?.state?.userAttributes?.email : ""
  );

  useEffect(() => {
    if (props.location?.state) {
      let email = props.location?.state?.userAttributes?.email;
      if (email) {
        setUserEmail(email);
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }, []);

  const newPasswordHandler = (values) => {
    setLoading(true);
    let oldPassword = props.location?.state?.oldPassword;
    if (oldPassword) {
      let newPassword = values?.confirm_password;
      Auth.signIn(userEmail, oldPassword)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, newPassword, [])
              .then(async (user) => {
                message.success("Login with new password.");
                history.push("/signin");
              })
              .catch((err) => {
                console.log(err);
                message.error(err?.messages);
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err?.message);
        });
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={0} md={0} lg={7} xl={6} className="sidebar-background">
        <Col className="info" style={{ position: "absolute", top: 0, left: 0 }}>
          <div className="info-logo">
            <a href="/signin">
              <img src={logoWhite} alt="" />
            </a>
          </div>
          <div className="info-text">
            <Typography.Title className="login-heading" level={1}>
              Welcome to Blustream!
            </Typography.Title>
          </div>
        </Col>
      </Col>
      <Col xs={24} md={24} lg={17} xl={18} style={{ transform: "scale(0.88)" }}>
        <Row
          justify="center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "100vh",
            minHeight: 580,
          }}
        >
          <Card
            className="set-new-password-form-container"
            style={{ border: "none", width: "437px" }}
          >
            <div>
              <Typography.Title
                level={3}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  paddingBottom: 34,
                }}
              >
                Set New Password{" "}
              </Typography.Title>
            </div>
            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="normal_reset"
              className="reset-form"
              onFinish={(e) => {
                if (
                  e?.password === e?.confirm_password &&
                  e?.password.length > 0
                ) {
                  newPasswordHandler(e);
                } else {
                  message.error("Password and Confirm Password are not same!");
                }
              }}
            >
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input
                  className="standard-input"
                  id="password"
                  key="password"
                  name="password"
                  placeholder="Enter New Password"
                  disabled={loading}
                  size="large"
                  prefix={<LockOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
              >
                <Input
                  className="standard-input"
                  id="confirm_password"
                  key="confirm_password"
                  name="confirm_password"
                  placeholder="Confirm New Password"
                  disabled={loading}
                  size="large"
                  prefix={<LockOutlined />}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    loading={loading}
                    tabIndex={5}
                    className="standard-button primary-orange-button"
                  >
                    <span style={{ fontWeight: "bold" }}>Set New Password</span>
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Card>

          <span
            style={{
              color: "#646C79",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "500",
              userSelect: "none",
              marginBottom: "0px",
              position: "fixed",
              bottom: 0,
            }}
          >
            <div>
              <div>
                Version{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "0.1.0"}
              </div>
              <CopyrightOutlined />
              {`${moment().year()}. Blustream.io`}
            </div>
          </span>
        </Row>
      </Col>
    </Row>
  );
};

export default SetNewPassword;
