import { CopyOutlined, DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Col, PageHeader, Row, Typography, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./microsite.css";
import NewMicrositeIcon from "assets/Images/NewMicrositeIcon.svg";
import Template from "./Template";
import Template2 from "./Template2";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { listMicrosites } from "graphql/queries";
import { createMicrosite, deleteMicrosite } from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import {
  setMicrositeFormElements,
  setMicrositeDefaultStyle,
  setTemplateName,
  setMicrositeId,
  setDisabled,
} from "../../store/actions/SettingAction";
import { gettimeDiff } from "utility/GetTimeDifference";
import { v4 as uuidv4 } from "uuid";
import YourLogo from "./../../assets/Images/YourLogo.png";
import DeleteIcon from "./../../assets/Images/DeleteIcon.svg";
import SkeletonBlock from "commons/SkeletonBlock";
import { useTranslation } from "react-i18next";

export default function Microsite() {
  const { t, i18n } = useTranslation("translations");
  const history = useHistory();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { groups, accountDetails, roAdmin } = useSelector(
    (state) => state.loginReducer
  );
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const showModal = (id) => {
    setIsDeleteModal(true);
    setTemplateId(id);
  };

  const handleCancel = () => {
    setIsDeleteModal(false);
  };
  const initialStyle = {
    micrositeName: "Write Your Form Title Here...",
    micrositePageTitle: "Untitled Form",
    themeStyle: {
      backgroundColor: "#CDE1F3",
      secondaryBackgroundColor: "#ffffff",
      backgroundImageUrl: "",
      backgroundWidth: "100",
      backgroundHeight: "100",
    },
    logoUrl: `${YourLogo}`,
    logoHeight: "30px",
    logoWidth: "auto",
    headingStyle: {
      fontFamily: "arial",
      fontSize: "23px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    subHeadingStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    textStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    questionStyle: {
      fontFamily: "arial",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    answerStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    descriptionStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    fieldStyle: {
      backgroundColor: "#ffffff",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "7px",
    },
    buttonStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      backgroundColor: "#ffffff",
      color: "#000000",
      height: "auto",
      borderColor: "#000000",
    },
    companyTextStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#000000",
    },
    errorMessageColor: "#ff0000",
    successMessage: "Congrats and we can't wait for your journey to start!",
    formLanguage: "en",
    permenantHideOnSubmit: false,
    temporaryHideOnPopupClose: false,
    numberOfHidingDays: 1,
  };
  // Dev Comment :- don't change the index of the array as it will break the flow'
  const initialFormElements = [
    {
      id: uuidv4(),
      htmlType: "",
      type: "image",
      src: "link",
      style: {
        display: "flex",
        justifyContent: "center",
        margin: "auto auto 10px",
      },
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "heading",
      headingText: "Let's get started!",
      style: { marginTop: 10 },
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "sub-heading",
      headingText: "Your sub heading goes here!",
      style: { marginBottom: 15 },
    },
    {
      id: uuidv4(),
      htmlType: "text",
      type: "default-input",
      label: t("FORMS.firstNameLabel"),
      placeholder: t("FORMS.firstNamePlaceholder"),
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "text",
      type: "default-input",
      label: t("FORMS.lastNameLabel"),
      placeholder: t("FORMS.lastNamePlaceholder"),
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "tel",
      type: "default-input",
      label: t("FORMS.phoneLabel"),
      placeholder: t("FORMS.phonePlaceholder"),
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "email",
      type: "default-email",
      label: t("FORMS.emailLabel"),
      placeholder: t("FORMS.emailPlaceholder"),
      isRequired: false,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "button",
      buttonText: "Send",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "Consent-text",
      textValue: `By submitting this form, you consent to receive marketing text messages from ${accountDetails?.company} via an automatic telephone dialing system and that the number you have provided is your own. Consent is not a condition of any purchase. Standard message and data rates may apply. At any time, you can unsubscribe from all messages by replying STOP. View our Privacy Policy and Terms of Service.`,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "copyright",
      textValue: `© ${new Date().getFullYear()} ${accountDetails?.company}`,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "company-text",
      textValue: `Sign up today to start your product journey via SMS with ${accountDetails?.company}. Get useful tips on the care, maintenance, and enjoyment around your products.`,
      style: {},
    },
  ];

  // const { disabled } = useSelector((state) => state.SettingReducer);
  const [microsites, setMicrosites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    listAllMicrosites();
    dispatch(setMicrositeFormElements(initialFormElements));
    // eslint-disable-next-line
  }, []);

  const listAllMicrosites = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(listMicrosites, {
          filter: {
            account: {
              eq: groups[0],
            },
          },
          limit: 999,
        })
      );
      const filteredMicrosites = response.data.listMicrosites.items;
      setMicrosites(
        filteredMicrosites.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        )
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const removeMicrosite = async () => {
    // setIsDeleteModal(true)
    try {
      await API.graphql(
        graphqlOperation(deleteMicrosite, { input: { id: templateId } })
      );
      setLoading(true);
      listAllMicrosites();
      setIsDeleteModal(false);
    } catch (error) {
      setIsDeleteModal(false);
      console.log(error);
    }
  };

  const copyMicrosite = async (micrositeObj) => {
    try {
      //This is for changing microsite Name
      dispatch(setMicrositeId(""));
      // dispatch(setDisabled(true));
      const micrositeStyle = JSON.parse(micrositeObj?.micrositeStyle);
      micrositeStyle.micrositeName = `${
        JSON.parse(micrositeObj?.micrositeStyle).micrositeName
      }-Copied`;

      await API.graphql(
        graphqlOperation(createMicrosite, {
          input: {
            account: micrositeObj?.account,
            templateName: micrositeObj?.templateName,
            disabled: true,
            micrositeFormElements: micrositeObj?.micrositeFormElements,
            micrositeStyle: JSON.stringify(micrositeStyle),
          },
        })
      );
      setLoading(true);
      listAllMicrosites();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Row gutter={[20, 20]}>
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
            <SkeletonBlock style={{ width: 270 }} />
          </Row>
        </>
      ) : (
        <Row gutter={[10, 10]} style={{ height: "auto" }}>
          <Col span={6} xl={6} lg={12} md={12} sm={12} xs={24}>
            <div
              className="create-new-card step-three"
              style={{ height: "100%" }}
            >
              <img
                src={NewMicrositeIcon}
                height="100"
                style={{ marginBottom: 20 }}
                alt="newMicrositeIcon"
              />
              <Button
                className="standard-button primary-orange-button"
                onClick={() => {
                  i18n.changeLanguage("en");
                  dispatch(setMicrositeId(""));
                  dispatch(setTemplateName("TWO_COLUMN_LEFT_FORM"));
                  dispatch(setDisabled(true));
                  dispatch(setMicrositeDefaultStyle(initialStyle));
                  dispatch(setMicrositeFormElements(initialFormElements));
                  history.push("/contact-form/contact-form-builder");
                }}
                disabled={roAdmin}
              >
                <PlusOutlined /> Create New Form
              </Button>
            </div>
          </Col>
          {
            // eslint-disable-next-line
            microsites.map((micrositeObj) => {
              if (micrositeObj?.templateName === "TWO_COLUMN_LEFT_FORM") {
                return (
                  <Col
                    span={6}
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    onClick={() => {
                      dispatch(setMicrositeId(micrositeObj?.id));
                      dispatch(setTemplateName(micrositeObj?.templateName));
                      dispatch(setDisabled(micrositeObj?.disabled));
                      dispatch(
                        setMicrositeDefaultStyle(
                          JSON.parse(micrositeObj?.micrositeStyle)
                        )
                      );
                      dispatch(
                        setMicrositeFormElements(
                          JSON.parse(micrositeObj?.micrositeFormElements)
                        )
                      );
                      i18n.changeLanguage(
                        JSON.parse(micrositeObj?.micrositeStyle)?.formLanguage
                      );
                      history.push("/contact-form/contact-form-builder");
                    }}
                    style={{ height: "fit-content" }}
                  >
                    <div className="create-new-card template">
                      <PageHeader
                        ghost={false}
                        title={
                          <>
                            <Text
                              style={{
                                width: 150,
                                fontSize: "13px",
                                lineHeight: "13px",
                              }}
                              ellipsis={{
                                tooltip: `${
                                  JSON.parse(micrositeObj?.micrositeStyle)
                                    ?.micrositeName
                                }`,
                              }}
                            >
                              {
                                JSON.parse(micrositeObj?.micrositeStyle)
                                  ?.micrositeName
                              }
                            </Text>
                            <br />
                            <span className="subtitle">{`Updated ${gettimeDiff(
                              new Date(),
                              new Date(micrositeObj?.updatedAt)
                            )} ago`}</span>
                          </>
                        }
                        extra={[
                          <Button
                            key="2"
                            shape="circle"
                            className={roAdmin ? "disable-pointer-events" : ""}
                            style={{
                              background: "#E2EBF3",
                              color: "#124173",
                              border: "none",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              copyMicrosite(micrositeObj);
                            }}
                          >
                            <CopyOutlined />
                          </Button>,
                          <Button
                            key="3"
                            shape="circle"
                            className={roAdmin ? "disable-pointer-events" : ""}
                            style={{
                              background: "#E2EBF3",
                              color: "#124173",
                              border: "none",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              showModal(micrositeObj?.id);
                            }}
                          >
                            <DeleteFilled />
                          </Button>,
                        ]}
                      />
                      <Template
                        micrositeStyle={JSON.parse(
                          micrositeObj?.micrositeStyle
                        )}
                        micrositeFormElements={JSON.parse(
                          micrositeObj?.micrositeFormElements
                        )}
                      />
                    </div>
                  </Col>
                );
              } else if (
                micrositeObj?.templateName === "TWO_COLUMN_RIGHT_FORM"
              ) {
                return (
                  <Col
                    span={6}
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    onClick={() => {
                      dispatch(setMicrositeId(micrositeObj?.id));
                      dispatch(setTemplateName(micrositeObj?.templateName));
                      dispatch(setDisabled(micrositeObj?.disabled));
                      dispatch(
                        setMicrositeDefaultStyle(
                          JSON.parse(micrositeObj?.micrositeStyle)
                        )
                      );
                      dispatch(
                        setMicrositeFormElements(
                          JSON.parse(micrositeObj?.micrositeFormElements)
                        )
                      );
                      i18n.changeLanguage(
                        JSON.parse(micrositeObj?.micrositeStyle)?.formLanguage
                      );
                      history.push("/contact-form/contact-form-builder");
                    }}
                    style={{ height: "fit-content" }}
                  >
                    <div className="create-new-card template">
                      <PageHeader
                        ghost={false}
                        title={
                          <>
                            <Text
                              style={{
                                width: 150,
                                fontSize: "13px",
                                lineHeight: "13px",
                              }}
                              ellipsis={{
                                tooltip: `${
                                  JSON.parse(micrositeObj?.micrositeStyle)
                                    ?.micrositeName
                                }`,
                              }}
                            >
                              {
                                JSON.parse(micrositeObj?.micrositeStyle)
                                  ?.micrositeName
                              }
                            </Text>
                            <br />
                            <span className="subtitle">{`Updated ${gettimeDiff(
                              new Date(),
                              new Date(micrositeObj?.updatedAt)
                            )} ago`}</span>
                          </>
                        }
                        extra={[
                          <Button
                            key="2"
                            shape="circle"
                            className={roAdmin ? "disable-pointer-events" : ""}
                            style={{
                              background: "#E2EBF3",
                              color: "#124173",
                              border: "none",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              copyMicrosite(micrositeObj);
                            }}
                          >
                            <CopyOutlined />
                          </Button>,
                          <Button
                            key="3"
                            shape="circle"
                            className={roAdmin ? "disable-pointer-events" : ""}
                            style={{
                              background: "#E2EBF3",
                              color: "#124173",
                              border: "none",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              showModal(micrositeObj?.id);
                            }}
                          >
                            <DeleteFilled />
                          </Button>,
                        ]}
                      />
                      <Template2
                        micrositeStyle={JSON.parse(
                          micrositeObj?.micrositeStyle
                        )}
                        micrositeFormElements={JSON.parse(
                          micrositeObj?.micrositeFormElements
                        )}
                      />
                    </div>
                  </Col>
                );
              }
            })
          }

          <Modal
            visible={isDeleteModal}
            footer={null}
            className="btn-modal"
            onCancel={handleCancel}
          >
            <div className="form-delete-modal">
              <img src={DeleteIcon} alt="" />
              <p className="delete-modal-text">
                Are you sure you want to delete this Form?
              </p>
              <div className="delete-modal-button-container">
                <Button
                  className="standard-button tertiary-button"
                  onClick={() => handleCancel()}
                >
                  No
                </Button>
                <Button
                  className="standard-button primary-orange-button"
                  type="primary"
                  onClick={() => removeMicrosite()}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        </Row>
      )}
    </>
  );
}
