import React, { useEffect, useState } from "react";
import sdk from "sdk/Accounts";
import Moment from "react-moment";
import { Space, Table, Form, Select, Input, Typography, Skeleton } from "antd";
import "./Admin.css";
import { useDispatch } from "react-redux";
import { setRoAdmin } from "store/actions/loginAction";

const Users = (props) => {
  const dispatch = useDispatch();
  const { Search } = Input;

  const [Usersdata, setUsersdata] = useState([]);
  const [tableloading, settableloading] = useState(false);
  const [SearchParams, setSearchParams] = useState({});
  const [searchOption, setsearchOption] = useState("Email");
  const [searchValue, setSearchValue] = useState("");
  const [userbackup, setuserbackup] = useState([]);

  const get_attribute = (user, key) => {
    let result = user.filter((attribute) => attribute.Name === key);
    if (result.length > 0) {
      return result[0]["Value"];
    } else {
      return "";
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "Attributes",
      key: "Name",
      align: "center",
      width: "18%",
      render: (name) => (
        <span>
          {get_attribute(name, "given_name") +
            " " +
            get_attribute(name, "family_name")}
        </span>
      ),
      sorter: (a, b) => {
        var namea = a?.Attributes[2]?.Value + " " + a?.Attributes[3]?.Value;
        var nameb = b?.Attributes[2]?.Value + " " + b?.Attributes[3]?.Value;
        return namea.localeCompare(nameb);
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Email",
      dataIndex: "Attributes",
      key: "Email",
      render: (attr) => <span>{get_attribute(attr, "email")}</span>,
      sorter: (a, b) => {
        const firstEmail = a?.Attributes[4]?.Value;
        var secondEmail = b?.Attributes[4]?.Value;
        return String(firstEmail).localeCompare(secondEmail);
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Status",
      dataIndex: "UserStatus",
      key: "user_status",
      render: (status) => <span>{status}</span>,
    },
    {
      title: "Company",
      dataIndex: "Company_Data",
      key: "Company_Name",
      align: "center",
      render: (name, record) => (
        <span
          title="Click here to select company for ro-admin view"
          className="company-name"
          onClick={() => {
            props.selectCompany(record, "user");
            dispatch(setRoAdmin(true));
          }}
        >
          {name?.company}
        </span>
      ),
      sorter: (a, b) => {
        return a?.Company_Data?.company.localeCompare(b?.Company_Data?.company);
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Created At",
      dataIndex: "UserCreateDate",
      key: "created_at",
      render: (modifyDate) => (
        <span>
          <Moment date={modifyDate} format="lll" />
        </span>
      ),

      sorter: (a, b) => {
        return String(a?.UserCreateDate).localeCompare(b?.UserCreateDate);
      },
      defaultSortOrder: "descend",
      showSorterTooltip: false,
      sortDirections: ["descend", "ascend"],
    },
  ];
  const getUserData = async () => {
    let nextToken = null;
    settableloading(true);
    setUsersdata([]);
    setuserbackup([]);
    do {
      let response = await sdk.getAllUsers(nextToken);
      let result = response?.data?.listCustomerAccounts?.items;
      let usersdata = [];
      // eslint-disable-next-line array-callback-return
      result?.map((item) => {
        // eslint-disable-next-line array-callback-return
        item?.users?.map((user) => {
          let data = JSON.parse(user);
          delete item?.users;
          data["Company_Data"] = item;
          usersdata.push(data);
        });
      });
      setUsersdata((old) => old.concat(usersdata));
      setuserbackup((old) => old.concat(usersdata));
      nextToken = response?.data?.listCustomerAccounts?.nextToken;
    } while (nextToken);
    settableloading(false);
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handle_search = () => {
    setUsersdata(userbackup);
    setSearchParams({
      Name: searchOption,
      Value: searchValue ? searchValue : "",
    });
  };

  useEffect(() => {
    searchUser(SearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  const searchUser = (SearchParams) => {
    if (SearchParams.Name === "First name") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(get_attribute(user.Attributes, "given_name"))
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    } else if (SearchParams.Name === "Last name") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(get_attribute(user.Attributes, "family_name"))
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    } else if (SearchParams.Name === "Email") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(get_attribute(user.Attributes, "email"))
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    } else if (SearchParams.Name === "Company") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(user["Company_Data"]?.company)
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    }
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }} size={1}>
        <Typography.Title level={5}>User Management</Typography.Title>
        <Form
          name="search_users"
          initialValues={{ search_type: "Email" }}
          className="search_users"
        >
          <Space
            direction="horizontal"
            style={{ padding: "0px 5px" }}
            className="flexCol user-search-type"
          >
            <Form.Item name="search_type">
              <Select
                size="large"
                className="standard-dropdown"
                onChange={(e) => setsearchOption(e)}
              >
                <Select.Option key="Email">Email</Select.Option>
                <Select.Option key="First name">First Name</Select.Option>
                <Select.Option key="Last name">Last Name</Select.Option>
                <Select.Option key="Company">Company</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="search_value">
              <Search
                className="company-searchbox"
                placeholder={`Enter ${searchOption}`}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                }}
                value={searchValue}
                onSearch={handle_search}
              />
            </Form.Item>
          </Space>
        </Form>

        {tableloading ? (
          <div style={{ background: "#fff", padding: 20 }}>
            <Skeleton active paragraph={{ rows: 7 }} />{" "}
          </div>
        ) : (
          <Table
            bordered
            size="middle"
            dataSource={Usersdata}
            columns={columns}
          ></Table>
        )}
      </Space>
    </div>
  );
};

export default Users;
