import { API, Auth } from "aws-amplify";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setVerificationDetails } from "store/actions/loginAction";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

const ShopifyRedirect = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const url_string = window.location.href;
  const url = new URL(url_string);
  const code = url.searchParams.get("code");
  const shopname = url.searchParams.get("shop");
  const hmac = url.searchParams.get("hmac");

  const verifyStoreAndUser = async (jwtToken) => {
    try {
      const apiName = "blustreamREST";
      const path = "/shopify-intergration/integrations_new";
      let body = {
        hmac: hmac,
        code: code,
        shop: shopname,
        url: url,
        login_token: jwtToken,
      };

      const response = await API.post(apiName, path, { body: body });
      dispatch(setVerificationDetails(response));
      history.push("/unboxing-tour");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const storeDetails = { url, code, shopname, hmac };
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const jwtToken = user?.signInUserSession?.accessToken?.jwtToken;
        await verifyStoreAndUser(jwtToken);
      })
      .catch(() => {
        localStorage.setItem("storeDetails", JSON.stringify(storeDetails));
        history.push("/signin");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" className="shopify-loader" />
      <h1>Please wait. We’re checking!</h1>
      {/* {beforeSignIn ? (
            <h1>Please wait. We’re setting up your account.</h1>
          ) : newUser && !isShopifyUserLogin ? (
            <h1>Almost there! Getting things organized...</h1>
          ) : (
            <h1>Logging you in! One second…</h1>
          )} */}
    </div>
  );
};

export default ShopifyRedirect;
