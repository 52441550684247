import React, { useState } from "react";
import Template from "../Template";
import Template2 from "../Template2";
import { useSelector, useDispatch } from "react-redux";
import { setTemplateName } from "store/actions/SettingAction";

export default function Layout() {
  const { templateName, micrositeDefaultStyle, micrositeFormElements } =
    useSelector((state) => state.SettingReducer);
  const { roAdmin } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: 15 }} className="layout-wrapper">
      <div
        className={
          !roAdmin
            ? templateName === "TWO_COLUMN_LEFT_FORM"
              ? "layouts active"
              : "layouts"
            : "layouts"
        }
        onClick={() => {
          if (!roAdmin) {
            dispatch(setTemplateName("TWO_COLUMN_LEFT_FORM"));
          }
        }}
      >
        <p style={{ marginBottom: 0 }}>Basic Template</p>
        <Template
          micrositeStyle={micrositeDefaultStyle}
          micrositeFormElements={micrositeFormElements}
        />
      </div>

      <div
        className={
          !roAdmin
            ? templateName === "TWO_COLUMN_RIGHT_FORM"
              ? "layouts active"
              : "layouts"
            : "layouts"
        }
        onClick={() => {
          if (!roAdmin) {
            dispatch(setTemplateName("TWO_COLUMN_RIGHT_FORM"));
          }
        }}
      >
        <p style={{ marginBottom: 0 }}>Basic Template 2</p>
        <Template2
          micrositeStyle={micrositeDefaultStyle}
          micrositeFormElements={micrositeFormElements}
        />
      </div>

      {/* <div className={active === 3 ? "layouts active" : 'layouts'}
            onClick={()=>{
                props.selectedLayout(3)
                setActive(3)
            }}>
            <p style={{marginBottom: 0}}>Basic Template 3</p>
            <Template3 />
        </div>

        <div className={active === 4 ? "layouts active" : 'layouts'} 
            onClick={()=>{
                props.selectedLayout(4)
                setActive(4)
            }}>
            <p style={{marginBottom: 0}}>Basic Template 4</p>
            <Template4 />
        </div> */}
    </div>
  );
}
