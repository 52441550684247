import React from "react";
import { Typography, Row, Col, Divider, Slider } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Lite from "assets/Images/basic-icon.svg";
import Standard from "assets/Images/plus-icon.svg";
import Enterprise from "assets/Images/premium-icon.svg";
import Premium from "assets/Images/advanced-icon.svg";

const PublicDesktopBilling = ({
  messageCostobject,
  switchState,
  touchpoint,
  minValue,
  maxValue,
  touchpointObject,
  handleSlider,
  onChange,
  toCommas,
}) => {
  const formatter = (value) => {
    return (
      <span className="tooltip-msg">
        Up to {touchpoint[value]} Product{" "}
        {switchState ? "Customers" : "Messages"}
      </span>
    );
  };
  return (
    <>
      <div style={{ margin: "10px" }}>
        <div style={{ background: "#EFF4F9", borderRadius: 7 }}>
          <div style={{ padding: "18px 0px 13px 20px" }}>
            <Typography.Title
              style={{
                fontSize: 18,
                fontWeight: "700",
                color: "#252525",
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              Choose Your Plan
            </Typography.Title>
          </div>
          <div
            className="divider-billing"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 20px 24px 20px",
            }}
          >
            <Divider
              style={{ display: "block", height: "2px", background: "#CDDFEF" }}
            />
          </div>
          <Row gutter={15} justify="center" style={{ paddingBottom: 24 }}>
            <Col
              span={18}
              xxl={18}
              xl={18}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="responsiveSlider"
            >
              <div style={{ padding: "0 30px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <div style={{ width: "31.8%" }}>
                    <span
                      style={{
                        color: "#124173",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      Lite
                    </span>
                    <p style={{ paddingTop: 6, maxWidth: "155px" }}>
                      Great for trials and companies with 1-1,000 customers
                    </p>
                  </div>
                  <div style={{ width: "31.8%" }}>
                    <span
                      style={{
                        color: "#124173",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      Standard
                    </span>
                    <p style={{ paddingTop: 6, maxWidth: "196px" }}>
                      Great for small & medium businesses looking to grow with
                      1,000 - 25,000 customers
                    </p>
                  </div>
                  <div style={{ width: "31.8%" }}>
                    <span
                      style={{
                        color: "#124173",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      Pro
                    </span>
                    <p style={{ paddingTop: 6, maxWidth: "241px" }}>
                      Great for medium to larger businesses looking to scale
                      widely with 25,000+ customers
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                  className="slider-wrapper"
                >
                  <div className="divider-col lite" style={{ width: "31.8%" }}>
                    <div
                      style={{
                        border: "1px solid #C5D5E5",
                        height: "10px",
                        borderBottom: "none",
                      }}
                    ></div>
                  </div>
                  <div
                    className="divider-col standard"
                    style={{ width: "31.8%" }}
                  >
                    <div
                      style={{
                        border: "1px solid #C5D5E5",
                        height: "10px",
                        borderBottom: "none",
                      }}
                    ></div>
                  </div>
                  <div className="divider-col pro" style={{ width: "31.8%" }}>
                    <div
                      style={{
                        border: "1px solid #C5D5E5",
                        height: "10px",
                        borderBottom: "none",
                      }}
                    ></div>
                  </div>
                </div>
                <Slider
                  className="slider-tab"
                  tooltipPlacement="bottom"
                  step={null}
                  tipFormatter={formatter}
                  marks={touchpoint}
                  min={0}
                  max={100}
                  onChange={(e) => handleSlider(e)}
                />
              </div>
              <div
                className="message-cost-text"
                style={{
                  display: "flex",
                  gap: "20px 7px",
                  flexWrap: "wrap",
                  padding: "0px 24px",
                  marginTop: "76px",
                }}
              >
                {/* dev :- needed in future */}
                {/* <div style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
            <div style={{display:"flex",alignItems:"center"}}>
              <Typography.Text className={`switch-text ${switchState ? "":"switchToggle"}`}>Messages</Typography.Text>
              <Switch value={switchState} onChange={onChange} style={{marginLeft: 7, background: '#124173', fontSize: 16}}/>
              <Typography.Text style={{marginLeft: 7}} className={`switch-text ${switchState ?'switchToggle':""}`}>Customer</Typography.Text>
              <Tooltip placement="bottom" title="Switch to see customer reach for the message range selected">
              <InfoCircleOutlined style={{cursor: 'pointer',marginLeft: 7, color: '#252525', fontSize: "17px",lineHeight:"17px"}}/>
              </Tooltip>
            </div>
        </div> */}
                {touchpointObject.map((plan) => {
                  return (
                    JSON.parse(plan.limits).touchpoints <= 50000 && (
                      <>
                        <span style={{ fontSize: 16 }}>
                          <CheckOutlined
                            style={{ color: "green", fontSize: 16 }}
                          />{" "}
                          $
                          {
                            messageCostobject[
                              JSON.parse(plan.limits).touchpoints
                            ][0]
                          }{" "}
                          Associated cost/message
                        </span>
                        <span style={{ marginLeft: 7, fontSize: 16 }}>
                          <CheckOutlined
                            style={{ color: "green", fontSize: 16 }}
                          />{" "}
                          $
                          {
                            messageCostobject[
                              JSON.parse(plan.limits).touchpoints
                            ][1]
                          }{" "}
                          Overage rates/message
                        </span>
                      </>
                    )
                  );
                })}
              </div>
            </Col>

            {touchpointObject &&
              touchpointObject.map((plan, index) => {
                console.log(touchpointObject, index, plan, 110);
                const priceValues = plan && JSON.parse(plan?.priceValues);
                return (
                  <Col
                    span={6}
                    xxl={6}
                    xl={6}
                    lg={10}
                    md={10}
                    sm={24}
                    xs={24}
                    key={index}
                    className={plan?.name}
                  >
                    <div style={{ marginLeft: 15 }}>
                      <div
                        className="plan-details-header plan-details-text"
                        style={{ paddingLeft: "0" }}
                      >
                        <img
                          src={
                            plan?.name === "Lite"
                              ? Lite
                              : plan.name === "Enterprise"
                              ? Enterprise
                              : plan.name === "Standard"
                              ? Standard
                              : Premium
                          }
                          style={{ display: "flex" }}
                          alt=""
                          className="responsiveImg"
                        />
                        <div className="plan-details-title">{plan?.name}</div>
                      </div>

                      {plan?.name === "Pro" ? (
                        <span className="pro-plan-text">
                          {plan?.description}
                        </span>
                      ) : (
                        <div
                          className="plan-detail-price plan-details-text"
                          style={{
                            paddingTop: "12px",
                            alignItems: "flex-end",
                            color: "#2E2E2E",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "28px",
                              lineHeight: "34px",
                              fontWeight: "600",
                            }}
                          >
                            $
                            {priceValues &&
                              toCommas(
                                priceValues?.[plan?.stripePriceID[0]]?.value
                              )}
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              lineHeight: "17px",
                              fontWeight: "600",
                            }}
                          >
                            /
                            {priceValues &&
                              priceValues?.[plan?.stripePriceID[0]]
                                ?.billing_period}
                          </span>
                        </div>
                      )}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default PublicDesktopBilling;
