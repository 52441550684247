import Logo from "commons/Logo";
import React from "react";

import thankyouImg from "assets/Images/plan-added-image.svg";
import { Button, Space, Typography } from "antd";
import { useHistory } from "react-router";

const PlanSelected = (props) => {
  const history = useHistory();
  return (
    <div className="thankyou-page">
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          display: "flex",
          paddingBottom: 40,
        }}
      >
        <Logo />
      </div>

      <div className="thankyou-modal">
        <Space
          direction="vertical"
          align="center"
          size={10}
          style={{
            paddingBottom: 20,
            paddingTop: 20,
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <img src={thankyouImg} alt="" />
          <Typography.Text strong style={{ fontSize: 20 }}>
            Thank you!
          </Typography.Text>
          <div style={{ textAlign: "center" }}>
            <Typography.Text style={{ fontSize: 16, textAlign: "center" }}>
              Your subscription has been completed and <br />
              your Blustream account is ready for use.
            </Typography.Text>
          </div>
          <Button
            size="large"
            type="primary"
            className="standard-button primary-orange-button"
            onClick={() => {
              props.planreset();
              history.push("/settings");
            }}
          >
            Get Started
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default PlanSelected;
