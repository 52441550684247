export const emailPattern = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9+.-]+\.[a-zA-Z]{2,4}$/;

let constants = {
  SHOPIFY_ONBOARDING_FLOW: {
    CURRENT_STATE: {
      LINK_STORE: "LINK_STORE",
      REQUEST_ACCESS: "REQUEST_ACCESS",
      SHOW_STEPPERS: "SHOW_STEPPERS",
    },
  },
};
export default constants;
