import { API, graphqlOperation } from "aws-amplify";
import {
  createCustomerAccountRequest,
  deleteCustomerAccountRequest,
  updateCustomerAccount,
  createTriTechProfile,
  deleteTriTechProfile,
  updateTriTechProfile,
  createTriTechProductMapping,
  updateTriTechProductMapping,
  deleteTriTechProductMapping,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  createTriTechContractActionMapper,
  updateTriTechContractActionMapper,
} from "graphql/mutations";
import {
  getCustomerAccount,
  listCompanyInviteHistories,
  listTriTechProductMappings,
  listDashboards,
  listCustomerAccountRequests,
} from "graphql/queries";
import { v4 as uuidv4 } from "uuid";
import {
  listCustomerAccountsCustom,
  getCustomerAccountCustom,
  searchCustomerAccountsCustom,
  getCustomerAccountForInvitedUsersCustom,
  triTechProfileByAccountIdCustom,
} from "graphql/CustomQueries";

// Create a new account
const createAccount = async (
  company,
  url,
  users = [],
  isFromShopify = false,
  isAdmin,
  account_status,
  dashboardID,
  late_start_date
) => {
  const path = `/accounts`;
  if (!isAdmin) {
    isAdmin = false;
  }
  try {
    var result = await API.post("blustreamREST", path, {
      body: {
        company,
        url,
        users,
        isFromShopify,
        isAdmin,
        account_status,
        dashboardID,
        late_start_date,
      },
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed creating account" };
  }
};

const EditAccount = async (inputData) => {
  try {
    let request = await API.graphql(
      graphqlOperation(updateCustomerAccount, {
        input: {
          id: inputData?.id,
          company: inputData?.name,
          url: inputData?.url,
          company_loweredcased: inputData?.name?.toLowerCase(),
        },
      })
    );
    request = request.data.updateCustomerAccount;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["Unable to Edit Company Information"] };
  }
};
const fetchAccount = async (accountID) => {
  try {
    let account = await API.graphql(
      graphqlOperation(getCustomerAccount, { id: accountID })
    );

    return account.data.getCustomerAccount;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving account"] };
  }
};

const fetchAccountDetailsOfInvitedUser = async (accountID) => {
  try {
    let account = await API.graphql(
      graphqlOperation(getCustomerAccountForInvitedUsersCustom, {
        id: accountID,
      })
    );
    return account.data.getCustomerAccount;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving account"] };
  }
};

const getCustomerAccountRequest = async (account, email) => {
  try {
    const request = await API.graphql(
      graphqlOperation(listCustomerAccountRequests, {
        filter: {
          account: {
            eq: account,
          },
          owner: {
            eq: email,
          },
        },
        limit: 999,
      })
    );
    return request?.data?.listCustomerAccountRequests?.items;
  } catch (error) {
    return { error: "Request not found!" };
  }
};

const requestAccess = async (accountID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(createCustomerAccountRequest, {
        input: { id: uuidv4(), account: accountID },
      })
    );

    request = request.data.createCustomerAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const deleteRequest = async (requestID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(deleteCustomerAccountRequest, {
        input: { id: requestID },
      })
    );

    request = request.data.deleteCustomerAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const approveRequest = async (id) => {
  const path = `/accounts/requests/${id}/approve`;
  try {
    var result = await API.post("blustreamREST", path);
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed approving request" };
  }
};

const sendEmailInvitation = async (value) => {
  const path = `/accounts/invite`;
  let body = {};
  body.emails = value.emails;
  if (value.account_id) {
    body.account_id = value.account_id;
  }
  if (value.name) {
    body.name = value.name;
  }
  try {
    var result = await API.post("blustreamREST", path, {
      body: body,
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed sending invitation" };
  }
};

const userSuspention = async (userid, suspend) => {
  let apiName = "blustreamREST";
  let path = "/admin/suspend";
  try {
    var result = await API.post(apiName, path, {
      body: { user_id: userid, suspend: suspend },
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed user suspension action" };
  }
};

const companySuspension = async (companyId, suspend) => {
  try {
    let request = await API.graphql(
      graphqlOperation(updateCustomerAccount, {
        input: {
          id: companyId,
          disabled: suspend,
        },
      })
    );

    request = request.data.updateCustomerAccount;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const getAllUsers = async (nextToken, filter = {}) => {
  try {
    let companyList;
    if (Object.keys(filter).length > 0) {
      companyList = await API.graphql(
        graphqlOperation(listCustomerAccountsCustom, {
          nextToken: nextToken,
          filter: filter,
        })
      );
    } else {
      companyList = await API.graphql(
        graphqlOperation(listCustomerAccountsCustom, { nextToken: nextToken })
      );
    }
    return companyList;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving Companies"] };
  }
};

const addSecretsKey = async (id) => {
  let path = `/apikey/create?account=${id}`;
  let apiName = "blustreamREST";
  const config = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  try {
    var result = await API.post(apiName, path, config);
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed add the secrets key" };
  }
};

const deleteSecretsKey = async (id, deleteId) => {
  let path = `/apikey/delete?account=${id}`;
  let apiName = "blustreamREST";
  let body = { id: deleteId };
  try {
    var result = await API.post(apiName, path, { body: body });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed add the secrets key" };
  }
};

const disableSecretsKey = async (id, disableId, value) => {
  let path = `/apikey/disable?account=${id}`;
  let apiName = "blustreamREST";
  let body = {
    id: disableId,
    disabled: value,
  };
  try {
    var result = await API.post(apiName, path, { body: body });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Disable Secrets Key failed" };
  }
};

const fecthCompanies = async (accountID) => {
  try {
    let account = await API.graphql(
      graphqlOperation(getCustomerAccountCustom, { id: accountID })
    );
    return account;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving companies"] };
  }
};

const addTriTech = async (accountId, secret_key) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createTriTechProfile, {
        input: {
          account: accountId,
          secret_key: secret_key,
          enabled: false,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getTriTech = async (accountId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(triTechProfileByAccountIdCustom, {
        account: accountId,
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updateTriTech = async (triTechProfileId, secret_key) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateTriTechProfile, {
        input: {
          id: triTechProfileId,
          secret_key: secret_key,
          enabled: false,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const deleteTriTech = async (triTechProfileId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteTriTechProfile, {
        input: { id: triTechProfileId },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getAllCompanies = async (
  from = 0,
  limit = 10,
  filter = {},
  sort = {}
) => {
  try {
    let companyList;
    if (Object.keys(filter)?.length > 0) {
      companyList = await API.graphql(
        graphqlOperation(searchCustomerAccountsCustom, {
          limit: limit,
          from: from,
          filter: filter,
          sort: sort,
        })
      );
    } else {
      companyList = await API.graphql(
        graphqlOperation(searchCustomerAccountsCustom, {
          from: from,
          limit: limit,
          sort: sort,
        })
      );
    }
    return companyList;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving Companies"] };
  }
};

const listTriTechMappings = async (accountId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listTriTechProductMappings, {
        filter: {
          account: {
            eq: accountId,
          },
        },
      })
    );
    return response?.data?.listTriTechProductMappings?.items;
  } catch (error) {
    console.log(error);
  }
};

const addTriTechMapping = async (account, productId, mappingList) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createTriTechProductMapping, {
        input: {
          account: account,
          blustream_product_id: productId,
          tritech_product_categories: mappingList,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updateTriTechMapping = async (mappingId, productId, mappingList) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateTriTechProductMapping, {
        input: {
          id: mappingId,
          blustream_product_id: productId,
          tritech_product_categories: mappingList,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const deleteTriTechMapping = async (mappingId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteTriTechProductMapping, {
        input: { id: mappingId },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const listInviteHistory = async (email) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listCompanyInviteHistories, {
        filter: {
          email: {
            eq: email,
          },
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const listDashboardsData = async () => {
  try {
    const response = await API.graphql(graphqlOperation(listDashboards));
    return response?.data?.listDashboards?.items;
  } catch (error) {
    console.log(error);
  }
};

const addDashboard = async (id, name) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createDashboard, {
        input: {
          id: id,
          name: name,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const updateDashboardDetails = async (id, name) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateDashboard, {
        input: {
          id: id,
          name: name,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const deleteDashboardData = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteDashboard, {
        input: { id: id },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const createTriTechContractAction = async (id, values) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createTriTechContractActionMapper, {
        input: {
          triTechProfileId: id,
          action: values?.Action,
          payloadDescription: values?.Description,
          payloadTransactionStatus: values?.Transaction_Status,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//updating payload transaction status
const updateTriTechContractAction = async (id, statusArray) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateTriTechContractActionMapper, {
        input: {
          id: id,
          payloadTransactionStatus: statusArray,
        },
      })
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const sdk = {
  createAccount,
  fetchAccount,
  fetchAccountDetailsOfInvitedUser,
  requestAccess,
  deleteRequest,
  approveRequest,
  sendEmailInvitation,
  userSuspention,
  companySuspension,
  getAllUsers,
  EditAccount,
  addSecretsKey,
  deleteSecretsKey,
  disableSecretsKey,
  fecthCompanies,
  addTriTech,
  getTriTech,
  updateTriTech,
  deleteTriTech,
  getAllCompanies,
  listTriTechMappings,
  addTriTechMapping,
  updateTriTechMapping,
  deleteTriTechMapping,
  listInviteHistory,
  listDashboardsData,
  addDashboard,
  updateDashboardDetails,
  deleteDashboardData,
  getCustomerAccountRequest,
  createTriTechContractAction,
  updateTriTechContractAction,
};

export default sdk;
