/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Radio,
  Col,
  Button,
  Divider,
  Upload,
  message,
  Form,
  Input,
  Checkbox,
  Select,
  TreeSelect,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import TextIcon from "assets/Images/TextIcon.svg";
import HeadingIcon from "assets/Images/HeadingIcon.svg";
import copyRightIcon from "assets/Images/Copyright.svg";
import SubheadingIcon from "assets/Images/SubheadingIcon.svg";
import ConsentText from "assets/Images/ConsentText.svg";
import EmailInitial from "assets/Images/EmailInitial.svg";
import ImageIconWhite from "assets/Images/ImageIconWhite.svg";
import InputIcon from "assets/Images/InputIcon.svg";
import DropdownIcon from "assets/Images/DropdownIcon.svg";
import MultichoiceIcon from "assets/Images/MultichoiceIcon.svg";
import dummyImg from "assets/Images/dummy-img.svg";
import {
  CloseOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import {
  getAllAttributeValues,
  getCustomerId,
  getProduct,
  getContactProductAttributeName,
} from "../../../apiService/RESTApi";
import { useSelector, useDispatch } from "react-redux";
import {
  setMicrositeFormElements,
  setCurrentFormElementId,
} from "../../../store/actions/SettingAction";
import { v4 as uuidv4 } from "uuid";
import TextEditor from "commons/TextEditor/TextEditor";

const { Option } = Select;

export default function Components() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { groups, roAdmin } = useSelector((state) => state.loginReducer);
  const { micrositeFormElements } = useSelector(
    (state) => state.SettingReducer
  );
  const { currentFormElementId } = useSelector((state) => state.SettingReducer);

  const [render, setRender] = useState("");
  const [elementType, setElementType] = useState("input");
  const [contactAttributeList, setContactAttributeList] = useState([]);
  const [contactProductAttributeList, setContactProductAttributeList] =
    useState([]);
  const [component, setComponent] = useState({});
  const [currentComponentBackup, setCurrentComponentBackup] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [productId, setproductId] = useState("");
  const [disableUp, setDisableUp] = useState(false);
  const [disableDown, setDisableDown] = useState(false);
  const { TextArea } = Input;
  const { TreeNode } = TreeSelect;
  const contactProductText = (
    <p>
      <i>* This will auto link related product to incoming contact</i>
    </p>
  );
  const productText = (
    <p>
      <i>
        * Hint: When this field is hidden, the form will upload the selected
        product(s) as the default for each contact.
      </i>
    </p>
  );

  useEffect(() => {
    fetchAllContactAttributes();
    fetchAllProducts();
    fetchAllContactProductAttributes();
  }, []);

  useEffect(() => {
    if (currentFormElementId.length > 0) {
      const currentComponent = micrositeFormElements.find(
        (element) => element.id === currentFormElementId
      );
      setComponent(currentComponent);
      if (currentComponent?.options) {
        let newObj = {};
        currentComponent.options.forEach((obj, index) => {
          newObj = {
            ...newObj,
            [`display${index}`]: obj.label,
            [`value${index}`]: obj.value,
          };
        });
        form.setFieldsValue({ ...currentComponent, ...newObj });
      } else {
        form.setFieldsValue(currentComponent);
      }
      if (currentComponent?.attributeType === "contact-product-attribute") {
        setproductId(currentComponent?.product);
      }
      setRender(
        currentComponent?.attributeType
          ? currentComponent?.attributeType
          : currentComponent?.type
      );
      if (currentComponent?.attributeType) {
        setElementType(currentComponent?.type);
      }

      setCurrentComponentBackup(currentComponent);
      const currentComponentIndex = micrositeFormElements.findIndex(
        (element) => element.id === currentFormElementId
      );
      if (currentComponentIndex === 7) {
        setDisableUp(true);
      } else {
        setDisableUp(false);
      }
      if (currentComponentIndex === micrositeFormElements.length - 5) {
        setDisableDown(true);
      } else {
        setDisableDown(false);
      }
    }
  }, [currentFormElementId]);

  //special case don't mix this with above useEffect
  useEffect(() => {
    if (currentFormElementId === "") {
      form.resetFields();
      setCurrentComponentBackup({});
    }
  }, [currentFormElementId]);

  useEffect(() => {
    if (currentFormElementId === "") {
      form.resetFields();
      setCurrentComponentBackup({});
    }
  }, [elementType]);

  useEffect(() => {
    if (render === "") {
      form.resetFields();
      setCurrentComponentBackup({});
    } else {
      let newObj = {};
      component?.options?.forEach((obj, index) => {
        newObj = {
          ...newObj,
          [`display${index}`]: obj.label,
          [`value${index}`]: obj.value,
        };
      });
      form.setFieldsValue({ ...component, ...newObj });
    }
  }, [render]);

  useEffect(() => {
    if (currentFormElementId === "") {
      form.resetFields();
      setCurrentComponentBackup({});
    }
  }, [elementType]);

  //this is for the single tree select

  const singleProductSelector = (newValue) => {
    setproductId(newValue);
    setComponentsProperties("product", newValue);
  };

  useEffect(() => {
    handleAttributeList();
  }, [micrositeFormElements]);

  const setComponentsProperties = (name, value) => {
    setComponent((previousState) => {
      return { ...previousState, [name]: value };
    });
  };

  const changeOrder = (direction) => {
    const addedElements = micrositeFormElements.slice(7, -4);
    const currentComponentIndex = addedElements.findIndex(
      (element) => element.id === currentFormElementId
    );

    if (direction === "up") {
      if (currentComponentIndex > 0) {
        const currentComponent = addedElements.splice(
          currentComponentIndex,
          1
        )[0];
        addedElements.splice(currentComponentIndex - 1, 0, currentComponent);
        if (currentComponentIndex - 1 === 0) {
          setDisableUp(true);
          setDisableDown(false);
        } else {
          setDisableUp(false);
          setDisableDown(false);
        }
      }
    } else {
      if (currentComponentIndex < addedElements.length - 1) {
        const currentComponent = addedElements.splice(
          currentComponentIndex,
          1
        )[0];
        addedElements.splice(currentComponentIndex + 1, 0, currentComponent);
        if (currentComponentIndex + 1 === addedElements.length - 1) {
          setDisableDown(true);
          setDisableUp(false);
        } else {
          setDisableDown(false);
          setDisableUp(false);
        }
      }
    }
    dispatch(
      setMicrositeFormElements([
        ...micrositeFormElements.slice(0, 7),
        ...addedElements,
        ...micrositeFormElements.slice(-4),
      ])
    );
  };

  const addComponent = () => {
    if (currentFormElementId.length !== 0) {
      const currentComponentIndex = micrositeFormElements.findIndex(
        (element) => element.id === currentFormElementId
      );
      const totalLength = micrositeFormElements.length;
      dispatch(
        setMicrositeFormElements([
          ...micrositeFormElements.slice(0, currentComponentIndex),
          component,
          ...micrositeFormElements.slice(
            currentComponentIndex + 1,
            totalLength
          ),
        ])
      );
    } else {
      dispatch(
        setMicrositeFormElements([
          ...micrositeFormElements.slice(0, -4),
          component,
          ...micrositeFormElements.slice(-4),
        ])
      );
    }
    setproductId("");
    setRender("");
    setComponent({});
    dispatch(setCurrentFormElementId(""));
    message.success("Saved successfully");
  };

  const deleteComponent = () => {
    const currentComponentIndex = micrositeFormElements.findIndex(
      (element) => element.id === currentFormElementId
    );

    const totalLength = micrositeFormElements.length;

    dispatch(
      setMicrositeFormElements([
        ...micrositeFormElements.slice(0, currentComponentIndex),
        ...micrositeFormElements.slice(currentComponentIndex + 1, totalLength),
      ])
    );
    setproductId("");
    setRender("");
    setComponent({});
    dispatch(setCurrentFormElementId(""));
    message.success("Deleted successfully");
  };

  const fetchAllContactAttributes = async () => {
    try {
      await getCustomerId(groups[0]).then(async (res) => {
        let id = res.response.data[0].customerId;
        await getAllAttributeValues(id).then((res) => {
          const availableAttributes = res.response.data.map((obj) => {
            if (
              micrositeFormElements.find((elementObj) => {
                return elementObj?.attributeId === obj.dataClassAttributeId;
              })
            ) {
              return {
                id: obj.dataClassAttributeId,
                attributeName: obj.name,
                disabled: true,
              };
            } else {
              return {
                id: obj.dataClassAttributeId,
                attributeName: obj.name,
                disabled: false,
              };
            }
          });
          setContactAttributeList(availableAttributes);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllProducts = async () => {
    try {
      await getProduct(groups[0]).then((res) => {
        if (res.response.data && res.response.data.length > 0) {
          const data = res.response.data.map((p) => ({
            attributeName: p?.name,
            id: p?.productId,
            cId: p?.customerDto?.customerId,
            externalUuid: p?.customerDto?.externalUuid,
            disabled: false,
          }));
          const data2 = [];
          data.forEach((val) => {
            data2.push({
              title: val.attributeName,
              value: val.id,
              key: val.id,
            });
          });
          setTreeData(data2);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllContactProductAttributes = async () => {
    try {
      await getCustomerId(groups[0]).then(async (res) => {
        let id = res.response.data[0].customerId;
        await getContactProductAttributeName(id).then((res) => {
          const data = res.response.data.map((obj) => {
            if (
              micrositeFormElements.find((elementObj) => {
                return elementObj?.attributeId === obj.name;
              })
            ) {
              return {
                id: obj.dataClassAttributeId,
                attributeName: obj.name,
                disabled: true,
              };
            } else {
              return {
                id: obj.dataClassAttributeId,
                attributeName: obj.name,
                disabled: false,
              };
            }
          });
          setContactProductAttributeList(data);
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAttributeList = () => {
    const newContactAttributeList = contactAttributeList.map((obj) => {
      if (
        micrositeFormElements.find((elementObj) => {
          return elementObj?.attributeId === obj.id;
        })
      ) {
        return { id: obj.id, attributeName: obj.attributeName, disabled: true };
      } else {
        return {
          id: obj.id,
          attributeName: obj.attributeName,
          disabled: false,
        };
      }
    });
    const newContactProductAttributeList = contactProductAttributeList.map(
      (obj) => {
        if (
          micrositeFormElements.find((elementObj) => {
            return elementObj?.attributeId === obj.attributeName;
          })
        ) {
          return {
            id: obj.id,
            attributeName: obj.attributeName,
            disabled: true,
          };
        } else {
          return {
            id: obj.id,
            attributeName: obj.attributeName,
            disabled: false,
          };
        }
      }
    );
    setContactAttributeList(newContactAttributeList);
    setContactProductAttributeList(newContactProductAttributeList);
  };

  const optionBtnRadioHandler = (e) => {
    setComponent((previousState) => {
      return { ...previousState, isCheckedIndex: e.target.value };
    });
  };

  const addOptionsField = () => {
    setComponent((previousState) => {
      return {
        ...previousState,
        options: [...previousState.options, { label: "", value: "" }],
      };
    });
  };

  const removeOptionsField = (index) => {
    const optionValuesForForm = {};
    setComponent((previousState) => {
      const newArray = [...previousState.options];
      newArray.splice(index, 1);
      newArray?.forEach((option, index) => {
        optionValuesForForm[`display${index}`] = option?.label;
        optionValuesForForm[`value${index}`] = option?.value;
      });
      form.resetFields();
      form.setFieldsValue({ ...component, ...optionValuesForForm });
      return {
        ...previousState,
        options: [...newArray],
        isCheckedIndex:
          Number(previousState?.isCheckedIndex) === index
            ? "0"
            : Number(previousState?.isCheckedIndex) > index
            ? (Number(previousState?.isCheckedIndex) - 1).toString()
            : previousState?.isCheckedIndex,
      };
    });
  };

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange: () => {},
  };

  const emptyState = () => {
    setRender("");
    setComponent({});
    dispatch(setCurrentFormElementId(""));
  };

  function handleEditorChange({ text }) {
    setComponentsProperties("textValue", text);
  }

  const inputField = (attributeList) => {
    return (
      <>
        <div className="input-component">
          <Row align="middle">
            <h3 style={{ marginBottom: 1 }}>Text</h3>
          </Row>

          <Divider style={{ margin: 2 }} />

          <Form
            className="attributes form-grid"
            form={form}
            onFinish={() => addComponent()}
            requiredMark={false}
          >
            <Form.Item label="Reorder" className="form-element-order">
              <Button
                className="standard-button primary-orange-button"
                disabled={disableUp || currentFormElementId === ""}
                onClick={() => changeOrder("up")}
              >
                <CaretUpOutlined />
              </Button>
              <Button
                className="standard-button primary-orange-button"
                disabled={disableDown || currentFormElementId === ""}
                onClick={() => changeOrder("down")}
              >
                <CaretDownOutlined />
              </Button>
            </Form.Item>
            {render === "contact-product-attribute" ? (
              <Form.Item
                name="product"
                label="Product"
                rules={[
                  {
                    required: true,
                    message: `Please select the product!`,
                  },
                ]}
                initialValue={component?.product}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  className="standard-dropdown"
                  value={productId}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Please select"
                  treeNodeFilterProp="title"
                  onChange={singleProductSelector}
                >
                  {treeData.map((obj) => (
                    <TreeNode
                      key={obj.key}
                      value={obj.value}
                      title={obj.title}
                    />
                  ))}
                </TreeSelect>
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item
              name="attributeId"
              label="Attribute"
              rules={[
                {
                  required: true,
                  message: `Please select value!`,
                },
              ]}
              initialValue={component?.attributeId}
            >
              <Select
                onChange={(e) => {
                  setComponentsProperties("attributeId", e);
                }}
                placeholder="Select Attribute"
                style={{ width: "100%" }}
                className="Opt-1 round standard-dropdown"
                // dev :- we gave initialvalue instead of value for resolving issue
                // value= {component.attributeId ? component.attributeId : ""}
                disabled={
                  render === "contact-product-attribute"
                    ? productId === ""
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  if (
                    render === "contact-product-attribute" &&
                    productId === ""
                  ) {
                    message.error("Select product first!");
                  }
                }}
              >
                {attributeList.map((obj) => {
                  return (
                    <Option
                      key={obj.id}
                      value={
                        render === "contact-product-attribute"
                          ? obj.attributeName
                          : obj.id
                      }
                      disabled={
                        currentComponentBackup.attributeId ===
                        (render === "contact-product-attribute"
                          ? obj.attributeName
                          : obj.id)
                          ? false
                          : obj?.disabled
                      }
                    >
                      {obj.attributeName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: `Please Enter label!`,
                },
              ]}
              initialValue={component?.label}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("label", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Description">
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("description", e.target.value)
                }
                value={component.description}
              />
            </Form.Item>
            <Form.Item
              name="placeholder"
              label="Placeholder"
              rules={[
                {
                  required: true,
                  message: "Please Enter placeholder!",
                },
              ]}
              initialValue={component?.placeholder}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("placeholder", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Required?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isRequired", e.target.checked)
                }
                checked={component.isRequired}
              />
            </Form.Item>
            <Form.Item label="Hidden?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isHidden", e.target.checked)
                }
                checked={component.isHidden}
              />
            </Form.Item>
            {render === "contact-product-attribute" ? (
              contactProductText
            ) : (
              <></>
            )}

            <Divider style={{ margin: 2 }} />

            <Row gutter={10} style={{ display: "flex" }}>
              <Col span={24}>
                <h3>Answer Type</h3>
              </Col>

              <Col span={24} className="form-flex-wrapper">
                <Form.Item
                  style={{ display: "inline" }}
                  rules={[
                    {
                      required: true,
                      message: `Please select Input Type!`,
                    },
                  ]}
                >
                  <Radio.Group
                    value={component?.htmlType ? component.htmlType : "text"}
                    onChange={(e) =>
                      setComponentsProperties("htmlType", e.target.value)
                    }
                    className="alignment-opt"
                  >
                    <Radio.Button
                      className="standard-button secondary-dark-blue-button"
                      value="text"
                    >
                      Any
                    </Radio.Button>
                    <Radio.Button
                      className="standard-button secondary-dark-blue-button"
                      value="number"
                    >
                      Number
                    </Radio.Button>
                    <Radio.Button
                      className="standard-button secondary-dark-blue-button"
                      value="email"
                    >
                      Email
                    </Radio.Button>
                    <Radio.Button
                      className="standard-button secondary-dark-blue-button"
                      value="url"
                    >
                      URL
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ margin: 2 }} />

            <Row>
              <Col
                span={24}
                style={{ display: "flex", marginTop: "10px", gap: "10px" }}
              >
                <Button
                  className="standard-button primary-orange-button"
                  htmlType="submit"
                >
                  Save
                </Button>
                {currentFormElementId !== "" ? (
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => deleteComponent()}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  };

  const dropDownField = (attributeList) => {
    return (
      <>
        <div className="input-component">
          <Row align="middle">
            <h3 style={{ marginBottom: 1 }}>Dropdown</h3>
          </Row>

          <Divider style={{ margin: 2 }} />

          <Form
            className="attributes form-grid"
            form={form}
            onFinish={() => addComponent()}
            requiredMark={false}
            preserve={false}
          >
            <Form.Item label="Reorder" className="form-element-order">
              <Button
                className="standard-button primary-orange-button"
                disabled={disableUp || currentFormElementId === ""}
                onClick={() => changeOrder("up")}
              >
                <CaretUpOutlined />
              </Button>
              <Button
                className="standard-button primary-orange-button"
                disabled={disableDown || currentFormElementId === ""}
                onClick={() => changeOrder("down")}
              >
                <CaretDownOutlined />
              </Button>
            </Form.Item>
            {render === "contact-product-attribute" ? (
              <Form.Item
                name="product"
                label="Product"
                rules={[
                  {
                    required: true,
                    message: `Please select the product!`,
                  },
                ]}
                initialValue={component?.product}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  className="standard-dropdown"
                  value={productId}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Please select"
                  treeNodeFilterProp="title"
                  onChange={singleProductSelector}
                >
                  {treeData.map((obj) => (
                    <TreeNode
                      key={obj.key}
                      value={obj.value}
                      title={obj.title}
                    />
                  ))}
                </TreeSelect>
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item
              name="attributeId"
              label="Attribute"
              rules={[
                {
                  required: true,
                  message: `Please select value!`,
                },
              ]}
              initialValue={component?.attributeId}
            >
              <Select
                onChange={(e) => {
                  setComponentsProperties("attributeId", e);
                }}
                placeholder="Select Attribute"
                style={{ width: "100%" }}
                className="Opt-1 round standard-dropdown"
                value={component.attributeId ? component.attributeId : ""}
                disabled={
                  render === "contact-product-attribute"
                    ? productId === ""
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  if (
                    render === "contact-product-attribute" &&
                    productId === ""
                  ) {
                    message.error("select product First!");
                  }
                }}
              >
                {attributeList.map((obj) => {
                  return (
                    <Option
                      key={obj.id}
                      value={
                        render === "contact-product-attribute"
                          ? obj.attributeName
                          : obj.id
                      }
                      disabled={
                        currentComponentBackup.attributeId ===
                        (render === "contact-product-attribute"
                          ? obj.attributeName
                          : obj.id)
                          ? false
                          : obj?.disabled
                      }
                    >
                      {obj.attributeName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: `Please Enter label!`,
                },
              ]}
              initialValue={component?.label}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("label", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Description">
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("description", e.target.value)
                }
                value={component.description}
              />
            </Form.Item>
            <Form.Item
              name="placeholder"
              label="Placeholder"
              rules={[
                {
                  required: true,
                  message: "Please Enter placeholder!",
                },
              ]}
              initialValue={component?.placeholder}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("placeholder", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Required?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isRequired", e.target.checked)
                }
                checked={component.isRequired}
              />
            </Form.Item>
            <Form.Item label="Hidden?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isHidden", e.target.checked)
                }
                checked={component.isHidden}
              />
            </Form.Item>
            {render === "contact-product-attribute" ? (
              contactProductText
            ) : (
              <></>
            )}

            <Divider style={{ margin: 2 }} />

            <Row gutter={10} style={{ display: "flex" }}>
              <Col span={24}>
                <h3 style={{ margin: "10px 0 10px 0", fontWeight: 600 }}>
                  Options
                </h3>
              </Col>

              <Col span={24} className="options-form">
                <Row gutter={10} style={{ marginBottom: 10, display: "flex" }}>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Default</span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Display</span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Value</span>
                  </Col>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span></span>
                  </Col>
                </Row>

                {component?.options &&
                  component?.options.map((option, index) => {
                    return (
                      <Row
                        gutter={10}
                        style={{ marginBottom: 10, display: "flex" }}
                      >
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Radio
                            value={index}
                            checked={component.isCheckedIndex === `${index}`}
                            onClick={(e) => optionBtnRadioHandler(e)}
                          />
                        </Col>
                        <Col span={8} className="form-flex-wrapper">
                          <Form.Item
                            name={`display${index}`}
                            rules={[
                              {
                                required: true,
                                message: `Please Enter value!`,
                              },
                            ]}
                            initialValue={component?.options[index].label}
                          >
                            <Input
                              className="standard-input"
                              onChange={(e) =>
                                setComponent((previousState) => {
                                  const currentObj =
                                    previousState.options[index];
                                  currentObj.label = e.target.value;
                                  const newArray = [...previousState.options];
                                  newArray[index] = currentObj;
                                  return {
                                    ...previousState,
                                    options: newArray,
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="form-flex-wrapper">
                          <Form.Item
                            name={`value${index}`}
                            rules={[
                              {
                                required: true,
                                message: `Please Enter value!`,
                              },
                            ]}
                            initialValue={component?.options[index].value}
                          >
                            <Input
                              className="standard-input"
                              onChange={(e) =>
                                setComponent((previousState) => {
                                  const currentObj =
                                    previousState.options[index];
                                  currentObj.value = e.target.value;
                                  const newArray = [...previousState.options];
                                  newArray[index] = currentObj;
                                  return {
                                    ...previousState,
                                    options: newArray,
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {index !== 0 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => removeOptionsField(index)}
                            />
                          )}
                        </Col>
                      </Row>
                    );
                  })}

                <Row style={{ display: "flex" }}>
                  <Col span={20} style={{ paddingRight: "10px" }}>
                    <Form.Item className="form-flex-wrapper">
                      <Button
                        className="standard-button primary-orange-button"
                        onClick={() => {
                          addOptionsField();
                        }}
                        style={{ width: "100%" }}
                        icon={<PlusOutlined style={{ fontSize: 14 }} />}
                      >
                        Add Options
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Divider style={{ margin: 10 }} />

              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    gap: "10px",
                    paddingLeft: "5px",
                  }}
                >
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  {currentFormElementId !== "" ? (
                    <Button
                      className="standard-button secondary-orange-button"
                      onClick={() => deleteComponent()}
                    >
                      Delete
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Row>
          </Form>
        </div>
      </>
    );
  };

  const multichoiceField = (attributeList) => {
    return (
      <>
        <div className="input-component">
          <Row align="middle">
            <h3 style={{ marginBottom: 1 }}>Multi Choice</h3>
          </Row>

          <Divider style={{ margin: 2 }} />

          <Form
            className="attributes form-grid"
            form={form}
            onFinish={() => addComponent()}
            requiredMark={false}
            preserve={false}
          >
            <Form.Item label="Reorder" className="form-element-order">
              <Button
                className="standard-button primary-orange-button"
                disabled={disableUp || currentFormElementId === ""}
                onClick={() => changeOrder("up")}
              >
                <CaretUpOutlined />
              </Button>
              <Button
                className="standard-button primary-orange-button"
                disabled={disableDown || currentFormElementId === ""}
                onClick={() => changeOrder("down")}
              >
                <CaretDownOutlined />
              </Button>
            </Form.Item>
            {render === "contact-product-attribute" ? (
              <Form.Item
                name="product"
                label="Product"
                rules={[
                  {
                    required: true,
                    message: `Please select the product!`,
                  },
                ]}
                initialValue={component?.product}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  className="standard-dropdown"
                  value={productId}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Please select"
                  treeNodeFilterProp="title"
                  onChange={singleProductSelector}
                >
                  {treeData.map((obj) => (
                    <TreeNode
                      key={obj.key}
                      value={obj.value}
                      title={obj.title}
                    />
                  ))}
                </TreeSelect>
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item
              name="attributeId"
              label="Attribute"
              rules={[
                {
                  required: true,
                  message: `Please select value!`,
                },
              ]}
              initialValue={component?.attributeId}
            >
              <Select
                onChange={(e) => {
                  setComponentsProperties("attributeId", e);
                }}
                placeholder="Select Attribute"
                style={{ width: "100%" }}
                className="Opt-1 round standard-dropdown"
                value={component.attributeId ? component.attributeId : ""}
                disabled={
                  render === "contact-product-attribute"
                    ? productId === ""
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  if (
                    render === "contact-product-attribute" &&
                    productId === ""
                  ) {
                    message.error("select product First!");
                  }
                }}
              >
                {attributeList.map((obj) => {
                  return (
                    <Option
                      key={obj.id}
                      value={
                        render === "contact-product-attribute"
                          ? obj.attributeName
                          : obj.id
                      }
                      disabled={
                        currentComponentBackup.attributeId ===
                        (render === "contact-product-attribute"
                          ? obj.attributeName
                          : obj.id)
                          ? false
                          : obj?.disabled
                      }
                    >
                      {obj.attributeName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: `Please Enter label!`,
                },
              ]}
              initialValue={component?.label}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("label", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Description">
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("description", e.target.value)
                }
                value={component.description}
              />
            </Form.Item>
            <Form.Item label="Required?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isRequired", e.target.checked)
                }
                checked={component.isRequired}
              />
            </Form.Item>
            <Form.Item label="Hidden?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isHidden", e.target.checked)
                }
                checked={component.isHidden}
              />
            </Form.Item>
            {render === "contact-product-attribute" ? (
              contactProductText
            ) : (
              <></>
            )}

            <Divider style={{ margin: 2 }} />

            <Row gutter={10} style={{ display: "flex" }}>
              <Col span={24}>
                <h3 style={{ margin: "10px 0 10px 0", fontWeight: 600 }}>
                  Options
                </h3>
              </Col>

              <Col span={24} className="options-form">
                <Row gutter={10} style={{ marginBottom: 10, display: "flex" }}>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Default</span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Display</span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Value</span>
                  </Col>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span></span>
                  </Col>
                </Row>

                {component?.options &&
                  component?.options.map((option, index) => {
                    return (
                      <Row
                        gutter={10}
                        style={{ marginBottom: 10, display: "flex" }}
                      >
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Radio
                            value={index}
                            checked={component.isCheckedIndex === `${index}`}
                            onClick={(e) => optionBtnRadioHandler(e)}
                          />
                        </Col>
                        <Col span={8} className="form-flex-wrapper">
                          <Form.Item
                            name={`display${index}`}
                            rules={[
                              {
                                required: true,
                                message: `Please Enter value!`,
                              },
                            ]}
                            initialValue={option?.label}
                          >
                            <Input
                              className="standard-input"
                              onChange={(e) =>
                                setComponent((previousState) => {
                                  const currentObj =
                                    previousState.options[index];
                                  currentObj.label = e.target.value;
                                  const newArray = [...previousState.options];
                                  newArray[index] = currentObj;
                                  return {
                                    ...previousState,
                                    options: newArray,
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="form-flex-wrapper">
                          <Form.Item
                            name={`value${index}`}
                            rules={[
                              {
                                required: true,
                                message: `Please Enter value!`,
                              },
                            ]}
                            initialValue={option?.value}
                          >
                            <Input
                              className="standard-input"
                              onChange={(e) =>
                                setComponent((previousState) => {
                                  const currentObj =
                                    previousState.options[index];
                                  currentObj.value = e.target.value;
                                  const newArray = [...previousState.options];
                                  newArray[index] = currentObj;
                                  return {
                                    ...previousState,
                                    options: newArray,
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {index !== 0 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => removeOptionsField(index)}
                            />
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                <Row style={{ display: "flex" }}>
                  <Col span={20} style={{ paddingRight: "10px" }}>
                    <Form.Item className="form-flex-wrapper">
                      <Button
                        className="standard-button primary-orange-button"
                        onClick={() => {
                          addOptionsField();
                        }}
                        style={{ width: "100%" }}
                        icon={<PlusOutlined style={{ fontSize: 14 }} />}
                      >
                        Add Options
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />

            <Form.Item label="Add “Other”">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isOther", e.target.checked)
                }
                checked={component.isOther}
              />
            </Form.Item>
            <Form.Item label="Style" className="form-flex-wrapper">
              <Radio.Group
                defaultValue="Tags"
                className="alignment-opt"
                value={component?.styleRadioValue}
                onChange={(e) =>
                  setComponent((previousState) => {
                    return {
                      ...previousState,
                      styleRadioValue: e.target.value,
                    };
                  })
                }
              >
                <Radio.Button
                  className="standard-button secondary-dark-blue-button"
                  value="Tags"
                >
                  Tags
                </Radio.Button>
                <Radio.Button
                  className="standard-button secondary-dark-blue-button"
                  value="Radio"
                >
                  Radio
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {/* dont remove this code will required in future versions */}
            {/* <Form.Item label="Layout">
                        <Radio.Group
                          className="alignment-opt"
                          value={component?.multichoiceLayout}
                          onChange={(e) => setComponent((previousState) => {
                            return {...previousState,multichoiceLayout : e.target.value}
                          
                          }) }
                        >
                          <Radio.Button value="row">Side by Side</Radio.Button>
                          <Radio.Button value="column">Column</Radio.Button>
                        </Radio.Group>
                      </Form.Item> */}

            <Divider style={{ margin: 10, marginLeft: "0px" }} />
            <Row>
              <Col
                span={24}
                style={{ display: "flex", marginTop: "10px", gap: "10px" }}
              >
                <Button
                  htmlType="submit"
                  className="standard-button primary-orange-button"
                >
                  Save
                </Button>
                {currentFormElementId !== "" ? (
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => deleteComponent()}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  };

  const productForm = () => {
    return (
      <>
        <div className="input-component">
          <Row align="middle" gutter={10} style={{ marginBottom: 25 }}>
            <Button
              type="text"
              onClick={() => {
                emptyState();
              }}
              style={{ paddingLeft: 0 }}
            >
              <LeftOutlined style={{ fontSize: 16 }} />
            </Button>
            <h3 style={{ marginBottom: 1 }}>Select Products</h3>
            <Divider style={{ margin: 2 }} />
          </Row>

          <Row gutter={10} style={{ marginBottom: 25 }}>
            <Col span={24}>
              <Radio.Group
                value={elementType ? elementType : "dropdown"}
                className="form-element-select"
                style={{ width: "100%" }}
              >
                <Row gutter={[10, 10]}>
                  <Col span={12} md={6} lg={12}>
                    <Radio.Button
                      value="dropdown"
                      onClick={() => {
                        setElementType("dropdown");

                        setComponent(() => {
                          return {
                            id: uuidv4(),
                            type: "dropdown",
                            attributeType: "product",
                            htmlType: "",
                            options: [{ label: "", value: "" }],
                            // isCheckedIndex: "0",
                          };
                        });
                        dispatch(setCurrentFormElementId(""));
                      }}
                      className="standard-button secondary-dark-blue-button component-main-buttons"
                    >
                      <img src={DropdownIcon} alt="DropdownIcon" /> Dropdown
                    </Radio.Button>
                  </Col>
                  <Col span={12} md={6} lg={12}>
                    <Radio.Button
                      value="multiselect-dropdown"
                      onClick={() => {
                        setElementType("multiselect-dropdown");
                        setComponent(() => {
                          return {
                            id: uuidv4(),
                            type: "multiselect-dropdown",
                            attributeType: "product",
                            htmlType: "",
                            options: [{ label: "", value: "" }],
                            // isCheckedIndex: "0",
                          };
                        });
                        dispatch(setCurrentFormElementId(""));
                      }}
                      className="standard-button secondary-dark-blue-button component-main-buttons"
                    >
                      <img src={MultichoiceIcon} alt="MultichoiceIcon" /> Multi
                      Choice
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
            </Col>
          </Row>

          <Form
            className="attributes form-grid"
            form={form}
            onFinish={() => {
              if (
                micrositeFormElements.find((elementObj) => {
                  return elementObj?.attributeType === "product";
                }) &&
                micrositeFormElements.find((elementObj) => {
                  return elementObj?.attributeType === "product";
                })?.id !== component?.id
              ) {
                message.error("You can't add more than 1 products dropdown!");
              } else {
                addComponent();
              }
            }}
            requiredMark={false}
          >
            <Form.Item label="Reorder" className="form-element-order">
              <Button
                className="standard-button primary-orange-button"
                disabled={disableUp || currentFormElementId === ""}
                onClick={() => changeOrder("up")}
              >
                <CaretUpOutlined />
              </Button>
              <Button
                className="standard-button primary-orange-button"
                disabled={disableDown || currentFormElementId === ""}
                onClick={() => changeOrder("down")}
              >
                <CaretDownOutlined />
              </Button>
            </Form.Item>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: `Please Enter value!`,
                },
              ]}
              initialValue={component?.label}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("label", e.target.value)
                }
                value={component.label}
              />
            </Form.Item>
            <Form.Item label="Description">
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("description", e.target.value)
                }
                value={component.description}
              />
            </Form.Item>
            <Form.Item
              name="placeholder"
              label="Placeholder"
              initialValue={component?.placeholder}
            >
              <Input
                className="standard-input"
                onChange={(e) =>
                  setComponentsProperties("placeholder", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Required?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isRequired", e.target.checked)
                }
                checked={component.isRequired}
              />
            </Form.Item>
            <Form.Item label="Hidden?">
              <Checkbox
                onChange={(e) =>
                  setComponentsProperties("isHidden", e.target.checked)
                }
                checked={component.isHidden}
              />
            </Form.Item>
            {render === "product" ? productText : <></>}

            <Divider style={{ margin: 2 }} />
            <Row align="middle" style={{ display: "flex" }}>
              <Col span={24}>
                <h3 style={{ margin: "10px 0 10px 0", fontWeight: 600 }}>
                  Options
                </h3>
                <p>
                  * Hint: Select the products which you want to show to your
                  contacts
                </p>
              </Col>
              <Col span={24} className="options-form">
                <Row gutter={10} style={{ marginBottom: 10, display: "flex" }}>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Default</span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Display name</span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Product</span>
                  </Col>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span></span>
                  </Col>
                </Row>

                {component?.options &&
                  component?.options.map((option, index) => {
                    return (
                      <Row
                        gutter={10}
                        style={{ marginBottom: 10, display: "flex" }}
                      >
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Radio
                            value={index}
                            checked={component.isCheckedIndex === `${index}`}
                            onClick={(e) => optionBtnRadioHandler(e)}
                          />
                        </Col>
                        <Col span={8} className="form-flex-wrapper">
                          <Form.Item
                            name={`display${index}`}
                            rules={[
                              {
                                required: true,
                                message: `Please Enter value!`,
                              },
                            ]}
                          >
                            <Input
                              className="standard-input"
                              onChange={(e) =>
                                setComponent((previousState) => {
                                  const currentObj =
                                    previousState.options[index];
                                  currentObj.label = e.target.value;
                                  const newArray = [...previousState.options];
                                  newArray[index] = currentObj;
                                  return {
                                    ...previousState,
                                    options: newArray,
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="form-flex-wrapper">
                          <Form.Item
                            name={`value${index}`}
                            rules={[
                              {
                                required: true,
                                message: `Please Enter value!`,
                              },
                            ]}
                          >
                            <Select
                              className="standard-dropdown"
                              onChange={(value) => {
                                setComponent((previousState) => {
                                  const currentObj =
                                    previousState.options[index];
                                  currentObj.value = value;
                                  const newArray = [...previousState.options];
                                  newArray[index] = currentObj;
                                  return {
                                    ...previousState,
                                    options: newArray,
                                  };
                                });
                              }}
                            >
                              {treeData?.map((obj) => {
                                return (
                                  <Option
                                    key={obj?.key}
                                    value={obj?.value}
                                    disabled={component?.options
                                      ?.map((option) => option?.value)
                                      ?.includes(obj?.value)}
                                  >
                                    {obj?.title}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {index !== 0 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => removeOptionsField(index)}
                            />
                          )}
                        </Col>
                      </Row>
                    );
                  })}

                <Row style={{ display: "flex" }}>
                  <Col span={20} style={{ paddingRight: "10px" }}>
                    <Form.Item className="form-flex-wrapper">
                      <Button
                        className="standard-button primary-orange-button"
                        onClick={() => {
                          addOptionsField();
                        }}
                        style={{ width: "100%" }}
                        icon={<PlusOutlined style={{ fontSize: 14 }} />}
                        disabled={
                          treeData?.length <= component?.options?.length
                        }
                      >
                        Add Options
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider style={{ margin: 2 }} />

            <Row gutter={10}>
              <Col
                span={24}
                style={{ display: "flex", marginTop: "10px", gap: "10px" }}
              >
                <Button
                  className="standard-button primary-orange-button"
                  htmlType="submit"
                >
                  Save
                </Button>
                {currentFormElementId !== "" ? (
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      deleteComponent();
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="component-wrapper"
        style={{
          background: "white",
          borderRadius: 7,
          padding: 10,
          marginTop: 15,
        }}
      >
        {render === "" ? (
          <>
            <div className="main-component">
              <Row
                gutter={[10, 10]}
                className="component-main-buttons-container"
              >
                <Col span={24}>
                  <h3>Content</h3>
                </Col>

                <Col className="component-main-button-wrapper">
                  <Button
                    className="secondary-dark-blue-button component-main-buttons"
                    onClick={() => {
                      setRender("company-text");
                      setComponent(
                        micrositeFormElements[micrositeFormElements.length - 1]
                      );
                      dispatch(
                        setCurrentFormElementId(
                          micrositeFormElements[
                            micrositeFormElements.length - 1
                          ].id
                        )
                      );
                    }}
                    disabled={roAdmin}
                  >
                    <img src={TextIcon} style={{ height: 20 }} alt="" />
                    Company Text
                  </Button>
                </Col>
                <Col className="component-main-button-wrapper">
                  <Button
                    className="secondary-dark-blue-button component-main-buttons"
                    onClick={() => {
                      setRender("heading");
                      setComponent(micrositeFormElements[1]);
                      dispatch(
                        setCurrentFormElementId(micrositeFormElements[1].id)
                      );
                    }}
                    disabled={roAdmin}
                  >
                    <img src={HeadingIcon} style={{ height: 20 }} alt="" />{" "}
                    Heading
                  </Button>
                </Col>
                <Col className="component-main-button-wrapper">
                  <Button
                    className="secondary-dark-blue-button component-main-buttons"
                    onClick={() => {
                      setRender("sub-heading");
                      setComponent(micrositeFormElements[2]);
                      dispatch(
                        setCurrentFormElementId(micrositeFormElements[2].id)
                      );
                    }}
                    disabled={roAdmin}
                  >
                    <img src={SubheadingIcon} style={{ height: 20 }} alt="" />{" "}
                    SubHeading
                  </Button>
                </Col>
                <Col className="component-main-button-wrapper">
                  <Button
                    className="secondary-dark-blue-button component-main-buttons"
                    onClick={() => {
                      setRender("default-email");
                      setComponent(micrositeFormElements[6]);
                      dispatch(
                        setCurrentFormElementId(micrositeFormElements[6].id)
                      );
                    }}
                    disabled={roAdmin}
                  >
                    <img src={EmailInitial} style={{ height: 20 }} alt="" />{" "}
                    Email
                  </Button>
                </Col>
                <Col className="component-main-button-wrapper">
                  <Button
                    className="secondary-dark-blue-button component-main-buttons"
                    onClick={() => {
                      setRender("copyright");
                      setComponent(
                        micrositeFormElements[micrositeFormElements.length - 2]
                      );
                      dispatch(
                        setCurrentFormElementId(
                          micrositeFormElements[
                            micrositeFormElements.length - 2
                          ].id
                        )
                      );
                    }}
                    disabled={roAdmin}
                  >
                    <img src={copyRightIcon} style={{ height: 20 }} alt="" />{" "}
                    Copyright
                  </Button>
                </Col>
                <Col className="component-main-button-wrapper">
                  <Button
                    className="secondary-dark-blue-button component-main-buttons"
                    onClick={() => {
                      setRender("Consent-text");
                      setComponent(
                        micrositeFormElements[micrositeFormElements.length - 3]
                      );
                      dispatch(
                        setCurrentFormElementId(
                          micrositeFormElements[
                            micrositeFormElements.length - 3
                          ].id
                        )
                      );
                    }}
                    disabled={roAdmin}
                  >
                    <img src={ConsentText} style={{ height: 20 }} alt="" />{" "}
                    Consent Text
                  </Button>
                </Col>
              </Row>

              <Row gutter={[10, 10]} style={{ marginTop: 25 }}>
                <Col span={24}>
                  <h3>Create a New Field</h3>
                </Col>

                <Col span={12} xs={24} sm={12} md={8} lg={24} xxl={12}>
                  <Button
                    onClick={() => {
                      setRender("contact-attribute");
                      setElementType("input");
                      setComponent(() => {
                        return {
                          id: uuidv4(),
                          attributeType: "contact-attribute",
                          type: "input",
                          htmlType: "text",
                        };
                      });
                      dispatch(setCurrentFormElementId(""));
                    }}
                    className="standard-button secondary-dark-blue-button component-main-buttons"
                    disabled={roAdmin}
                  >
                    Contact Attribute
                  </Button>
                </Col>
                <Col span={12} xs={24} sm={12} md={8} lg={24} xxl={12}>
                  <Button
                    onClick={() => {
                      if (
                        micrositeFormElements.find((elementObj) => {
                          return elementObj?.attributeType === "product";
                        })
                      ) {
                        message.error(
                          "You can't add more than 1 products dropdown!"
                        );
                      } else {
                        setRender("product");
                        setElementType("dropdown");
                        setComponent(() => {
                          return {
                            id: uuidv4(),
                            type: "dropdown",
                            attributeType: "product",
                            htmlType: "",
                            options: [{ label: "", value: "" }],
                          };
                        });
                        dispatch(setCurrentFormElementId(""));
                      }
                    }}
                    className="standard-button secondary-dark-blue-button component-main-buttons"
                    disabled={roAdmin}
                  >
                    Product
                  </Button>
                </Col>
                <Col span={12} xs={24} sm={12} md={8} lg={24} xxl={12}>
                  <Button
                    onClick={() => {
                      setRender("contact-product-attribute");
                      setElementType("input");
                      setComponent(() => {
                        return {
                          id: uuidv4(),
                          type: "input",
                          attributeType: "contact-product-attribute",
                          htmlType: "text",
                        };
                      });
                      dispatch(setCurrentFormElementId(""));
                    }}
                    className="standard-button secondary-dark-blue-button component-main-buttons"
                    disabled={roAdmin}
                  >
                    Contact Product Attribute
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

        {render === "image" ? (
          <>
            <div className="image-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => setRender("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <h3 style={{ marginBottom: 1 }}>Image</h3>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Upload {...props}>
                <Button
                  className="standard-button primary-orange-button"
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                  icon={<img src={ImageIconWhite} alt="ImageIconWhite" />}
                >
                  Upload png only
                </Button>
              </Upload>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 20,
                }}
              >
                <img src={dummyImg} alt="dummyImg" />
                <Button type="text">
                  <CloseOutlined />
                </Button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {render === "default-email" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <h3 style={{ marginBottom: 1 }}>Email</h3>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form
                className="attributes default-email form-grid"
                style={{ marginTop: 15 }}
                onFinish={() => addComponent()}
                layout="vertical"
              >
                <Form.Item label="Required? :">
                  <Checkbox
                    onChange={(e) =>
                      setComponentsProperties("isRequired", e.target.checked)
                    }
                    checked={component.isRequired}
                  />
                </Form.Item>
                <Form.Item label="Hidden? :">
                  <Checkbox
                    onChange={(e) =>
                      setComponentsProperties("isHidden", e.target.checked)
                    }
                    checked={component.isHidden}
                  />
                </Form.Item>

                <Divider style={{ margin: 2 }} />

                <Row>
                  <Col span={24}>
                    <Button
                      className="standard-button primary-orange-button"
                      htmlType="submit"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        ) : (
          <></>
        )}

        {render === "contact-attribute" ? (
          <>
            <Row align="middle" gutter={10} style={{ marginBottom: 25 }}>
              <Button
                type="text"
                onClick={() => {
                  emptyState();
                }}
                style={{ paddingLeft: 0 }}
              >
                <LeftOutlined style={{ fontSize: 16 }} />
              </Button>
              <h3 style={{ marginBottom: 1 }}>Select Element Type</h3>
              <Divider style={{ margin: 2 }} />
            </Row>

            <Row gutter={10} style={{ marginBottom: 25 }}>
              <Col span={24}>
                <Radio.Group
                  value={elementType ? elementType : "input"}
                  className="form-element-select"
                >
                  <Row gutter={[10, 10]}>
                    <Col span={12} md={6} lg={12}>
                      <Radio.Button
                        value="input"
                        onClick={() => {
                          setElementType("input");
                          setComponent(() => {
                            return {
                              id: uuidv4(),
                              type: "input",
                              attributeType: "contact-attribute",
                              htmlType: "text",
                            };
                          });
                          dispatch(setCurrentFormElementId(""));
                        }}
                        className="standard-button secondary-dark-blue-button component-main-buttons"
                      >
                        <img src={InputIcon} alt="InputIcon" /> Text
                      </Radio.Button>
                    </Col>
                    <Col span={12} md={6} lg={12}>
                      <Radio.Button
                        value="dropdown"
                        onClick={() => {
                          setElementType("dropdown");

                          setComponent(() => {
                            return {
                              id: uuidv4(),
                              type: "dropdown",
                              attributeType: "contact-attribute",
                              htmlType: "",
                              options: [{ label: "", value: "" }],
                            };
                          });
                          dispatch(setCurrentFormElementId(""));
                        }}
                        className="standard-button secondary-dark-blue-button component-main-buttons"
                      >
                        <img src={DropdownIcon} alt="DropdownIcon" /> Dropdown
                      </Radio.Button>
                    </Col>
                    <Col span={12} md={6} lg={12}>
                      <Radio.Button
                        value="multichoice"
                        onClick={() => {
                          setElementType("multichoice");

                          setComponent(() => {
                            return {
                              id: uuidv4(),
                              type: "multichoice",
                              attributeType: "contact-attribute",
                              htmlType: "",
                              options: [{ label: "", value: "" }],
                              styleRadioValue: "Tags",
                              multichoiceLayout: "row",
                            };
                          });
                          dispatch(setCurrentFormElementId(""));
                        }}
                        className="standard-button secondary-dark-blue-button component-main-buttons"
                      >
                        <img src={MultichoiceIcon} alt="MultichoiceIcon" />{" "}
                        Multi Choice
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Col>
            </Row>
            {elementType === "input" ? inputField(contactAttributeList) : <></>}
            {elementType === "dropdown" ? (
              dropDownField(contactAttributeList)
            ) : (
              <></>
            )}
            {elementType === "multichoice" ? (
              multichoiceField(contactAttributeList)
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {render === "contact-product-attribute" ? (
          <>
            <Row align="middle" gutter={10} style={{ marginBottom: 25 }}>
              <Button
                type="text"
                onClick={() => {
                  emptyState();
                  setproductId("");
                }}
                style={{ paddingLeft: 0 }}
              >
                <LeftOutlined style={{ fontSize: 16 }} />
              </Button>
              <h3 style={{ marginBottom: 1 }}>Select Element Type</h3>
              <Divider style={{ margin: 2 }} />
            </Row>

            <Row gutter={10} style={{ marginBottom: 25 }}>
              <Col span={24}>
                <Radio.Group
                  value={elementType ? elementType : "input"}
                  className="form-element-select"
                >
                  <Row gutter={[10, 10]}>
                    <Col span={12} md={6} lg={12}>
                      <Radio.Button
                        value="input"
                        onClick={() => {
                          setElementType("input");
                          setComponent(() => {
                            return {
                              id: uuidv4(),
                              type: "input",
                              attributeType: "contact-product-attribute",
                              htmlType: "text",
                            };
                          });
                          dispatch(setCurrentFormElementId(""));
                          setproductId("");
                        }}
                        className="standard-button secondary-dark-blue-button component-main-buttons"
                      >
                        <img src={InputIcon} alt="InputIcon" /> Text
                      </Radio.Button>
                    </Col>
                    <Col span={12} md={6} lg={12}>
                      <Radio.Button
                        value="dropdown"
                        onClick={() => {
                          setElementType("dropdown");

                          setComponent(() => {
                            return {
                              id: uuidv4(),
                              type: "dropdown",
                              attributeType: "contact-product-attribute",
                              htmlType: "",
                              options: [{ label: "", value: "" }],
                            };
                          });
                          dispatch(setCurrentFormElementId(""));
                          setproductId("");
                        }}
                        className="standard-button secondary-dark-blue-button component-main-buttons"
                      >
                        <img src={DropdownIcon} alt="DropdownIcon" /> Dropdown
                      </Radio.Button>
                    </Col>
                    <Col span={12} md={6} lg={12}>
                      <Radio.Button
                        value="multichoice"
                        onClick={() => {
                          setElementType("multichoice");

                          setComponent(() => {
                            return {
                              id: uuidv4(),
                              type: "multichoice",
                              attributeType: "contact-product-attribute",
                              htmlType: "",
                              options: [{ label: "", value: "" }],
                              styleRadioValue: "Tags",
                              multichoiceLayout: "row",
                            };
                          });
                          dispatch(setCurrentFormElementId(""));
                          setproductId("");
                        }}
                        className="standard-button secondary-dark-blue-button component-main-buttons"
                      >
                        <img src={MultichoiceIcon} alt="MultichoiceIcon" />{" "}
                        Multi Choice
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Col>
            </Row>
            {elementType === "input" ? (
              inputField(contactProductAttributeList)
            ) : (
              <></>
            )}
            {elementType === "dropdown" ? (
              dropDownField(contactProductAttributeList)
            ) : (
              <></>
            )}
            {elementType === "multichoice" ? (
              multichoiceField(contactProductAttributeList)
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {render === "product" ? productForm() : <></>}

        {render === "button" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <h3 style={{ marginBottom: 1 }}>Button</h3>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form className="attributes">
                <Form.Item label="Text">
                  <Input
                    className="standard-input"
                    onChange={(e) =>
                      setComponentsProperties("buttonText", e.target.value)
                    }
                    value={component.buttonText}
                  />
                </Form.Item>
              </Form>

              <Divider style={{ margin: 10, marginLeft: "0px" }} />

              <Row>
                <Col span={24}>
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                    onClick={() => {
                      addComponent();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

        {render === "heading" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 style={{ marginBottom: 1 }}>Heading</h3>
                  <div style={{ marginLeft: "10px", lineHeight: "1" }}>
                    <Tooltip title="Customize the heading text that your contacts see when viewing your form. Edits can be made on the left side and will show the preview on the right side. For example, add a link to a word or phrase or change the style of the text.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </div>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form style={{ marginTop: 15 }} layout="vertical">
                <Form.Item label="Enter Heading">
                  <TextEditor
                    initialHtmlContent={component?.headingText}
                    onChange={(text) => {
                      setComponentsProperties("headingText", text);
                    }}
                    style={{
                      width: "100%",
                      pointerEvents: roAdmin ? "none" : "",
                    }}
                  />
                </Form.Item>
              </Form>

              <Row>
                <Col span={24}>
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                    onClick={() => {
                      addComponent();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
        {render === "sub-heading" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 style={{ marginBottom: 1 }}>Sub Heading</h3>
                  <div style={{ marginLeft: "10px", lineHeight: "1" }}>
                    <Tooltip title="Customize the sub-heading text that your contacts see when viewing your form. Edits can be made on the left side and will show the preview on the right side. For example, add a link to a word or phrase or change the style of the text.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </div>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form style={{ marginTop: 15 }} layout="vertical">
                <Form.Item label="Enter Sub Heading">
                  <TextEditor
                    initialHtmlContent={component?.headingText}
                    onChange={(text) => {
                      setComponentsProperties("headingText", text);
                    }}
                    style={{
                      width: "100%",
                      pointerEvents: roAdmin ? "none" : "",
                    }}
                  />
                </Form.Item>
              </Form>

              <Row>
                <Col span={24}>
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                    onClick={() => {
                      addComponent();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

        {render === "copyright" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <h3 style={{ marginBottom: 1 }}>Copyright Text</h3>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form style={{ marginTop: 15 }} layout="vertical">
                <Form.Item label="Enter Copyright Text">
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={(e) =>
                      setComponentsProperties("textValue", e.target.value)
                    }
                    value={component?.textValue}
                  />
                </Form.Item>
              </Form>

              <Row>
                <Col span={24}>
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                    onClick={() => {
                      addComponent();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

        {render === "company-text" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 style={{ marginBottom: 1 }}>Company Text</h3>
                  <div style={{ marginLeft: "10px", lineHeight: "1" }}>
                    <Tooltip title="Customize the company text that your contacts see when viewing your form. Edits can be made on the left side and will show the preview on the right side. For example, add a link to a word or phrase or change the style of the text.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </div>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form style={{ marginTop: 15 }} layout="vertical">
                <Form.Item label="Enter Company Text">
                  <TextEditor
                    initialHtmlContent={component?.textValue}
                    onChange={(text) => {
                      setComponentsProperties("textValue", text);
                    }}
                    style={{
                      width: "100%",
                      pointerEvents: roAdmin ? "none" : "",
                    }}
                  />
                </Form.Item>
              </Form>

              <Row>
                <Col span={24}>
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                    onClick={() => {
                      addComponent();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
        {render === "Consent-text" ? (
          <>
            <div className="input-component">
              <Row align="middle">
                <Button
                  type="text"
                  onClick={() => emptyState("")}
                  style={{ paddingLeft: 0 }}
                >
                  <LeftOutlined style={{ fontSize: 16 }} />
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 style={{ marginBottom: 1 }}>Consent Text</h3>
                  <div style={{ marginLeft: "10px", lineHeight: "1" }}>
                    <Tooltip title="Customize the consent/opt-in message that your contacts see when viewing your form. Edits can be made on the left side and will show the preview on the right side. For example, add a link to a word or phrase or change the style of the text.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </div>
              </Row>

              <Divider style={{ margin: 2 }} />

              <Form style={{ marginTop: 15 }} layout="vertical">
                <Form.Item label="Enter Consent Text">
                  <TextEditor
                    initialHtmlContent={component?.textValue}
                    onChange={(text) => {
                      setComponentsProperties("textValue", text);
                    }}
                    style={{
                      width: "100%",
                      pointerEvents: roAdmin ? "none" : "",
                    }}
                  />
                </Form.Item>
              </Form>
              <Row>
                <Col span={24}>
                  <Button
                    className="standard-button primary-orange-button"
                    htmlType="submit"
                    onClick={() => {
                      addComponent();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
