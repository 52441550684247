import React, { Component } from "react";
import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login, setVerificationDetails } from "store/actions/loginAction";
import { emailPattern } from "utility/Constants";
import moment from "moment";
import "../user.css";
import logoWhite from "assets/Images/logo-white.svg";
import {
  MailOutlined,
  LockOutlined,
  CopyrightOutlined,
} from "@ant-design/icons";
import { API } from "aws-amplify";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      loading: false,
      feedback: props.history.location.state
        ? props.history.location.state.feedback
        : "",
      error: "",
    };
  }

  handleState = (name, value) => {
    this.setState({ [name]: value });
  };

  verifyStoreAndUser = async (storeDetails, jwtToken) => {
    try {
      const apiName = "blustreamREST";
      const path = "/shopify-intergration/integrations_new";
      let body = {
        hmac: storeDetails?.hmac,
        code: storeDetails?.code,
        shop: storeDetails?.shopname,
        url: storeDetails?.url,
        login_token: jwtToken,
      };

      const response = await API.post(apiName, path, { body: body });
      this.props.setVerificationDetails(response);
      this.props.history.push("/unboxing-tour");
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = (e) => {
    this.setState(
      {
        loading: true,
        error: "",
      },
      () => {
        this.props
          .loginData(e.userName, e.password)
          .then((response) => {
            this.setState({
              loading: false,
            });
            const jwtToken = response?.signInUserSession?.accessToken?.jwtToken;
            const storeDetails = JSON.parse(
              localStorage.getItem("storeDetails")
            );
            if (storeDetails) {
              this.verifyStoreAndUser(storeDetails, jwtToken);
            } else if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
              this.props.history.push({
                pathname: `/set-new-password`,
                state: {
                  userAttributes: response?.challengeParam?.userAttributes,
                  oldPassword: e.password,
                },
              });
            }
          })
          .catch((error) => {
            console.log("error signing in:", error.message);
            this.setState({
              error: error.message,
              loading: false,
              userName: "",
              password: "",
            });
            if (error.message === "User is not confirmed.") {
              this.props.history.push({
                pathname: `/confirm_sign_up`,
                state: { feedback: "", userName: e.userName },
              });
            }
          });
      }
    );
  };

  render() {
    const { email, password, loading, error, feedback } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={7} xl={6} className="sidebar-background">
          <Col
            className="info"
            style={{ position: "absolute", top: 0, left: 0 }}
          >
            <div className="info-logo">
              <a href="/signin">
                <img src={logoWhite} alt="" />
              </a>
            </div>
            <div className="info-text">
              <Typography.Title className="login-heading" level={1}>
                Welcome to Blustream!
              </Typography.Title>
              <Typography.Text className="login-heading-text">
                Be a part of Blustream to manage and organise daily activities
                of your customers{" "}
              </Typography.Text>
              <Button
                type="primary"
                size="large"
                tabIndex={5}
                className="btn-secondary"
                style={{
                  marginTop: 44,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  lineHeight: "16px",
                }}
                onClick={() => history.push("/signup")}
              >
                <span style={{ fontWeight: "bold" }}>Create Account</span>
              </Button>
            </div>
          </Col>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={17}
          xl={18}
          style={{ transform: "scale(0.88)" }}
        >
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight: 580,
            }}
          >
            <Card
              className="login-form-container"
              style={{ border: "none", width: "437px" }}
            >
              <div>
                <Typography.Title
                  level={3}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingBottom: 34,
                  }}
                >
                  Login{" "}
                </Typography.Title>
              </div>
              {feedback && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="success"
                  showIcon={false}
                  message={feedback}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_login"
                className="login-form"
                onFinish={(e) => this.handleSubmit(e)}
              >
                <Form.Item
                  initialValue={email}
                  name="userName"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    autoFocus
                    value={email}
                    onChange={(e) =>
                      this.handleState(e.target.name, e.target.value)
                    }
                    id="userName"
                    key="userName"
                    name="userName"
                    placeholder="Email Address"
                    disabled={loading}
                    tabIndex={1}
                    size="large"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 }}
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  style={{ marginBottom: 10 }}
                >
                  <Input.Password
                    className="standard-input"
                    value={password}
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    disabled={loading}
                    tabIndex={2}
                    size="large"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>

                <h4
                  style={{
                    fontSize: 16,
                    textAlign: "right",
                    color: "#F5786D",
                    paddingBottom: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/forgot-password")}
                >
                  Reset password ?
                </h4>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loading}
                      tabIndex={5}
                      className="standard-button primary-orange-button"
                    >
                      <span style={{ fontWeight: "bold" }}>Login</span>
                    </Button>
                    <div className="mobile-sign-up-text">
                      <h3
                        style={{
                          fontSize: 16,
                          textAlign: "center",
                          paddingBottom: 30,
                          cursor: "pointer",
                        }}
                        onClick={() => history.push("/signup")}
                      >
                        Don't have an Account ?
                        <Link to="/signup" style={{ color: "#F5786D" }}>
                          {" "}
                          SIGN UP
                        </Link>
                      </h3>
                    </div>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
            <span
              style={{
                color: "#646C79",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
                userSelect: "none",
                marginBottom: "0px",
                position: "fixed",
                bottom: 0,
              }}
            >
              <div>
                <div>
                  Version{" "}
                  {process.env.REACT_APP_VERSION
                    ? process.env.REACT_APP_VERSION
                    : "0.1.0"}
                </div>
                <CopyrightOutlined />
                {`${moment().year()}. Blustream.io`}
              </div>
            </span>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginData: (userName, password) => dispatch(login(userName, password)),
    setVerificationDetails: (storeDetails) =>
      dispatch(setVerificationDetails(storeDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
