import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router";
import logoWhite from "assets/Images/logo-white.svg";
import moment from "moment";
import {
  CopyrightOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";

function ConfirmPassword(props) {
  const [msg, setmsg] = useState({
    text: "Check your email for a confirmation code",
    type: "success",
  });
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userName : ""
  );
  const history = useHistory();

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/reset-password") &&
        window.location.href.includes("email=")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        console.log(Data.code);
        form.setFieldsValue({
          code: Data.code,
          password: "",
        });
        setemail(window.location.href.split("email=")[1]);
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email).then(() => {
        setmsg({
          text: "A new confirmation code has been sent.",
          type: "success",
        });
        setloading(false);
      });
    } catch (error) {
      console.log("error resend code :", error.message);
      setloading(false);
      setmsg({ text: error.message, type: "error" });
    }
  };

  const confirmReset = async (e) => {
    setloading(true);
    setmsg({ text: "", type: "" });
    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        history.push({
          pathname: "/signin",
          state: { feedback: "Sign in with new account", userName: "" },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      setloading(false);
      setmsg({ text: error.message, type: "error" });
    }
  };

  return (
    <>
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={7} xl={6} className="sidebar-background">
          <Col
            className="info"
            style={{ position: "absolute", top: 0, left: 0 }}
          >
            <div className="info-logo">
              <img src={logoWhite} alt="" />
            </div>
            <div className="info-text">
              <Typography.Title className="login-heading" level={1}>
                Welcome to Blustream!
              </Typography.Title>
              <Button
                type="primary"
                size="large"
                tabIndex={5}
                className="btn-secondary"
                style={{
                  marginTop: 44,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  lineHeight: "16px",
                }}
                onClick={() => history.push("/login")}
              >
                <span style={{ fontWeight: "bold" }}>Sign In</span>
              </Button>
            </div>
          </Col>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={17}
          xl={18}
          style={{ transform: "scale(0.88)" }}
        >
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight: 580,
            }}
          >
            <Card
              className="confirm-password-form-container"
              style={{ border: "none", width: "437px" }}
            >
              <div>
                <Typography.Title
                  level={3}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingBottom: 34,
                  }}
                >
                  Reset Password{" "}
                </Typography.Title>
              </div>
              {msg?.text && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type={msg?.type}
                  showIcon={false}
                  message={msg.text}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_reset"
                className="reset-form"
                onFinish={(e) => confirmReset(e)}
                autoComplete="off"
                form={form}
              >
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Confirmation Code!",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    style={{ fontSize: 18 }}
                    id="code"
                    key="code"
                    name="code"
                    placeholder="Enter your confirmation code"
                    disabled={loading}
                    tabIndex={1}
                    type="number"
                    size="large"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  style={{ fontSize: 18 }}
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    className="standard-input"
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    disabled={loading}
                    tabIndex={2}
                    autoFocus
                    autocomplete="new-password"
                    size="large"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <h4
                  style={{
                    fontSize: 16,
                    textAlign: "right",
                    color: "#F5786D",
                    paddingBottom: 30,
                    cursor: "pointer",
                  }}
                  onClick={resendConfirmationCode}
                >
                  Resend Code
                </h4>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loading}
                      tabIndex={5}
                      className="standard-button primary-orange-button"
                    >
                      <span style={{ fontWeight: "bold" }}>Submit</span>
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
            <span
              style={{
                color: "#646C79",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
                userSelect: "none",
                marginBottom: "0px",
                position: "fixed",
                bottom: 0,
              }}
            >
              <div>
                <div>
                  Version{" "}
                  {process.env.REACT_APP_VERSION
                    ? process.env.REACT_APP_VERSION
                    : "0.1.0"}
                </div>
                <CopyrightOutlined />
                {`${moment().year()}. Blustream.io`}
              </div>
            </span>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ConfirmPassword;
