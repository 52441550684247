import React, { useState } from "react";
import {
  Table,
  Dropdown,
  Menu,
  Button,
  Form,
  Input,
  Modal,
  Avatar,
  Select,
  Row,
  Col,
  Drawer,
  Space,
  Radio,
  Divider,
} from "antd";
import {
  DeleteFilled,
  MoreOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import dog1 from "../../assets/Images/dog1.svg";
import dog2 from "../../assets/Images/dog2.svg";
import dog3 from "../../assets/Images/dog3.svg";
import dog4 from "../../assets/Images/dog4.svg";
import settingIcon from "../../assets/Images/settingsBlue.svg";
import { Link } from "react-router-dom";
import AttributeDrawer from "./AttributeDrawer";
import DeleteIcon from "../../assets/Images/DeleteIcon.svg";

const { Option } = Select;
const { Search } = Input;

const data = [
  {
    key: "1",
    photo: dog1,
    Product: "Golden Retriever",
    PetName: "Simba",
    AdoptionDate: "6/7/2021",
    LastVisit: "11/17/2021",
    WantsGrooming: "Yes",
    Diet: "Science Diet",
  },
  {
    key: "2",
    photo: dog2,
    Product: "Labrador",
    PetName: "Keeley",
    AdoptionDate: "9/28/2012",
    LastVisit: "8/15/2021",
    WantsGrooming: "No",
    Diet: "Wellness Cans",
  },
  {
    key: "3",
    photo: dog3,
    Product: "Poodle",
    PetName: "Wicket",
    AdoptionDate: "2/22/2015",
    LastVisit: "8/5/2021",
    WantsGrooming: "Yes",
    Diet: "Evanger's Cans",
  },
  {
    key: "4",
    photo: dog4,
    Product: "Australian Shepherd      ",
    PetName: "Wicket",
    AdoptionDate: "12/22/2015",
    LastVisit: "8/15/2021",
    WantsGrooming: "Yes",
    Diet: "Evanger's Cans",
  },
];

export default function ContactsDetailPage() {
  const [form] = Form.useForm();
  const [editProductAttributeDrawer, setEditProductAttributeDrawer] =
    useState(false);
  const [
    editContactProductAttributeDrawer,
    setEditContactProductAttributeDrawer,
  ] = useState(false);
  const [addProductAttributeDrawer, setAddProductAttributeDrawer] =
    useState(false);
  const [
    addContactProductAttributeDrawer,
    setAddContactProductAttributeDrawer,
  ] = useState(false);
  const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);

  // edit product attribute
  const showDrawer = () => {
    setEditProductAttributeDrawer(true);
  };
  const onClose = () => {
    setEditProductAttributeDrawer(false);
  };

  // edit contact product attribute
  const showContactEditDrawer = () => {
    setEditContactProductAttributeDrawer(true);
  };
  const ContactEditonClose = () => {
    setEditContactProductAttributeDrawer(false);
  };

  // add product attribute
  const showAddDrawer = () => {
    setAddProductAttributeDrawer(true);
  };
  const AddonClose = () => {
    setAddProductAttributeDrawer(false);
  };

  // add contact product attribute
  const showAddContactDrawer = () => {
    setAddContactProductAttributeDrawer(true);
  };
  const ContactonClose = () => {
    setAddContactProductAttributeDrawer(false);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href=" ">
          Contact Product attributes
        </a>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: (text) => (
        <Link to="/product-detail">
          <Avatar src={text} />
        </Link>
      ),
    },
    {
      title: "Product",
      dataIndex: "Product",
      render: (text) => (
        <span style={{ color: "#053262", fontWeight: 600 }}>{text}</span>
      ),
    },
    {
      title: "Pet Name",
      dataIndex: "PetName",
      render: (text) => <>{text}</>,
    },
    {
      title: "Adoption Date",
      dataIndex: "AdoptionDate",
      render: (text) => <>{text}</>,
    },
    {
      title: "Last Vet Visit",
      dataIndex: "LastVisit",
      render: (text) => <>{text}</>,
    },
    {
      title: "Wants Grooming",
      dataIndex: "WantsGrooming",
      render: (text) => <>{text}</>,
    },
    {
      title: "Diet",
      dataIndex: "Diet",
      render: (text) => <>{text}</>,
    },
    {
      title: "",
      dataIndex: "edit",
      render: () => (
        <Link type="text" to="#" className="button">
          <MoreOutlined />
        </Link>
      ),
      align: "center",
    },
  ];

  // const itemRender = (current, type, originalElement) => {
  //     if (type === 'prev') {
  //       return <a>{"< Prev"}</a>;
  //     }
  //     if (type === 'next') {
  //       return <a>{"Next >"}</a>;
  //     }
  //     return originalElement;
  // }

  return (
    <>
      <div className="detailPage">
        <div className="formBox">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginBottom: "10px",
              padding: "0 10px 10px 10px",
              alignItems: "center",
              borderBottom: "3px solid rgb(239, 244, 249)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <UserOutlined style={{ color: "#F37064", fontSize: 20 }} />
              <h2
                style={{
                  paddingLeft: "10px",
                  color: "#F37064",
                  fontWeight: "600",
                  marginBottom: 0,
                }}
              >
                Edward Wilson
              </h2>
            </div>

            <div>
              <Button
                style={{
                  background: "#F37064",
                  color: "white",
                  border: "none",
                  borderRadius: 7,
                  marginRight: 10,
                }}
              >
                Add Touchpoint
              </Button>
              <Dropdown overlay={menu} placement="bottomRight">
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  href=" "
                >
                  <MoreOutlined style={{ color: "black" }} />
                </a>
              </Dropdown>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 10px 7px 10px",
                alignItems: "center",
                borderBottom: "3px solid rgb(239, 244, 249)",
              }}
            >
              <h3
                style={{ marginBottom: 0, color: "#124173", fontWeight: 600 }}
              >
                Contact Attributes
              </h3>
              <div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    background: "#124173",
                    color: "white",
                    marginRight: 10,
                    borderRadius: 7,
                  }}
                >
                  Add
                </Button>
                <Button
                  onClick={showDrawer}
                  style={{
                    background: "transparent",
                    color: "#124173",
                    borderColor: "#124173",
                    marginRight: 10,
                    borderRadius: 7,
                  }}
                >
                  Edit
                </Button>
                <Button style={{ borderRadius: 7 }} danger>
                  Save
                </Button>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "0 20px",
              }}
            >
              <Form form={form} layout="vertical">
                <Row gutter={[10, 10]}>
                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item label="Full name" style={{ width: "100%" }}>
                      <Input
                        className="standard-input"
                        placeholder="Enter your full name"
                        value="Edward Wilson"
                      />
                    </Form.Item>
                    <Form.Item label="Age" style={{ width: "100%" }}>
                      <Input
                        className="standard-input"
                        placeholder="Age"
                        value="29"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item label="Cell Number" style={{ width: "100%" }}>
                      <Input
                        className="standard-input"
                        placeholder="Cell Number"
                        value="+18143008294"
                      />
                    </Form.Item>
                    <Form.Item label="Gender" style={{ width: "100%" }}>
                      <Select placeholder="Gender" defaultValue="Male">
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item label="Email" style={{ width: "100%" }}>
                      <Input
                        className="standard-input"
                        placeholder="Email"
                        value="edwardwil34@gmail.com"
                      />
                    </Form.Item>
                    <Form.Item label="Location" style={{ width: "100%" }}>
                      <Select
                        placeholder="Location"
                        defaultValue="Pennsylvania"
                      >
                        <Option value="Pennsylvania">Pennsylvania</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>

        <div
          className="productBox contactBox border"
          style={{
            width: "100%",
            background: "white",
            padding: "0px",
            marginTop: 10,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "10px 10px 7px 10px",
              alignItems: "center",
              borderBottom: "3px solid rgb(239, 244, 249)",
            }}
          >
            <h3 style={{ marginBottom: 0, color: "#124173", fontWeight: 600 }}>
              Contacts Product attributes
            </h3>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginRight: "10px",
                }}
              >
                <Search
                  prefix={<SearchOutlined />}
                  placeholder="Search Contacts"
                  style={{ width: "100%" }}
                />
              </div>

              <Button
                type="secondary"
                style={{
                  marginRight: "10px",
                  borderRadius: "7px",
                  borderColor: "#124173",
                }}
              >
                {" "}
                <img src={settingIcon} height="15" alt="" />{" "}
              </Button>
              <Button
                onClick={showAddContactDrawer}
                style={{
                  background: "#124173",
                  color: "white",
                  marginRight: 10,
                  borderRadius: 7,
                }}
              >
                Add
              </Button>
              <Button
                onClick={showContactEditDrawer}
                style={{ borderRadius: 7 }}
                danger
              >
                Edit
              </Button>
            </div>
          </div>

          <div style={{ background: "white", padding: 10 }}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </div>

      <AttributeDrawer />

      {/* edit contact attribute drawer  */}
      <Drawer
        className="addProperty"
        width={430}
        title="Edit Contact attributes"
        placement="right"
        onClose={onClose}
        visible={editProductAttributeDrawer}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[10, 10]}>
            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item
                label={
                  <span style={{ color: "#124173", fontWeight: 600 }}>
                    Attribute Title
                  </span>
                }
                style={{ width: "100%" }}
              >
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Full name"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item
                label={
                  <span style={{ color: "#124173", fontWeight: 600 }}>
                    Attribute Type
                  </span>
                }
                style={{ width: "100%", display: "flex" }}
              >
                <Input value="Text field" style={{ width: "auto" }} />
                <Button
                  type="text"
                  onClick={() => setisInviteUserModelOpen(true)}
                >
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Cell Number"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item
                // style={{width: '100%'}}
                style={{ width: "100%", display: "flex" }}
              >
                <Input value="Text field" style={{ width: "auto" }} />
                <Button
                  type="text"
                  onClick={() => setisInviteUserModelOpen(true)}
                >
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Email"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input value="Date Selection" style={{ width: "auto" }} />
                <Button
                  type="text"
                  onClick={() => setisInviteUserModelOpen(true)}
                >
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Age"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input value="Number" style={{ width: "auto" }} />
                <Button
                  type="text"
                  onClick={() => setisInviteUserModelOpen(true)}
                >
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Gender"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input value="select dropdown" style={{ width: "auto" }} />
                <Button
                  type="text"
                  onClick={() => setisInviteUserModelOpen(true)}
                >
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Location"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input value="select dropdown" style={{ width: "auto" }} />
                <Button
                  type="text"
                  onClick={() => setisInviteUserModelOpen(true)}
                >
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            width: "auto",
            borderTop: "1px solid #e9e9e9",
            fontSize: "18px",
            padding: "10px 16px",
            background: "#EFF4F9",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            className="btn-secondary"
            style={{
              marginRight: 8,
              width: "auto",
              border: "1px solid #252525",
              color: "black",
            }}
            size="middle"
            onClick={() => {
              showAddDrawer();
              setEditProductAttributeDrawer(false);
            }}
          >
            Add new attribute
          </Button>
          <Button
            type="primary"
            style={{ width: "auto" }}
            htmlType="submit"
            className="btn-primary"
            size="middle"
          >
            Save
          </Button>
        </div>
      </Drawer>

      {/* Add new product attribute drawer  */}
      <Drawer
        className="addProperty"
        width={430}
        title="Add new product attribute"
        placement="right"
        onClose={AddonClose}
        visible={addProductAttributeDrawer}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Attribute Title" style={{ width: "100%" }}>
            <Input placeholder="Enter attribute title" />
          </Form.Item>

          <Form.Item label="Attribute Title" style={{ width: "100%" }}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={1}>Text field</Radio>
                <Radio value={2}>Date Selection</Radio>
                <Radio value={3}>Multi-select dropdown</Radio>
                <Radio value={4}>Single-select dropdown</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            width: "auto",
            borderTop: "1px solid #e9e9e9",
            fontSize: "18px",
            padding: "10px 16px",
            background: "#EFF4F9",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            className="btn-secondary"
            style={{
              marginRight: 8,
              width: "auto",
              border: "1px solid #252525",
              color: "black",
            }}
            size="middle"
            onClick={AddonClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ width: "auto" }}
            htmlType="submit"
            className="btn-primary"
            size="middle"
          >
            Add
          </Button>
        </div>
      </Drawer>

      {/* edit contact product attribute drawer  */}
      <Drawer
        className="addProperty"
        width={430}
        title="Edit Contact-Product attributes"
        placement="right"
        onClose={ContactEditonClose}
        visible={editContactProductAttributeDrawer}
      >
        <h3 style={{ color: "#124173", fontWeight: 600, marginBottom: 10 }}>
          Attribute Title
        </h3>

        <Form form={form} layout="vertical">
          <Form.Item style={{ width: "100%" }}>
            <Input value="Text field" style={{ width: "85%" }} />
            <Button type="text" onClick={() => setisInviteUserModelOpen(true)}>
              <DeleteFilled style={{ color: "#CACACA" }} />
            </Button>
          </Form.Item>

          <Form.Item style={{ width: "100%" }}>
            <Input value="Text field" style={{ width: "85%" }} />
            <Button type="text" onClick={() => setisInviteUserModelOpen(true)}>
              <DeleteFilled style={{ color: "#CACACA" }} />
            </Button>
          </Form.Item>

          <Form.Item style={{ width: "100%" }}>
            <Input value="Date Selection" style={{ width: "85%" }} />
            <Button type="text" onClick={() => setisInviteUserModelOpen(true)}>
              <DeleteFilled style={{ color: "#CACACA" }} />
            </Button>
          </Form.Item>

          <Form.Item style={{ width: "100%" }}>
            <Input value="Text field" style={{ width: "85%" }} />
            <Button type="text" onClick={() => setisInviteUserModelOpen(true)}>
              <DeleteFilled style={{ color: "#CACACA" }} />
            </Button>
          </Form.Item>

          <Form.Item style={{ width: "100%" }}>
            <Input value="Date Selection" style={{ width: "85%" }} />
            <Button type="text" onClick={() => setisInviteUserModelOpen(true)}>
              <DeleteFilled style={{ color: "#CACACA" }} />
            </Button>
          </Form.Item>

          <Form.Item style={{ width: "100%" }}>
            <Input value="Multi-select dropdown" style={{ width: "85%" }} />
            <Button type="text" onClick={() => setisInviteUserModelOpen(true)}>
              <DeleteFilled style={{ color: "#CACACA" }} />
            </Button>
          </Form.Item>
        </Form>

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            width: "auto",
            borderTop: "1px solid #e9e9e9",
            fontSize: "18px",
            padding: "10px 16px",
            background: "#EFF4F9",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            className="btn-secondary"
            style={{
              marginRight: 8,
              width: "auto",
              border: "1px solid #252525",
              color: "black",
            }}
            size="middle"
            onClick={() => {
              showAddContactDrawer();
              setEditContactProductAttributeDrawer(false);
            }}
          >
            Add new attribute
          </Button>
          <Button
            type="primary"
            style={{ width: "auto" }}
            htmlType="submit"
            className="btn-primary"
            size="middle"
          >
            Save
          </Button>
        </div>
      </Drawer>

      {/* Add new contact product attribute drawer  */}
      <Drawer
        className="addProperty"
        width={430}
        title="Add new Customer product attribute"
        placement="right"
        onClose={ContactonClose}
        visible={addContactProductAttributeDrawer}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Attribute Title" style={{ width: "100%" }}>
            <Input placeholder="Enter attribute title" />
            <Divider
              style={{
                marginTop: 40,
                color: "#F37064",
                borderTopColor: "#F37064",
              }}
              plain
            >
              Or
            </Divider>
          </Form.Item>

          <Form.Item label="Select from library" style={{ width: "100%" }}>
            <Select
              defaultValue="Product purchase date"
              className="selectTab"
              style={{ width: "100%" }}
            >
              <Option value="1">Product purchase date</Option>
            </Select>
          </Form.Item>
        </Form>

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            width: "auto",
            borderTop: "1px solid #e9e9e9",
            fontSize: "18px",
            padding: "10px 16px",
            background: "#EFF4F9",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            className="btn-secondary"
            style={{
              marginRight: 8,
              width: "auto",
              border: "1px solid #252525",
              color: "black",
            }}
            size="middle"
            onClick={ContactonClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ width: "auto" }}
            htmlType="submit"
            className="btn-primary"
            size="middle"
          >
            Add
          </Button>
        </div>
      </Drawer>

      {/* delete modal  */}
      <Modal
        visible={isInviteUserModelOpen}
        className="invite-modal"
        footer={null}
        width={370}
        centered
        closeIcon={<></>}
        onCancel={() => {
          setisInviteUserModelOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "10px 10px 50px",
          }}
        >
          <img src={DeleteIcon} height="40" alt="" />
          <h3 style={{ color: "#252525", margin: "10px 0 20px" }}>
            Are you sure you want to delete this Attribute ?
          </h3>

          <p style={{ color: "#797979" }}>
            You will loose all the data relevant to this attribute in Touchpoint
            & Contact manager
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              className="btn-secondary"
              style={{
                marginRight: 8,
                width: "48%",
                border: "1px solid #252525",
                color: "black",
              }}
              size="middle"
              onClick={() => {
                setisInviteUserModelOpen(false);
              }}
            >
              No
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="btn-primary"
              size="middle"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
