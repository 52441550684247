import darklogo from "./../../assets/Images/darklogo.png";
import frenchDark from "./../../assets/Images/frenchDark.png";
import lightlogo from "./../../assets/Images/lightlogo.png";
import frenchLight from "./../../assets/Images/frenchLight.png";

export const handleImagesOnLanguageChange = (isDark = true, lang = "en") => {
  if (isDark) {
    if (lang === "en") {
      return darklogo;
    } else if (lang === "fr") {
      return frenchDark;
    }
  } else {
    if (lang === "en") {
      return lightlogo;
    } else if (lang === "fr") {
      return frenchLight;
    }
  }
};
