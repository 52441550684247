import React, { useEffect } from "react";

const ShopifyInstall = () => {
  const shopifyurl = async () => {
    try {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var code = url.searchParams.get("code");
      var hmac = url.searchParams.get("hmac");
      var shopname = url.searchParams.get("shop");
      if (!code && hmac) {
        window.open(
          `https://${shopname}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENTID}&scope=read_orders,read_customers,read_products,read_checkouts&redirect_uri=${process.env.REACT_APP_SHOPIFY_REDIRECT_URL}&state=shopify`,
          "_self"
        );
      }
      // ${process.env.REACT_APP_SHOPIFY_REDIRECT_URL}
      // ${process.env.REACT_APP_SHOPIFY_CLIENTID}
      // http://localhost:3000/shopify-redirect
    } catch (e) {}
  };

  useEffect(() => {
    shopifyurl();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    ></div>
  );
};

export default ShopifyInstall;
