/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Dropdown, Modal, Row, message } from "antd";
import NewAccount from "./NewAccount";
import RequestAccess from "./RequestAccess";
import Logo from "commons/Logo";
import RequestPending from "./RequestPending";
import { useDispatch, useSelector } from "react-redux";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  getaccountDetails,
  logout,
  setAccountDetails,
  setLoginData,
  updateCompanyAccount,
  updateUserGroups,
} from "store/actions/loginAction";
import { useHistory } from "react-router-dom";
import moment from "moment";

import userIcon from "./../../../assets/Images/userIcon.svg";
import deleteIcon4 from "./../../../assets/Images/deleteIcon4.svg";
import glowingBulbIcon from "./../../../assets/Images/glowingBulbIcon.svg";
import groupIcon from "./../../../assets/Images/groupIcon.svg";

import sdk from "sdk/Accounts";
import { deleteCompanyInviteHistory } from "graphql/mutations";
import {
  onCreateCompanyInviteHistory,
  onDeleteCompanyInviteHistory,
} from "graphql/subscriptions";

const NEW = "newAccount";
const ACCESS = "requestAccess";

const Selection = (props) => {
  const history = useHistory();
  const [step, setStep] = useState("");
  const [requestAccessState, setrequestAccessState] = useState(false);
  const [accountid, setaccountid] = useState("");
  const [openDropDown, setOpenDropDown] = useState(false);
  const [inviteHistory, setInviteHistory] = useState(null);
  const [invitedOrganizationDetails, setInvitedOrganizationDetails] =
    useState(null);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [acceptInvitationLoader, setAcceptInvitationLoader] = useState(false);
  const [declineInvitationLoader, setDeclineInvitationLoader] = useState(false);
  const [showDeclineInviteModal, setShowDeclineInviteModal] = useState(false);

  const dispatch = useDispatch();
  const handleRequestAccessState = () => {
    setrequestAccessState(true);
  };

  const { userData } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (requestAccessState) {
      setInterval(() => {
        fetchuser();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAccessState]);

  useEffect(() => {
    document.addEventListener("click", globalClickListener);
    return () => {
      clearInterval();
      document.removeEventListener("click", globalClickListener);
    };
  }, []);

  const globalClickListener = () => {
    setOpenDropDown(false);
  };

  async function fetchuser() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          const { accessToken } = session;
          if ("cognito:groups" in accessToken.payload) {
            let userAccountId = [];
            userAccountId = accessToken.payload["cognito:groups"];
            userAccountId.push(
              userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]
            );
            dispatch(getaccountDetails(userAccountId[0]));
            const response = Auth.currentUserInfo();
            response
              .then((data) => {
                dispatch(setLoginData(session, data?.attributes));
                clearInterval();
              })
              .then((_) => {
                setTimeout(() => {
                  window.location.href = "/";
                }, 3000);
              });
          }
        }
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  }
  useEffect(() => {
    const createInviteHistorySubscription = API.graphql(
      graphqlOperation(onCreateCompanyInviteHistory)
    ).subscribe({
      next: () => {
        getInviteHistory(userData?.email);
      },
      error: (error) => console.warn(error),
    });

    const deleteInviteHistorySubscription = API.graphql(
      graphqlOperation(onDeleteCompanyInviteHistory)
    ).subscribe({
      next: () => {
        getInviteHistory(userData?.email);
      },
      error: (error) => console.warn(error),
    });
    if (
      window.location.href.includes("requestid=") &&
      window.location.href.includes("/setup")
    ) {
      setTimeout(() => {
        setaccountid(window.location.href.split("requestid=")[1]);
        setStep(ACCESS);
        setrequestAccessState(true);
      }, 1000);
    }
    getInviteHistory(userData?.email);
    return () => {
      createInviteHistorySubscription?.unsubscribe();
      deleteInviteHistorySubscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Hey, a popstate event happened!
    window.addEventListener("popstate", (e) => {
      // Nope, go back to your page
      history.go(1);
    });
  }, []);

  const handleSignOut = async () => {
    Auth.signOut()
      .then((data) => {
        dispatch(logout());
      })
      .catch((err) => console.log(err));
  };

  const getRequest = async (account) => {
    const request = await sdk?.getCustomerAccountRequest(
      account,
      userData?.email
    );
    return request;
  };

  const getInviteHistory = async (email) => {
    try {
      const response = await sdk.listInviteHistory(email);
      const sortedHistory =
        response?.data?.listCompanyInviteHistories?.items?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      setInviteHistory(sortedHistory);
      if (sortedHistory?.length > 0) {
        const accountData = await sdk.fetchAccountDetailsOfInvitedUser(
          sortedHistory?.[0]?.account
        );
        setInvitedOrganizationDetails(accountData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const acceptInvitation = async (id) => {
    try {
      setAcceptInvitationLoader(true);
      const apiName = "blustreamREST";
      const path = "/accounts/join-organisation";
      const body = {
        id: id,
      };
      await API.post(apiName, path, { body: body });
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          const { accessToken } = session;
          if ("cognito:groups" in accessToken.payload) {
            const response = Auth.currentUserInfo();
            response
              .then((data) => {
                dispatch(updateUserGroups(session, data?.attributes));
              })
              .then(async (_) => {
                dispatch(updateCompanyAccount(invitedOrganizationDetails));
                if (invitedOrganizationDetails?.id) {
                  let account = await sdk.fetchAccount(
                    invitedOrganizationDetails?.id
                  );
                  dispatch(setAccountDetails(account));
                }
                history.push("/");
                message.success("Joined organization successfully!");
              });
          }
        }
      );
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    } finally {
      setAcceptInvitationLoader(false);
      setShowInvitationModal(false);
    }
  };

  const declineInvitation = async (id) => {
    setDeclineInvitationLoader(true);
    try {
      await API.graphql(
        graphqlOperation(deleteCompanyInviteHistory, { input: { id: id } })
      );
      await getInviteHistory(userData?.email);
    } catch (error) {
      console.log(error);
    } finally {
      setDeclineInvitationLoader(false);
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#FAFAFA",
        }}
      >
        <div
          style={{
            height: "71px",
            width: "100vw",
            backgroundColor: "white",
            marginBottom: "50px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "30px",
          }}
        >
          <Dropdown
            visible={openDropDown}
            overlay={
              <div
                style={{
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                  maxWidth: "260px",
                }}
              >
                {inviteHistory?.length > 0 && (
                  <div className="invitation-menu">
                    <div className="invite-decline-container">
                      <p className="invitation-text">
                        You have received invitation to join{" "}
                        <span style={{ fontStyle: "italic" }}>
                          {invitedOrganizationDetails?.company}
                        </span>
                      </p>
                      <img
                        src={deleteIcon4}
                        alt="deleteIcon"
                        style={{ marginTop: "5px" }}
                        onClick={() => setShowDeclineInviteModal(true)}
                      />
                    </div>
                    <div className="menu-invite-buttons-container">
                      <Button
                        loading={acceptInvitationLoader}
                        className="standard-button primary-orange-button"
                        onClick={async () => {
                          await acceptInvitation(inviteHistory?.[0]?.id);
                        }}
                        style={{ width: "110px" }}
                      >
                        Accept
                      </Button>
                      <Button
                        className="standard-button secondary-orange-button"
                        style={{ width: "110px" }}
                        onClick={() => {
                          setShowInvitationModal(true);
                        }}
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    borderRadius:
                      inviteHistory?.length > 0 ? "0px 0px 6px 6px" : "6px",
                    backgroundColor: "#FFF",
                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                    color: "#E24B4C",
                    fontFamily: "Hellix",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    minWidth: "258px",
                    padding: "8px 12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleSignOut}
                >
                  Logout
                </div>
              </div>
            }
            trigger={["click"]}
            placement={"bottomRight"}
          >
            <Badge dot={inviteHistory?.length > 0} className="profile-badge">
              <img
                src={userIcon}
                alt="user-icon"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenDropDown(true);
                }}
              />
            </Badge>
          </Dropdown>
        </div>
        <Row justify="center" align="middle">
          <Col span={24}>
            {requestAccessState ? (
              <Row justify="center" align="middle" style={{ paddingTop: 20 }}>
                <Col xs={20} md={18} lg={18} xxl={18}>
                  <Row justify="center">
                    <Col span={15}>
                      <RequestPending accountid={accountid} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <Row justify="center">
                  <Col style={{ justifyContent: "center", paddingTop: 20 }}>
                    <Logo />
                  </Col>
                </Row>
                <Row
                  justify="center"
                  align="middle"
                  style={{
                    paddingTop: 20,
                    flexDirection: "column",
                    rowGap: 20,
                  }}
                >
                  <Col xs={20} md={18} lg={18} xxl={18}>
                    <Row justify="center">
                      <NewAccount
                        select={() => setStep(NEW)}
                        expand={step === NEW}
                        reset={() => setStep("")}
                      />
                    </Row>
                  </Col>
                  <Col xs={20} md={18} lg={18} xxl={18}>
                    <Row justify="center">
                      <RequestAccess
                        select={() => setStep(ACCESS)}
                        expand={step === ACCESS}
                        reset={() => setStep("")}
                        requestAccesss={handleRequestAccessState}
                        accountid={accountid}
                        getRequest={getRequest}
                      />
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <span
            style={{
              color: "#646C79",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "500",
              userSelect: "none",
              padding: "25px 0px",
              position: "relative",
            }}
          >
            {`Version ${
              process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : "0.1.0"
            }  © ${moment().year()} Blustream copyright all rights reserved.`}
          </span>
        </Row>
      </div>
      {/* for decline invite */}
      <Modal
        centered
        visible={showDeclineInviteModal}
        footer={null}
        className="decline-invite-modal"
        onCancel={() => {
          setShowDeclineInviteModal(false);
        }}
      >
        <div className="decline-invite-modal-content-wrapper">
          <img src={glowingBulbIcon} alt="" />
          <p className="decline-invite-confirmation-text">
            Are you sure you want to decline this invitation to join the
            organization?
          </p>
          <div className="decline-invite-buttons-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => {
                setShowDeclineInviteModal(false);
              }}
            >
              No
            </Button>
            <Button
              loading={declineInvitationLoader}
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                await declineInvitation(inviteHistory?.[0]?.id);
                setShowDeclineInviteModal(false);
              }}
            >
              Yes, Sure
            </Button>
          </div>
        </div>
      </Modal>
      {/* invite user modal */}
      <Modal
        title={null}
        visible={showInvitationModal}
        footer={null}
        className="invitation-modal"
        centered
        onCancel={() => {
          setShowInvitationModal(false);
        }}
      >
        <div className="invitation-modal-content-wrapper">
          <img src={groupIcon} alt="groupIcon" />
          <p className="invitation-title">
            {`${inviteHistory?.[0]?.invitedby}
                 has invited you to join`}
          </p>
          <p className="company-name">{invitedOrganizationDetails?.company}</p>

          <div className="invitation-buttons-container">
            <Button
              loading={acceptInvitationLoader}
              className="standard-button primary-orange-button"
              onClick={async () => {
                await acceptInvitation(inviteHistory?.[0]?.id);
              }}
            >
              Accept
            </Button>
            <Button
              loading={declineInvitationLoader}
              className="standard-button tertiary-button"
              onClick={async () => {
                setShowDeclineInviteModal(true);
                setShowInvitationModal(false);
              }}
            >
              Decline
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Selection;
