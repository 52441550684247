import API from "@aws-amplify/api";

// Get Stripe checkout session for an organization account
const getOrgCheckoutSession = async (
  stripePriceId,
  blustreamPriceId,
  priceQty,
  accountId,
  line_items,
  planName,
  late_start_timestamp
) => {
  let url = `/billing/checkout/org?stripePriceId=${stripePriceId}&blustreamPriceId=${blustreamPriceId}&priceQty=${priceQty}&accountId=${accountId}&line_items=${line_items}&planName=${
    planName ? planName : ""
  }&late_start_timestamp=${late_start_timestamp ? late_start_timestamp : ""}`;
  try {
    let result = await API.get("blustreamREST", url);
    return result;
  } catch (e) {
    console.log("error retrieving data", e);
    return { error: "Failed to fetch session" };
  }
};

// Get Stripe checkout session for an organization account
const getOrgMeteredUsage = async (accountId) => {
  let url = `/billing/getUsage?account_id=${accountId}`;

  try {
    let result = await API.get("blustreamREST", url);
    return result;
  } catch (e) {
    console.log("error retrieving data", e);
    return { error: "Failed to fetch session" };
  }
};

// Get Stripe checkout session for an organization account
const getPortalLink = async (account_id) => {
  let url = `/billing/portal?account_id=${account_id}`;

  try {
    let result = await API.get("blustreamREST", url);
    return result;
  } catch (e) {
    console.log("error retrieving data", e);
    return { error: "Failed to fetch session" };
  }
};

const sdk = {
  getOrgCheckoutSession,
  getOrgMeteredUsage,
  getPortalLink,
};

export default sdk;
