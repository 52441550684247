import { DoubleRightOutlined } from "@ant-design/icons";
import { Button, Drawer, Select } from "antd";
import React, { useState } from "react";
import AttributeIcon from "../../assets/Images/AttributeIcon.svg";

const { Option } = Select;

export default function AttributeDrawer() {
  const [attributeDrawer, setAttributeDrawer] = useState(false);

  const showDrawer = () => {
    setAttributeDrawer(true);
  };
  const onClose = () => {
    setAttributeDrawer(false);
  };

  return (
    <>
      <Button
        icon={<DoubleRightOutlined />}
        onClick={showDrawer}
        style={{
          position: "absolute",
          right: 0,
          top: 100,
          padding: 2,
          borderRadius: "7px 0 0 7px",
          width: "18px",
          background: "black",
          color: "white",
        }}
      />

      <Drawer
        className="attributeDrawer"
        width={316}
        title={
          <div>
            <h3
              style={{
                display: "flex",
                marginBottom: 7,
                alignItems: "center",
                color: "#FE867C",
              }}
            >
              <img
                src={AttributeIcon}
                style={{ marginRight: 5 }}
                alt="attribute library"
              />{" "}
              Attributes Library
            </h3>
            <Select
              defaultValue="Product global attibutes"
              style={{ width: "100%" }}
            >
              <Option value="1">Product global attibutes</Option>
              <Option value="2">Keyboard</Option>
              <Option value="3">Bass Guitar</Option>
              <Option value="4">Saxophone</Option>
              <Option value="5">Flute</Option>
              <Option value="6">Electric Guitar</Option>
              <Option value="7">Drum set</Option>
            </Select>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={attributeDrawer}
      >
        <div className="attributes">
          <Button>
            <h3>Terrarium Volume</h3>
          </Button>
          <Button>
            <h3>Material Type</h3>
          </Button>
          <Button>
            <h3>Weight</h3>
          </Button>
          <Button>
            <h3>Height</h3>
          </Button>
          <Button>
            <h3>Model</h3>
          </Button>
          <Button>
            <h3>Make</h3>
          </Button>
        </div>
      </Drawer>
    </>
  );
}
