import React from "react";
import { Typography, Button, Row, Col, Tooltip, Divider, Slider } from "antd";
import { CheckOutlined } from "@ant-design/icons";

//CSS import
import "../account/Account.css";
import "./setting.css";

// Images Import
import blueArrowCircle from "./../../assets/Images/blue-arrow-circle.svg";
import orangeArrowCircle from "./../../assets/Images/orange-arrow-circle.svg";
import infoIcon from "./../../assets/Images/info-icon.svg";
import stars_Image from "./../../assets/Images/3-star.svg";
import Lite from "assets/Images/basic-icon.svg";
import Standard from "assets/Images/plus-icon.svg";
import Enterprise from "assets/Images/premium-icon.svg";
import Premium from "assets/Images/advanced-icon.svg";
import freeplanIndicationIcon from "assets/Images/freeplanIndicationIcon.svg";
import noPlans from "assets/Images/noPlans.svg";
import noStatistics from "assets/Images/noStatistics.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const DesktopBilling = ({
  messageCostobject,
  switchState,
  touchpoint,
  accountInfo,
  touchpointObject,
  loader,
  selectedIndex,
  planDetails,
  accountDetails,
  billingList,
  onChange,
  toCommas,
  handlePlan,
  handleSlider,
  handleHere,
  sliderValue,
  cancelPlan,
  ShopifyprofileData,
}) => {
  const { roAdmin, groups } = useSelector((state) => state.loginReducer);
  const history = useHistory();
  const formatter = (value) => {
    return `Up to ${touchpoint[value]} Product ${
      switchState ? "Customers" : "Messages"
    }`;
  };
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <div style={{ background: "#EFF4F9", borderRadius: 7 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "18px 20px 13px 20px",
            }}
          >
            <Typography.Title
              style={{
                fontSize: 18,
                fontWeight: "700",
                color: "#252525",
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              Choose Your Plan
            </Typography.Title>
            {accountDetails && accountDetails?.planID === "0" && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <img
                  src={freeplanIndicationIcon}
                  alt="freeplanIndicationIcon"
                />
                <Typography.Text
                  style={{
                    color: "#3B84D1",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontStyle: "italic",
                  }}
                >
                  You are using a free plan ($0 , 250 msgs)
                </Typography.Text>

                <Button
                  className="standard-button secondary-orange-button"
                  disabled={roAdmin}
                  onClick={() => handleHere(accountDetails?.id)}
                >
                  Manage Billing Details
                </Button>
              </div>
            )}
          </div>
          <div
            className="divider-billing"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 20px 24px 20px",
            }}
          >
            <Divider
              style={{ display: "block", height: "2px", background: "#CDDFEF" }}
            />
          </div>
          <Row gutter={15} justify="center" style={{ paddingBottom: 24 }}>
            <Col
              span={18}
              xxl={18}
              xl={18}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="responsiveSlider"
            >
              <div style={{ padding: "0 30px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <div style={{ width: "31.8%" }}>
                    <span
                      style={{
                        color: "#124173",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      Lite
                    </span>
                    <p style={{ paddingTop: 6, maxWidth: "155px" }}>
                      Great for trials and companies with 1-1,000 customers
                    </p>
                  </div>
                  <div style={{ width: "31.8%" }}>
                    <span
                      style={{
                        color: "#124173",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      Standard
                    </span>
                    <p style={{ paddingTop: 6, maxWidth: "196px" }}>
                      Great for small & medium businesses looking to grow with
                      1,000 - 25,000 customers
                    </p>
                  </div>
                  <div style={{ width: "31.8%" }}>
                    <span
                      style={{
                        color: "#124173",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      Pro
                    </span>
                    <p style={{ paddingTop: 6, maxWidth: "241px" }}>
                      Great for medium to larger businesses looking to scale
                      widely with 25,000+ customers
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                  className="slider-wrapper"
                >
                  <div className="divider-col lite" style={{ width: "31.8%" }}>
                    <div
                      style={{
                        border: "1px solid #C5D5E5",
                        height: "10px",
                        borderBottom: "none",
                      }}
                    ></div>
                  </div>
                  <div
                    className="divider-col standard"
                    style={{ width: "31.8%" }}
                  >
                    <div
                      style={{
                        border: "1px solid #C5D5E5",
                        height: "10px",
                        borderBottom: "none",
                      }}
                    ></div>
                  </div>
                  <div className="divider-col pro" style={{ width: "31.8%" }}>
                    <div
                      style={{
                        border: "1px solid #C5D5E5",
                        height: "10px",
                        borderBottom: "none",
                      }}
                    ></div>
                  </div>
                </div>
                <Slider
                  className="slider-tab"
                  tooltipPlacement="bottom"
                  step={null}
                  tipFormatter={formatter}
                  marks={touchpoint}
                  min={0}
                  max={100}
                  onChange={(e) => handleSlider(e < 2 ? 2 : e > 85 ? 85 : e)}
                  value={sliderValue}
                />
              </div>
              <div
                className="message-cost-text"
                style={{
                  display: "flex",
                  gap: "20px 7px",
                  flexWrap: "wrap",
                  padding: "0px 24px",
                  marginTop: "76px",
                }}
              >
                {/* dev :- needed in future */}
                {/* <div style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
                  <div style={{display:"flex",alignItems:"center"}}>
                    <Typography.Text className={`switch-text ${switchState ? "":"switchToggle"}`}>Messages</Typography.Text>
                    <Switch value={switchState} onChange={onChange} style={{marginLeft: 7, background: '#124173', fontSize: 16}}/>
                    <Typography.Text style={{marginLeft: 7}} className={`switch-text ${switchState ?'switchToggle':""}`}>Customer</Typography.Text>
                    <Tooltip placement="bottom" title="Switch to see customer reach for the message range selected">
                    <InfoCircleOutlined style={{cursor: 'pointer',marginLeft: 7, color: '#252525', fontSize: "17px",lineHeight:"17px"}}/>
                    </Tooltip>
                  </div>
              </div> */}
                {touchpointObject?.length > 0 &&
                  touchpointObject?.map((plan) => {
                    return (
                      JSON.parse(plan?.limits)?.touchpoints <= 50000 && (
                        <>
                          <span style={{ fontSize: 16 }}>
                            <CheckOutlined
                              style={{ color: "green", fontSize: 16 }}
                            />{" "}
                            $
                            {
                              messageCostobject[
                                JSON.parse(plan.limits).touchpoints
                              ][0]
                            }{" "}
                            Associated cost/message
                          </span>
                          <span style={{ marginLeft: 7, fontSize: 16 }}>
                            <CheckOutlined
                              style={{ color: "green", fontSize: 16 }}
                            />{" "}
                            $
                            {
                              messageCostobject[
                                JSON.parse(plan.limits).touchpoints
                              ][1]
                            }{" "}
                            Overage rates/message
                          </span>
                        </>
                      )
                    );
                  })}
              </div>
            </Col>

            {touchpointObject &&
              touchpointObject.map((plan, index) => {
                const priceValues = plan && JSON.parse(plan?.priceValues);
                return (
                  <Col
                    span={6}
                    xxl={6}
                    xl={6}
                    lg={10}
                    md={10}
                    sm={24}
                    xs={24}
                    key={index}
                    className={plan?.name}
                  >
                    <div style={{ marginLeft: 15 }}>
                      <div
                        className="plan-details-header plan-details-text"
                        style={{ paddingLeft: "0" }}
                      >
                        <img
                          src={
                            plan?.name === "Lite"
                              ? Lite
                              : plan.name === "Enterprise"
                              ? Enterprise
                              : plan.name === "Standard"
                              ? Standard
                              : Premium
                          }
                          style={{ display: "flex" }}
                          alt=""
                          className="responsiveImg"
                        />
                        <div className="plan-details-title">{plan?.name}</div>
                      </div>

                      {plan?.name === "Pro" ? (
                        <span className="pro-plan-text">
                          {plan?.description}
                        </span>
                      ) : (
                        <div
                          className="plan-detail-price plan-details-text"
                          style={{
                            paddingTop: "12px",
                            alignItems: "flex-end",
                            color: "#2E2E2E",
                            marginBottom: "20px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "28px",
                              lineHeight: "34px",
                              fontWeight: "600",
                            }}
                          >
                            $
                            {priceValues &&
                              toCommas(
                                priceValues?.[plan?.stripePriceID[0]]?.value
                              )}
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              lineHeight: "17px",
                              fontWeight: "600",
                            }}
                          >
                            /
                            {priceValues &&
                              priceValues?.[plan?.stripePriceID[0]]
                                ?.billing_period}
                          </span>
                        </div>
                      )}

                      <div className="section-select-plan-btn">
                        <Tooltip
                          title={
                            !groups?.includes("admin") &&
                            (accountDetails?.source !== "shopify" ||
                              ShopifyprofileData?.length === 0) &&
                            accountInfo?.planID
                              ? parseInt(accountInfo?.planID || 0) <
                                parseInt(
                                  priceValues?.[plan?.stripePriceID[0]]?.value
                                )
                                ? ""
                                : "Connect blustream support to select this plan"
                              : ""
                          }
                        >
                          {plan?.name === "Pro" ? (
                            <Button
                              disabled={
                                roAdmin ||
                                (accountInfo?.planID &&
                                  parseInt(accountInfo?.planID || 0) >=
                                    parseInt(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    ))
                              }
                              block
                              size="large"
                              loading={loader && selectedIndex === index}
                              onClick={() => history.push("/support")}
                              className="primary-orange-button"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "12px 20px",
                                gap: "10px",
                                height: "40px",
                                borderRadius: "8px",
                                fontFamily: "Hellix",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "19px",
                                borderColor: "#f37064",
                                background: "#f37064",
                                color: "#ffffff",
                              }}
                            >
                              Contact Sales
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                roAdmin ||
                                parseInt(accountInfo?.planID) ===
                                  parseInt(
                                    priceValues?.[plan?.stripePriceID[0]]?.value
                                  ) ||
                                (!groups?.includes("admin") &&
                                  ShopifyprofileData?.length === 0 &&
                                  parseInt(accountInfo?.planID || 0) >=
                                    parseInt(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    )) ||
                                ((accountDetails?.source !== "shopify" ||
                                  parseInt(accountInfo?.planID || 0) ===
                                    parseInt(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    )) &&
                                  accountInfo?.planID &&
                                  parseInt(accountInfo?.planID || 0) >=
                                    parseInt(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    ))
                              }
                              className="primary-orange-button"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "12px 20px",
                                gap: "10px",
                                height: "40px",
                                borderRadius: "8px",
                                fontFamily: "Hellix",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "19px",
                                borderColor: "#f37064",
                                background: "#f37064",
                                color: "#ffffff",
                              }}
                              block
                              size="large"
                              loading={loader && selectedIndex === index}
                              onClick={() => handlePlan(plan, index)}
                            >
                              Select Plan
                            </Button>
                          )}
                        </Tooltip>
                      </div>
                      {!groups?.includes("admin") &&
                      ((ShopifyprofileData?.length === 0 &&
                        parseInt(accountInfo?.planID || 0) >=
                          parseInt(
                            priceValues?.[plan?.stripePriceID[0]]?.value
                          )) ||
                        (accountDetails?.source !== "shopify" &&
                          (roAdmin ||
                            (accountInfo?.planID &&
                              parseInt(accountInfo?.planID || 0) >=
                                parseInt(
                                  priceValues?.[plan?.stripePriceID[0]]?.value
                                ))))) ? (
                        <p className="contact-sales-text">
                          Please reach out to Blustream support team
                        </p>
                      ) : null}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      {(accountInfo?.accountStatus === "ACTIVE" ||
        accountInfo?.accountStatus === "LATE_START") &&
      planDetails ? (
        <div className="billing-tab-card-body" style={{ marginTop: 10 }}>
          <div className="background_image_container"></div>
          <Row>
            <Col span={24} xl={7}>
              <div className="my-current-plan">
                <div>
                  <Typography.Title
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#124173",
                      marginBottom: "19px",
                    }}
                  >
                    My Plan
                  </Typography.Title>

                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        style={{ height: "36px", width: "36px" }}
                        src={
                          planDetails?.name === "Lite"
                            ? Lite
                            : planDetails?.name === "Enterprise"
                            ? Enterprise
                            : planDetails?.name === "Standard"
                            ? Standard
                            : Premium
                        }
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "22px",
                          color: "#252525",
                          lineHeight: "22px",
                          fontWeight: 700,
                          marginBottom: "7px",
                        }}
                      >
                        {planDetails?.name}
                      </span>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#252525",
                          lineHeight: "19px",
                          marginBottom: "7px",
                        }}
                      >
                        $
                        {JSON.parse(planDetails?.priceValues)
                          ?.[planDetails?.stripePriceID[0]]?.value.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        /
                        {
                          JSON.parse(planDetails?.priceValues)?.[
                            planDetails?.stripePriceID[0]
                          ]?.billing_period
                        }
                      </span>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#69829B",
                          lineHeight: "19px",
                          marginBottom: "12px",
                        }}
                      >
                        Up to{" "}
                        {JSON.parse(planDetails?.limits)
                          ?.touchpoints.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        Product Messages
                      </span>
                      <div className="manage-billing-button-container">
                        {accountDetails?.shopifyPlanID?.length > 0 ? (
                          <Button
                            disabled={roAdmin}
                            className="standard-button secondary-orange-button"
                            onClick={() => cancelPlan()}
                          >
                            Cancel Plan
                          </Button>
                        ) : (
                          <Button
                            className="standard-button secondary-orange-button"
                            disabled={roAdmin}
                            onClick={() => handleHere(accountDetails?.id)}
                          >
                            Manage Billing Details
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} xl={1}>
              <div className="my-plan-vertical-divider">
                <Divider
                  type="vertical"
                  style={{
                    height: "100%",
                    border: "1px solid #C5D5E5",
                    margin: "0px",
                  }}
                />
              </div>
              <div className="my-plan-horizontal-divider">
                <Divider
                  style={{
                    width: "100%",
                    border: "1px solid #C5D5E5",
                    margin: "0px",
                  }}
                />
              </div>
            </Col>

            <Col span={24} xl={16}>
              <div
                className="statistics-container"
                style={{ paddingLeft: "25px" }}
              >
                <div>
                  <Typography.Title
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#124173",
                      textTransform: "capitalize",
                      marginBottom: "19px",
                    }}
                  >
                    My current statistics{" "}
                  </Typography.Title>
                </div>
                <div className="plan-statistics-container">
                  <Row>
                    <Col span={8}>
                      <div>
                        <Typography.Title
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#F37064",
                            lineHeight: "21px",
                            textTransform: "capitalize",
                            marginBottom: "7px",
                          }}
                        >
                          Last billing period
                        </Typography.Title>
                        {billingList?.period?.start &&
                        billingList?.period?.end ? (
                          <span
                            style={{
                              display: "inline-block",
                              marginBottom: "10px",
                            }}
                          >
                            {`${new Date(
                              billingList?.period?.start
                            ).getDate()}/${new Date(
                              billingList?.period?.start
                            ).getMonth()}/${new Date(billingList?.period?.start)
                              .getFullYear()
                              .toString()
                              .slice(-2)}`}{" "}
                            to{" "}
                            {`${new Date(
                              billingList?.period?.end
                            ).getDate()}/${new Date(
                              billingList?.period?.end
                            ).getMonth()}/${new Date(billingList?.period?.end)
                              .getFullYear()
                              .toString()
                              .slice(-2)}`}
                          </span>
                        ) : (
                          ""
                        )}
                        {/* needed*/}
                        {/* we will get epoc time from the billing list */}
                        {/* new Date(epocTime * 1000) ==> to convert epoc time to human readable dates */}
                        <div style={{ display: "flex" }}>
                          <div>
                            <img
                              style={{ height: "30px", width: "30px" }}
                              src={orangeArrowCircle}
                              alt=""
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "10px",
                              lineHeight: "22px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "24px",
                                color: "#252525",
                                fontWeight: "700",
                                marginBottom: "6px",
                              }}
                            >
                              {billingList[1]?.total_usage
                                ? billingList[1]?.total_usage
                                : 0}
                            </span>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  color: "#252525",
                                }}
                              >
                                Messages
                              </span>
                              <Tooltip
                                placement="bottom"
                                title="Messages sent or received by Blustream platform"
                                style={{ fontWeight: 600 }}
                              >
                                <img
                                  src={infoIcon}
                                  style={{
                                    marginLeft: "8px",
                                    height: "13px",
                                  }}
                                  alt=""
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div>
                        <Typography.Title
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#124173",
                            lineHeight: "21px",
                            textTransform: "capitalize",
                            marginBottom: "7px",
                          }}
                        >
                          This billing period
                        </Typography.Title>
                        {billingList?.period?.start ? (
                          <span
                            style={{
                              display: "inline-block",
                              marginBottom: "10px",
                            }}
                          >
                            since{" "}
                            {`${new Date(
                              billingList?.period?.start
                            ).getDate()}/${new Date(
                              billingList?.period?.start
                            ).getMonth()}/${new Date(billingList?.period?.start)
                              .getFullYear()
                              .toString()
                              .slice(-2)}`}
                          </span>
                        ) : (
                          ""
                        )}

                        <div style={{ display: "flex" }}>
                          <div>
                            <img
                              style={{ height: "30px", width: "30px" }}
                              src={blueArrowCircle}
                              alt=""
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "10px",
                              lineHeight: "22px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "24px",
                                color: "#252525",
                                fontWeight: "700",
                                marginBottom: "6px",
                              }}
                            >
                              {billingList[0]?.total_usage
                                ? billingList[0]?.total_usage
                                : 0}
                            </span>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  color: "#252525",
                                }}
                              >
                                Messages
                              </span>
                              <Tooltip
                                placement="bottom"
                                title="Messages sent or received by Blustream platform"
                                style={{ fontWeight: 600 }}
                              >
                                <img
                                  src={infoIcon}
                                  style={{
                                    marginLeft: "8px",
                                    height: "13px",
                                  }}
                                  alt=""
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-end",
                          height: "100%",
                        }}
                      >
                        {accountDetails?.source !== "shopify" && (
                          <Button
                            className="standard-button secondary-orange-button"
                            onClick={() => history.push("/")}
                          >
                            View more usage data
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    marginTop: "22px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {billingList[1]?.overagesSms && (
                    <div className="overage-text">
                      {`${
                        billingList[1]?.overagesSms
                          ? billingList[1]?.overagesSms
                          : ""
                      } Overraged messages last month!`}
                    </div>
                  )}
                  {billingList[0]?.total_usage >
                    (billingList[1]?.total_usage
                      ? billingList[1]?.total_usage
                      : 0) && (
                    <div className="greeting-text-container">
                      <img
                        src={stars_Image}
                        alt=""
                        height="20px"
                        width="20px"
                      />
                      <span className="greeting-text">
                        Congrats on your growth! Save money by upgrading your
                        plan
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="billing-tab-card-body" style={{ marginTop: 10 }}>
          <div className="background_image_container"></div>
          <Row>
            <Col span={24} xl={5}>
              <div className="my-current-plan">
                <div>
                  <Typography.Title
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#124173",
                      marginBottom: "19px",
                    }}
                  >
                    My Plan
                  </Typography.Title>

                  <div className="billing-no-plan-container">
                    <img src={noPlans} alt="" />
                    <p className="billing-no-plan-text">No plan selected!</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} xl={1}>
              <div className="my-plan-vertical-divider">
                <Divider
                  type="vertical"
                  style={{
                    height: "100%",
                    border: "1px solid #C5D5E5",
                    margin: "0px",
                  }}
                />
              </div>
              <div className="my-plan-horizontal-divider">
                <Divider
                  style={{
                    width: "100%",
                    border: "1px solid #C5D5E5",
                    margin: "0px",
                  }}
                />
              </div>
            </Col>

            <Col span={24} xl={16}>
              <div
                className="statistics-container"
                style={{ paddingLeft: "25px" }}
              >
                <div>
                  <Typography.Title
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#124173",
                      textTransform: "capitalize",
                      marginBottom: "19px",
                    }}
                  >
                    My current statistics{" "}
                  </Typography.Title>
                </div>
                <div className="plan-statistics-container">
                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <div className="billing-no-statistics-container">
                        <img src={noStatistics} alt="" />
                        <p className="billing-no-statistics-text">
                          No statistics found!
                        </p>
                      </div>
                    </Col>
                    <Col span={8}></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default DesktopBilling;
