import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popover, Row, Select, Modal } from "antd";
import React, { useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useSelector, useDispatch } from "react-redux";
import { setMicrositeDefaultStyle } from "../../../store/actions/SettingAction";
import { invertColor } from "../../../utility/LightenDarkenColor";

const { Option } = Select;

export default function TypographySettings() {
  const initialStyle = {
    // headingStyle: {
    //   fontFamily: "arial",
    //   fontSize: "23px",
    //   fontStyle: "normal",
    //   fontWeight: 700,
    //   color: "#000000",
    // },
    // subHeadingStyle: {
    //   fontFamily: "arial",
    //   fontSize: "14px",
    //   fontStyle: "normal",
    //   fontWeight: 400,
    //   color: "#000000",
    // },
    textStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    buttonStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      backgroundColor: "#ffffff",
      color: "#000000",
      height: "auto",
      borderColor: "#000000",
    },
    companyTextStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#000000",
    },
    errorMessageColor: "#ff0000",
  };

  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );

  const { roAdmin } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const [visible, setvisible] = useState(false);

  const handleCancel = () => {
    setvisible(false);
  };

  const content = (data) => {
    return (
      <div style={{ padding: "20px" }}>
        <HexColorPicker
          color={
            data ===
            // "heading"
            //   ? micrositeDefaultStyle?.headingStyle?.color
            //   : data === "subheading"
            //   ? micrositeDefaultStyle?.subHeadingStyle?.color
            //   : data ===
            "button-bg-color"
              ? micrositeDefaultStyle?.buttonStyle?.backgroundColor
              : data === "button-text-color"
              ? micrositeDefaultStyle?.buttonStyle?.color
              : data === "text"
              ? micrositeDefaultStyle?.textStyle?.color
              : data === "button-border-color"
              ? micrositeDefaultStyle?.buttonStyle?.borderColor
              : data === "error-message-color"
              ? micrositeDefaultStyle?.errorMessageColor
              : // : data === "companyText"
                // ? micrositeDefaultStyle?.companyTextStyle?.color
                "black"
          }
          onChange={(e) => handleSetColor(data, e)}
          style={{ border: "1px solid black", borderRadius: "8px" }}
        />
        <HexColorInput
          color={
            data ===
            // "heading"
            //   ? micrositeDefaultStyle?.headingStyle?.color
            //   : data === "subheading"
            //   ? micrositeDefaultStyle?.subHeadingStyle?.color
            //   : data ===
            "button-bg-color"
              ? micrositeDefaultStyle?.buttonStyle?.backgroundColor
              : data === "button-text-color"
              ? micrositeDefaultStyle?.buttonStyle?.color
              : data === "text"
              ? micrositeDefaultStyle?.textStyle?.color
              : data === "button-border-color"
              ? micrositeDefaultStyle?.buttonStyle?.borderColor
              : data === "error-message-color"
              ? micrositeDefaultStyle?.errorMessageColor
              : // : data === "companyText"
                // ? micrositeDefaultStyle?.companyTextStyle?.color
                "black"
          }
          style={{
            border: "1px solid black",
            borderRadius: "3px",
            marginTop: "5px",
            width: "100%",
            textAlign: "center",
          }}
          onChange={(e) => handleSetColor(data, e)}
          placeholder="Type a color"
        />
      </div>
    );
  };

  const handleSetColor = (data, e) => {
    // if (data === "heading") {
    //   dispatch(
    //     setMicrositeDefaultStyle({
    //       ...micrositeDefaultStyle,
    //       headingStyle: {
    //         ...micrositeDefaultStyle?.headingStyle,
    //         color: e,
    //       },
    //     })
    //   );
    // } else if (data === "subheading") {
    //   dispatch(
    //     setMicrositeDefaultStyle({
    //       ...micrositeDefaultStyle,
    //       subHeadingStyle: {
    //         ...micrositeDefaultStyle?.subHeadingStyle,
    //         color: e,
    //       },
    //     })
    //   );
    // } else
    if (data === "button-bg-color") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          buttonStyle: {
            ...micrositeDefaultStyle?.buttonStyle,
            backgroundColor: e,
          },
        })
      );
    } else if (data === "button-text-color") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          buttonStyle: {
            ...micrositeDefaultStyle?.buttonStyle,
            color: e,
          },
        })
      );
    } else if (data === "button-border-color") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          buttonStyle: {
            ...micrositeDefaultStyle?.buttonStyle,
            borderColor: e,
          },
        })
      );
    } else if (data === "error-message-color") {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          errorMessageColor: e,
        })
      );
    }
    // else if (data === "companyText") {
    //   dispatch(
    //     setMicrositeDefaultStyle({
    //       ...micrositeDefaultStyle,
    //       companyTextStyle: {
    //         ...micrositeDefaultStyle?.companyTextStyle,
    //         color: e,
    //       },
    //     })
    //   );
    // }
    else {
      dispatch(
        setMicrositeDefaultStyle({
          ...micrositeDefaultStyle,
          textStyle: {
            ...micrositeDefaultStyle?.textStyle,
            color: e,
          },
        })
      );
    }
  };

  return (
    <>
      <Form className="typography-form form-grid" layout="horizontal">
        {/* <Form.Item label="Heading">
          <Select
            value={micrositeDefaultStyle?.headingStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  headingStyle: {
                    ...micrositeDefaultStyle?.headingStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.headingStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  headingStyle: {
                    ...micrositeDefaultStyle?.headingStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={micrositeDefaultStyle?.headingStyle?.fontStyle === "Italic" ? micrositeDefaultStyle?.headingStyle?.fontStyle : micrositeDefaultStyle?.headingStyle?.fontWeight === 700 ? "Bold":"SemiB"}
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      headingStyle: {
                        ...micrositeDefaultStyle?.headingStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      headingStyle: {
                        ...micrositeDefaultStyle?.headingStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      headingStyle: {
                        ...micrositeDefaultStyle?.headingStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Subheading">
          <Select
            value={micrositeDefaultStyle?.subHeadingStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  subHeadingStyle: {
                    ...micrositeDefaultStyle?.subHeadingStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.subHeadingStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  subHeadingStyle: {
                    ...micrositeDefaultStyle?.subHeadingStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={micrositeDefaultStyle?.subHeadingStyle?.fontStyle === "Italic" ? micrositeDefaultStyle?.subHeadingStyle?.fontStyle : micrositeDefaultStyle?.subHeadingStyle?.fontWeight === 700 ? "Bold":"SemiB"}
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      subHeadingStyle: {
                        ...micrositeDefaultStyle?.subHeadingStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      subHeadingStyle: {
                        ...micrositeDefaultStyle?.subHeadingStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      subHeadingStyle: {
                        ...micrositeDefaultStyle?.subHeadingStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Company Text">
          <Select
            value={micrositeDefaultStyle?.companyTextStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  companyTextStyle: {
                    ...micrositeDefaultStyle?.companyTextStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.companyTextStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  companyTextStyle: {
                    ...micrositeDefaultStyle?.companyTextStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={micrositeDefaultStyle?.companyTextStyle?.fontStyle === "Italic" ? micrositeDefaultStyle?.companyTextStyle?.fontStyle : micrositeDefaultStyle?.companyTextStyle?.fontWeight === 700 ? "Bold":"SemiB"}
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      companyTextStyle: {
                        ...micrositeDefaultStyle?.companyTextStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      companyTextStyle: {
                        ...micrositeDefaultStyle?.companyTextStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      companyTextStyle: {
                        ...micrositeDefaultStyle?.companyTextStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item> */}

        <Form.Item className="tnc-label" label="Terms and Copyright">
          <Select
            value={micrositeDefaultStyle?.textStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  textStyle: {
                    ...micrositeDefaultStyle?.textStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.textStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-2"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  textStyle: {
                    ...micrositeDefaultStyle?.textStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={
              micrositeDefaultStyle?.textStyle?.fontStyle === "Italic"
                ? micrositeDefaultStyle?.textStyle?.fontStyle
                : micrositeDefaultStyle?.textStyle?.fontWeight === 700
                ? "Bold"
                : "SemiB"
            }
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      textStyle: {
                        ...micrositeDefaultStyle?.textStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      textStyle: {
                        ...micrositeDefaultStyle?.textStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      textStyle: {
                        ...micrositeDefaultStyle?.textStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
            disabled={roAdmin}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Button">
          <Select
            value={micrositeDefaultStyle?.buttonStyle?.fontFamily}
            style={{ width: "33%" }}
            className="Opt-1"
            onChange={(e) =>
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  buttonStyle: {
                    ...micrositeDefaultStyle?.buttonStyle,
                    fontFamily: e,
                  },
                })
              )
            }
            disabled={roAdmin}
          >
            <Option value="arial">Lato</Option>
            <Option value="Roboto">Roboto</Option>
          </Select>
          <Select
            value={micrositeDefaultStyle?.buttonStyle?.fontSize}
            style={{ width: "33%" }}
            className="Opt-2"
            onChange={(e) => {
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  buttonStyle: {
                    ...micrositeDefaultStyle?.buttonStyle,
                    fontSize: `${e}px`,
                  },
                })
              );
            }}
            disabled={roAdmin}
          >
            {[...Array(40).keys()].map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}px
                </Option>
              );
            })}
          </Select>
          <Select
            value={
              micrositeDefaultStyle?.buttonStyle?.fontStyle === "Italic"
                ? micrositeDefaultStyle?.buttonStyle?.fontStyle
                : micrositeDefaultStyle?.buttonStyle?.fontWeight === 700
                ? "Bold"
                : "SemiB"
            }
            style={{ width: "33%" }}
            className="Opt-3"
            onChange={(e) => {
              switch (e) {
                case "Italic":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      buttonStyle: {
                        ...micrositeDefaultStyle?.buttonStyle,
                        fontWeight: 400,
                        fontStyle: e,
                      },
                    })
                  );
                  break;
                case "SemiB":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      buttonStyle: {
                        ...micrositeDefaultStyle?.buttonStyle,
                        fontWeight: 500,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                case "Bold":
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      buttonStyle: {
                        ...micrositeDefaultStyle?.buttonStyle,
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
            }}
            disabled={roAdmin}
          >
            <Option value="SemiB">Semi B.</Option>
            <Option value="Bold">Bold</Option>
            <Option value="Italic">Italic</Option>
          </Select>
        </Form.Item>
      </Form>

      {/* <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Heading Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.headingStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.headingStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.headingStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("heading", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("heading")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Subheading Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.subHeadingStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.subHeadingStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.subHeadingStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("subheading", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("subheading")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Terms and Copyright Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.textStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.textStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.textStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("text", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("text")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Button Background Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.buttonStyle?.backgroundColor}
                style={{
                  background:
                    micrositeDefaultStyle?.buttonStyle?.backgroundColor,
                  color: `${invertColor(
                    micrositeDefaultStyle?.buttonStyle?.backgroundColor
                  )}`,
                }}
                onChange={(e) => handleSetColor("button-bg-color", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("button-bg-color")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Button Text Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.buttonStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.buttonStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.buttonStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("button-text-color", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("button-text-color")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Button Border Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                background: micrositeDefaultStyle?.buttonStyle?.borderColor,
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.buttonStyle?.borderColor}
                style={{
                  background: micrositeDefaultStyle?.buttonStyle?.borderColor,
                  color: `${invertColor(
                    micrositeDefaultStyle?.buttonStyle?.borderColor
                  )}`,
                }}
                onChange={(e) => handleSetColor("button-border-color", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("button-border-color")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Error Message Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.errorMessageColor}
                style={{
                  background: micrositeDefaultStyle?.errorMessageColor,
                  color: `${invertColor(
                    micrositeDefaultStyle?.errorMessageColor
                  )}`,
                }}
                onChange={(e) => handleSetColor("error-message-color", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("error-message-color")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: 10 }} className="typography-setting-wrapper">
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <h3 style={{ marginBottom: 0 }}>Company Text Color</h3>
        </Col>
        <Col span={12} xs={24} sm={12} lg={24} xl={24} xxl={12}>
          <Row>
            <Col
              span={18}
              style={{
                borderRadius: "7px 0 0 7px",
              }}
            >
              <HexColorInput
                className="colorpicker-input"
                color={micrositeDefaultStyle?.companyTextStyle?.color}
                style={{
                  background: micrositeDefaultStyle?.companyTextStyle?.color,
                  color: `${invertColor(
                    micrositeDefaultStyle?.companyTextStyle?.color
                  )}`,
                }}
                onChange={(e) => handleSetColor("companyText", e)}
                disabled={roAdmin}
                placeholder="Type a color"
              />
            </Col>
            <Col span={6}>
              <Popover
                trigger={roAdmin ? "" : "hover"}
                content={() => content("companyText")}
              >
                <Button
                  style={{
                    background: "#124173",
                    border: "none",
                    color: "white",
                    width: "100%",
                    borderRadius: "0 7px 7px 0",
                  }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row style={{ marginTop: 20 }} className="typography-setting-wrapper">
        <Col span={24}>
          <Button
            className="standard-button primary-dark-blue-button"
            onClick={() => {
              setvisible(true);
            }}
            disabled={roAdmin}
          >
            Reset Typography
          </Button>
        </Col>
      </Row>
      <Modal
        visible={visible}
        footer={null}
        className="btn-modal"
        onCancel={handleCancel}
      >
        <div className="form-delete-modal">
          <p className="delete-modal-text">
            Are you sure you want to reset Typography?
          </p>
          <div className="delete-modal-button-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => handleCancel()}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                dispatch(
                  setMicrositeDefaultStyle({
                    ...micrositeDefaultStyle,
                    // headingStyle: initialStyle?.headingStyle,
                    // subHeadingStyle: initialStyle?.subHeadingStyle,
                    textStyle: initialStyle?.textStyle,
                    buttonStyle: initialStyle?.buttonStyle,
                    // companyTextStyle: initialStyle?.companyTextStyle,
                    errorMessageColor: initialStyle?.errorMessageColor,
                  })
                );
                handleCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
