import { Checkbox, Form, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMicrositeDefaultStyle } from "store/actions/SettingAction";

const HideForm = ({ popupForm }) => {
  const dispatch = useDispatch();
  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );

  const [isPermanentHide, setIsPermanentHide] = useState(
    micrositeDefaultStyle?.permenantHideOnSubmit
  );
  const [isTemporaryHide, setIsTemporaryHide] = useState(
    micrositeDefaultStyle?.temporaryHideOnPopupClose
  );
  const [hidingDays, setHidingDays] = useState(
    micrositeDefaultStyle?.numberOfHidingDays
  );

  useEffect(() => {
    popupForm.setFieldsValue({
      days: micrositeDefaultStyle?.numberOfHidingDays,
    });
  }, []);

  return (
    <div>
      <div className="hide-form-content-wrapper">
        <div className="permanent-hide-form">
          <Checkbox
            checked={isPermanentHide}
            onChange={(event) => {
              console.log(event.target.checked);
              setIsPermanentHide(event.target.checked);
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  permenantHideOnSubmit: event.target.checked,
                })
              );
            }}
          >
            Hide on submit
          </Checkbox>
        </div>
        <div className="temporary-hide-form">
          <Checkbox
            checked={isTemporaryHide}
            onChange={(event) => {
              console.log(event.target.checked);
              setIsTemporaryHide(event.target.checked);
              dispatch(
                setMicrositeDefaultStyle({
                  ...micrositeDefaultStyle,
                  temporaryHideOnPopupClose: event.target.checked,
                })
              );
            }}
          >
            Prevent from showing again after close
          </Checkbox>
        </div>
        {isTemporaryHide && (
          <div>
            <Form form={popupForm}>
              <Form.Item
                label="Wait time to show again (days)"
                name="days"
                rules={[
                  {
                    required: true,
                    message: "Please enter days!",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "120px" }}
                  placeholder="Min 1 day"
                  value={hidingDays}
                  min={1}
                  onChange={(value) => {
                    setHidingDays(value);
                    dispatch(
                      setMicrositeDefaultStyle({
                        ...micrositeDefaultStyle,
                        numberOfHidingDays: value,
                      })
                    );
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default HideForm;
